import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M3060 4996 c-3 -2 -46 -7 -96 -10 -292 -19 -476 -71 -583 -163 -22
-18 -44 -33 -49 -33 -14 0 -133 -143 -188 -227 -50 -73 -108 -191 -118 -239
-6 -27 -12 -33 -41 -36 -22 -2 -41 2 -55 14 -12 10 -28 18 -35 18 -33 0 -225
-219 -225 -257 l0 -25 -30 25 c-16 14 -45 31 -65 37 -30 10 -41 9 -72 -7 l-37
-20 -54 44 c-111 90 -287 137 -564 150 -71 4 -98 2 -98 -7 0 -6 15 -30 34 -53
40 -49 63 -105 111 -268 79 -268 226 -481 385 -560 36 -18 76 -35 90 -39 26
-7 26 -9 14 -81 -5 -29 -1 -41 21 -67 15 -18 36 -32 48 -32 12 0 34 -13 49
-29 24 -25 26 -32 16 -58 -16 -44 -4 -80 31 -88 16 -3 48 -23 72 -43 37 -32
51 -37 113 -43 74 -7 121 6 133 37 8 20 23 17 23 -5 0 -12 9 -20 28 -24 46 -9
99 -58 106 -97 3 -19 13 -136 21 -260 26 -409 25 -378 10 -350 -15 26 -26 75
-46 205 -6 44 -16 85 -21 92 -5 6 -6 34 -2 62 13 99 -46 202 -163 282 -47 33
-71 36 -117 14 -32 -15 -35 -14 -139 34 -152 71 -313 106 -434 96 -87 -8 -213
-44 -213 -62 0 -7 8 -13 18 -13 60 0 267 -142 439 -301 122 -114 183 -157 350
-251 162 -90 228 -146 299 -253 l55 -82 15 -139 c20 -177 23 -690 6 -859 -16
-148 -80 -571 -92 -605 l-8 -25 -1 25 c-1 14 -13 75 -27 135 -14 61 -32 142
-40 180 -8 39 -27 97 -44 130 -24 49 -32 81 -41 182 l-12 122 56 53 c75 72 86
111 79 283 -5 136 -12 163 -55 204 -21 19 -32 22 -54 16 -15 -5 -34 -7 -41 -6
-27 4 -64 -32 -89 -86 -22 -48 -25 -64 -19 -117 4 -33 8 -95 9 -137 2 -71 5
-81 40 -132 29 -42 41 -71 47 -119 16 -109 11 -106 -39 17 -56 140 -116 262
-214 434 l-70 124 13 119 c14 137 7 254 -19 331 -34 98 -116 246 -168 302 -10
11 -40 46 -66 77 -52 62 -212 187 -239 188 -25 0 -33 -68 -31 -245 1 -140 5
-174 32 -280 28 -112 134 -279 222 -352 19 -15 37 -33 42 -40 4 -6 40 -30 80
-52 80 -44 99 -62 144 -136 26 -43 124 -220 162 -292 19 -37 5 -26 -20 14 -61
101 -179 200 -269 225 -220 60 -316 76 -396 66 -82 -11 -249 -69 -296 -104
-44 -33 -116 -94 -143 -121 -25 -26 -85 -137 -85 -158 0 -8 15 -22 33 -31 17
-9 34 -21 37 -25 5 -8 115 -31 200 -42 61 -8 231 21 332 56 140 50 337 75 461
59 122 -15 184 -37 201 -71 16 -30 106 -256 106 -265 0 -3 -21 -6 -48 -6 -26
0 -79 -9 -117 -21 -115 -34 -112 -34 -155 -4 -53 37 -126 45 -159 18 -13 -10
-29 -37 -37 -58 -19 -54 -19 -225 0 -250 14 -19 15 -19 35 5 11 14 20 28 21
31 0 16 118 60 183 71 99 15 169 -1 250 -56 72 -49 153 -145 167 -196 19 -75
29 -217 19 -289 -12 -83 -8 -167 6 -164 6 1 13 35 17 75 10 104 34 270 73 518
19 118 41 271 49 340 8 69 24 150 34 180 42 122 170 346 229 402 50 48 188 62
284 29 84 -28 227 -104 299 -157 88 -64 196 -169 210 -205 17 -40 30 -36 31 9
1 20 3 53 5 72 4 48 -33 262 -59 335 -11 33 -33 76 -49 95 -15 19 -28 40 -28
47 0 18 -81 94 -116 109 -46 20 -124 27 -172 16 -36 -8 -41 -8 -32 3 28 33
240 190 285 211 61 28 262 64 357 64 58 0 101 -10 266 -64 108 -36 224 -69
257 -75 33 -5 146 -12 250 -16 105 -3 232 -8 283 -11 106 -7 111 -3 64 62 -16
22 -31 44 -33 50 -9 23 -98 123 -155 175 -96 86 -266 163 -418 190 -156 27
-397 -7 -541 -77 -107 -51 -226 -134 -300 -207 -39 -40 -114 -103 -166 -141
l-94 -68 70 73 c126 131 195 225 195 265 0 11 11 31 24 43 37 35 66 113 69
183 l2 63 63 62 c35 35 83 74 106 88 l43 25 64 -16 c111 -28 276 -5 408 57 95
45 241 193 215 219 -15 15 -124 40 -199 46 -110 10 -250 -24 -327 -78 -24 -17
10 22 78 88 187 183 253 301 295 524 7 36 13 79 15 95 1 17 5 53 9 81 8 68
-18 154 -47 154 -29 0 -155 -65 -213 -110 -88 -69 -211 -202 -243 -265 -88
-172 -100 -228 -105 -525 l-3 -165 -73 -110 c-41 -60 -78 -114 -84 -120 -6 -7
-8 11 -4 52 8 78 -6 123 -38 123 -13 0 -27 5 -30 10 -4 6 -24 19 -46 30 -25
13 -40 29 -44 45 -3 14 -7 36 -10 50 -12 55 -115 83 -180 49 -16 -9 -64 -30
-105 -46 -182 -73 -280 -197 -331 -418 -26 -112 -31 -314 -11 -395 11 -44 11
-55 -2 -80 -30 -56 -16 -153 29 -215 l25 -34 -24 -25 c-45 -49 -57 -86 -55
-169 l3 -77 -59 -89 c-32 -50 -79 -131 -104 -181 l-46 -91 3 194 c3 191 -5
351 -32 647 -10 102 -12 174 -6 225 25 218 132 413 338 619 255 254 709 594
867 647 91 32 36 47 -211 59 -81 4 -124 1 -159 -9 l-47 -15 7 57 c4 31 13 71
22 87 21 42 53 146 53 172 0 12 -5 25 -11 29 -6 3 -9 19 -5 34 4 24 -4 37 -53
89 -52 54 -62 61 -97 61 l-39 0 37 23 c64 39 217 222 244 292 70 183 105 370
101 535 l-2 65 -45 3 c-25 2 -48 1 -50 -2z m75 -133 c-4 -65 -10 -136 -15
-158 -5 -22 -14 -67 -20 -100 -20 -96 -61 -204 -99 -259 -62 -90 -161 -200
-203 -225 -39 -24 -41 -24 -54 -6 -12 16 -27 18 -181 13 -92 -2 -148 -1 -123
3 25 4 74 8 110 9 36 1 83 5 105 9 68 14 -137 12 -232 -3 -76 -11 -83 -11 -83
5 0 16 46 36 58 24 3 -3 -2 -5 -11 -5 -10 0 -17 -5 -17 -11 0 -6 8 -8 18 -5 9
3 46 10 81 15 74 12 89 20 40 23 -19 0 2 4 46 9 44 4 110 5 148 2 46 -4 67 -2
67 5 0 7 -36 13 -102 15 -65 2 -49 3 44 3 96 -1 144 2 137 9 -14 14 -308 13
-317 -1 -4 -6 -17 -15 -30 -19 -29 -9 -174 -13 -164 -4 6 5 153 39 247 56 l40
7 -35 6 c-60 10 126 22 242 16 64 -3 94 -2 78 3 -50 18 -313 12 -421 -9 -57
-11 -105 -18 -107 -15 -12 12 106 38 288 64 61 8 57 9 -52 8 -64 -1 -120 2
-124 6 -4 4 7 7 24 7 18 0 32 5 32 11 0 8 -15 9 -47 4 l-48 -6 20 20 c11 11
34 22 50 24 17 3 22 2 13 0 -10 -2 -18 -8 -18 -14 0 -9 65 -3 130 11 32 7 29
8 -25 10 l-60 3 50 7 c28 5 77 9 110 9 33 1 67 6 75 12 15 10 -136 6 -221 -6
l-34 -5 35 10 c19 5 58 13 85 18 l50 10 -45 2 -45 3 45 8 c25 4 61 7 80 7 l35
0 -30 -8 c-21 -5 -2 -8 61 -9 50 0 119 -4 153 -7 43 -5 61 -3 61 5 0 7 -21 11
-59 11 -32 0 -73 3 -92 7 -29 5 -30 7 -14 15 19 8 -176 3 -240 -7 -16 -2 -21
-2 -10 1 38 11 42 27 5 20 -31 -6 -33 -5 -20 8 16 17 107 35 97 20 -3 -5 72
-9 178 -9 123 0 181 3 174 10 -6 6 -79 10 -177 10 -138 -1 -155 1 -97 8 39 4
105 7 148 5 42 -2 77 0 77 4 0 10 -251 9 -313 -1 l-48 -8 27 28 c21 22 40 30
86 35 33 4 65 13 73 20 10 10 47 14 142 14 86 0 123 3 113 10 -8 5 -43 10 -78
10 -40 0 -61 4 -57 10 4 6 -15 10 -49 10 -56 0 -85 -15 -44 -23 13 -2 -2 -5
-33 -6 -40 -1 -59 -5 -63 -16 -3 -8 -13 -15 -23 -15 -13 0 -9 8 18 36 l36 36
124 6 c120 5 123 6 79 18 l-45 12 65 3 65 3 -85 6 -85 6 68 2 c73 2 93 21 25
24 -29 1 -18 4 32 8 l75 6 -90 3 c-62 2 -82 5 -65 11 l25 8 -25 1 c-36 2 -4
21 35 21 23 0 27 -3 15 -10 -11 -7 -4 -10 25 -10 37 1 39 2 21 15 -18 14 -17
15 25 17 37 1 34 2 -21 8 -67 6 -65 13 5 26 30 6 34 9 17 12 l-22 3 22 19 c31
26 16 25 -35 -2 -54 -28 -65 -30 -77 -10 -8 14 -9 13 -10 -9 0 -15 -6 -32 -14
-38 -12 -10 -15 -8 -18 11 -2 13 -7 22 -13 20 -5 -2 -12 5 -16 15 -6 13 -8 9
-8 -14 -1 -18 4 -33 9 -33 6 0 10 -9 10 -19 0 -11 -4 -23 -10 -26 -5 -3 -10 3
-10 14 0 38 -20 33 -21 -6 -1 -45 -5 -36 -14 30 -3 26 -11 47 -16 47 -7 0 -8
-12 -4 -31 5 -22 4 -29 -4 -25 -7 4 -11 -1 -11 -13 0 -12 5 -21 10 -21 6 0 10
-11 10 -25 0 -25 -19 -36 -21 -12 0 6 -4 3 -9 -8 -6 -15 -9 -6 -10 35 -3 101
-19 112 -18 13 1 -51 -1 -95 -4 -98 -3 -4 -9 26 -13 65 -5 47 -12 73 -21 77
-8 3 -14 11 -14 18 0 8 -5 17 -11 21 -8 4 -9 -5 -5 -32 3 -22 9 -61 13 -89 l7
-50 7 40 c6 36 6 34 8 -21 1 -55 -2 -63 -29 -89 l-30 -29 1 45 c1 40 2 42 9
14 7 -27 8 -25 9 18 1 31 -4 54 -14 63 -8 9 -15 26 -15 39 0 13 -5 27 -11 31
-8 5 -10 -3 -6 -27 15 -82 19 -186 8 -193 -7 -5 -11 4 -11 26 0 41 -37 209
-48 220 -10 10 -9 -109 1 -187 5 -37 4 -44 -3 -25 -7 18 -8 7 -5 -42 6 -79
-10 -100 -19 -25 -14 103 -36 191 -51 200 -8 4 -15 17 -15 27 0 10 -4 22 -10
25 -14 9 -12 -21 5 -85 8 -30 14 -64 14 -75 0 -11 -6 4 -13 33 -24 95 -35 79
-15 -21 11 -55 22 -70 32 -44 3 6 6 -2 6 -20 1 -17 4 -40 8 -50 6 -16 8 -15
14 7 7 23 7 23 8 -7 1 -17 3 -38 6 -45 3 -8 -4 -27 -17 -43 l-22 -29 -7 25
c-3 13 -11 23 -17 21 -7 -1 -12 7 -12 19 0 11 5 17 10 14 6 -4 10 6 10 24 0
33 -16 51 -22 24 -3 -10 -8 6 -12 35 -4 28 -12 52 -17 52 -5 0 -9 -6 -10 -12
0 -7 -7 15 -16 50 -8 34 -19 62 -24 62 -6 0 -8 -8 -5 -17 13 -47 46 -225 46
-252 0 -41 18 -49 22 -9 3 25 5 20 8 -21 4 -48 2 -58 -24 -93 -15 -21 -31 -36
-35 -32 -3 4 0 9 7 11 10 4 11 23 6 81 -6 76 -22 131 -25 87 0 -11 -7 11 -15
49 -15 67 -41 132 -50 124 -2 -3 1 -19 7 -37 6 -17 8 -39 5 -48 -8 -21 25
-273 36 -273 16 0 7 -36 -17 -65 -18 -22 -24 -25 -25 -13 0 9 -3 24 -7 34 -5
13 -3 15 7 9 11 -7 12 6 7 66 -8 95 -17 139 -28 139 -5 0 -7 -10 -4 -22 3 -13
1 -54 -4 -93 -4 -38 -9 -59 -10 -45 -1 14 -4 36 -8 50 l-7 25 -7 -25 c-7 -23
-7 -23 -8 7 -1 17 4 33 10 35 7 2 8 14 1 38 l-10 35 -2 -30 c-1 -22 -6 -12
-17 35 -8 36 -19 69 -24 74 -13 14 -11 -8 5 -79 18 -80 13 -215 -9 -228 -21
-11 -21 -10 -28 38 -3 22 -3 32 -1 22 7 -22 23 -13 19 11 -17 82 -28 122 -35
122 -4 0 -6 -12 -4 -27 3 -23 2 -22 -7 6 -19 62 -22 23 -4 -67 18 -97 17 -133
-4 -140 -11 -4 -12 3 -7 30 7 36 -9 131 -26 158 -6 10 -8 4 -4 -22 2 -20 1
-38 -4 -40 -4 -2 -2 -33 5 -71 9 -47 9 -69 2 -74 -6 -4 -13 -1 -15 6 -4 11 -6
11 -13 0 -6 -10 -9 -7 -9 11 -1 28 -28 94 -29 70 0 -8 6 -44 14 -80 8 -36 14
-83 14 -105 l-1 -40 -8 32 c-4 18 -12 39 -17 45 -32 45 -38 128 -13 177 5 9
20 41 35 71 49 100 221 330 247 330 5 0 30 18 56 39 108 89 286 139 566 157
50 4 93 8 95 10 2 2 22 4 43 4 l39 0 -6 -117z m-1184 -641 c10 -28 20 -77 23
-109 l5 -58 -10 50 c-6 28 -15 55 -21 60 -7 7 -9 2 -5 -15 22 -95 39 -321 35
-467 -1 -90 0 -163 5 -163 8 0 8 -8 16 160 l8 135 3 -110 c2 -94 4 -103 11
-65 16 87 -14 471 -46 575 -14 47 -11 55 16 55 37 0 49 -41 56 -181 6 -132 21
-154 22 -34 1 39 4 55 10 46 17 -27 40 -3 47 50 4 31 15 60 28 74 31 34 113
78 121 65 10 -17 45 -148 45 -171 0 -11 -45 -104 -99 -207 -93 -176 -140 -286
-127 -299 3 -3 13 13 22 36 9 22 21 41 28 41 6 0 43 62 81 137 83 162 83 162
60 143 -12 -10 -9 1 11 37 16 28 33 49 37 46 16 -10 37 -71 37 -107 0 -33 -43
-100 -112 -176 -13 -14 -20 -21 -15 -16 4 6 7 17 7 25 -1 46 -87 -109 -119
-213 -25 -81 -25 -86 -6 -86 10 0 15 -10 15 -31 0 -17 -4 -28 -10 -24 -5 3
-10 15 -10 25 0 10 -6 20 -12 22 -10 4 -7 24 10 83 13 42 21 79 18 82 -5 6
-46 -103 -46 -123 0 -9 -6 -14 -12 -11 -7 2 -12 16 -11 31 1 16 -3 26 -11 26
-10 0 -13 -23 -10 -97 2 -65 0 -92 -6 -82 -6 10 -10 44 -11 78 -1 44 -5 61
-15 61 -8 0 -14 -5 -14 -12 0 -6 -3 -8 -7 -5 -3 4 -12 2 -19 -4 -9 -8 -12 -25
-7 -57 8 -66 -6 -46 -18 25 -6 32 -12 73 -16 91 -3 17 -11 32 -18 32 -18 0
-26 -25 -12 -39 9 -9 9 -14 -2 -20 -10 -7 -12 -21 -6 -57 12 -80 13 -126 3
-116 -5 5 -12 37 -15 71 -5 52 -8 61 -23 56 -12 -4 -22 6 -36 36 -26 58 -74
132 -81 126 -3 -4 9 -32 27 -64 18 -32 28 -53 23 -48 -16 18 -79 99 -89 115
-11 18 -14 170 -3 170 8 0 113 -190 124 -225 4 -11 -14 17 -38 63 -49 90 -54
98 -63 90 -9 -9 87 -178 101 -178 7 0 16 -10 20 -22 l8 -23 4 23 c5 24 -74
191 -133 284 -19 29 -34 64 -34 78 0 22 4 19 36 -25 20 -28 45 -69 56 -92 10
-24 23 -43 28 -43 5 0 12 -11 16 -25 6 -24 24 -35 24 -15 0 26 -46 140 -72
178 -29 42 -45 55 -34 27 9 -24 -1 -18 -25 16 -28 39 -40 92 -29 130 9 32 64
100 149 185 50 49 56 53 75 41 11 -8 27 -37 37 -65z m-891 5 c100 -14 129 -22
245 -69 50 -21 135 -83 135 -99 0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 24 -20
17 -21 -7 -1 -18 -2 -19 -6 -5 -2 9 -9 15 -14 12 -5 -4 -9 0 -9 7 0 9 -3 9
-10 -2 -7 -12 -10 -8 -11 15 0 25 -2 22 -13 -18 -15 -54 -26 -71 -26 -39 0 16
-4 20 -16 15 -15 -5 -16 0 -10 40 5 31 4 47 -3 47 -7 0 -12 -33 -12 -87 -1
-65 -4 -79 -9 -53 -4 19 -8 31 -10 25 -1 -5 -8 13 -15 40 -7 28 -14 41 -16 30
-1 -11 -5 3 -9 30 l-7 50 -2 -70 c-2 -52 -5 -64 -11 -45 -8 25 -8 25 -9 2 -1
-14 -6 -22 -13 -20 -8 3 -12 22 -11 49 1 43 1 43 -7 9 -7 -31 -8 -29 -9 25 -1
48 -4 56 -12 42 -6 -10 -9 -36 -7 -58 3 -34 2 -37 -11 -24 -8 8 -17 40 -19 75
-5 64 -21 73 -23 12 l-1 -37 -8 30 c-8 30 -8 30 -9 -7 0 -21 -4 -38 -8 -38 -5
0 -6 23 -4 51 2 36 0 49 -8 45 -6 -4 -11 -16 -11 -27 0 -31 -20 -22 -28 14
l-7 32 -6 -40 -6 -40 -2 38 c0 20 -6 37 -12 37 -7 0 -9 -12 -6 -32 3 -18 3
-27 0 -20 -2 6 -8 10 -13 7 -4 -2 -11 10 -15 28 -7 29 -8 30 -12 9 -4 -22 -5
-23 -18 -5 -9 11 -14 13 -15 5 0 -10 -5 -10 -20 -2 -27 14 -26 -4 1 -23 14
-10 17 -17 9 -22 -6 -4 -12 -5 -14 -4 -1 2 -17 24 -36 48 l-34 44 90 -6 c49
-3 136 -12 194 -20z m1306 -116 c-3 -4 78 -7 182 -5 103 2 180 1 172 -2 -8 -4
-28 -16 -45 -28 -16 -12 -55 -26 -85 -32 -30 -6 -62 -13 -70 -16 -8 -3 -12 -3
-7 -1 22 13 -13 23 -78 23 -71 0 -103 11 -69 24 24 9 42 6 19 -4 -25 -11 90
-12 155 -1 38 6 26 8 -75 14 -66 5 -121 10 -123 12 -6 6 8 25 19 25 6 0 8 -4
5 -9z m-1459 -54 c22 -30 48 -68 59 -85 10 -18 22 -32 26 -32 4 0 22 -28 39
-62 17 -34 39 -73 49 -87 9 -14 43 -65 74 -112 57 -89 189 -239 209 -239 7 0
23 -9 36 -19 19 -15 22 -22 13 -37 -9 -17 -12 -16 -29 14 -10 17 -22 30 -28
29 -5 -2 -16 3 -23 12 -11 14 -12 14 -10 -5 3 -20 -1 -24 -21 -24 -5 0 -14 14
-22 32 -10 26 -14 28 -19 13 -4 -13 -12 -1 -24 41 -22 71 -42 96 -32 41 4 -21
9 -46 12 -55 2 -9 -5 1 -16 23 l-20 40 5 -32 c6 -45 -11 -42 -26 4 -10 32 -13
35 -19 18 -5 -14 -10 -2 -14 40 -10 88 -13 96 -16 40 -3 -72 -17 -21 -20 70
-2 76 -5 76 -16 0 -3 -27 -9 -44 -11 -37 -3 6 -9 10 -14 6 -5 -3 -10 19 -10
48 -1 46 -2 49 -10 23 -6 -22 -7 -10 -6 43 2 39 -1 72 -5 72 -4 0 -8 -9 -8
-19 0 -55 -29 -63 -31 -8 0 28 -2 29 -10 12 -6 -14 -7 -7 -3 24 5 34 3 42 -6
36 -10 -6 -12 1 -6 34 4 28 3 40 -4 36 -5 -3 -10 -12 -10 -20 0 -8 -3 -16 -8
-19 -4 -2 -6 14 -5 37 2 28 0 36 -5 23 -6 -14 -14 -1 -33 58 -14 42 -24 76
-24 76 1 0 20 -24 42 -53z m683 13 c59 -30 84 -72 82 -138 -1 -30 3 -62 8 -71
5 -9 5 -23 0 -30 -6 -10 -12 -6 -23 16 -21 40 -108 146 -114 139 -3 -3 17 -36
44 -73 48 -65 78 -113 70 -113 -2 0 -18 21 -36 46 -50 69 -50 44 0 -31 30 -45
45 -61 51 -53 5 8 6 -6 3 -32 -8 -55 5 -90 53 -149 20 -25 31 -42 24 -37 -6 4
-12 4 -12 0 0 -13 42 -74 51 -74 6 0 7 5 4 10 -3 6 1 10 11 10 14 0 14 -3 4
-15 -10 -12 -10 -21 -2 -38 18 -38 24 -67 13 -67 -6 0 -11 14 -11 30 0 20 -5
30 -15 30 -19 0 -19 -4 1 -69 11 -38 12 -57 5 -64 -8 -8 -11 -2 -11 21 0 18
-5 32 -10 32 -23 0 -20 -31 9 -95 17 -38 30 -70 27 -72 -14 -14 -127 201 -125
239 0 7 5 1 11 -14 5 -16 14 -28 18 -28 20 0 -27 115 -113 280 -75 144 -102
186 -153 238 -34 34 -69 62 -78 62 -19 0 -19 1 -6 34 6 17 19 27 43 31 19 4
54 20 78 36 52 34 50 34 99 9z m1180 -6 c0 -2 -22 -32 -49 -67 -27 -34 -85
-122 -130 -194 -45 -73 -118 -174 -162 -225 -43 -51 -79 -98 -79 -105 0 -6
-17 -25 -37 -42 -37 -31 -41 -51 -6 -32 38 19 220 218 271 295 51 76 60 104
26 75 -25 -21 85 153 141 223 66 82 82 84 145 16 63 -68 66 -96 10 -121 -23
-11 -87 -66 -148 -128 -59 -60 -148 -144 -199 -187 -51 -42 -88 -79 -82 -81 6
-1 19 5 29 14 10 9 24 14 30 10 6 -4 37 13 68 36 67 50 276 258 268 267 -3 3
-14 -1 -24 -9 -10 -8 0 3 22 24 80 73 107 61 78 -34 -11 -35 -27 -77 -36 -94
-9 -16 -16 -41 -16 -55 -1 -60 -9 -70 -59 -70 -55 0 -106 -23 -252 -112 -58
-35 -151 -84 -207 -107 -56 -24 -100 -46 -97 -51 2 -4 -7 -12 -21 -19 -32 -14
-34 -14 -34 3 0 7 14 19 31 27 42 17 153 99 145 107 -4 3 -32 -12 -64 -35 -31
-22 -67 -45 -79 -52 -21 -11 -23 -10 -23 18 0 21 18 52 65 110 66 82 75 98 53
85 -7 -4 4 11 24 32 47 50 172 294 150 294 -5 0 -26 -36 -47 -80 -38 -77 -61
-91 -48 -28 3 17 -1 37 -11 54 -19 28 -11 48 24 59 11 3 33 29 51 58 40 64 53
74 109 83 25 3 65 19 89 35 34 22 48 26 62 18 11 -5 19 -12 19 -15z m-1343
-170 c40 -38 41 -40 5 -10 -64 55 -71 43 -11 -22 30 -32 78 -103 109 -160 29
-56 58 -102 62 -102 11 0 10 7 -2 31 -6 10 -9 19 -6 19 2 0 17 -24 33 -52 l28
-53 -30 0 c-19 0 -48 13 -75 33 -25 18 -46 28 -48 22 -2 -6 -15 -3 -30 8 -43
30 -52 34 -52 24 0 -5 17 -21 38 -35 81 -57 115 -93 108 -115 l-7 -22 -49 25
c-54 27 -60 29 -60 17 0 -4 20 -18 45 -30 49 -24 59 -42 22 -42 -12 0 -41 17
-64 39 -33 29 -44 48 -49 81 -4 24 -19 57 -33 74 -15 17 -42 61 -60 98 -27 54
-31 70 -22 82 20 24 65 0 121 -62 28 -31 50 -49 50 -40 0 24 -80 109 -117 125
-32 13 -43 36 -23 48 6 3 10 19 10 36 0 25 4 29 29 29 21 0 43 -13 78 -46z
m2490 -24 c9 -27 12 -55 8 -74 -4 -17 -9 -55 -11 -83 -1 -29 -5 -57 -9 -63 -3
-5 -8 -29 -11 -52 -8 -68 -33 -147 -71 -223 -52 -104 -107 -171 -269 -327 -62
-60 -124 -91 -124 -62 0 8 9 14 19 14 11 0 27 8 37 18 10 9 29 22 43 29 22 10
23 12 6 12 -17 1 -17 3 -5 11 12 8 12 10 -5 10 -11 1 2 9 29 20 55 21 73 36
36 27 -14 -3 -34 -1 -45 3 l-20 9 20 1 c25 1 76 29 55 30 -8 1 7 10 35 21 62
26 70 34 25 26 -34 -5 -34 -5 10 15 54 25 39 36 -17 13 -61 -27 -113 -45 -113
-39 0 3 20 13 45 24 25 11 45 22 45 26 0 3 -6 4 -13 1 -8 -3 -14 1 -14 9 0 11
9 13 33 8 32 -6 79 10 70 25 -3 4 13 14 35 21 44 15 67 36 28 27 -13 -3 -33
-2 -43 2 -16 6 -12 10 24 25 51 20 47 33 -5 16 -66 -22 -56 4 11 29 60 24 90
49 42 37 -14 -4 -10 1 10 11 18 9 31 22 29 27 -2 6 3 16 12 22 12 10 9 13 -19
19 l-34 7 35 19 c22 12 28 18 16 19 -10 0 -31 -7 -47 -15 -28 -15 -65 -11 -55
6 3 4 12 6 19 3 8 -3 34 5 58 17 23 12 37 24 30 26 -6 3 -10 9 -6 14 9 14 -15
10 -42 -7 -13 -9 -27 -14 -30 -11 -3 3 23 20 57 38 35 18 59 34 52 36 -7 2
-21 -1 -32 -6 -12 -7 -22 -7 -26 -1 -3 5 7 16 23 24 30 16 60 46 45 46 -5 0
-20 -7 -33 -16 -21 -14 -20 -12 4 12 18 17 25 29 17 32 -8 3 -6 9 6 19 17 13
16 14 -12 8 -22 -4 -26 -3 -15 4 8 6 15 24 15 41 0 34 -11 40 -20 10 -3 -11
-11 -20 -16 -20 -5 0 -10 -12 -10 -27 l-2 -28 -4 28 c-7 43 -33 34 -33 -12 0
-22 4 -41 9 -43 5 -1 3 -9 -5 -16 -12 -11 -14 -9 -14 13 0 15 -4 24 -10 20 -5
-3 -10 3 -10 14 0 11 -4 23 -10 26 -6 4 -10 -7 -10 -25 0 -25 -4 -31 -15 -26
-11 4 -15 -2 -16 -22 l-1 -27 -8 30 c-7 27 -8 26 -8 -15 -1 -55 20 -126 36
-123 6 2 9 -4 6 -12 -10 -24 -22 -17 -29 14 -3 16 -13 35 -21 42 -8 6 -14 18
-14 25 0 8 -4 14 -9 14 -8 0 1 -70 15 -112 2 -9 0 -19 -5 -22 -5 -4 -12 7 -16
24 -4 16 -12 30 -20 30 -7 0 -16 18 -20 43 l-7 42 -10 -35 c-10 -35 -7 -89 7
-122 5 -13 2 -18 -10 -18 -13 0 -16 -6 -11 -27 4 -24 3 -23 -10 6 -10 23 -12
36 -5 40 7 5 6 27 -3 71 l-14 65 57 58 c78 82 253 194 285 184 4 -2 15 -22 23
-47z m-1532 -40 c22 -24 30 -141 13 -174 -6 -12 -44 -56 -84 -98 -41 -43 -74
-85 -74 -93 0 -10 5 -13 12 -9 7 4 8 3 4 -5 -4 -6 -15 -10 -24 -9 -14 2 -16
-4 -14 -29 3 -34 -11 -45 -22 -17 -3 9 -6 49 -6 90 0 49 -4 74 -11 74 -8 0
-10 -14 -6 -45 4 -27 3 -45 -3 -45 -6 0 -10 9 -10 21 0 14 -5 19 -14 16 -23
-9 85 189 146 268 33 41 63 75 67 75 4 0 16 -9 26 -20z m491 -284 c17 -13 18
-17 5 -38 -8 -13 -25 -44 -37 -69 -28 -56 -55 -79 -131 -109 -32 -12 -67 -33
-78 -45 -27 -31 -84 -45 -186 -45 -47 0 -108 -7 -134 -15 -61 -18 -69 -13 -14
9 23 9 39 19 36 22 -8 8 -77 -16 -107 -37 l-26 -17 4 34 c2 18 7 32 11 30 8
-5 224 79 289 113 23 12 42 27 42 34 1 12 66 55 175 114 65 35 119 42 151 19z
m354 -1 c57 -11 59 -15 8 -19 -7 -1 -16 -5 -20 -11 -5 -6 -8 -4 -8 5 0 13 -1
13 -10 0 -8 -12 -10 -12 -11 5 l0 20 -9 -20 c-8 -19 -9 -19 -9 3 -1 21 -2 22
-17 7 -9 -8 -13 -22 -10 -31 3 -8 2 -12 -4 -9 -6 3 -11 14 -11 23 -1 9 -5 1
-9 -18 l-7 -35 -2 43 c0 23 -6 42 -12 42 -7 0 -10 -17 -8 -44 2 -24 1 -42 -3
-39 -5 2 -8 14 -8 26 0 18 -5 22 -27 21 -17 0 -31 5 -36 15 -4 9 -5 -10 -3
-42 4 -49 2 -59 -15 -67 -15 -9 -19 -7 -19 5 0 9 5 13 10 10 11 -7 13 46 4 83
-10 36 -23 25 -19 -15 3 -21 2 -31 -1 -22 -7 23 -54 20 -54 -3 0 -10 -4 -18
-8 -18 -5 0 -7 -15 -6 -32 2 -18 1 -25 -2 -15 -3 9 -12 17 -20 17 -20 0 -17
20 6 50 11 14 20 30 20 37 0 6 6 15 13 20 38 23 211 28 307 8z m-1572 -96 c37
-117 73 -198 111 -247 l35 -46 -59 42 c-33 23 -89 75 -124 117 -62 72 -92 94
-57 40 9 -14 14 -30 11 -35 -8 -13 34 -64 89 -109 22 -19 32 -31 21 -27 -11 4
-40 12 -65 19 -25 6 -52 16 -60 22 -8 7 -36 24 -62 39 -49 27 -67 21 -25 -9
22 -16 22 -16 -8 -9 l-30 6 25 -18 c13 -10 45 -23 70 -30 l45 -12 -57 -7 c-68
-9 -81 -5 -104 30 -14 21 -15 32 -6 68 6 23 13 54 17 69 6 29 48 58 84 58 35
0 50 11 62 48 13 43 38 74 53 69 6 -2 21 -37 34 -78z m1806 -14 c2 -22 14 -63
28 -92 15 -32 19 -49 11 -46 -7 3 -13 9 -13 14 0 5 -6 9 -14 9 -7 0 -23 23
-35 50 -23 54 -37 66 -27 24 5 -17 3 -23 -4 -19 -18 11 -11 -18 20 -85 17 -36
24 -56 15 -46 -8 10 -16 24 -18 30 -2 6 -8 8 -12 4 -4 -4 2 -23 14 -43 26 -44
29 -74 6 -55 -8 7 -15 9 -15 4 -1 -5 -8 5 -17 21 -22 40 -29 16 -8 -25 9 -18
12 -29 6 -26 -17 11 -13 -6 10 -43 16 -27 23 -31 30 -20 7 12 9 12 9 -1 0 -8
-4 -21 -9 -29 -7 -11 -11 -9 -21 9 -6 12 -14 19 -18 16 -3 -4 -12 4 -20 16
-13 22 -13 22 -7 -7 3 -16 5 -36 5 -42 -3 -50 -6 -63 -12 -63 -10 0 -10 4 -8
120 2 121 16 262 28 286 5 11 14 30 20 44 5 14 15 39 22 55 l12 30 10 -25 c5
-13 10 -42 12 -65z m-1024 -225 c0 -18 -73 -91 -109 -110 -53 -27 -120 -42
-150 -35 -22 6 -14 10 49 26 77 20 126 48 60 34 -40 -8 -37 -8 -70 -5 -17 1
-6 7 35 19 78 23 140 50 132 57 -3 4 -28 -1 -54 -11 -94 -33 -134 -44 -128
-35 25 40 235 93 235 60z m-813 -117 c-22 -9 -36 -21 -33 -28 2 -7 -1 -18 -7
-24 -24 -24 4 -21 43 5 32 21 57 29 103 31 l62 4 -37 -31 c-21 -18 -35 -36
-32 -42 3 -5 20 3 36 19 l30 28 -33 -47 c-41 -60 -33 -73 12 -17 38 49 113 99
146 99 29 0 29 -5 -2 -42 -14 -17 -23 -33 -20 -37 4 -3 20 11 37 32 28 35 68
50 68 26 0 -19 -67 -79 -88 -79 -12 0 -40 -17 -65 -38 -43 -37 -44 -38 -108
-32 -57 4 -71 10 -108 42 -24 20 -56 40 -72 43 -36 8 -39 40 -8 79 17 22 28
26 67 25 l47 0 -38 -16z m721 -23 c-7 -10 -35 -31 -62 -46 -40 -22 -68 -28
-150 -34 -82 -5 -112 -12 -158 -35 -63 -32 -88 -26 -46 10 43 37 129 64 237
74 60 6 116 17 142 29 24 11 45 21 47 21 1 1 -3 -8 -10 -19z m1646 -33 c27 -7
51 -18 54 -25 7 -21 -137 -152 -200 -182 -93 -45 -308 -88 -308 -62 0 6 -9 14
-20 17 -11 4 -18 11 -15 16 4 5 15 4 28 -3 12 -6 45 -13 72 -14 43 -3 47 -2
30 9 -36 22 -28 37 11 21 54 -22 66 -10 14 14 -25 11 -41 21 -36 21 5 1 3 8
-4 16 -17 20 -13 19 44 -10 49 -25 99 -36 66 -15 -10 6 -2 7 25 3 38 -6 39 -6
22 11 -25 25 -22 27 25 10 41 -14 65 -11 38 6 -10 6 0 12 35 21 l50 13 -33 13
c-41 17 -41 26 1 18 30 -6 31 -5 13 10 -23 18 -18 19 19 5 20 -8 30 -7 40 4 8
7 12 16 9 19 -3 3 5 9 18 13 20 7 19 9 -17 15 -25 4 -46 3 -55 -4 -8 -7 -6 -2
4 11 26 30 5 28 -23 -2 -22 -23 -67 -44 -55 -25 3 5 10 9 16 9 14 0 48 30 48
42 0 22 -43 3 -71 -32 -18 -22 -35 -38 -37 -35 -3 3 -12 -1 -21 -8 -9 -6 -3 5
12 26 26 35 24 54 -3 22 -7 -8 -19 -15 -27 -15 -12 0 -13 3 -5 13 6 7 7 18 3
26 -6 9 -17 4 -45 -22 -37 -35 -53 -74 -19 -46 14 12 13 9 -2 -15 -11 -16 -21
-23 -23 -17 -3 8 -8 9 -16 2 -21 -18 -12 26 12 59 30 40 28 49 -5 18 -26 -23
-56 -40 -76 -42 -10 -1 -37 -36 -37 -49 0 -4 5 -5 10 -2 6 4 5 -5 -2 -20 -10
-23 -13 -25 -23 -11 -7 9 -16 12 -21 8 -4 -4 -2 1 5 10 7 10 11 23 8 30 -2 8
-13 -1 -25 -22 -23 -37 -29 -66 -12 -55 6 3 10 4 10 1 0 -3 -8 -10 -19 -15
-16 -9 -18 -7 -14 25 3 27 0 34 -9 29 -24 -12 46 46 102 84 107 74 285 98 434
58z m-1135 -64 c-13 -16 -37 -43 -54 -62 -16 -18 -58 -46 -92 -61 -65 -30 -72
-38 -125 -137 -32 -61 -188 -235 -188 -210 1 6 21 32 45 57 25 25 45 49 45 54
0 6 -5 5 -12 -2 -7 -7 -17 -12 -22 -12 -6 0 14 25 44 55 64 65 57 72 -14 14
l-51 -41 54 51 c58 54 54 69 -7 24 l-36 -28 19 33 c11 18 33 45 50 60 16 15
23 19 16 9 -21 -26 -8 -30 22 -6 15 11 53 36 84 55 37 22 50 34 37 35 -10 0 8
13 41 29 33 16 52 29 43 29 -23 1 -91 -26 -153 -61 l-50 -28 40 35 c22 19 69
46 105 60 36 14 85 37 110 50 62 33 80 32 49 -2z m109 -6 c13 -12 22 -32 22
-49 0 -29 16 -48 80 -97 30 -22 32 -28 27 -69 -3 -28 -13 -52 -27 -66 l-22
-21 7 35 c13 65 -10 20 -50 -98 -38 -112 -41 -117 -70 -120 -16 -1 -25 1 -20
5 16 12 15 63 0 63 -10 0 -5 19 16 66 38 84 30 96 -10 15 -22 -43 -35 -59 -46
-55 -10 4 -18 -3 -25 -20 -11 -30 -24 -36 -15 -6 5 14 2 20 -9 20 -9 0 -16 2
-16 5 0 10 30 65 36 65 2 0 0 -9 -6 -19 -29 -56 2 -32 44 32 18 29 52 74 74
101 46 55 48 75 4 33 -16 -15 -32 -27 -37 -27 -5 0 -18 -9 -30 -20 l-21 -19 1
26 c0 20 -7 31 -28 41 l-29 14 36 26 c20 14 36 29 36 34 0 5 -4 6 -10 3 -5 -3
-10 -4 -10 -1 0 3 15 13 33 22 18 10 26 19 19 22 -7 2 -24 -4 -39 -13 -14 -9
-22 -12 -19 -6 9 14 0 14 -27 -1 -46 -24 4 21 57 52 48 28 42 43 -7 19 -17 -8
-16 -5 6 16 30 26 37 26 75 -8z m-723 -19 c-4 -18 -8 -33 -9 -34 0 0 -17 -10
-37 -22 -37 -22 -79 -29 -79 -14 0 12 -50 34 -83 37 -39 3 -29 -16 23 -42 24
-13 39 -23 32 -23 -20 0 -81 28 -109 50 l-25 20 53 25 c72 33 86 32 131 -12
21 -20 41 -33 45 -29 4 3 -6 19 -22 34 l-30 27 45 6 c25 3 51 7 58 7 10 2 12
-6 7 -30z m-755 -48 c30 -15 66 -31 80 -35 14 -4 42 -19 62 -33 36 -23 38 -27
37 -76 -1 -31 -4 -41 -7 -26 -5 23 -6 22 -18 -15 l-12 -40 -2 55 c-1 40 -4 50
-10 35 -5 -11 -9 -32 -9 -47 -1 -29 -14 -37 -27 -16 -4 7 -3 8 4 4 9 -5 12 7
11 41 0 38 -2 44 -10 28 -7 -15 -8 -10 -4 18 4 21 2 37 -4 37 -5 0 -12 -15
-14 -32 -3 -28 -4 -26 -6 12 -2 43 -3 44 -13 18 -5 -16 -14 -28 -19 -28 -5 0
-9 12 -9 28 l-1 27 -13 -24 c-11 -20 -14 -21 -19 -6 -4 9 -7 25 -7 36 0 10 -4
19 -10 19 -5 0 -11 -21 -12 -47 -1 -44 -2 -42 -7 17 l-6 65 -17 -50 -17 -50 0
43 c-1 23 -5 42 -11 42 -5 0 -10 -7 -10 -16 0 -22 -24 3 -32 34 -6 23 -5 23
35 17 23 -4 67 -19 97 -35z m324 -79 c24 -17 51 -42 61 -58 13 -22 7 -18 -27
15 -51 49 -76 57 -32 10 l29 -31 -42 33 c-53 41 -71 31 -20 -11 21 -17 35 -34
33 -38 -3 -4 6 -24 19 -44 18 -25 25 -50 25 -82 0 -28 -4 -44 -10 -40 -5 3
-10 16 -10 28 -1 26 -29 80 -30 57 -1 -8 -6 -4 -12 10 -9 20 -12 22 -15 8 -2
-9 3 -31 12 -48 9 -18 14 -34 11 -37 -7 -7 -56 96 -56 119 0 20 -20 35 -21 16
-1 -15 -17 30 -18 53 -1 19 36 69 50 69 6 0 30 -13 53 -29z m314 -26 c-11 -45
-2 -158 16 -225 11 -42 10 -52 -20 -138 l-32 -93 -6 108 c-8 139 -27 363 -32
377 -3 7 13 11 39 11 l44 0 -9 -40z m-900 4 c164 -90 301 -189 411 -299 50
-49 91 -92 91 -95 0 -21 -47 14 -69 51 -6 11 -11 13 -11 7 0 -22 -20 -14 -32
12 -6 14 -17 25 -24 25 -6 0 -17 14 -23 30 -12 36 -26 40 -17 5 5 -19 3 -23
-8 -19 -8 3 -17 17 -21 32 -7 25 -8 25 -15 7 -7 -19 -7 -19 -15 4 -5 16 -10
20 -15 12 -4 -8 -12 6 -20 33 -13 48 -31 64 -22 21 3 -20 1 -24 -12 -19 -9 3
-16 10 -16 14 0 5 -5 21 -11 37 -7 20 -12 24 -18 14 -7 -10 -11 -8 -16 10 -8
23 -8 23 -16 4 -6 -17 -8 -17 -8 -2 -1 9 -5 17 -11 17 -5 0 -16 12 -24 28 -8
15 -15 21 -16 15 0 -21 -14 -15 -34 16 -11 16 -25 27 -30 25 -6 -2 -24 8 -41
21 -41 34 -25 31 43 -6z m1606 -29 c14 -5 26 -14 26 -19 0 -5 -16 -31 -36 -57
-57 -74 -61 -97 -29 -146 41 -61 22 -128 -35 -128 -28 0 -90 18 -90 27 0 3 10
14 23 25 17 15 18 18 5 14 -30 -9 -20 2 20 24 36 20 36 21 7 15 -29 -6 -139
-75 -197 -124 -45 -37 -77 -101 -88 -176 -6 -39 -14 -88 -18 -110 l-8 -40 -7
37 c-5 21 -3 66 3 100 12 67 57 178 72 178 5 0 39 31 76 68 37 38 80 79 96 91
16 12 26 24 23 28 -7 6 -59 -30 -117 -81 l-35 -31 35 39 c19 21 68 63 108 93
86 64 89 84 5 28 -121 -80 -231 -220 -264 -335 -17 -61 -29 -72 -29 -27 0 20
4 37 8 37 5 0 19 26 32 57 13 31 52 92 87 135 34 43 59 78 54 78 -4 0 -16 -9
-26 -20 -10 -11 -22 -17 -27 -14 -5 4 -30 -16 -55 -42 -40 -43 -44 -45 -27
-16 28 50 176 180 247 218 72 39 57 45 -22 9 l-54 -25 15 22 c8 13 39 35 69
50 56 29 109 35 153 18z m319 -162 c-2 -119 -5 -136 -28 -185 -15 -29 -31 -53
-36 -53 -15 0 -10 18 7 28 22 12 34 74 32 172 -1 47 -3 68 -5 47 -2 -20 -8
-35 -13 -32 -11 7 -17 -14 -26 -95 -4 -36 -9 -57 -12 -47 -6 30 -19 19 -26
-24 -6 -39 -24 -49 -34 -19 -3 7 0 10 6 6 15 -9 20 13 27 111 9 124 11 130 54
158 22 13 42 33 46 45 3 11 7 20 8 20 1 0 1 -59 0 -132z m-2028 8 c91 -71 216
-223 270 -329 73 -142 84 -180 91 -300 6 -94 5 -107 -8 -102 -12 5 -19 -6 -29
-40 -10 -34 -15 -41 -21 -28 -3 10 -6 32 -6 48 0 17 2 22 5 13 6 -24 23 -23
23 1 0 11 3 26 6 35 3 9 1 16 -5 16 -6 0 -11 -8 -11 -17 0 -9 -3 -14 -6 -10
-3 3 1 30 10 60 20 67 20 80 1 47 -19 -33 -19 -23 0 30 19 54 19 72 -1 40 -8
-14 -13 -17 -10 -7 3 9 1 17 -4 17 -5 0 -15 -18 -21 -40 -7 -22 -14 -38 -17
-36 -2 3 3 29 12 59 9 30 16 61 15 69 -1 21 -38 -64 -40 -92 l-1 -25 -8 25
c-6 19 -8 21 -9 6 -1 -11 -4 -17 -7 -14 -5 5 8 67 23 104 3 8 1 14 -5 14 -6 0
-13 -14 -17 -31 -4 -17 -9 -28 -12 -25 -4 4 25 112 43 161 3 8 1 15 -5 15 -6
0 -13 -11 -17 -25 -3 -14 -10 -23 -14 -20 -5 3 -13 -3 -19 -12 -15 -26 -13 -9
3 34 14 37 17 103 5 103 -4 0 -13 -14 -20 -32 -12 -29 -49 -76 -49 -63 0 3 7
22 15 42 18 43 19 64 2 43 -17 -22 -41 -25 -33 -4 3 9 9 28 12 42 5 25 4 25
-9 10 -8 -10 -19 -18 -25 -18 -6 0 -18 -21 -27 -47 -11 -35 -14 -39 -10 -15 2
18 1 31 -4 28 -4 -3 -6 7 -3 22 4 22 6 24 12 8 5 -15 12 -4 30 43 24 65 22 92
-4 44 -8 -15 -15 -22 -16 -16 0 15 -21 13 -33 -2 -9 -10 -8 -7 7 43 10 32 -8
27 -22 -5 -9 -22 -11 -23 -8 -5 6 31 -10 28 -18 -4 -6 -21 -10 -24 -19 -15 -8
8 -9 14 -1 19 9 6 34 64 34 78 0 10 -20 2 -34 -12 -15 -14 -16 -14 -16 1 0 9
-4 13 -10 10 -12 -8 -12 -8 -1 33 9 30 -2 47 -12 20 -2 -7 -12 3 -21 22 l-16
35 4 -39 c5 -34 3 -38 -14 -33 -11 3 -20 1 -20 -4 0 -5 11 -11 25 -15 28 -7
29 -27 1 -34 -24 -7 -33 -30 -12 -30 22 0 15 -24 -9 -31 -17 -5 -14 -8 15 -17
l35 -10 -35 -2 c-19 -1 -27 -4 -17 -7 9 -2 17 -11 17 -19 0 -8 8 -14 18 -14
15 -1 15 -2 -2 -15 -19 -14 -18 -15 5 -24 22 -9 21 -10 -13 -10 -35 -1 -37 1
-44 36 -8 41 1 259 11 285 8 20 8 20 120 -66z m2875 -195 c36 -12 86 -31 113
-43 l47 -22 -27 -29 c-16 -16 -44 -42 -63 -57 -36 -28 -36 -28 -35 -5 1 13 14
37 29 55 39 47 23 51 -23 6 -22 -21 -40 -33 -43 -26 -2 7 -19 -1 -42 -19 -61
-49 -55 -36 12 25 34 31 62 63 62 72 0 11 -18 -1 -52 -34 -76 -71 -110 -96
-62 -44 39 42 64 80 64 95 0 4 -32 -26 -71 -67 -39 -40 -61 -59 -50 -41 24 36
27 47 9 37 -10 -6 -10 -4 0 7 21 22 13 33 -9 13 l-21 -19 21 35 c22 37 27 55
12 46 -5 -4 -7 3 -4 14 8 32 -2 24 -42 -31 -35 -50 -41 -75 -10 -49 31 26 13
3 -27 -35 -23 -22 -35 -31 -25 -19 24 29 21 41 -5 18 l-22 -19 22 29 c26 34
29 54 5 35 -12 -9 -11 -5 3 11 26 31 47 70 41 77 -3 2 -23 -19 -45 -49 -42
-57 -92 -107 -59 -58 24 34 21 47 -4 24 -22 -19 -22 -19 -14 5 4 14 3 21 -2
18 -6 -4 -8 4 -5 18 7 40 -6 29 -29 -25 -12 -27 -25 -50 -30 -50 -12 0 -12 1
7 45 8 20 13 44 11 53 -3 12 -8 7 -17 -16 -8 -21 -15 -29 -20 -22 -7 12 -33
-38 -29 -56 3 -14 -12 -44 -22 -44 -5 0 -4 11 1 25 5 14 9 36 8 48 -1 12 2 32
6 45 5 13 3 22 -3 22 -6 0 -11 -6 -11 -12 -1 -7 -7 -26 -15 -43 l-15 -30 4 28
c2 15 0 27 -4 27 -9 0 -32 -81 -28 -100 2 -8 -5 -15 -14 -17 -15 -4 -15 0 -2
43 17 55 18 78 4 69 -5 -3 -10 -15 -10 -26 0 -10 -6 -19 -14 -19 -7 0 -16 -9
-19 -20 -8 -30 -30 -25 -25 5 7 36 -7 31 -19 -7 l-10 -33 -4 38 c-5 36 -3 39
30 54 47 20 121 41 201 58 98 21 307 13 390 -14z m-1313 -6 c19 -8 59 -15 89
-15 114 0 140 -79 73 -221 -20 -41 -57 -100 -83 -132 -26 -33 -45 -62 -41 -65
13 -12 93 90 134 170 35 68 41 89 41 143 l0 63 43 6 c79 11 126 -16 113 -67
-8 -35 -149 -209 -229 -284 -96 -89 -120 -97 -34 -11 76 76 138 179 136 227
l0 26 -11 -25 c-53 -123 -104 -193 -188 -260 -40 -32 -115 -50 -107 -27 2 7
11 11 20 9 28 -5 126 143 160 243 25 73 12 67 -23 -10 -30 -68 -112 -199 -131
-211 -6 -3 1 17 16 46 39 78 23 72 -25 -9 -51 -86 -70 -103 -28 -26 16 30 28
61 25 68 -2 6 -14 -8 -27 -33 -13 -25 -30 -55 -38 -68 l-15 -23 -23 42 c-13
22 -24 55 -24 73 0 59 31 161 70 233 44 81 38 99 -7 26 -38 -61 -49 -89 -73
-193 l-20 -85 6 64 c4 35 10 78 15 95 14 48 9 65 -8 26 -9 -19 -19 -57 -23
-85 -7 -45 -8 -40 -9 41 -1 78 2 97 24 140 28 56 54 85 100 108 38 20 56 20
102 1z m1071 -254 c48 -11 148 -31 222 -45 140 -27 322 -67 329 -73 3 -2 -4
-10 -15 -18 -17 -12 -20 -12 -31 1 -10 14 -14 14 -39 -3 -16 -10 -25 -12 -21
-5 5 7 3 12 -4 12 -8 0 -8 4 1 15 14 17 0 21 -17 4 -6 -6 -33 -13 -59 -16 -46
-3 -47 -3 -31 15 27 30 19 35 -15 10 -18 -13 -43 -23 -55 -23 -20 -1 -21 1 -8
15 25 27 17 33 -15 10 -22 -16 -28 -17 -24 -6 7 17 -11 22 -21 6 -3 -5 -16
-15 -28 -21 -21 -12 -22 -11 -4 8 10 11 15 27 11 36 -5 14 -10 13 -42 -8 -35
-24 -37 -24 -47 -6 -8 16 -14 18 -30 9 -11 -6 -4 3 15 20 42 38 29 42 -22 7
-42 -28 -56 -32 -38 -10 8 9 9 15 2 15 -6 0 -14 -4 -17 -10 -3 -5 -11 -10 -17
-10 -5 0 0 9 13 19 18 15 21 22 13 31 -9 9 -18 9 -35 -1 -33 -18 -59 -20 -52
-3 7 19 -7 18 -29 -3 -12 -10 -18 -12 -18 -4 0 7 -5 9 -12 5 -10 -6 -10 -4 0
7 21 22 13 32 -12 16 -20 -13 -25 -14 -29 -3 -3 8 -3 17 0 20 12 12 95 6 181
-13z m-808 -323 c28 -10 110 -87 110 -103 0 -5 15 -29 34 -52 26 -33 39 -66
59 -150 15 -59 30 -127 33 -151 l7 -42 -42 18 c-49 22 -62 33 -30 25 14 -4 20
-2 15 5 -3 6 2 12 11 14 11 3 1 10 -27 18 -50 15 -67 26 -56 37 4 4 10 2 15
-5 4 -6 21 -15 38 -18 27 -5 31 -3 29 12 -2 10 -16 22 -32 27 -16 6 -31 13
-34 18 -3 4 -21 10 -40 12 -19 2 -3 3 35 1 47 -2 64 -1 50 6 -11 5 -28 9 -38
9 -10 1 -25 8 -33 16 -14 13 -13 15 3 16 10 1 5 5 -12 10 -21 6 -12 7 33 4 72
-5 77 10 7 21 -25 3 -45 10 -45 15 0 5 -13 9 -30 9 -16 0 -30 5 -30 10 0 6 19
10 43 10 l42 1 -40 15 c-22 9 -51 19 -65 22 -18 5 -7 9 40 13 58 5 62 6 33 13
-18 5 -30 12 -27 17 10 17 -49 9 -63 -8 -8 -10 -11 -12 -7 -4 6 11 1 13 -23 8
-17 -3 -44 -9 -59 -12 l-29 -5 30 14 c17 9 40 15 53 16 12 0 22 4 22 10 0 5
-13 7 -32 3 -22 -4 -29 -3 -24 5 4 7 1 12 -6 13 -27 2 39 17 80 18 52 1 57 21
5 21 -30 0 -35 3 -23 11 11 8 4 9 -28 6 -39 -5 -41 -4 -25 9 23 17 18 17 -46
-2 -38 -11 -51 -19 -47 -30 3 -8 4 -14 2 -14 -3 0 -11 -3 -19 -6 -12 -4 -14 0
-10 15 3 11 22 27 46 37 23 9 36 19 30 21 -7 3 -38 -7 -70 -21 -50 -23 -54
-24 -39 -7 40 45 147 58 226 29z m-275 -63 c46 -23 91 -55 117 -84 22 -25 72
-78 109 -117 l68 -72 -32 -4 c-18 -3 -41 -9 -51 -13 -16 -7 -15 -4 4 15 21 21
22 24 6 30 -21 7 -23 20 -4 31 19 13 -24 12 -57 -1 -17 -7 -24 -7 -20 0 8 13
-11 13 -36 0 -10 -6 -19 -8 -19 -6 0 3 19 16 43 28 30 16 37 23 25 26 -10 2
-18 8 -18 13 0 11 -54 -14 -85 -40 -12 -9 -8 -4 7 11 37 36 35 42 -4 23 l-33
-16 25 20 c14 11 34 27 45 35 16 12 17 15 4 16 -8 0 -39 -20 -68 -45 -55 -47
-61 -51 -61 -36 0 5 9 11 20 14 22 6 44 50 28 56 -5 1 -19 -5 -31 -15 -12 -10
-4 1 18 24 46 50 36 56 -15 10 -19 -18 -29 -26 -22 -18 21 24 13 35 -10 13
-63 -56 -44 -10 26 65 37 40 15 44 -28 5 -17 -16 -25 -21 -18 -13 6 8 12 18
12 24 0 17 -42 -29 -72 -79 -17 -27 -27 -41 -23 -30 4 11 9 29 12 40 4 19 3
19 -9 1 -7 -10 -21 -28 -31 -40 -15 -19 -16 -19 -10 -2 4 13 3 17 -5 12 -14
-8 -15 -5 -6 19 5 11 9 13 14 5 4 -6 23 15 46 52 51 81 53 89 16 55 -16 -16
-34 -28 -39 -27 -4 2 -23 -25 -40 -59 -18 -35 -33 -58 -33 -51 0 6 5 21 11 32
7 12 7 23 2 27 -5 3 -14 15 -20 25 -10 18 -12 17 -27 -12 -9 -17 -20 -48 -25
-69 l-9 -38 -1 38 c-2 65 40 126 109 159 35 17 120 1 195 -37z m-835 -121 c23
-53 24 -58 9 -48 -11 7 -6 -45 8 -82 3 -8 -1 -14 -11 -14 -10 0 -16 9 -17 23
0 12 -5 31 -10 42 -6 13 -7 -3 -3 -49 4 -48 3 -66 -5 -62 -7 5 -11 -2 -11 -16
0 -22 0 -22 -14 -2 -8 11 -16 36 -19 55 -2 18 -2 26 0 17 8 -32 23 -19 23 20
0 27 -3 33 -10 22 -15 -23 -12 17 4 63 9 25 10 37 3 34 -18 -6 -35 -68 -37
-132 -1 -43 -4 -54 -10 -40 -22 52 -4 138 39 184 16 17 32 31 35 31 4 0 16
-21 26 -46z m-555 -93 c44 -12 87 -24 97 -26 29 -6 73 -33 97 -59 14 -14 30
-26 36 -26 18 0 115 -123 115 -145 -1 -5 -8 3 -16 18 -8 15 -19 25 -24 22 -6
-4 -10 0 -10 7 0 10 -2 10 -9 0 -6 -10 -11 -5 -19 18 -7 17 -16 29 -22 25 -5
-3 -10 -1 -10 5 0 7 -7 10 -15 6 -8 -3 -15 -2 -15 2 0 5 -7 17 -16 28 -13 16
-15 16 -10 2 10 -34 -5 -18 -24 25 -15 33 -19 37 -19 18 -1 -20 -3 -21 -16
-11 -17 15 -19 7 -5 -21 6 -10 8 -19 6 -19 -3 0 -15 18 -26 39 -11 22 -25 43
-31 47 -17 10 -1 -34 28 -81 21 -32 22 -36 6 -22 -11 9 -22 15 -25 12 -3 -3
-11 1 -18 10 -9 10 -9 15 -1 15 7 0 4 15 -9 40 -13 26 -27 40 -40 40 -24 0
-24 -3 -4 -42 29 -56 -1 -49 -39 10 -37 58 -50 57 -21 -1 l15 -32 -20 25 c-11
14 -23 33 -27 43 -3 9 -10 17 -15 17 -5 0 -2 -16 6 -36 13 -32 13 -35 0 -24
-18 15 -20 -1 -3 -18 7 -7 9 -12 6 -12 -4 0 -28 30 -54 68 -26 37 -49 69 -51
71 -10 14 -13 -11 -3 -29 8 -15 8 -20 1 -16 -23 14 -10 -14 34 -73 25 -34 41
-61 36 -61 -10 0 -73 67 -115 123 -38 50 -46 39 -10 -15 15 -24 27 -47 26 -52
-2 -4 2 -16 9 -25 6 -9 -14 11 -45 44 -65 71 -74 61 -14 -16 l42 -54 -37 34
c-20 19 -37 30 -37 24 0 -5 22 -31 50 -57 46 -43 47 -46 24 -46 -15 0 -23 4
-19 11 4 5 -11 27 -32 47 -21 20 -53 55 -71 77 -18 23 -32 33 -32 24 0 -8 17
-35 37 -60 34 -41 34 -42 5 -15 -17 16 -35 26 -39 22 -4 -3 19 -31 51 -61 32
-30 54 -55 49 -55 -15 1 -58 28 -56 37 1 7 -104 123 -112 123 -2 0 -3 -10 -3
-22 1 -13 22 -45 47 -73 l46 -50 -55 53 c-64 60 -84 68 -46 17 15 -19 24 -35
20 -35 -3 0 -1 -7 6 -15 26 -31 -3 -13 -50 31 -28 26 -50 43 -50 37 0 -5 6
-15 13 -22 10 -10 9 -11 -5 -6 -26 10 -22 -4 10 -39 18 -20 12 -17 -23 12 -64
53 -74 44 -13 -14 26 -24 42 -44 36 -44 -6 0 -5 -7 3 -17 12 -15 12 -16 -1 -8
-8 5 -30 20 -48 33 -46 35 -51 21 -5 -17 l38 -31 -28 0 c-16 0 -26 4 -22 9 6
11 -53 56 -62 47 -4 -3 -15 1 -25 11 -10 9 -18 11 -18 6 0 -6 18 -27 39 -46
36 -32 42 -47 19 -47 -5 0 -7 4 -4 9 8 13 -40 42 -58 35 -23 -9 -20 -17 12
-38 25 -16 17 -16 -23 0 -8 3 -15 1 -15 -5 0 -6 5 -11 11 -11 8 0 7 -6 -2 -16
-12 -15 -11 -16 9 -10 68 19 65 19 53 -2 -17 -32 -13 -37 10 -16 l22 19 -13
-25 c-12 -22 -12 -24 3 -12 13 11 15 10 11 -10 -5 -23 -5 -23 13 2 23 32 87
99 78 83 -3 -7 -1 -13 5 -13 5 0 -1 -18 -15 -40 -27 -42 -29 -50 -17 -50 4 0
16 14 26 31 28 47 76 99 93 99 8 0 11 -5 8 -10 -4 -6 -10 -8 -15 -5 -4 3 -20
-9 -34 -26 -26 -31 -35 -56 -15 -43 6 3 4 -8 -5 -24 -15 -30 -15 -31 5 -29 12
1 34 20 52 44 33 47 40 52 30 26 -6 -16 -5 -16 11 -4 9 8 22 23 28 34 8 17 8
19 -6 14 -22 -9 -29 8 -10 24 13 10 17 10 24 -1 7 -12 13 -11 32 5 17 14 15
11 -4 -11 -28 -30 -38 -62 -16 -48 6 4 4 -3 -5 -17 -23 -32 -21 -46 2 -25 19
17 19 17 14 -6 -4 -18 0 -16 20 13 14 20 37 43 52 53 l26 17 -16 -25 c-27 -41
-8 -30 47 27 28 28 43 43 34 31 -20 -26 -21 -45 -1 -38 41 16 -6 -35 -50 -54
-17 -7 -47 -25 -67 -39 -20 -14 -52 -26 -70 -28 -18 -2 -60 -8 -93 -13 -90
-14 -252 10 -303 45 -19 12 -39 23 -43 23 -17 0 -9 28 21 68 16 23 30 46 30
51 0 5 18 24 39 42 21 19 52 45 67 59 55 47 125 87 182 104 31 10 66 22 77 27
11 5 52 13 90 17 73 8 128 0 300 -47z m165 -661 c11 -7 6 -10 -22 -10 -56 0
-58 -20 -1 -21 31 0 41 -3 30 -8 -16 -6 -16 -9 -5 -18 7 -6 22 -13 33 -16 11
-2 -19 -5 -67 -6 -48 0 -90 -5 -93 -11 -3 -5 3 -10 15 -10 13 0 19 -4 14 -12
-5 -8 6 -10 42 -6 27 3 40 4 30 1 -14 -4 -15 -7 -6 -13 7 -4 -6 -10 -34 -14
-25 -4 -49 -12 -52 -18 -5 -7 0 -9 14 -6 12 4 20 2 17 -2 -3 -5 3 -11 12 -14
15 -4 15 -5 -2 -4 -45 1 -55 -3 -55 -21 0 -15 5 -18 21 -14 21 6 21 5 4 -21
-9 -14 -21 -26 -26 -26 -13 0 -11 171 2 211 6 19 20 42 31 52 22 19 74 23 98
7z m399 -22 c36 -33 59 -96 64 -178 l6 -75 -13 75 c-8 41 -14 77 -15 80 -1 3
-6 -2 -11 -10 -8 -12 -10 -11 -10 7 0 12 -6 26 -12 30 -7 4 -21 25 -31 47 -23
50 -31 41 -12 -14 18 -52 18 -77 0 -35 -7 17 -16 29 -20 27 -3 -2 -15 7 -25
20 -23 28 -26 18 -5 -22 21 -40 -3 -46 -30 -6 -11 16 -24 29 -30 29 -5 -1 -15
3 -22 9 -17 14 -10 -40 11 -80 20 -40 20 -51 0 -26 -15 19 -15 18 -11 -3 3
-15 0 -23 -9 -23 -7 0 -19 10 -25 23 -11 20 -11 21 4 8 25 -20 22 4 -4 41 -24
33 -37 31 -18 -4 12 -22 11 -23 -8 -5 -31 27 -36 20 -12 -15 19 -27 20 -30 4
-18 -17 14 -18 13 -11 -5 5 -14 -3 -10 -28 14 -33 32 -43 31 -36 -4 1 -5 -4
-4 -11 3 -16 15 -33 16 -24 1 4 -5 18 -15 32 -20 14 -5 23 -12 19 -16 -3 -4
-20 3 -36 15 -37 28 -49 28 -24 1 19 -22 19 -22 -3 -10 -29 15 -38 14 -24 -3
10 -12 8 -13 -10 -9 -14 4 -20 2 -16 -4 7 -11 -22 -28 -31 -19 -10 10 129 124
188 154 42 21 122 40 176 41 38 1 56 -4 73 -21z"/>
</g>


    </Svg>
  );
};

export default Icon;
