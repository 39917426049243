import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Layers',
    icon: 'LayerIcon',
    items: [
      {
        label: 'BASTILLE (NEW)',
        href: 'https://bastille.bouje.finance/',
      },
      {
        label: 'L2',
        href: 'https://vive.bouje.finance/',
      },
      {
        label: 'L3',
        href: 'https://bastille.bouje.finance/',
      },
    ],
  },
  {
    label: 'Trade (Spooky)',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://spookyswap.finance/swap?outputCurrency=0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
      },
      {
        label: 'Liquidity',
        href: 'https://spookyswap.finance/add/FTM/0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Community Pools',
  //   icon: 'GroupsIcon',
  //   href: '/community',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Dividends',
    icon: 'ByofIcon',
    items: [
      {
        label: 'In-house',
        href: '/dividends',
      },
      {
        label: 'Xmas Past',
        href: 'https://xmas-past.com/pools',
      },
    ],
  },
  {
    label: 'Games',
    icon: 'DogIcon',
    href: 'https://game.bouje.finance/r/8bw4AsJ2',
  },
  {
    label: 'Vaults',
    icon: 'VaultIcon',
    items: [
      {
        label: 'Yield Wolf',
        href: 'https://yieldwolf.finance/fantom/bouje-finance',
      },
    ],
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Coin Market Cap',
        href: 'https://coinmarketcap.com/currencies/bouje-token/',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/bouje-token',
      },
      {
        label: 'DeBank',
        href: 'https://debank.com/projects/ftm_bouje?mode=stats',
      },
      {
        label: 'VFat',
        href: 'https://vfat.tools/fantom/boujefinance/',
      },
      {
        label: 'Tin.Network',
        href: 'https://tin.network',
      },
      {
        label: 'Kek.tools',
        href: 'https://kek.tools/t/0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41?pair=0x91af3c43b77217c1f9622bfc2cd313e213332901',
      },
      {
        label: 'Zoo Coin',
        href: 'https://charts.zoocoin.cash/charts?exchange=SpookySwap&pair=0x91af3c43b77217c1f9622BFC2Cd313e213332901-inverted',
      },
      {
        label: 'Spooky Info',
        href: 'https://info.spookyswap.finance/token/0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
      },
      {
        label: 'Dex Tools',
        href: 'https://www.dextools.io/app/fantom/pair-explorer/0xacf6728c977e349440827375c73ee23bce4db291',
      },
      {
        label: 'Nomics',
        href: 'https://nomics.com/assets/BOUJE-Bouje?utm_source=twitter&utm_medium=bot&utm_campaign=new_asset&utm_content=bouje&d=1635378351',
      },
      {
        label: 'FarmScan',
        href: 'https://ftm.farmscan.io/address/0x89dcd1dc698ad6a422ad505efe66261a4320d8b5',
      },
      {
        label: '0xTracker',
        href: 'https://0xtracker.app/farms',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Github',
      //   href: 'https://github.com/polydaedalus',
      // },
      {
        label: 'Docs',
        href: 'https://docs.bouje.finance/',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/ypsxjFe6w2',
      },
      {
        label: 'Ape O Clock',
        href: 'https://www.apeoclock.com/launch/bouje-farm-launch/',
      },
      {
        label: 'Jago',
        href: 'https://jagosafer.io/bouje-finance',
      },
      {
        label: 'Dapp',
        href: 'https://www.dapp.com/app/bouje-finance',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/other/defi/bouje-finance',
      },
      {
        label: 'DefiLlama',
        href: 'https://defillama.com/protocol/bouje-finance',
      },
     // {
     //   label: 'Vfat.tools',
     //   href: 'https://vfat.tools/polygon/platinumfinance/',
     // }
    ],
  },
]

export default config
