import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2590 4954 c-36 -25 -128 -76 -205 -114 -126 -61 -147 -75 -212 -141
-118 -122 -178 -233 -189 -347 -8 -87 -28 -160 -65 -230 -31 -61 -49 -137 -49
-209 0 -41 -4 -48 -61 -100 -67 -62 -89 -97 -89 -141 0 -16 -9 -37 -20 -47
-19 -18 -20 -17 -20 3 0 55 -38 69 -90 32 l-28 -20 -56 26 c-31 14 -96 32
-144 40 -48 9 -114 24 -147 36 -33 11 -96 23 -140 26 -71 4 -93 0 -180 -28
-71 -22 -115 -31 -150 -28 -27 1 -85 -1 -128 -6 -124 -13 -240 26 -342 114
-53 45 -60 48 -75 21 -16 -30 -13 -49 31 -229 48 -196 74 -252 170 -357 51
-56 72 -87 84 -127 31 -104 125 -201 275 -283 51 -27 129 -78 174 -113 44 -34
107 -75 138 -90 44 -20 57 -31 52 -44 -2 -9 2 -32 9 -51 17 -39 57 -61 131
-72 l48 -7 -20 -21 c-28 -30 -36 -70 -23 -108 6 -18 10 -34 8 -36 -2 -2 -23 3
-46 12 -157 55 -321 3 -321 -102 0 -21 4 -52 9 -69 l9 -31 -67 16 c-37 9 -87
29 -111 45 -56 37 -86 40 -255 28 -115 -9 -161 -17 -255 -47 -63 -20 -143 -39
-177 -42 -52 -5 -63 -9 -63 -24 0 -22 15 -30 110 -65 88 -31 164 -76 252 -146
178 -142 240 -189 325 -242 98 -61 120 -80 196 -169 38 -45 66 -66 130 -97
113 -54 249 -89 351 -89 92 -1 152 -12 149 -29 -7 -31 -67 -113 -79 -108 -8 3
-36 8 -64 12 -44 6 -59 2 -118 -25 -170 -80 -215 -107 -264 -160 -27 -30 -70
-68 -94 -84 -25 -16 -56 -50 -71 -76 -15 -25 -49 -67 -75 -92 -34 -34 -48 -57
-53 -85 -4 -22 -24 -77 -45 -124 -48 -109 -54 -142 -35 -213 8 -32 15 -77 15
-100 0 -90 18 -104 82 -68 18 10 56 22 83 26 28 4 70 17 95 29 25 13 69 31 97
40 63 21 115 74 150 155 14 31 32 64 41 74 18 20 85 52 132 62 21 5 55 29 94
69 33 34 81 77 105 96 60 47 91 118 91 210 0 66 2 73 34 107 l33 36 31 -22
c17 -12 53 -55 81 -96 57 -85 61 -105 64 -310 2 -112 -1 -140 -21 -200 -13
-38 -46 -140 -74 -225 -27 -85 -52 -161 -55 -167 -3 -7 0 -13 6 -13 24 0 73
122 140 350 27 91 75 235 106 320 32 85 82 227 112 315 29 88 56 167 59 175 5
14 24 -27 68 -152 30 -84 54 -108 159 -162 54 -28 132 -79 173 -114 43 -36 82
-62 92 -60 14 3 18 19 23 83 3 45 16 107 28 140 47 126 35 275 -31 378 -17 26
-60 74 -97 107 -42 39 -84 89 -116 142 -39 65 -57 85 -82 93 l-31 10 33 72
c18 39 38 87 45 107 8 27 24 43 58 63 26 15 61 37 79 50 l31 24 69 -34 c75
-38 210 -87 240 -87 11 0 51 -11 89 -24 133 -46 343 -43 649 11 189 33 227 32
304 -7 33 -16 94 -41 135 -55 76 -25 176 -34 176 -15 0 27 -35 73 -99 130 -57
50 -73 72 -87 114 -35 105 -78 163 -155 202 -20 11 -80 67 -134 126 -113 122
-155 150 -232 153 -46 1 -62 8 -123 51 -39 27 -87 59 -108 71 -42 25 -161 47
-202 38 -15 -3 -67 1 -115 10 -47 9 -94 14 -103 11 -32 -12 34 34 85 59 46 22
64 25 171 25 141 0 207 17 310 78 37 23 85 44 107 47 84 14 153 39 235 85 195
109 317 170 369 186 86 25 119 45 259 162 105 87 146 114 204 137 107 41 184
90 266 172 41 40 89 77 108 83 26 9 34 17 34 35 0 19 -10 27 -53 43 -29 11
-71 25 -93 31 -23 6 -74 24 -114 41 -39 16 -95 32 -123 36 -29 3 -83 17 -120
30 -92 32 -179 32 -272 -1 -51 -19 -105 -28 -198 -35 -152 -11 -184 -19 -303
-75 -61 -28 -112 -61 -152 -98 -33 -31 -102 -83 -152 -116 -76 -50 -89 -56
-74 -33 40 60 66 113 89 183 14 41 42 101 62 134 52 87 177 238 240 292 60 51
80 80 66 94 -12 12 -334 -24 -417 -46 -32 -8 -86 -31 -121 -50 -34 -19 -75
-35 -90 -35 -42 0 -103 -33 -169 -93 -58 -52 -61 -53 -97 -43 -26 7 -49 6 -74
-1 l-36 -12 6 147 c4 113 2 166 -10 227 -9 44 -18 127 -21 185 -4 79 -13 127
-35 192 -19 57 -29 108 -30 150 -1 75 -19 142 -39 145 -8 2 -44 -18 -80 -43z
m99 -102 c1 -87 -36 -143 -131 -196 l-33 -18 30 26 c17 14 33 25 36 26 4 0 9
7 13 15 7 20 -11 11 -92 -49 -63 -47 -69 -49 -53 -18 5 9 12 24 14 33 3 9 35
37 72 63 36 25 64 42 60 36 -3 -6 -33 -28 -66 -50 -57 -40 -87 -80 -31 -45 15
10 38 25 52 33 32 20 100 84 100 94 0 19 -44 5 -90 -28 -28 -20 -53 -34 -55
-32 -3 3 13 29 35 58 22 30 40 58 40 63 0 18 -41 -27 -83 -90 -36 -55 -57 -71
-57 -42 0 6 5 7 11 4 7 -5 9 9 7 41 -4 60 -24 69 -33 14 -4 -23 -11 -37 -17
-34 -6 4 -8 -4 -5 -22 15 -78 18 -112 8 -118 -6 -4 -13 9 -17 29 -3 19 -11 38
-17 42 -6 4 -7 -7 -3 -33 5 -28 4 -35 -3 -24 -6 8 -14 36 -17 63 -4 26 -11 50
-15 53 -18 10 -8 -59 17 -132 30 -89 24 -136 -9 -64 -11 25 -28 74 -38 110
-17 63 -17 64 -18 24 -1 -23 6 -61 14 -84 13 -38 13 -41 0 -30 -21 17 -19 12
15 -66 17 -37 29 -69 27 -71 -2 -2 -12 15 -22 37 -23 51 -41 53 -19 3 9 -21
18 -44 21 -50 3 -7 9 -13 14 -13 5 0 9 -12 9 -27 -1 -22 -8 -11 -36 46 -19 41
-42 97 -52 123 -9 26 -20 48 -25 48 -10 0 -3 -24 28 -102 12 -32 21 -58 18
-58 -11 0 -74 151 -79 193 -12 84 22 118 197 196 52 24 131 67 175 96 l79 54
12 -37 c6 -20 12 -61 12 -90z m36 -173 c25 -64 27 -79 23 -172 -5 -106 -41
-235 -58 -207 -6 9 -19 -8 -40 -51 -18 -35 -55 -99 -83 -143 -46 -72 -51 -77
-59 -56 -7 20 -2 30 29 62 20 21 33 41 29 45 -4 4 -28 -14 -52 -40 -25 -26
-48 -47 -52 -47 -3 0 28 35 69 78 80 82 126 142 111 142 -5 0 -15 -7 -22 -15
-7 -8 -17 -12 -23 -8 -6 4 -54 -39 -110 -98 -55 -57 -88 -87 -73 -66 15 20 25
41 22 45 -3 5 10 24 29 42 36 35 48 62 18 40 -10 -7 14 18 53 56 69 68 134
151 134 174 0 6 -4 9 -9 6 -5 -4 -11 -1 -13 5 -2 6 -18 -9 -35 -34 -18 -24
-65 -73 -105 -108 l-73 -63 59 62 c83 86 61 92 -24 7 -64 -64 -94 -106 -52
-74 9 7 6 0 -8 -16 -14 -17 -28 -34 -32 -39 -5 -6 -8 0 -8 13 0 15 31 55 98
122 53 55 97 103 98 107 0 4 30 37 67 74 90 91 85 109 -6 22 -40 -38 -105 -95
-145 -127 -66 -53 -86 -77 -63 -77 6 0 12 4 15 9 7 11 106 94 106 89 0 -2 -37
-37 -81 -78 -45 -41 -77 -64 -70 -53 7 15 8 22 0 25 -18 6 65 91 160 163 50
38 91 73 91 77 0 10 -12 4 -56 -29 -17 -13 -37 -23 -45 -23 -8 0 -37 -15 -64
-33 -61 -41 -94 -55 -75 -33 6 9 15 15 18 13 10 -2 160 103 209 147 55 49 39
68 -21 26 -23 -15 -60 -40 -84 -54 -23 -15 -61 -43 -82 -63 -22 -19 -40 -32
-40 -29 0 20 30 74 51 91 l24 20 -22 -26 c-40 -45 -25 -55 20 -13 23 23 70 58
105 78 34 21 75 53 90 72 15 19 27 34 28 34 0 0 13 -32 29 -71z m-461 -232
c32 -64 61 -117 63 -117 3 0 3 12 -1 28 -5 27 -5 27 8 -3 8 -17 10 -32 5 -36
-6 -3 -3 -21 6 -42 8 -20 14 -37 12 -37 -9 0 -126 209 -142 253 -21 57 -29 60
-21 10 l5 -38 -14 30 c-8 17 -14 36 -15 43 0 6 -4 12 -9 12 -13 0 11 -86 39
-140 12 -25 40 -70 60 -100 43 -64 65 -105 74 -140 6 -22 5 -21 -10 5 -9 17
-31 48 -47 70 -17 22 -32 42 -34 44 -2 2 -6 2 -9 -2 -3 -3 5 -21 19 -39 31
-42 45 -71 19 -38 -31 37 -35 19 -6 -26 l25 -39 -26 30 c-71 80 -127 180 -171
304 -2 8 -7 11 -10 8 -5 -4 5 -52 22 -105 5 -14 4 -15 -5 -2 -22 32 -21 0 3
-53 31 -71 72 -131 140 -204 29 -32 61 -74 71 -93 21 -42 11 -33 -39 38 -43
59 -63 63 -24 5 13 -21 33 -51 43 -68 l18 -30 -24 27 c-13 14 -37 38 -54 53
-16 15 -24 24 -17 20 31 -16 -12 56 -81 135 -35 40 -62 87 -95 170 l-11 25 0
-26 c-1 -33 24 -94 54 -135 13 -17 22 -40 19 -50 -3 -13 13 -39 47 -79 66 -76
129 -172 129 -197 0 -17 -2 -17 -18 -6 -20 14 -107 115 -165 192 -21 26 -37
41 -37 34 0 -17 42 -79 100 -148 26 -30 57 -71 69 -90 25 -39 30 -44 -62 64
-23 27 -65 75 -94 107 -78 86 -86 108 -79 198 4 42 11 99 17 128 11 57 91 198
142 251 l32 33 10 -58 c6 -34 35 -108 69 -176z m515 -144 c6 -43 10 -125 7
-183 -2 -58 -4 -121 -5 -141 -1 -33 -4 -36 -48 -52 -27 -9 -59 -17 -71 -17
-22 0 -21 3 17 47 41 46 48 69 14 42 -17 -13 -17 -12 0 8 10 11 17 27 16 35
-2 7 11 41 29 75 33 64 43 103 18 71 -7 -10 -17 -18 -22 -18 -5 0 -28 -29 -52
-63 -53 -78 -109 -143 -116 -136 -3 3 26 43 65 88 64 77 140 201 121 201 -4 0
-23 -26 -41 -57 -29 -47 -117 -162 -154 -198 -9 -9 18 40 51 93 9 14 10 22 3
22 -6 0 2 20 16 45 17 28 30 40 35 33 7 -12 88 143 88 169 0 7 4 13 9 13 5 0
14 -35 20 -77z m925 -165 c-106 -108 -169 -186 -223 -277 -25 -40 -59 -82 -75
-92 -31 -19 -109 -46 -196 -70 -41 -11 -43 -13 -20 -19 18 -4 -1 -10 -65 -19
-49 -7 -101 -15 -115 -18 -21 -5 -23 -4 -13 9 7 8 38 20 68 27 30 7 53 17 50
22 -4 5 9 9 28 9 59 0 99 11 93 26 -3 9 2 14 14 14 25 0 74 19 67 26 -3 3 -22
1 -43 -4 -70 -18 -131 -24 -114 -11 11 8 6 9 -25 5 -34 -4 -37 -3 -20 6 11 7
32 11 46 9 42 -4 188 19 231 36 60 24 44 33 -23 12 -33 -10 -71 -19 -82 -18
-12 0 3 7 33 15 30 8 59 19 64 24 13 13 4 13 -63 -4 -31 -8 -78 -16 -105 -19
l-50 -5 25 19 c13 10 45 21 72 25 26 4 47 11 47 16 0 5 -17 6 -37 4 -29 -5
-32 -4 -13 3 14 4 58 11 99 15 106 11 136 28 36 21 -57 -4 -76 -3 -65 4 13 9
13 11 1 11 -12 0 -12 3 -3 12 10 10 14 10 21 0 6 -11 23 -11 85 -3 43 6 82 11
87 11 5 0 9 5 9 10 0 7 -15 9 -37 6 l-38 -6 35 11 c92 27 79 33 -31 14 -46 -8
-86 -15 -89 -15 -3 0 9 9 25 20 17 11 47 20 69 20 33 0 37 2 26 15 -11 13 -7
15 23 15 20 0 39 5 43 11 5 8 -3 10 -25 7 -17 -2 -31 -4 -31 -3 0 1 23 16 51
34 73 45 53 51 -26 7 -37 -20 -63 -31 -59 -25 4 7 8 27 10 46 2 34 2 34 -17
17 -11 -10 -34 -44 -51 -76 -17 -32 -35 -58 -40 -58 -6 0 -7 8 -3 18 20 47 13
51 -11 7 -40 -76 -80 -114 -67 -64 4 14 8 17 13 8 5 -7 22 17 46 67 21 43 40
82 42 87 2 4 -1 7 -6 7 -6 0 -19 -15 -31 -32 -19 -31 -27 -27 -21 11 1 13 -1
13 -15 1 -35 -29 -83 -159 -63 -171 6 -4 -4 -21 -27 -45 -21 -22 -34 -31 -31
-21 3 10 12 46 20 80 8 34 18 71 22 82 5 15 4 17 -5 9 -7 -6 -19 -29 -26 -50
-10 -28 -14 -32 -15 -17 0 13 7 40 15 61 13 32 27 44 88 75 87 44 162 62 327
77 69 7 127 14 129 16 2 2 11 4 20 4 9 0 -19 -36 -65 -82z m-1600 -133 c44
-50 90 -101 101 -115 20 -24 19 -24 -12 2 -18 15 -42 29 -52 33 -11 3 -38 30
-61 61 -42 54 -68 68 -36 19 27 -42 9 -28 -29 21 -19 27 -35 40 -35 31 0 -9
20 -41 44 -70 44 -55 44 -55 21 -65 -19 -9 -26 -7 -43 11 -11 12 -25 32 -31
44 -6 12 -20 21 -31 20 -24 -2 -24 1 -1 -46 22 -43 51 -72 51 -51 0 8 3 11 6
7 3 -3 -1 -15 -9 -27 l-15 -22 -14 21 c-7 11 -17 21 -22 21 -4 0 -1 -12 9 -26
14 -23 15 -28 2 -42 -8 -10 -17 -24 -20 -32 -4 -10 -6 -8 -6 6 -1 12 -8 27
-16 34 -10 9 -15 31 -15 74 0 78 18 150 54 210 l27 47 26 -38 c14 -21 62 -79
107 -128z m1082 59 c-3 -8 -11 -45 -16 -82 -6 -37 -14 -78 -17 -91 -5 -18 -3
-22 8 -16 29 18 -28 -59 -68 -93 l-43 -35 1 44 c1 39 2 41 8 14 9 -41 17 -23
26 55 8 68 -3 77 -15 13 -7 -35 -8 -36 -9 -8 0 17 6 52 14 79 8 27 13 51 10
54 -11 12 -36 -76 -49 -173 -14 -112 -32 -162 -21 -60 3 33 8 70 11 83 2 12 2
22 -1 22 -3 0 -10 -10 -16 -22 -9 -23 -8 8 6 102 l5 35 -18 -39 c-16 -35 -19
-37 -26 -20 -6 15 2 27 36 58 24 21 63 52 87 67 50 32 97 39 87 13z m-690 -36
c4 -13 20 -38 35 -56 34 -38 36 -61 9 -93 l-19 -24 -1 40 c0 26 -3 35 -9 27
-6 -9 -12 1 -20 32 -15 60 -24 59 -18 -1 2 -27 2 -38 0 -25 -4 20 -23 32 -23
15 0 -5 -15 -9 -34 -10 -30 -1 -33 1 -28 20 3 16 2 19 -7 11 -6 -6 -17 -38
-24 -72 -13 -61 -13 -62 -35 -46 -31 22 -30 83 1 113 34 31 129 89 149 90 9 1
20 -9 24 -21z m289 -166 c-58 -85 -84 -102 -148 -101 l-42 0 58 26 c31 14 57
30 57 35 0 6 -6 8 -12 5 -97 -39 -118 -45 -118 -38 0 5 38 25 85 46 47 21 90
44 96 52 6 7 10 13 7 13 -11 0 -179 -78 -195 -91 -17 -13 -17 -13 -4 4 8 10
28 25 45 34 17 9 26 18 19 20 -7 2 -21 -1 -33 -7 -24 -13 -60 -21 -60 -14 0
11 88 44 120 44 19 1 62 12 95 24 33 13 65 24 72 25 7 0 -12 -34 -42 -77z
m-615 20 c19 -15 57 -34 85 -44 45 -15 47 -17 24 -23 -15 -4 -55 4 -102 18
-42 14 -77 21 -77 17 0 -10 101 -47 160 -60 36 -7 27 -8 -50 -5 -52 2 -111 7
-130 10 l-35 5 35 -16 c19 -9 69 -20 110 -24 41 -4 64 -8 50 -10 -20 -2 -16
-5 15 -11 27 -5 7 -7 -60 -4 -108 4 -113 -10 -8 -23 l58 -8 -61 -13 c-70 -15
-97 -6 -149 49 -16 17 -43 38 -58 46 l-28 15 36 43 c65 80 116 90 185 38z
m807 -73 c-7 -72 -7 -93 4 -99 10 -7 10 -14 -2 -37 -13 -26 -14 -21 -19 87 -6
135 -21 108 -17 -31 2 -95 -14 -149 -40 -128 -9 8 -13 38 -13 101 0 99 -19
116 -22 20 l-1 -57 -7 60 -7 60 -1 -63 -2 -63 -28 15 c-55 28 -56 39 -13 94
47 59 95 98 134 107 15 4 30 11 33 16 10 16 10 10 1 -82z m1513 59 l35 -15
-38 -16 c-68 -29 -253 -167 -224 -167 4 0 53 32 109 70 155 107 226 120 348
67 30 -13 101 -38 157 -56 56 -17 104 -35 107 -40 3 -5 -12 -16 -34 -25 -22
-9 -70 -46 -106 -82 -146 -145 -305 -208 -439 -174 -16 4 -24 8 -17 9 18 2 15
21 -5 21 -22 0 -194 52 -189 57 2 3 28 -3 57 -11 63 -19 72 -20 63 -4 -5 8 -2
9 12 4 10 -4 41 -12 69 -17 27 -6 60 -12 73 -15 12 -2 22 -1 22 3 0 5 -46 19
-103 33 -86 21 -97 25 -72 31 17 4 41 7 55 7 22 1 23 0 5 -8 -16 -6 -17 -9 -5
-14 61 -23 267 -46 256 -28 -4 7 -31 13 -59 13 -42 1 -46 3 -22 8 25 5 27 8
12 14 -10 4 -27 7 -37 7 -11 0 -43 5 -72 12 -44 10 -49 12 -28 18 14 3 47 0
75 -7 35 -9 79 -11 143 -7 100 7 107 22 12 26 l-55 1 60 6 60 7 -70 8 -70 9
108 28 c111 28 154 47 82 36 -119 -19 -115 -19 -55 10 31 15 49 28 41 31 -10
3 -7 9 10 18 18 10 20 13 7 14 -30 0 -121 -41 -182 -82 -33 -22 -75 -47 -95
-55 l-36 -15 35 32 c24 22 29 30 15 26 l-20 -6 20 16 c11 9 53 32 93 51 76 37
89 49 45 39 l-28 -5 30 15 30 15 -39 -5 c-57 -8 -240 -115 -224 -132 4 -4 15
-1 25 7 9 8 14 9 11 4 -13 -22 -114 -83 -148 -90 -23 -5 -31 -4 -25 3 35 33
172 135 221 163 32 18 53 34 47 36 -22 8 -126 -55 -223 -136 -30 -25 -44 -33
-30 -17 13 15 48 45 77 68 30 22 51 43 48 46 -8 8 -79 -43 -167 -121 -46 -41
-96 -77 -113 -81 -29 -7 -27 -5 24 48 77 79 54 83 -27 5 -37 -35 -61 -57 -54
-49 23 27 12 34 -15 9 -28 -24 -28 -23 1 8 16 18 33 30 39 26 5 -3 41 26 81
64 39 39 92 85 118 103 53 37 57 51 9 25 -53 -27 -34 -8 26 26 75 43 51 50
-29 8 -47 -25 -98 -68 -203 -175 -133 -134 -190 -181 -165 -134 14 26 31 36
24 13 -4 -10 39 29 94 87 56 58 101 110 101 116 1 7 13 19 27 29 40 26 20 30
-23 4 -53 -32 -134 -110 -213 -204 -41 -48 -80 -85 -99 -92 l-31 -11 22 39
c32 57 149 211 156 204 3 -3 -7 -19 -23 -35 -16 -16 -26 -32 -23 -35 3 -3 61
51 129 121 128 130 143 142 194 155 47 12 176 4 215 -13z m-340 -36 c0 -14
-146 -153 -153 -146 -13 13 -67 -48 -139 -156 -83 -123 -78 -118 -71 -89 5 16
3 20 -6 15 -7 -5 -3 10 8 33 24 46 32 52 24 19 -8 -31 3 -19 66 73 28 42 85
109 126 149 41 39 75 74 75 76 0 28 -124 -86 -192 -175 -27 -36 -56 -66 -64
-67 -17 -3 -17 -3 40 69 43 54 37 61 -14 15 -19 -18 -4 0 34 39 82 85 82 109
1 32 -63 -60 -125 -149 -180 -259 -42 -84 -75 -134 -82 -126 -3 3 9 30 26 60
32 58 39 82 19 70 -12 -7 4 20 69 125 47 73 18 59 -32 -16 -64 -98 -66 -100
-45 -56 27 56 25 64 -7 28 l-26 -30 28 50 c16 28 46 76 68 108 49 73 42 85
-11 19 -50 -64 -149 -255 -134 -260 8 -2 8 -10 0 -27 -6 -14 -15 -22 -19 -19
-5 2 -20 -17 -34 -43 -37 -69 -21 -18 27 85 41 87 61 142 52 142 -3 0 -44 -83
-93 -183 -48 -101 -96 -187 -105 -190 -19 -8 -19 -9 -1 42 8 22 11 41 7 41 -5
0 -14 -16 -21 -36 -7 -19 -15 -34 -17 -31 -2 2 14 57 36 122 22 66 37 121 35
124 -7 6 -52 -98 -66 -151 -6 -26 -15 -45 -20 -43 -4 3 -13 -3 -19 -12 -21
-36 1 40 37 122 19 44 37 86 40 94 4 10 2 12 -6 6 -17 -10 -79 -141 -87 -183
-4 -21 -9 -29 -14 -22 -5 8 -11 0 -18 -21 -6 -19 -18 -60 -27 -91 -10 -32 -24
-63 -33 -70 -13 -11 -14 -10 -9 7 23 79 50 197 52 231 1 22 10 54 19 72 19 37
20 46 5 36 -6 -4 -25 -36 -41 -72 -16 -36 -36 -71 -44 -79 -14 -14 -18 -30
-48 -165 -15 -66 -38 -108 -59 -108 -3 0 0 18 8 40 8 22 10 40 5 40 -13 0 -13
42 0 76 6 15 9 29 6 31 -2 2 -33 -56 -69 -129 -36 -73 -76 -144 -90 -156 l-25
-24 24 67 c27 74 29 89 12 79 -7 -4 -2 9 10 30 24 40 30 72 8 44 -7 -10 -16
-15 -19 -12 -11 12 -65 -87 -82 -151 -10 -35 -22 -62 -26 -59 -5 3 0 27 10 54
11 28 15 50 10 50 -5 0 -9 -1 -9 -2 0 -2 -12 -30 -26 -63 -14 -33 -23 -50 -21
-37 3 12 2 22 -3 22 -8 0 -26 -64 -34 -124 -6 -44 -37 -98 -44 -77 -3 8 3 18
12 23 9 5 16 22 16 37 0 15 6 49 14 74 8 26 10 47 6 47 -10 0 -50 -111 -57
-160 -5 -29 -7 -32 -14 -15 -6 17 -8 17 -8 3 -1 -10 -6 -18 -12 -18 -7 0 -9 9
-5 23 9 39 105 207 151 266 40 51 67 79 193 196 l43 40 -27 -70 c-14 -38 -28
-79 -30 -90 -5 -24 60 104 100 199 23 53 42 80 95 127 55 50 281 211 288 205
1 -1 -13 -40 -31 -86 -19 -47 -37 -96 -40 -110 -15 -55 23 13 64 115 50 125
88 171 179 219 129 67 196 88 316 96 61 4 118 8 128 8 9 1 17 -1 17 -6z
m-3891 -26 c45 -43 139 -93 207 -112 58 -15 78 -16 156 -6 141 17 165 5 360
-185 69 -68 132 -123 138 -123 24 0 -216 241 -291 292 -19 12 -21 17 -9 21
157 54 184 60 260 54 42 -3 99 -15 126 -26 27 -10 85 -24 129 -30 69 -10 205
-55 205 -68 0 -2 -7 -18 -16 -34 -15 -29 -16 -30 -29 -12 -8 10 -15 25 -15 34
0 22 -20 39 -46 39 -13 0 -26 5 -29 10 -10 17 -27 12 -17 -5 6 -11 -2 -27 -29
-57 l-38 -40 -27 31 c-14 18 -32 30 -38 28 -6 -2 -35 18 -64 45 -74 67 -88 60
-21 -9 31 -32 72 -78 91 -103 32 -41 34 -48 24 -73 l-12 -27 -40 48 c-45 56
-124 129 -124 115 0 -5 16 -25 36 -44 54 -52 124 -148 124 -171 0 -17 -6 -13
-29 18 -52 68 -99 119 -105 113 -4 -4 15 -33 40 -65 76 -95 74 -94 -26 10 -52
54 -105 112 -117 129 -14 19 -23 25 -23 15 0 -21 28 -56 130 -164 47 -50 93
-106 103 -125 18 -34 18 -34 -15 6 -62 75 -96 110 -102 104 -3 -3 18 -34 46
-70 28 -35 39 -53 25 -40 -38 34 -42 21 -7 -20 29 -34 38 -59 21 -59 -5 0 -25
21 -44 48 -40 53 -127 141 -127 127 0 -4 23 -35 51 -67 27 -32 42 -55 33 -51
-20 8 -15 -3 28 -58 15 -20 28 -45 28 -55 -1 -24 -14 -8 -110 131 -74 108
-177 225 -198 225 -7 0 20 -37 60 -82 41 -46 89 -105 107 -133 l33 -50 -42 43
c-45 46 -70 50 -29 4 40 -43 134 -168 117 -154 -9 7 -35 38 -58 68 -24 30 -48
54 -55 54 -7 0 -34 28 -58 63 -48 66 -205 222 -215 212 -3 -3 33 -45 80 -93
47 -48 117 -130 155 -182 91 -125 91 -125 62 -110 -42 22 -51 32 -38 41 13 8
-77 130 -165 223 -77 83 -63 43 18 -51 42 -48 76 -97 76 -108 0 -12 5 -26 12
-33 7 -7 9 -12 6 -12 -4 0 -26 27 -50 61 -23 34 -44 56 -46 50 -2 -6 11 -31
29 -57 18 -26 27 -44 20 -39 -7 4 -54 71 -106 149 -85 130 -166 226 -188 226
-5 0 6 -17 25 -38 37 -40 101 -126 132 -177 l19 -30 -23 20 -24 20 25 -45 c14
-25 34 -58 44 -75 18 -27 18 -28 2 -16 -10 8 -46 62 -80 120 -56 97 -161 221
-175 207 -3 -3 16 -28 41 -55 45 -48 83 -103 141 -205 49 -85 -2 -38 -58 55
-28 45 -58 85 -66 87 -11 4 -10 -2 5 -24 10 -16 30 -49 44 -74 22 -39 22 -43
5 -27 -12 9 -25 17 -31 17 -5 0 -18 10 -29 23 l-19 22 23 -19 c12 -11 22 -15
22 -10 0 16 -98 166 -135 208 -53 59 -53 36 0 -31 46 -58 100 -143 91 -143 -2
0 -15 17 -27 37 -13 20 -25 31 -27 25 -2 -7 -24 20 -50 58 -25 38 -52 72 -59
75 -16 5 -13 1 27 -60 15 -22 33 -51 40 -64 22 -42 -67 40 -105 96 -34 51 -75
92 -75 77 0 -5 -14 7 -30 26 -19 22 -30 29 -30 19 0 -8 15 -33 33 -55 61 -73
62 -75 42 -68 -32 13 -7 -15 33 -36 20 -11 53 -34 72 -51 l35 -31 -33 17 c-18
9 -39 14 -45 12 -7 -3 22 -21 64 -40 70 -33 105 -57 80 -57 -5 0 -38 9 -73 20
-75 23 -90 25 -64 5 28 -21 12 -19 -34 5 -22 11 -40 17 -40 12 0 -14 82 -55
135 -68 27 -7 41 -13 30 -14 -15 -1 -11 -5 15 -16 l35 -14 -35 6 c-19 3 -57
12 -83 21 -62 19 -70 12 -15 -13 24 -10 48 -25 54 -34 9 -10 41 -16 120 -19
86 -4 99 -7 64 -12 -39 -5 -34 -6 32 -7 72 -2 128 -16 128 -32 0 -9 -126 -30
-192 -31 -29 -1 -66 -5 -83 -8 l-30 -7 40 -12 c22 -7 60 -12 85 -12 33 0 41
-3 30 -10 -11 -7 -5 -10 25 -11 l40 -1 -45 -10 c-32 -7 -61 -7 -100 2 -49 11
-63 20 -131 91 -86 89 -128 161 -157 269 -75 287 -77 337 -13 276z m1647 -26
c19 -61 18 -63 -18 -90 -24 -17 -30 -19 -25 -7 4 10 2 17 -3 17 -5 0 -12 -9
-15 -21 -4 -11 -9 -19 -11 -16 -2 2 3 21 11 42 17 39 15 55 -2 33 -15 -19 -43
-104 -44 -136 -1 -21 -2 -23 -7 -7 l-5 20 -8 -20 c-7 -18 -8 -18 -8 5 0 14 4
44 8 68 5 23 5 42 0 42 -4 0 -11 -18 -16 -40 -6 -28 -12 -37 -21 -32 -26 17
-1 74 59 135 67 70 86 71 105 7z m805 -59 c143 -89 192 -137 203 -196 4 -20 5
-40 2 -43 -3 -3 -6 3 -6 13 0 28 -30 55 -61 55 -16 0 -58 -16 -95 -35 -103
-55 -162 -45 -215 33 l-30 44 27 -21 c31 -24 52 -28 34 -6 -11 13 -8 13 24 0
22 -9 36 -11 36 -5 0 7 6 8 19 0 11 -5 28 -10 38 -10 10 1 -7 14 -37 31 -30
17 -47 28 -37 25 33 -10 17 9 -27 33 -25 13 -53 32 -63 44 -18 20 -18 20 44
14 40 -3 88 -17 133 -38 38 -17 73 -30 76 -27 7 7 -53 44 -102 63 -28 11 -31
14 -11 10 18 -4 26 -2 22 4 -3 6 -21 13 -40 17 -19 3 -35 10 -35 16 0 13 70
-1 101 -21z m688 -46 c-25 -68 -117 -207 -139 -210 -8 -2 -53 -14 -100 -29
-47 -14 -120 -31 -162 -38 -76 -12 -95 -22 -60 -31 9 -3 3 -5 -15 -6 -17 0
-33 4 -35 9 -2 7 -21 5 -55 -3 l-51 -14 16 34 c9 18 18 29 21 24 6 -10 150 3
241 21 45 9 51 12 30 18 -22 5 -20 7 23 19 26 8 50 18 54 24 4 6 -3 8 -18 3
-38 -10 -140 -33 -183 -41 -55 -9 -43 16 15 31 l44 12 -44 1 c-82 2 -28 18
114 35 77 9 144 21 150 27 7 8 0 10 -25 6 -79 -14 -165 -17 -145 -7 15 8 4 9
-45 6 -36 -3 -73 -8 -83 -11 -11 -5 -17 -2 -17 6 0 9 12 14 33 15 67 1 274 23
323 34 29 6 60 18 70 26 18 15 18 15 -6 9 -96 -23 -167 -35 -245 -39 -86 -5
-87 -5 -21 4 104 13 216 40 216 52 0 5 -6 8 -14 5 -8 -3 -32 -9 -53 -12 l-38
-6 30 15 c24 12 26 15 10 16 -11 0 18 12 64 26 46 14 91 29 100 34 24 13 24
-1 0 -65z m-1054 33 c-28 -15 -85 -70 -85 -82 0 -5 10 0 23 11 12 11 35 28 50
38 l28 18 -20 -35 c-12 -19 -23 -57 -26 -84 l-5 -49 18 52 c9 28 21 55 26 60
5 5 6 0 1 -12 -4 -11 -9 -47 -11 -80 l-3 -60 14 63 c14 60 43 129 32 75 -3
-13 -1 -23 4 -23 5 0 9 6 10 13 0 6 5 -1 10 -18 5 -16 6 -39 3 -50 -5 -19 -4
-19 9 -3 12 16 14 14 12 -16 -1 -19 -14 -49 -28 -67 l-25 -32 -49 20 c-40 16
-51 26 -63 59 -8 22 -25 53 -37 68 -13 16 -23 38 -23 50 0 29 46 72 90 84 49
14 70 14 45 0z m-296 -12 c6 -8 25 -19 41 -26 26 -11 30 -18 30 -49 0 -44 -30
-95 -67 -115 -39 -19 -96 -29 -123 -21 -20 6 -18 9 21 27 34 15 40 21 29 28
-8 5 -10 10 -4 10 14 0 97 60 91 66 -2 2 -43 -16 -91 -42 -87 -46 -116 -54
-50 -14 42 27 39 38 -5 15 -52 -26 -35 -8 30 32 34 20 57 39 51 41 -11 3 -63
-20 -93 -42 -8 -6 -2 1 13 15 15 14 28 29 28 33 0 12 -41 -15 -88 -58 l-45
-41 21 40 c11 22 31 50 44 62 49 46 143 68 167 39z m-387 -99 c4 -32 17 -78
28 -104 36 -82 37 -116 1 -186 -17 -34 -43 -75 -56 -92 l-26 -30 11 28 c5 16
7 40 4 54 l-7 26 -14 -24 c-14 -23 -14 -23 -9 11 14 95 18 144 11 138 -5 -4
-11 -25 -15 -45 -6 -31 -8 -24 -10 42 l-3 80 -12 -90 c-8 -60 -13 -76 -14 -50
-1 22 -6 47 -11 55 -7 10 -10 -7 -10 -59 0 -59 -4 -76 -17 -87 -16 -11 -19 -7
-30 39 -21 92 -17 129 27 214 44 84 101 146 128 141 13 -2 19 -18 24 -61z
m-255 -20 c1 -19 10 -52 22 -75 14 -27 21 -61 21 -99 1 -32 7 -85 15 -118 18
-75 18 -103 -1 -50 l-14 40 4 -45 4 -45 -14 50 c-8 28 -19 59 -24 70 -16 36
-12 -25 6 -84 8 -29 13 -56 10 -59 -3 -3 -6 2 -6 11 0 9 -5 19 -11 23 -9 5
-10 -2 -5 -22 l6 -29 -16 28 c-19 35 -44 148 -44 205 0 23 -4 42 -10 42 -21 0
0 -168 30 -239 14 -33 15 -40 4 -26 -8 11 -19 19 -24 18 -13 -4 -29 60 -41
162 -6 49 -13 92 -15 94 -2 2 -4 -31 -5 -75 0 -63 -2 -74 -10 -54 -13 32 -2
123 21 177 21 49 77 133 88 133 5 0 8 -15 9 -33z m2798 -87 c17 -7 16 -9 -10
-10 -28 -2 -28 -3 10 -12 22 -5 74 -20 115 -33 41 -14 97 -28 124 -32 l49 -8
-124 -103 c-68 -57 -146 -116 -174 -130 -70 -37 -142 -35 -268 6 -54 18 -133
39 -175 46 -43 8 -68 15 -57 16 l20 1 -20 9 c-40 17 12 10 116 -15 56 -13 136
-27 178 -30 73 -5 70 -4 -64 25 -77 17 -185 40 -240 50 l-100 20 85 -5 c58 -4
80 -2 70 5 -8 5 -30 10 -49 10 -19 0 -32 3 -28 6 3 4 37 3 74 -1 37 -5 60 -10
51 -12 -38 -9 -14 -21 85 -42 153 -32 215 -41 291 -41 99 0 74 12 -45 21 -99
8 -314 47 -305 56 2 3 47 -5 98 -16 96 -21 316 -39 268 -22 -14 5 -43 9 -65
10 -77 2 -272 43 -272 57 0 17 -10 18 109 -6 102 -21 145 -21 67 0 -72 20
-172 40 -204 41 -28 1 -29 1 -6 8 38 10 38 31 -1 32 -31 0 -31 1 -9 10 30 12
210 -5 314 -30 42 -10 77 -14 77 -9 0 5 -13 11 -30 15 -16 3 -30 11 -30 19 0
11 -49 25 -170 48 -14 3 4 3 40 1 36 -2 117 -20 180 -39 125 -38 220 -58 220
-46 0 4 -15 10 -32 14 -55 11 -248 69 -244 73 2 3 54 -10 115 -27 143 -41 167
-34 30 9 -111 34 -111 34 -149 56 -24 14 -24 15 20 14 25 0 54 -4 65 -9z
m-2262 -34 c36 -15 72 -51 81 -82 9 -31 -19 -89 -59 -120 -32 -24 -47 -28
-118 -30 -45 -2 -93 -4 -106 -6 -29 -4 -46 21 -37 55 9 36 20 34 35 -8 7 -19
17 -35 22 -35 12 0 12 4 -6 47 -17 41 -19 57 -5 48 6 -4 10 6 11 22 0 23 3 19
15 -19 17 -55 30 -64 20 -15 l-6 32 20 -34 c26 -41 37 -35 15 9 -9 18 -14 34
-11 38 3 3 6 0 6 -7 0 -18 80 -101 98 -101 12 0 11 5 -6 23 -12 13 -19 27 -15
30 3 4 -3 17 -14 30 -11 12 -29 36 -39 52 l-18 30 27 -24 c26 -24 39 -17 15 7
-7 7 -9 12 -5 12 4 0 28 -19 52 -41 64 -59 69 -39 6 22 -51 49 -35 51 23 2 16
-13 31 -21 34 -18 10 9 -42 57 -83 76 -38 17 -38 19 -12 19 15 0 42 -6 60 -14z
m1227 -91 c-58 -59 -131 -86 -357 -131 -13 -2 -23 -10 -23 -18 0 -15 -77 -39
-85 -26 -8 13 75 48 155 65 89 18 182 49 230 75 53 29 15 24 -71 -10 -42 -16
-117 -38 -167 -49 -50 -11 -101 -25 -112 -31 -12 -6 -20 -7 -20 -1 0 21 33 40
87 51 90 19 166 41 160 46 -4 5 -94 -11 -182 -33 -16 -3 -17 -2 -5 6 13 10 13
11 0 11 -11 0 -10 3 4 11 14 9 20 8 26 -1 4 -7 12 -10 17 -7 5 3 49 13 97 21
49 9 96 22 105 29 9 8 32 17 51 21 25 4 31 3 20 -4 -24 -16 39 -12 75 5 17 7
32 14 35 14 2 1 -16 -19 -40 -44z m-1018 -6 c34 -17 52 -20 97 -15 66 7 108
-13 141 -66 24 -40 27 -125 5 -154 -14 -19 -14 -19 -15 2 0 32 -31 114 -42
114 -6 0 -7 -8 -3 -17 3 -10 8 -61 9 -113 3 -94 3 -94 -9 -42 -15 63 -31 84
-21 27 24 -145 16 -146 -25 -2 -12 42 -26 77 -31 77 -5 0 -6 -10 -2 -22 15
-49 14 -51 -6 -8 -29 61 -83 150 -92 150 -4 0 15 -44 42 -97 65 -132 76 -179
22 -98 -43 64 -88 112 -98 103 -3 -3 9 -22 26 -42 17 -19 29 -40 25 -46 -4 -6
12 -39 34 -74 43 -65 47 -76 32 -76 -5 0 -23 22 -40 50 -17 27 -56 77 -87 111
-91 100 -106 163 -53 224 17 19 35 35 40 35 6 0 28 -9 51 -21z m499 -105 c8
-30 28 -64 56 -96 23 -27 43 -57 43 -68 0 -31 -29 -131 -44 -155 -20 -31 -98
-67 -149 -70 -23 -1 -32 -1 -21 2 38 7 129 67 132 87 4 17 0 16 -28 -8 -18
-15 -46 -33 -64 -42 -17 -8 -22 -9 -12 -2 72 47 144 140 133 171 -2 7 -13 -7
-25 -30 -12 -23 -27 -45 -34 -49 -10 -6 -10 -5 -1 7 16 20 34 69 25 69 -4 0
-16 -16 -27 -36 -21 -40 -70 -86 -117 -110 l-32 -17 27 33 c38 45 25 51 -21 9
-20 -18 -8 -3 28 34 59 62 75 91 26 50 -11 -10 1 7 27 39 75 90 55 81 -48 -22
-105 -104 -126 -109 -39 -9 55 63 94 126 94 151 0 7 -16 -12 -36 -42 -20 -30
-51 -71 -69 -90 -18 -19 -42 -51 -54 -70 -26 -44 -27 -19 -1 39 23 50 24 55 9
45 -6 -3 -20 -25 -31 -48 l-19 -41 6 40 c4 21 14 53 22 69 21 40 194 205 215
206 13 0 21 -13 29 -46z m-1356 -91 c19 -40 15 -49 -14 -33 -16 8 -21 8 -21
-2 0 -9 -3 -9 -12 0 -28 28 -29 9 -1 -20 17 -18 28 -39 26 -46 -8 -20 18 -43
46 -41 14 1 34 1 44 1 16 -2 17 -4 3 -17 -9 -9 -35 -15 -68 -16 -51 -1 -52 -2
-15 -9 21 -4 50 -5 65 -3 21 4 24 2 12 -6 -8 -6 -10 -11 -5 -12 22 -1 -32 -18
-62 -18 -17 -1 -35 -6 -39 -13 -5 -8 7 -9 46 -5 62 7 61 0 -1 -13 l-44 -9 57
2 c63 2 65 -3 13 -27 -30 -14 -42 -15 -71 -6 -58 19 -63 36 -57 198 4 108 10
147 21 158 13 13 17 13 38 -7 12 -12 30 -37 39 -56z m2492 1 c51 -9 97 -13
102 -10 5 4 22 1 38 -5 15 -6 50 -16 77 -23 l50 -13 -110 -58 c-60 -32 -152
-81 -204 -111 -68 -38 -117 -57 -172 -68 -81 -17 -210 -21 -173 -7 17 7 14 10
-20 19 -22 6 -60 14 -85 18 -25 4 -39 9 -33 11 7 3 47 -2 90 -10 128 -25 143
-27 143 -18 0 4 -58 18 -130 32 -88 16 -124 26 -113 32 14 9 61 5 123 -10 18
-4 22 -3 14 6 -6 6 -59 19 -119 28 -75 12 -100 20 -84 24 l24 7 -25 2 c-49 3
11 19 70 18 l55 -1 -45 -6 -45 -7 60 -8 c33 -4 105 -17 160 -28 55 -11 111
-22 125 -24 23 -4 22 -3 -5 11 -16 8 -48 18 -70 21 -31 6 -35 9 -20 14 29 10
-109 40 -186 40 -34 0 -60 2 -57 5 3 3 59 6 124 7 116 2 118 2 69 14 -27 7
-72 13 -100 13 -46 2 -49 3 -31 16 27 20 203 20 316 0 100 -18 103 -18 96 -6
-4 5 -30 13 -59 17 -28 4 -44 9 -35 11 15 4 162 -27 201 -42 9 -4 17 -3 17 2
0 24 -202 65 -370 77 -30 2 4 2 75 0 72 -2 140 -7 153 -10 12 -4 22 -2 22 4 0
6 -30 14 -67 18 -38 3 -77 11 -88 15 -28 13 129 1 242 -17z m-2997 6 c-11 -7
-11 -10 -2 -10 7 0 10 -5 7 -10 -4 -7 9 -9 37 -5 l43 5 -45 -16 c-64 -22 -179
-29 -235 -15 -26 7 -50 10 -53 7 -8 -9 50 -27 106 -33 28 -3 52 -10 52 -15 0
-16 109 3 223 38 120 37 134 39 172 22 29 -13 33 -28 7 -28 -25 0 -145 -40
-157 -53 -7 -6 0 -7 20 -3 24 5 26 4 12 -4 -39 -24 -161 -42 -247 -36 -59 5
-80 3 -70 -4 8 -5 32 -10 52 -10 30 0 36 -3 31 -16 -5 -15 4 -16 88 -12 77 4
110 12 184 41 96 38 165 55 165 40 0 -4 -21 -13 -47 -20 -62 -15 -107 -32
-101 -39 3 -2 38 4 77 15 39 11 71 16 71 10 0 -5 -8 -9 -17 -9 -10 0 -61 -16
-114 -34 -53 -19 -116 -37 -140 -42 -43 -8 -43 -8 -14 -15 29 -6 29 -7 -10 -9
-22 -1 -65 -2 -95 -1 -48 1 -52 -1 -30 -10 41 -18 186 -5 260 23 38 15 73 22
84 18 13 -4 -12 -16 -85 -41 -114 -37 -114 -37 -108 -43 2 -2 34 4 71 13 37 9
65 13 62 9 -8 -13 12 -9 68 12 60 23 68 24 68 10 0 -5 -8 -10 -17 -10 -9 0
-48 -14 -87 -32 -83 -37 -81 -36 -73 -44 3 -3 26 4 52 15 59 26 51 13 -11 -18
-65 -33 -116 -30 -195 9 -169 86 -289 186 -330 275 -32 69 -28 74 40 57 55
-13 66 -13 143 6 99 23 108 25 88 12z m1296 -88 c22 -11 48 -29 58 -41 l19
-21 -34 13 c-48 20 -89 29 -89 20 0 -5 21 -16 48 -25 26 -9 56 -24 67 -32 12
-9 -5 -6 -40 8 -66 26 -135 39 -135 26 0 -4 17 -11 38 -15 37 -7 130 -42 145
-55 10 -8 -23 -6 -129 10 -80 12 -168 7 -179 -10 -3 -5 34 -7 82 -5 70 2 75 2
26 -4 -34 -3 -65 -10 -70 -15 -5 -5 15 -6 47 -3 59 7 163 -6 212 -26 27 -11
23 -12 -44 -12 -53 0 -72 -3 -71 -12 2 -8 -9 -13 -30 -14 -17 0 -48 -7 -67
-15 l-35 -14 45 5 c139 16 180 18 180 6 0 -6 -12 -11 -27 -12 -16 0 -37 -4
-48 -9 -13 -6 10 -8 65 -5 l85 5 -58 -15 c-32 -8 -68 -20 -80 -27 -24 -12
-178 -22 -214 -13 -24 6 -73 80 -73 110 0 10 11 37 24 59 13 23 31 67 40 98
16 56 17 57 59 61 72 8 143 0 183 -21z m-395 -158 c25 -47 29 -95 9 -119 -17
-21 -136 -75 -164 -75 -11 0 -26 4 -33 8 -18 12 -43 77 -43 114 l0 32 29 -45
c15 -24 42 -52 60 -62 39 -23 41 -6 2 22 -15 11 -36 33 -46 48 l-18 28 29 -26
c36 -33 94 -63 94 -49 0 5 -4 10 -8 10 -4 0 -23 14 -42 31 -27 23 -29 26 -8
14 45 -28 53 -11 9 18 -22 15 -41 29 -41 31 0 1 25 -10 56 -26 31 -15 66 -28
78 -27 13 0 -3 12 -41 29 -35 16 -63 32 -63 35 0 3 14 -2 31 -10 53 -28 43 -8
-14 28 -65 42 -76 56 -40 49 18 -3 24 -1 19 7 -4 6 -16 11 -27 11 -11 0 -18 4
-15 9 4 5 41 -3 85 -17 71 -25 81 -31 102 -68z m1441 16 c44 0 48 -2 34 -15
-8 -8 -31 -15 -51 -15 -22 0 -34 -4 -30 -10 3 -5 27 -10 53 -10 26 -1 102 -13
170 -28 l124 -28 -40 -26 c-71 -47 -127 -61 -282 -67 -158 -7 -195 -19 -284
-90 -21 -17 -42 -31 -45 -31 -3 0 3 7 13 15 20 15 20 15 0 16 -15 1 -14 3 6 9
l25 8 -25 2 c-22 2 -21 3 9 12 26 7 31 12 20 19 -10 6 -7 9 13 9 15 0 29 4 32
8 7 12 -68 1 -76 -12 -6 -8 -8 -8 -8 0 0 21 27 35 79 41 51 6 66 18 34 26 -10
3 17 5 60 6 86 1 108 20 25 22 -29 1 -64 4 -78 8 -25 7 -25 7 -3 13 12 3 23 3
25 -1 2 -4 55 -5 118 -2 l115 5 -70 11 -70 12 110 2 c86 1 106 4 91 13 -12 7
-60 9 -135 6 -64 -2 -104 -3 -88 0 15 2 34 14 43 27 11 17 27 25 57 28 39 3
38 4 -11 5 -35 1 -51 6 -47 13 4 6 14 8 22 5 8 -3 37 -6 65 -6z m-1892 -89 c0
-4 -30 -22 -67 -40 -38 -19 -63 -36 -56 -38 6 -2 31 5 55 17 45 22 45 21 -24
-33 -17 -14 -19 -17 -5 -13 11 3 15 2 12 -4 -3 -6 -12 -10 -19 -10 -7 0 -21
-7 -32 -16 -18 -15 -18 -15 8 -10 14 3 34 9 43 12 15 6 15 4 0 -32 -8 -21 -18
-40 -20 -42 -9 -9 -111 49 -155 88 l-45 39 40 2 c22 1 49 1 60 0 23 -2 184 70
185 83 0 4 -9 6 -19 3 -13 -3 -18 -1 -14 9 4 10 10 10 29 2 13 -6 24 -14 24
-17z m1289 -81 c22 -41 52 -86 67 -100 14 -14 33 -40 41 -58 13 -30 12 -39 -7
-90 -30 -79 -36 -85 -70 -67 -58 30 -131 139 -129 192 1 21 3 23 6 8 7 -29 23
-43 23 -20 0 13 9 5 26 -25 30 -52 32 -55 39 -48 3 3 -8 34 -25 69 -16 35 -30
71 -29 79 0 13 1 13 6 0 13 -38 57 -120 65 -120 11 0 11 1 -21 71 -16 35 -32
77 -36 93 l-6 30 20 -25 c11 -13 20 -20 21 -16 0 12 -41 90 -59 111 -15 18
-15 18 7 5 12 -8 40 -48 61 -89z m-1110 5 c-10 -38 -18 -97 -18 -130 l1 -60 9
50 c19 121 32 169 40 149 13 -32 10 -129 -5 -172 -17 -49 -80 -109 -121 -117
-39 -8 -124 11 -148 33 -36 32 -38 57 -11 129 33 88 59 134 90 156 23 17 23
16 -6 -29 -16 -26 -27 -50 -24 -53 3 -3 9 1 14 10 5 9 18 27 29 40 l21 24 -19
-40 c-11 -22 -16 -41 -11 -43 4 -2 -1 -25 -11 -53 -32 -83 -20 -96 14 -16 l17
42 -6 -45 c-8 -58 -1 -50 26 30 20 59 66 150 76 150 2 0 -4 -17 -15 -39 -22
-43 -46 -129 -39 -137 3 -3 16 25 29 61 14 40 26 60 30 53 9 -15 10 -14 34 40
10 23 19 41 21 39 1 -1 -6 -34 -17 -72z m386 6 c3 -5 0 -13 -6 -17 -8 -4 -9
-3 -5 4 12 21 -22 13 -82 -17 -31 -15 -85 -39 -119 -51 -35 -12 -63 -25 -63
-28 0 -2 19 -2 43 1 l42 6 -35 -14 c-34 -13 -34 -14 -8 -14 15 -1 49 8 76 18
27 11 54 16 61 12 16 -10 -115 -69 -186 -83 -29 -6 -53 -15 -53 -19 0 -5 -4
-9 -9 -9 -5 0 -3 17 5 38 7 20 13 53 14 72 0 35 0 36 41 32 25 -3 56 2 79 13
57 26 163 63 182 64 9 1 19 -3 23 -8z m291 -14 c-44 -29 -62 -47 -84 -86 -30
-54 -14 -53 25 2 17 23 46 51 64 61 l34 19 -28 -26 c-15 -15 -26 -31 -23 -36
3 -5 -1 -15 -10 -22 -9 -7 -27 -36 -42 -63 -20 -40 -26 -66 -27 -118 0 -37 5
-71 10 -77 7 -7 10 14 10 62 0 105 41 179 130 233 22 13 18 7 -13 -20 -40 -35
-100 -132 -89 -143 3 -2 17 16 32 41 32 55 90 108 147 136 l43 21 -30 -23
c-112 -85 -172 -166 -180 -245 l-5 -48 18 50 c22 63 53 113 88 145 l27 25 -25
-35 c-34 -48 -41 -60 -31 -60 5 0 26 22 48 50 21 27 52 59 69 71 l30 22 -23
-32 c-13 -18 -20 -35 -17 -38 3 -4 13 2 20 13 13 17 14 16 12 -19 -4 -46 -26
-71 -82 -93 -51 -19 -56 -29 -63 -114 l-6 -65 -42 2 c-23 1 -51 6 -62 12 -21
12 -91 150 -91 180 0 24 25 84 56 132 24 39 97 100 118 98 6 0 3 -6 -8 -12z
m313 -120 c1 -46 30 -105 78 -158 42 -46 35 -59 -30 -59 -32 0 -45 7 -83 46
-25 26 -52 60 -60 76 -15 29 -22 124 -10 135 3 4 6 -16 6 -43 1 -44 28 -123
30 -84 0 8 7 2 16 -15 22 -45 52 -81 60 -72 4 4 -1 15 -10 26 -10 11 -13 20
-7 22 7 3 6 21 -4 53 -8 27 -15 79 -14 115 l1 66 13 -35 c7 -19 14 -52 14 -73z
m545 -41 l69 -12 -71 -42 c-40 -24 -72 -47 -72 -53 0 -11 35 6 133 64 63 37
71 39 120 33 81 -10 117 -25 187 -76 36 -26 80 -55 99 -65 l35 -17 -27 -14
c-15 -8 -62 -28 -104 -45 -43 -17 -80 -34 -83 -37 -17 -17 85 14 177 54 l108
47 50 -27 c31 -16 84 -63 137 -120 l87 -94 -37 9 c-49 11 -159 11 -166 0 -3
-5 34 -11 82 -13 64 -4 104 -12 152 -32 98 -40 132 -78 171 -195 16 -46 33
-69 99 -132 44 -41 80 -81 80 -87 0 -29 -143 4 -253 59 -84 43 -142 52 -230
34 -232 -47 -505 -71 -626 -56 -145 18 -196 62 -291 249 -58 114 -81 152 -93
152 -10 0 134 -273 165 -312 36 -47 26 -48 -80 -12 -83 28 -184 76 -274 130
-105 64 -227 91 -279 64 -17 -10 -18 -8 -9 15 5 14 17 39 25 55 13 25 19 29
35 20 10 -5 27 -21 38 -35 11 -14 25 -25 32 -25 6 0 0 14 -14 31 -14 17 -23
35 -20 41 3 5 -2 16 -12 23 -11 8 -15 15 -9 15 5 0 42 -35 81 -77 79 -85 109
-98 41 -18 -23 27 -36 46 -29 42 24 -15 12 15 -18 45 -20 19 -36 28 -47 24
-13 -6 -14 -4 -3 9 7 9 23 21 34 27 12 6 18 7 14 3 -4 -4 8 -26 26 -49 18 -22
49 -66 68 -96 35 -56 96 -116 106 -105 3 3 -10 22 -29 43 -19 20 -42 46 -50
57 -13 19 -13 19 5 5 18 -14 19 -13 13 3 -10 28 0 20 23 -19 24 -38 38 -40 21
-2 -10 20 -10 21 1 4 52 -76 100 -136 109 -136 7 0 5 9 -4 23 -9 12 -45 65
-80 117 -66 97 -122 159 -133 147 -4 -4 -1 -13 6 -22 25 -30 4 -23 -27 10 -26
27 -28 32 -11 27 16 -4 19 -2 13 8 -5 7 6 5 24 -6 33 -19 92 -78 124 -123 9
-14 19 -22 23 -18 9 9 -60 100 -104 137 l-37 31 34 -18 c42 -23 71 -57 122
-146 49 -84 119 -181 126 -173 3 2 -8 24 -25 48 -16 24 -53 84 -82 133 -29 50
-69 105 -87 123 l-34 32 36 0 c24 0 34 -4 29 -11 -3 -6 16 -34 44 -62 32 -33
69 -87 99 -146 26 -52 54 -96 60 -98 7 -3 -12 45 -42 105 -30 60 -55 115 -55
123 0 7 -15 29 -32 49 -18 20 -5 8 28 -26 34 -34 78 -92 99 -128 20 -36 41
-68 46 -72 37 -23 -58 140 -124 212 -34 37 -52 62 -40 56 12 -7 42 -35 67 -64
54 -62 62 -51 11 15 -23 30 -29 44 -19 40 12 -5 15 -3 9 6 -6 10 -3 11 14 1
21 -11 91 -110 91 -127 0 -4 -11 9 -24 29 -28 46 -56 78 -69 78 -5 0 2 -15 17
-34 14 -19 52 -90 85 -158 58 -122 112 -208 131 -208 5 0 -9 28 -31 63 -43 66
-113 206 -106 213 2 2 18 -26 35 -64 39 -83 71 -136 78 -129 3 3 -9 33 -27 68
-46 91 -99 209 -94 209 3 0 19 -27 36 -61 16 -34 33 -59 36 -56 6 7 -47 120
-73 155 -16 21 -16 22 10 22 17 0 26 -5 26 -15 0 -9 11 -32 25 -53 13 -20 40
-77 59 -125 41 -105 63 -140 81 -133 9 3 26 -14 46 -46 34 -52 95 -118 111
-118 5 0 -16 29 -45 65 -43 52 -130 190 -151 240 -3 6 -8 4 -14 -3 -7 -11 -11
-8 -16 11 -3 14 -9 33 -12 44 -6 16 -5 16 9 -2 13 -15 16 -16 17 -5 0 8 26
-33 58 -92 64 -119 97 -166 108 -156 4 5 0 16 -8 25 -8 10 -18 25 -22 33 -4 8
3 2 16 -15 40 -52 101 -116 106 -111 2 3 -18 33 -45 68 -66 85 -93 128 -148
238 -25 51 -54 94 -63 97 -9 2 -25 15 -34 27 l-17 23 47 -9 c72 -13 80 -17
104 -51 l22 -32 -22 19 c-33 29 -27 13 36 -91 56 -93 137 -197 111 -143 -7 14
-24 43 -39 65 -14 22 -37 58 -49 80 l-23 40 26 -30 c37 -43 32 -15 -10 52
l-35 58 41 -45 c23 -25 39 -37 36 -27 -8 24 -1 21 19 -9 9 -15 12 -23 6 -20
-21 14 -10 -17 40 -114 50 -96 136 -217 146 -206 3 2 -4 18 -16 35 -12 16 -19
36 -15 44 3 8 -10 39 -29 68 -39 63 -108 189 -103 189 2 0 16 -18 30 -41 15
-22 30 -38 33 -35 5 5 -13 41 -52 99 -17 27 -17 27 6 19 15 -6 36 -32 58 -73
64 -122 83 -152 90 -140 5 7 19 -5 39 -31 51 -69 91 -112 97 -106 4 3 -25 48
-64 99 -39 51 -83 116 -98 146 -15 29 -33 53 -40 53 -7 0 -10 7 -7 15 4 10 29
-20 75 -88 76 -112 107 -152 114 -145 2 2 -32 58 -75 123 -56 84 -76 121 -66
123 26 6 55 -18 77 -61 29 -56 80 -130 86 -123 3 2 -7 25 -22 50 -43 73 -22
53 33 -32 46 -71 92 -125 92 -108 0 4 -26 46 -58 94 -74 111 -82 125 -44 82
40 -45 40 -30 1 32 -37 57 -34 69 8 34 17 -14 32 -23 35 -21 3 3 20 -23 38
-57 44 -84 92 -160 97 -154 3 2 -23 55 -56 118 -46 86 -53 105 -31 78 17 -19
30 -38 30 -43 0 -5 6 -7 14 -4 8 3 18 -5 25 -21 23 -50 63 -114 68 -108 3 3
-11 37 -31 75 -20 38 -36 72 -36 76 0 21 36 -27 66 -89 34 -69 63 -110 71
-102 2 2 -12 33 -32 70 -20 36 -34 66 -31 66 3 0 13 -14 22 -31 11 -22 18 -28
24 -20 5 8 21 -4 50 -39 23 -28 45 -48 48 -46 3 3 -1 12 -8 21 -7 9 -8 15 -2
15 6 0 29 -22 52 -50 42 -50 65 -65 53 -34 -5 14 -3 14 26 -1 62 -31 41 -8
-44 49 -84 58 -117 87 -79 72 10 -5 37 -10 59 -12 l40 -3 -40 24 c-22 14 -47
25 -55 25 -13 0 -13 1 0 10 11 7 8 11 -15 20 -17 6 -51 14 -77 17 -27 3 -53
12 -60 20 -10 12 -7 13 18 7 40 -10 21 8 -24 22 -27 9 -31 12 -15 13 12 1 57
-10 100 -24 43 -14 85 -24 93 -24 20 1 -62 37 -125 55 -47 13 -47 14 -10 9 22
-3 51 -8 65 -11 42 -11 9 11 -36 24 -48 14 -156 15 -114 2 24 -8 23 -8 -8 -9
-20 -1 -31 3 -27 9 4 6 -7 10 -26 10 -18 0 -30 3 -27 6 3 3 40 5 82 6 45 0 76
4 76 10 0 7 -37 9 -106 6 -63 -3 -126 0 -157 7 l-52 12 61 7 c65 8 109 26 63
26 -42 0 -144 -21 -150 -32 -4 -5 -15 -8 -25 -5 -15 3 -12 7 14 17 20 7 29 15
23 20 -17 10 106 30 183 30 42 0 65 4 61 10 -12 20 -127 11 -300 -24 -16 -3
-3 3 30 14 33 11 91 23 128 26 37 4 70 11 73 16 10 15 -139 -4 -226 -30 -118
-34 -151 -42 -180 -41 -35 0 203 85 281 100 88 16 95 27 11 17 -57 -7 -150
-33 -225 -63 -12 -5 -17 -3 -15 6 2 7 44 26 93 42 84 27 125 47 97 47 -19 0
-337 -101 -342 -109 -3 -4 -29 0 -59 9 l-53 17 63 23 c35 12 89 35 120 50 30
16 82 36 115 45 32 10 63 21 68 26 29 27 -108 -13 -222 -65 -119 -54 -166 -67
-92 -26 51 28 44 35 -15 16 -11 -3 16 12 60 34 65 33 74 40 45 36 l-35 -5 40
19 c22 11 71 24 108 31 79 14 104 34 31 24 -70 -9 -151 -33 -209 -61 -27 -14
-41 -18 -31 -10 39 31 5 28 -46 -3 -42 -27 -128 -64 -128 -57 0 7 154 98 205
122 73 35 70 33 63 39 -11 11 -110 -34 -210 -97 -103 -65 -208 -122 -208 -114
0 2 16 14 36 26 48 30 34 39 -19 11 -37 -20 -39 -20 -19 -3 34 30 26 36 -15
13 l-38 -21 30 25 c17 14 56 42 87 63 54 35 78 62 42 48 -29 -11 -157 -100
-193 -134 -19 -18 -38 -31 -43 -30 -14 5 199 174 265 211 20 11 37 24 37 29 0
12 -90 -36 -144 -77 -26 -19 -51 -33 -57 -29 -5 3 -42 -19 -82 -49 -118 -89
-140 -99 -77 -34 30 31 62 57 72 57 10 0 25 11 34 25 8 13 37 36 62 51 26 15
41 29 34 32 -7 2 -24 -4 -38 -13 -26 -17 -41 -15 -29 5 8 14 99 60 150 76 49
16 62 16 149 0z m-1034 -4 c0 -8 -189 -102 -234 -116 -10 -4 -16 -9 -13 -12 3
-3 28 2 56 11 28 9 68 18 88 20 l38 3 -50 -25 c-71 -37 -41 -39 39 -3 37 16
70 30 72 30 14 0 0 -40 -16 -45 -11 -3 -20 -10 -20 -15 0 -6 11 -5 26 0 14 6
27 10 29 10 2 0 13 -20 24 -44 10 -24 30 -51 43 -59 37 -25 128 -31 171 -13
20 8 37 11 37 6 0 -5 8 -7 18 -4 9 3 4 -2 -11 -11 -35 -20 -30 -30 7 -16 14 6
26 7 26 2 0 -5 18 -8 39 -7 22 0 37 -3 33 -8 -3 -5 5 -7 16 -4 12 4 25 2 28
-4 10 -16 -18 -29 -51 -23 -16 4 -81 13 -143 20 -103 12 -120 18 -180 54 -90
54 -112 61 -191 61 -69 0 -131 15 -131 31 0 5 -21 19 -47 32 l-47 24 46 7 c25
4 79 22 119 41 41 20 65 28 55 20 -33 -25 4 -17 64 15 64 34 60 32 60 22z
m-404 -81 c14 -16 50 -42 79 -56 51 -26 75 -56 75 -93 0 -10 -22 -34 -50 -55
-55 -41 -62 -44 -54 -22 4 8 1 15 -4 15 -6 0 -14 -9 -17 -21 -4 -11 -9 -18
-11 -16 -3 3 2 22 11 42 8 21 13 40 10 43 -8 9 -17 -5 -37 -60 l-19 -53 6 43
c3 24 17 66 31 94 14 27 24 52 21 54 -9 9 -41 -40 -58 -88 -18 -52 -18 -53
-19 -17 0 20 7 56 15 79 16 45 15 60 -2 38 -17 -22 -33 -79 -33 -119 l0 -38
-25 25 c-33 33 -31 71 10 179 7 19 7 19 -10 3 -10 -9 -24 -38 -31 -65 -12 -46
-13 -47 -13 -13 -2 54 -21 16 -21 -41 0 -25 -3 -67 -6 -93 l-7 -47 -23 22
c-30 28 -58 101 -50 132 3 13 34 49 68 79 102 89 123 95 164 49z m414 -160
c91 -47 116 -104 50 -117 -20 -4 -44 -23 -70 -54 -22 -26 -55 -55 -72 -63 -31
-14 -38 -14 -98 6 -61 20 -63 22 -35 28 25 6 22 7 -20 9 -27 1 -44 4 -37 7 19
7 14 23 -5 17 -10 -3 -7 2 7 10 51 31 137 59 225 72 l90 13 -95 -3 c-81 -2
-106 -7 -172 -35 -42 -18 -95 -46 -118 -62 -22 -16 -40 -26 -40 -22 1 14 103
82 157 105 58 24 72 42 21 28 -30 -8 -31 -8 -14 5 10 8 44 19 75 25 81 16 85
21 13 16 -51 -3 -77 -12 -133 -44 -38 -22 -69 -39 -69 -37 0 8 127 94 160 108
56 24 118 20 180 -12z m-676 -81 c34 -16 77 -44 96 -60 l35 -31 -35 20 c-45
25 -145 54 -168 48 -20 -5 -12 -9 60 -27 26 -7 45 -16 43 -21 -3 -4 7 -15 22
-23 35 -19 30 -19 -31 -1 -27 8 -70 15 -95 15 -47 0 -122 -28 -136 -50 -13
-22 4 -22 34 0 38 27 86 35 148 25 l48 -8 -51 -8 c-58 -10 -109 -27 -101 -35
3 -3 22 -1 44 5 42 12 115 13 153 2 24 -7 22 -9 -23 -29 -26 -11 -64 -20 -85
-19 -29 1 -52 -7 -88 -32 -76 -51 -125 -32 -170 64 -45 94 -20 170 64 194 65
18 168 6 236 -29z m928 -5 c68 -8 124 -15 124 -15 1 0 -14 -17 -34 -37 -45
-48 -92 -147 -92 -195 0 -45 17 -58 110 -87 97 -30 98 -35 34 -185 -43 -100
-64 -136 -98 -169 -48 -46 -66 -92 -66 -170 0 -35 -7 -58 -25 -85 -14 -20 -25
-46 -25 -57 0 -25 -108 -356 -178 -544 -58 -157 -58 -157 -67 74 -14 315 21
542 110 721 50 102 59 115 109 148 64 43 92 75 107 126 23 75 -27 108 -105 71
-59 -29 -72 -52 -80 -141 -5 -62 -15 -92 -56 -175 -67 -133 -108 -305 -111
-458 -1 -59 -1 -58 -24 44 l-17 75 28 55 c25 49 27 64 28 169 1 123 -9 193
-26 188 -9 -3 -51 -144 -67 -228 -6 -32 -7 -29 -25 45 -10 44 -36 127 -56 185
-43 118 -61 236 -35 226 9 -3 39 -1 67 5 50 11 53 11 75 -16 13 -15 27 -40 30
-56 3 -16 10 -32 16 -36 16 -10 76 65 83 105 11 56 -13 136 -56 191 l-39 49
30 12 c17 7 48 34 70 61 23 26 53 50 67 54 38 9 52 24 52 52 0 13 4 21 10 18
5 -3 65 -12 132 -20z m-1473 -105 c60 -30 142 -111 191 -188 46 -72 67 -92 50
-47 -14 37 -57 101 -97 146 l-36 40 29 -7 c57 -13 95 -34 121 -64 14 -17 40
-37 59 -45 26 -11 35 -22 45 -57 19 -71 22 -75 28 -43 5 21 9 26 15 17 4 -7 5
-20 2 -29 -10 -24 5 -193 17 -193 6 0 8 25 4 68 -3 38 -2 58 3 47 10 -25 32
-166 27 -172 -2 -2 -15 6 -29 16 -22 17 -26 31 -31 98 -7 86 -14 116 -28 107
-5 -3 -9 4 -9 15 0 26 -30 101 -40 101 -4 0 0 -28 10 -63 13 -47 16 -78 10
-127 l-7 -65 -2 66 c0 36 -8 90 -18 120 -14 48 -17 51 -23 29 l-6 -25 -9 25
c-11 36 -78 135 -82 123 -2 -6 9 -33 26 -60 36 -59 66 -132 57 -140 -3 -4 -6
1 -6 10 0 10 -5 17 -11 17 -6 0 -9 -6 -6 -14 3 -8 9 -32 13 -53 6 -33 5 -36
-8 -26 -8 7 -20 32 -28 55 -17 58 -56 135 -92 181 -49 62 -51 42 -4 -30 25
-38 54 -93 65 -123 22 -60 19 -57 -37 37 -20 35 -61 90 -91 124 -46 52 -55 59
-63 44 -7 -11 -5 -22 4 -34 7 -9 -4 1 -24 24 -53 58 -59 35 -7 -27 77 -93 76
-91 46 -77 -15 7 -27 18 -27 24 0 21 -97 139 -129 158 -46 27 -38 10 27 -58
37 -39 41 -45 12 -17 -60 55 -74 54 -21 -3 22 -24 41 -47 41 -50 0 -14 -61 22
-100 59 -50 48 -89 76 -105 76 -5 0 10 -19 35 -41 25 -22 40 -38 33 -35 -6 3
-14 0 -16 -6 -4 -9 -6 -8 -6 3 -1 9 -23 28 -50 42 -52 29 -66 33 -56 17 3 -5
-2 -10 -12 -10 -11 0 -4 -9 22 -25 45 -27 40 -33 -7 -9 -36 18 -108 28 -108
15 0 -5 6 -11 13 -14 6 -2 -10 -8 -38 -11 -82 -12 -76 -14 43 -15 116 -1 143
-14 54 -27 -26 -4 -56 -10 -67 -15 -39 -15 49 -10 110 8 33 9 72 16 86 15 31
-1 219 -71 219 -82 0 -10 -145 -30 -218 -30 -40 0 -61 -4 -57 -10 3 -5 37 -10
75 -10 37 0 70 -4 72 -10 2 -5 41 -4 103 4 96 13 101 13 132 -7 36 -24 34 -37
-5 -37 -15 -1 -54 -7 -87 -15 -33 -8 -91 -15 -128 -15 -43 0 -66 -4 -62 -10 9
-15 121 -12 190 5 33 8 67 14 75 14 22 0 -67 -28 -125 -38 -40 -8 -28 -9 60
-5 61 2 127 6 147 10 29 5 44 1 70 -17 l32 -23 -24 -7 c-14 -4 -60 -7 -102 -8
-99 -1 -104 -21 -5 -22 72 -2 72 -2 22 -9 -27 -5 -86 -9 -130 -10 -77 -2 -78
-3 -35 -12 60 -12 250 5 250 23 0 5 -7 9 -17 9 -25 0 -12 7 27 13 30 5 40 1
71 -26 l36 -32 -121 -2 c-67 -1 -134 -6 -151 -11 -21 -7 22 -9 137 -8 122 1
170 -2 179 -11 10 -11 5 -13 -30 -13 -25 0 -40 4 -36 10 9 14 -84 12 -185 -4
-47 -8 -98 -12 -115 -8 -48 9 -160 82 -273 179 l-34 28 119 0 c70 0 126 5 137
11 15 9 -6 11 -91 7 -138 -7 -187 4 -267 61 -73 52 -161 97 -236 121 -78 25
-80 31 -9 39 34 4 109 22 168 41 160 50 188 52 251 20 58 -29 75 -28 35 3
l-26 21 33 4 c82 10 130 6 167 -13z m1024 -56 c69 -23 142 -107 157 -179 10
-48 9 -60 -8 -98 -28 -64 -42 -68 -42 -12 0 79 -56 157 -137 192 -60 24 -69
13 -13 -18 84 -46 123 -89 83 -89 -10 0 -31 -9 -48 -20 -38 -25 -66 -25 -96
-1 -13 10 -38 25 -56 34 -32 17 -65 62 -78 107 -6 21 0 17 37 -21 51 -54 78
-73 136 -93 62 -22 51 -6 -22 32 -35 18 -74 44 -87 59 -19 20 -10 16 40 -16
56 -36 91 -50 91 -37 0 2 -19 16 -42 30 -24 14 -56 36 -73 51 l-30 25 28 -10
c32 -11 36 2 5 18 -21 10 -22 11 -3 11 11 1 49 -13 83 -29 73 -34 98 -38 62
-10 -14 11 -21 20 -17 20 13 0 67 -39 110 -79 50 -48 47 -26 -4 28 -48 50
-123 88 -191 97 -27 4 -48 10 -48 15 0 15 114 10 163 -7z m-384 -42 c-59 -38
-105 -86 -130 -134 -27 -52 -19 -58 14 -11 15 21 47 59 73 83 39 38 45 41 39
20 -8 -24 -7 -23 29 8 20 17 39 32 41 32 3 0 -11 -31 -30 -70 -38 -75 -39 -80
-26 -80 5 0 21 25 37 55 16 30 32 55 36 55 13 0 -61 -155 -98 -207 -10 -14
-12 -23 -5 -23 18 0 68 77 97 151 32 80 42 85 20 8 -18 -60 -20 -72 -7 -65 4
3 14 26 21 51 14 49 25 57 35 24 10 -32 -2 -81 -34 -130 -31 -50 -80 -79 -131
-79 -18 0 -51 -5 -73 -12 -38 -11 -41 -11 -62 15 -31 40 -58 121 -51 155 11
49 44 90 102 127 68 43 91 54 123 54 21 1 18 -3 -20 -27z m181 -250 c13 -20
36 -48 50 -62 29 -28 95 -203 130 -343 12 -45 23 -89 25 -97 3 -9 -22 10 -55
41 -32 31 -62 69 -66 85 -4 15 -13 39 -20 53 -8 14 -14 31 -14 37 0 6 -16 29
-36 53 -42 49 -122 92 -174 95 -20 1 -49 12 -64 24 -32 24 -28 27 48 36 57 7
108 41 128 86 7 16 15 30 18 30 3 0 16 -17 30 -38z m-92 -179 c18 -9 35 -20
38 -25 4 -5 -8 -2 -26 7 -17 9 -35 13 -38 9 -4 -4 -1 -9 6 -11 22 -8 97 -68
97 -79 0 -5 4 -16 10 -24 5 -8 -8 0 -29 18 -51 45 -59 31 -11 -18 49 -50 34
-53 -17 -3 -23 23 -38 31 -38 23 -1 -10 -7 -7 -21 9 -11 13 -23 21 -26 17 -4
-4 -15 8 -26 26 l-18 33 6 -35 c7 -47 47 -104 97 -140 50 -37 40 -39 -18 -5
-45 27 -82 79 -104 149 -12 37 -11 41 10 58 24 19 50 17 108 -9z m-485 0 c-18
-2 -33 -8 -33 -12 0 -4 50 -5 111 -3 70 3 114 1 121 -6 12 -12 -13 -18 -127
-31 l-70 -8 80 -3 c106 -3 56 -17 -68 -19 -107 -1 -110 -20 -4 -23 63 -1 66
-2 19 -5 -33 -3 -50 -8 -46 -15 4 -6 -1 -8 -12 -5 -11 3 -31 8 -46 11 -16 4
-28 11 -28 16 0 5 -23 28 -51 50 l-51 40 49 1 c26 1 62 5 78 9 17 4 48 7 70 7
35 0 36 -1 8 -4z m1389 -56 c17 -13 93 -136 86 -141 -1 -1 -29 -8 -60 -15 -31
-7 -62 -19 -70 -28 -11 -14 -10 -15 12 -9 19 6 21 5 10 -3 -8 -6 -26 -11 -39
-11 -23 0 -23 1 -7 19 10 11 31 25 47 31 16 7 29 16 29 21 0 12 -6 11 -42 -7
-20 -10 -28 -11 -22 -3 5 9 2 10 -10 6 -36 -14 -3 14 47 40 63 32 46 44 -18
13 -42 -21 -69 -46 -107 -100 l-17 -25 0 28 c-1 16 3 26 8 23 5 -4 24 12 42
35 17 22 50 52 71 66 46 29 44 42 -3 19 -18 -9 -37 -16 -43 -16 -5 0 -25 -14
-43 -32 l-33 -32 10 35 c20 72 106 120 152 86z m-1113 -31 l32 -33 -75 -14
c-80 -14 -107 -35 -33 -25 42 5 42 5 -8 -9 -27 -7 -76 -14 -108 -14 -36 -1
-56 -5 -52 -11 10 -17 95 -11 208 15 l108 24 40 -28 c30 -22 35 -30 23 -35
-23 -9 -36 -7 -29 4 9 14 -14 13 -82 -5 -31 -8 -82 -14 -113 -14 l-55 1 60 9
c77 11 161 37 125 38 -14 1 -60 -6 -103 -14 -43 -8 -102 -15 -130 -15 -37 0
-48 -3 -37 -10 12 -8 11 -10 -7 -10 -12 0 -43 20 -70 46 l-48 45 46 -5 c51 -6
170 10 179 24 3 5 25 13 49 19 53 12 64 21 25 22 -25 0 -26 2 -9 9 17 6 14 8
-15 12 l-35 5 41 1 c33 2 45 -3 73 -32z m1301 -212 c102 -99 134 -164 134
-279 1 -44 -5 -102 -13 -129 l-14 -48 -18 33 c-18 36 -56 76 -64 67 -3 -2 10
-24 28 -49 31 -43 32 -46 30 -143 -1 -54 -4 -101 -7 -103 -3 -3 -40 25 -83 61
-46 39 -115 85 -169 111 -94 47 -122 74 -144 139 -18 56 7 206 35 206 6 0 22
18 34 40 28 50 62 83 73 72 5 -5 0 -25 -12 -46 -23 -38 -39 -51 -20 -15 18 33
5 46 -15 16 -44 -67 -87 -277 -56 -277 7 0 11 19 11 49 0 45 36 188 45 179 2
-2 -2 -26 -10 -53 -17 -56 -19 -79 -5 -70 6 3 10 12 10 20 0 22 38 119 56 141
15 18 15 18 5 -3 -21 -42 -30 -73 -23 -73 4 0 16 18 26 40 11 22 23 40 27 40
5 0 0 -15 -10 -32 -11 -18 -22 -44 -25 -58 -7 -25 -7 -25 10 -5 l17 20 -7 -20
c-14 -39 -36 -130 -36 -148 0 -11 4 -15 12 -11 8 5 10 -7 5 -47 -3 -30 -2 -63
3 -74 6 -15 9 -4 11 40 3 58 3 59 6 13 6 -67 23 -60 24 10 1 31 5 71 10 87 8
29 8 28 6 -7 -2 -21 1 -38 5 -38 4 0 8 7 8 15 0 7 5 38 11 66 l11 53 23 -55
c15 -39 18 -55 9 -57 -17 -4 -24 -38 -22 -117 1 -65 1 -67 8 -25 4 25 8 37 8
28 1 -10 7 -18 12 -18 6 0 10 24 10 53 0 80 19 37 21 -48 1 -38 3 -62 6 -52 7
27 20 20 28 -15 6 -24 9 -28 12 -14 3 10 0 38 -7 63 -13 56 -8 64 16 24 10
-17 22 -31 26 -31 12 0 -19 59 -46 90 -13 14 -30 44 -39 68 l-16 43 36 -31
c39 -35 57 -33 27 2 -10 13 -3 8 17 -9 42 -40 56 -42 33 -7 -22 33 -86 77 -94
64 -4 -6 -14 -2 -25 10 -10 11 -15 23 -12 26 8 9 74 -33 125 -80 23 -21 42
-34 42 -28 0 12 -54 70 -90 95 -14 10 -19 15 -12 12 6 -3 12 -1 12 5 0 6 14 2
30 -10 17 -12 30 -17 30 -12 0 15 -44 50 -90 71 -25 11 -38 21 -30 21 8 0 39
-14 69 -30 54 -30 82 -33 45 -5 -51 39 -156 73 -139 45 3 -5 2 -10 -3 -10 -8
0 -32 42 -32 55 0 3 10 4 23 4 17 -1 18 -2 4 -6 -25 -7 -10 -23 21 -23 15 0
51 -11 81 -25 30 -14 56 -23 59 -21 7 7 -60 46 -91 53 -27 5 -44 20 -34 29 2
2 17 0 33 -6 25 -10 27 -9 14 3 -19 18 -75 37 -110 37 -27 0 -88 39 -62 40 6
0 12 -4 12 -9 0 -5 33 -9 73 -9 78 0 68 15 -13 19 -45 2 -45 2 -10 8 33 7 31
8 -27 15 -34 4 -68 5 -75 1 -7 -3 -4 1 6 9 11 9 28 16 37 16 10 0 26 3 36 7
15 6 13 9 -12 14 -29 6 -28 7 9 8 35 1 45 -6 116 -75z m-343 -2 c-25 -27 -21
-39 6 -15 42 38 52 37 19 -1 -28 -32 -52 -74 -52 -92 0 -13 20 -1 25 17 9 26
62 87 89 101 16 8 14 4 -6 -15 -38 -36 -35 -55 3 -23 41 34 60 33 27 -1 -15
-16 -33 -44 -41 -63 -9 -19 -16 -29 -16 -21 -1 11 -5 10 -17 -5 -14 -18 -15
-18 -4 4 6 13 9 28 7 35 -6 15 -47 -71 -47 -98 0 -17 2 -18 11 -5 8 12 9 10 5
-10 -3 -14 -6 -34 -6 -45 0 -29 -15 -7 -39 58 -30 82 -28 125 8 165 17 17 33
32 37 32 4 0 0 -8 -9 -18z m-502 -159 c62 -67 123 -169 130 -218 l8 -50 -42
52 c-38 48 -101 92 -133 93 -7 1 -37 11 -66 24 l-52 22 36 43 c32 37 62 58 86
60 4 1 19 -11 33 -26z m-252 -62 c8 -8 2 -11 -18 -11 l-30 -1 25 -15 25 -15
-30 7 c-16 3 -45 10 -62 14 -51 12 -39 -4 18 -24 37 -14 49 -22 44 -34 -4 -12
1 -14 30 -9 45 7 45 1 0 -12 -27 -7 -51 -5 -103 10 -73 21 -129 24 -152 9 -11
-7 -1 -10 35 -10 28 0 79 -9 115 -19 l65 -19 -85 -2 c-54 -1 -76 -5 -60 -9 17
-5 -3 -9 -63 -13 -48 -2 -92 -9 -98 -15 -7 -7 28 -9 113 -7 86 2 103 0 56 -4
-86 -9 -98 -22 -22 -23 l59 -1 -34 -15 c-19 -8 -91 -19 -159 -24 -86 -7 -139
-16 -170 -29 -58 -26 -48 -36 15 -15 26 9 86 19 133 21 81 3 82 3 27 -6 -73
-12 -115 -34 -47 -25 25 3 61 8 80 12 19 3 32 3 29 -1 -3 -4 -46 -13 -94 -22
-80 -14 -153 -39 -143 -49 2 -3 26 2 53 11 27 8 54 12 60 10 5 -2 2 -5 -7 -5
-10 -1 -18 -6 -18 -11 0 -6 11 -8 28 -4 52 13 -77 -85 -168 -127 -47 -22 -80
-36 -75 -30 13 13 110 61 123 61 6 0 14 5 18 12 5 8 -2 9 -27 4 -46 -9 -141
-67 -135 -83 3 -7 -7 -21 -22 -32 -41 -31 -9 -25 45 9 61 37 123 63 123 51 0
-12 -45 -50 -104 -87 -46 -29 -62 -52 -28 -40 9 3 4 -4 -13 -16 -65 -49 -95
-81 -95 -100 0 -11 -9 -33 -21 -50 -12 -16 -19 -32 -16 -35 6 -5 56 47 57 59
0 4 27 28 60 54 37 29 56 50 49 54 -7 5 1 15 20 29 17 12 31 19 31 14 0 -5
-27 -62 -60 -128 -33 -66 -60 -121 -60 -123 0 -12 26 17 51 56 31 49 38 38 18
-31 -12 -46 -6 -72 10 -37 10 23 11 22 5 -9 -9 -49 11 -30 25 23 15 60 15 117
0 96 -14 -19 -4 74 11 98 7 12 10 -12 11 -83 2 -128 16 -81 18 60 1 55 6 107
11 115 7 11 10 3 11 -30 1 -41 2 -43 9 -15 6 24 8 17 10 -35 2 -51 4 -60 11
-40 6 17 7 -1 5 -56 -5 -105 -24 -147 -79 -173 -23 -11 -64 -23 -92 -27 -27
-3 -66 -15 -86 -25 -20 -10 -39 -15 -43 -12 -3 4 -6 26 -6 49 0 24 -7 76 -16
116 -15 70 -15 75 5 128 41 107 76 155 146 201 36 23 63 44 61 46 -5 6 -64
-21 -105 -48 l-33 -23 7 25 c21 65 81 111 202 153 74 26 111 46 86 46 -10 0
-127 -34 -161 -47 -23 -9 13 46 50 76 45 36 95 50 181 51 48 0 77 4 77 11 0 7
-29 9 -85 7 -47 -2 -85 0 -85 3 0 16 94 91 149 118 69 35 98 38 165 15 66 -23
70 -9 5 20 l-54 24 35 16 c38 18 134 16 153 -3z m137 -97 c-6 -14 -10 -33 -10
-42 1 -14 2 -14 12 3 28 52 39 64 53 59 12 -5 9 -13 -15 -40 -27 -32 -30 -41
-30 -107 0 -95 -29 -157 -100 -211 -27 -21 -61 -51 -75 -67 -54 -65 -125 -107
-125 -75 0 9 6 13 14 10 19 -7 27 29 36 156 6 85 7 93 8 41 1 -35 4 -62 7 -59
4 3 10 43 15 89 11 98 24 147 46 174 14 18 15 18 10 2 -3 -10 -9 -51 -13 -89
-4 -53 -3 -69 5 -64 7 4 12 27 12 52 0 29 4 43 12 41 7 -1 15 12 19 28 6 29 7
29 8 -8 0 -21 -4 -70 -10 -108 -6 -39 -8 -73 -6 -76 10 -9 25 69 35 170 5 54
15 104 21 110 10 9 10 6 2 -16 -6 -16 -11 -45 -10 -65 1 -41 1 -41 24 41 14
53 25 66 62 76 1 1 -2 -11 -7 -25z m-217 -127 c7 4 8 0 4 -11 -4 -10 -7 -27
-8 -39 -1 -20 -2 -20 -8 3 -7 24 -8 23 -19 -15 -7 -22 -12 -76 -13 -120 -1
-44 -5 -93 -9 -110 -5 -18 -6 3 -3 49 8 104 -12 95 -21 -10 -8 -88 -26 -129
-58 -139 -37 -12 -69 -47 -95 -105 -33 -72 -79 -129 -120 -146 -48 -21 -51
-18 -38 33 14 54 29 300 18 289 -4 -4 -10 -28 -14 -54 l-6 -47 -1 45 c0 49 25
162 27 121 1 -30 17 -17 24 19 2 14 3 -42 1 -125 -3 -98 -1 -143 5 -130 5 11
10 63 11 115 1 94 16 210 19 160 2 -23 3 -24 11 -5 6 14 9 15 10 5 2 -18 16
12 24 52 6 25 51 67 60 57 3 -2 0 -24 -4 -49 -10 -52 -13 -136 -4 -127 3 3 10
30 15 59 7 46 8 42 3 -27 -2 -44 -7 -93 -11 -109 -4 -18 -2 -27 4 -23 14 9 22
52 33 175 11 119 31 176 23 66 -2 -38 -8 -99 -14 -136 -11 -83 -11 -91 0 -83
17 10 45 286 31 295 -4 3 2 13 15 22 l22 17 -3 -77 c-1 -42 0 -63 2 -45 4 21
10 30 18 27 9 -3 15 9 19 39 7 53 28 107 34 89 3 -6 10 -9 16 -5z"/>
</g>

    </Svg>
  );
};

export default Icon;
