export default {
  cake: {
    250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41' 
  },
  masterChef: {
    250: '0x89dcd1DC698Ad6A422ad505eFE66261A4320D8B5',
  },
  sousMasterChef: {
    250: '0x563373d51Eb8e9F9074216763a39F3aD79597edd'
  },
  weth: {
    250: "0x74b23882a30290451A17c44f4F05243b6b58C76d"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    250: '0x0118EF741097D0d3cc88e46233Da1e407d9ac139'
  },
  usdc: {
    250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
  },
  fusdt: {
    250: '0x049d68029688eabf473097a2fc38ef61633a3c7a', // USDT
    80001: '',
  },
  wbtc: {
    250: '0x321162Cd933E2Be498Cd2267a90534A804051b11', // wbtc
    80001: '',
  },
  wmatic: {
    250: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
  },
  wftm: {
    250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'
  },
  ftm: {
    250: '0x0000000000000000000000000000000000001010'
  },
  dai: {
    250: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E'
  },
  matic: {
    250: '0x0000000000000000000000000000000000001010'
  }
}
