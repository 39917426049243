import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 512.000000 512.000000" {...props}>
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M3663 4990 c-26 -16 -38 -17 -62 -9 -20 7 -42 7 -71 -1 -23 -6 -55
-8 -71 -5 -24 6 -34 1 -58 -24 -16 -17 -36 -52 -45 -77 -8 -25 -25 -53 -38
-64 -48 -38 -20 -199 47 -265 31 -32 25 -45 -23 -45 -20 0 -46 -7 -56 -15 -30
-22 -56 -67 -56 -95 0 -14 -9 -31 -20 -37 -10 -7 -24 -32 -31 -55 -16 -61 -41
-108 -57 -108 -25 0 -71 -30 -77 -50 -4 -14 -15 -20 -34 -20 -37 0 -117 -63
-130 -102 -6 -17 -11 -46 -11 -65 0 -43 -13 -36 -43 25 -99 197 -132 260 -146
278 -29 39 -41 58 -75 119 -44 80 -60 81 -125 9 -28 -32 -51 -62 -51 -66 0 -4
-6 -8 -14 -8 -8 0 -34 -23 -58 -52 -23 -28 -67 -77 -96 -107 -30 -31 -66 -72
-80 -91 -14 -19 -33 -42 -42 -50 -9 -8 -35 -41 -59 -72 -24 -32 -47 -58 -51
-58 -4 0 -27 -29 -51 -65 -23 -36 -46 -65 -49 -65 -4 0 -36 -55 -70 -122 -35
-68 -67 -125 -71 -128 -12 -8 -75 -189 -84 -238 -4 -23 -10 -42 -15 -42 -20 0
-33 -498 -19 -739 5 -84 6 -158 3 -163 -4 -6 -14 13 -23 40 -10 28 -34 71 -54
95 -43 53 -152 137 -176 137 -21 0 -46 -25 -59 -61 -10 -24 -14 -26 -64 -21
-42 3 -58 0 -71 -12 -21 -22 -37 -79 -37 -138 0 -37 -4 -48 -16 -48 -8 0 -35
-11 -58 -25 -72 -42 -83 -96 -40 -184 25 -49 25 -53 9 -71 -9 -10 -15 -30 -13
-47 3 -24 11 -31 63 -50 117 -42 205 -40 298 7 20 11 37 16 37 11 0 -5 -30
-24 -66 -42 -50 -26 -70 -42 -80 -66 -15 -35 -17 -87 -5 -118 5 -11 4 -25 -1
-32 -6 -7 -8 -23 -4 -36 11 -44 48 -78 121 -112 91 -43 180 -54 245 -31 30 10
48 12 55 5 14 -14 18 -480 4 -544 -28 -132 -52 -215 -90 -309 l-41 -102 -101
-80 c-141 -111 -356 -263 -525 -369 -128 -81 -166 -115 -129 -115 35 0 480
300 683 461 61 49 124 93 139 99 15 6 89 15 164 20 243 18 505 47 546 62 17 6
66 14 110 19 44 5 148 16 230 24 108 12 163 14 195 7 49 -11 368 -17 705 -14
116 1 386 3 600 5 215 2 400 7 413 11 12 4 22 13 22 20 0 7 -28 46 -63 87 -48
56 -95 95 -193 161 -126 85 -350 206 -449 242 -27 10 -55 23 -61 27 -23 18
-161 39 -257 38 -135 0 -174 -4 -238 -22 -30 -9 -75 -18 -101 -21 -25 -2 -55
-9 -67 -15 -11 -6 -29 -11 -40 -11 -18 0 -19 3 -11 25 5 14 7 25 4 25 -18 0
28 36 71 57 55 26 162 96 213 140 36 30 73 56 110 74 12 7 22 16 22 21 0 4 6
8 13 8 11 0 104 61 142 93 6 4 48 35 95 67 118 82 138 99 275 236 131 131 239
264 292 359 33 61 70 125 88 154 79 125 99 167 146 300 11 30 26 65 34 78 8
12 15 29 15 37 0 7 9 33 20 57 38 84 41 82 -134 74 l-153 -7 -7 29 c-4 15 -10
46 -14 68 -6 36 -12 42 -64 68 -31 16 -77 32 -102 34 l-46 6 25 29 c43 51 45
105 5 115 -16 4 -20 14 -20 43 0 42 -20 70 -51 70 -19 0 -19 1 3 31 13 18 35
44 49 58 14 14 28 33 31 41 3 11 12 8 39 -14 19 -16 61 -42 94 -58 52 -25 67
-28 115 -22 66 8 125 36 142 67 6 12 13 47 13 79 2 41 8 64 24 84 18 23 22 37
17 67 -7 41 -32 87 -48 87 -5 0 -7 8 -4 18 13 41 -72 82 -169 82 -97 0 -135
-21 -213 -119 -28 -36 -69 -19 -91 37 -24 61 -52 85 -108 92 l-48 5 64 42 c56
37 66 40 78 27 25 -24 110 -64 139 -64 55 0 137 32 175 69 50 47 72 120 46
149 -9 10 -17 26 -17 34 0 9 -6 21 -14 27 -8 7 -17 22 -20 35 -14 54 -158 75
-236 33 -59 -31 -112 -130 -108 -199 l3 -47 -74 -53 c-40 -29 -80 -57 -87 -63
-24 -18 -23 0 6 95 16 52 43 140 59 196 l31 101 52 -5 c69 -5 132 12 171 48
18 16 51 45 73 64 53 46 88 124 81 178 -7 51 -31 86 -65 94 -15 3 -38 19 -52
34 -14 16 -45 45 -68 64 -46 38 -93 46 -145 27 -20 -8 -29 -6 -43 10 -27 30
-66 24 -116 -21 -56 -49 -74 -91 -83 -196 -6 -71 -4 -87 15 -132 23 -53 98
-132 125 -132 35 0 31 -18 -67 -332 -16 -54 -33 -98 -37 -97 -39 4 -44 17 -77
181 -18 91 -47 209 -64 263 l-32 97 35 29 c93 76 103 336 18 428 -34 36 -63
39 -108 11z m82 -120 c34 -38 58 -215 34 -250 -8 -12 -10 -12 -8 5 2 27 -11
65 -22 65 -5 0 -9 -16 -9 -36 0 -21 4 -33 10 -29 13 8 12 5 -1 -34 -6 -17 -14
-31 -17 -31 -4 0 -7 50 -8 110 0 65 -5 115 -11 121 -8 8 -10 -20 -7 -98 l4
-108 -21 70 c-28 95 -40 120 -36 78 2 -18 1 -33 -1 -33 -12 0 -42 34 -42 47 0
38 74 143 100 143 9 0 25 -9 35 -20z m344 -154 c28 -30 51 -61 51 -69 0 -7
-12 -24 -26 -38 -34 -32 -52 -67 -65 -129 -13 -60 -25 -55 -38 15 -9 46 -2
127 14 168 4 9 3 17 -3 17 -11 0 -35 -82 -36 -124 0 -17 3 -53 7 -79 8 -47 8
-48 -13 -37 -31 16 -40 49 -42 158 l-2 96 -18 -39 c-19 -43 -24 -115 -8 -115
6 0 10 -10 10 -22 -1 -39 -49 62 -50 104 0 47 34 88 102 122 29 14 55 26 59
26 3 0 29 -24 58 -54z m-606 28 c108 -33 137 -46 161 -75 27 -32 51 -103 41
-119 -9 -15 -41 6 -62 40 -9 17 -21 30 -25 30 -10 0 1 -31 21 -55 10 -13 6
-11 -13 5 -15 14 -40 45 -54 69 -14 24 -28 39 -30 33 -5 -16 32 -83 63 -115
l28 -27 -42 15 c-58 20 -66 26 -50 35 9 6 7 16 -9 42 -22 34 -31 23 -11 -14 8
-15 8 -19 0 -14 -6 4 -20 24 -31 44 -14 27 -19 32 -19 17 -1 -11 6 -33 14 -49
9 -16 13 -31 11 -33 -3 -3 -12 10 -22 29 -9 18 -19 28 -21 21 -2 -7 1 -22 7
-33 17 -31 5 -36 -15 -7 -21 33 -31 127 -16 155 14 26 10 26 74 6z m841 -134
c-3 -66 -54 -174 -93 -194 -51 -27 -57 -18 -16 24 47 49 34 53 -21 7 -47 -41
-76 -57 -101 -57 -15 0 -14 3 7 19 13 11 28 17 32 15 25 -16 158 142 142 168
-4 5 -15 -5 -26 -24 -31 -51 -79 -108 -90 -108 -5 0 2 14 16 31 53 62 24 51
-41 -16 -75 -78 -86 -75 -58 20 22 78 57 113 140 141 99 33 110 30 109 -26z
m-794 -76 c67 -20 97 -39 116 -75 8 -16 13 -29 10 -29 -2 0 -11 -3 -20 -6 -12
-5 -16 0 -17 17 0 20 -4 17 -19 -16 l-19 -40 4 55 5 55 -14 -35 c-8 -19 -15
-47 -15 -62 -1 -16 -6 -28 -11 -28 -7 0 -8 13 -4 35 5 29 3 35 -11 35 -12 0
-16 -6 -12 -19 4 -15 -2 -20 -24 -23 -29 -5 -29 -4 -29 49 0 34 -4 52 -10 48
-5 -3 -10 -24 -10 -46 0 -32 -3 -39 -20 -39 -11 0 -20 -7 -20 -15 0 -8 4 -15
9 -15 5 0 11 -9 14 -21 4 -16 3 -18 -9 -8 -11 9 -15 8 -20 -5 -3 -9 -4 -23 0
-31 8 -22 -12 -18 -24 4 -33 62 13 196 75 222 11 4 20 8 21 8 0 1 24 -6 54
-15z m188 -106 c64 -201 111 -436 90 -449 -17 -11 -22 -3 -37 51 -7 25 -26 69
-43 99 -27 49 -29 57 -19 97 22 85 9 151 -49 256 -13 24 -13 26 8 31 9 3 18 5
19 6 1 0 15 -41 31 -91z m-1111 -103 c14 -27 28 -52 32 -55 10 -9 47 -64 52
-80 3 -8 -3 -23 -13 -32 -10 -10 -18 -15 -18 -12 0 4 -7 0 -17 -7 -15 -13 -15
-12 -5 8 14 26 16 46 3 39 -5 -4 -7 4 -3 16 3 13 1 19 -5 15 -7 -5 -9 1 -6 15
8 30 -1 21 -42 -43 -27 -43 -35 -49 -35 -31 0 12 12 45 26 73 27 55 28 93 1
44 l-17 -30 6 40 c4 30 3 36 -5 25 -9 -12 -11 -11 -11 8 0 16 -6 22 -21 22
-15 0 -19 -5 -15 -15 3 -8 1 -15 -5 -15 -5 0 -3 -13 6 -30 8 -16 15 -43 15
-61 0 -38 -12 -31 -15 8 -2 16 -11 49 -21 75 l-18 48 34 35 35 36 19 -23 c10
-12 29 -45 43 -73z m1082 36 c10 -31 11 -57 5 -94 l-9 -52 -12 38 c-6 20 -7
35 -2 31 5 -3 9 4 9 15 0 11 -4 23 -10 26 -5 3 -10 1 -10 -6 0 -8 -4 -8 -15 1
-18 15 -19 11 -1 -31 22 -55 27 -113 12 -134 -13 -18 -14 -16 -21 16 -9 41
-44 113 -52 106 -3 -3 4 -31 16 -62 27 -72 27 -101 -2 -120 -37 -24 -81 -29
-96 -11 -25 30 8 187 51 241 27 34 98 87 112 82 6 -2 17 -23 25 -46z m667
-107 c49 -24 52 -40 17 -81 -28 -32 -29 -32 -82 -22 -48 9 -58 8 -102 -14 -34
-16 -49 -20 -49 -11 0 26 53 74 101 91 52 19 68 38 22 27 -52 -13 -78 -25
-105 -52 -16 -14 -28 -22 -28 -18 0 20 33 66 62 85 40 26 104 24 164 -5z
m-978 -97 c49 -102 65 -242 30 -263 -6 -4 -8 1 -4 14 8 24 -11 139 -26 162 -6
11 -8 3 -4 -25 18 -149 16 -157 -13 -48 -6 23 -27 63 -46 88 -41 53 -42 63
-14 99 29 37 50 30 77 -27z m-201 -8 c80 -30 117 -60 147 -121 26 -53 49 -169
27 -138 -5 8 -13 32 -16 52 -4 21 -11 38 -16 38 -5 0 -6 -13 -3 -29 4 -16 1
-32 -5 -35 -6 -4 -11 -19 -11 -34 l-1 -27 -13 30 c-9 20 -9 32 -3 37 7 4 4 21
-8 47 -20 45 -41 57 -24 15 16 -44 4 -37 -30 17 -52 85 -46 48 9 -55 28 -53
48 -96 43 -96 -5 0 -19 18 -32 40 -23 40 -52 59 -31 19 10 -19 10 -19 -8 -10
-11 6 -34 34 -50 63 -38 64 -71 107 -72 91 0 -7 16 -38 35 -70 19 -31 33 -58
32 -59 -7 -8 -110 24 -117 36 -32 50 26 190 87 209 1 1 28 -9 60 -20z m-434
-62 c43 -83 44 -90 17 -135 -11 -18 -20 -38 -20 -45 1 -12 31 20 50 52 9 14
11 14 20 1 17 -26 11 -45 -35 -116 -24 -38 -45 -72 -45 -76 0 -23 22 0 65 68
40 61 50 72 56 57 20 -48 6 -97 -27 -98 -5 0 -38 -33 -73 -72 -59 -70 -95
-101 -52 -47 46 59 17 45 -45 -21 l-65 -70 60 74 c34 41 61 81 61 89 0 12 -3
12 -16 1 -9 -7 5 19 30 59 70 108 50 111 -23 4 -34 -51 -89 -124 -121 -162
-60 -69 -82 -115 -35 -70 14 13 25 21 25 19 0 -3 -13 -18 -30 -34 l-30 -29 0
25 c0 15 18 46 45 78 69 81 137 179 133 192 -2 7 -35 -29 -72 -80 -37 -50 -70
-91 -73 -91 -3 0 0 7 7 15 7 9 10 18 7 21 -3 3 24 41 59 83 75 87 154 202 154
222 -1 8 -12 1 -26 -16 -15 -16 -23 -23 -18 -15 12 22 14 100 3 100 -6 0 -7 5
-4 10 3 6 2 10 -2 10 -5 0 -37 -38 -72 -85 -75 -101 -100 -123 -36 -32 46 67
60 102 29 76 -9 -7 -2 9 15 36 17 27 31 55 31 62 0 28 17 9 53 -60z m986 -21
c17 -41 31 -84 31 -95 0 -32 -58 -128 -80 -134 -31 -8 -53 -72 -48 -138 4 -51
12 -70 52 -123 8 -12 -72 -212 -136 -340 l-34 -68 -12 131 c-14 148 -48 290
-92 387 l-30 66 29 27 c27 25 29 32 35 139 6 88 11 112 22 112 8 0 44 10 80
21 47 15 76 32 102 60 20 21 39 36 43 33 4 -2 21 -37 38 -78z m-1464 -12 c19
-45 19 -53 1 -29 -13 18 -14 17 -20 -12 -12 -53 62 -208 181 -381 35 -51 61
-98 57 -103 -3 -6 -26 19 -50 55 -25 36 -49 66 -55 66 -6 0 -7 -6 -4 -12 4 -7
-1 -4 -9 6 -11 14 -13 22 -5 27 15 9 -71 171 -88 165 -6 -3 -14 2 -17 10 -3 9
-1 12 4 9 6 -3 10 -2 10 3 0 14 -60 155 -71 167 -14 15 -11 -18 7 -60 8 -20
10 -34 5 -30 -15 9 -13 -11 4 -45 10 -19 11 -30 4 -32 -23 -8 97 -250 179
-359 20 -27 37 -56 38 -66 3 -17 2 -17 -11 1 -8 10 -18 15 -23 12 -6 -3 -20
10 -32 29 -12 19 -27 38 -34 42 -13 8 -86 135 -86 149 0 5 19 -24 43 -62 46
-77 95 -139 103 -131 3 2 -20 41 -50 86 -71 104 -128 223 -160 335 l-26 90 26
44 c25 42 28 44 40 27 13 -18 14 -17 14 8 0 36 7 34 25 -9z m-111 -116 c4 -18
2 -34 -3 -36 -12 -4 11 -85 55 -186 18 -43 31 -83 27 -89 -7 -12 -45 78 -80
193 -29 94 -50 130 -38 65 6 -33 39 -139 71 -220 4 -11 4 -17 0 -14 -4 4 -22
44 -40 90 -18 46 -38 83 -43 82 -6 -1 -14 12 -18 30 -8 30 -8 31 -12 7 -3 -14
-11 -29 -19 -33 -8 -4 -14 -19 -14 -31 0 -20 28 -124 47 -174 4 -10 2 -13 -5
-8 -7 4 -12 1 -12 -7 0 -15 65 -167 105 -246 14 -28 23 -51 20 -51 -2 0 -14
18 -25 39 -11 22 -25 43 -31 47 -23 14 5 -44 91 -188 48 -82 86 -148 84 -148
-3 0 -29 37 -58 83 -71 108 -153 265 -212 406 -46 109 -72 139 -41 46 12 -33
12 -43 3 -39 -7 3 -23 41 -36 85 -23 79 -23 80 -4 115 11 19 35 52 54 73 19
21 53 61 75 89 21 29 42 52 46 52 3 0 9 -15 13 -32z m1727 22 c5 0 0 -15 -11
-32 -11 -18 -20 -49 -21 -68 l-1 -35 -11 32 c-7 17 -16 35 -21 40 -12 11 1
-54 18 -89 13 -24 13 -28 0 -28 -8 0 -14 -5 -14 -12 0 -6 -12 -1 -27 13 -19
17 -25 19 -20 7 4 -12 2 -15 -9 -11 -22 8 -17 -3 19 -44 l32 -37 -35 24 c-47
33 -59 50 -60 83 0 69 91 176 138 161 8 -2 18 -4 23 -4z m210 0 c20 -11 52
-68 63 -113 8 -32 -20 -102 -50 -129 -24 -21 -98 -53 -89 -38 3 5 19 17 36 25
31 16 40 45 13 45 -13 0 -12 5 5 29 22 31 17 52 -6 23 -8 -9 -4 2 7 26 34 69
17 72 -20 4 -19 -34 -41 -62 -49 -62 -11 0 -11 4 2 18 23 25 21 42 -3 42 -13
0 -20 -7 -20 -18 0 -10 -7 -27 -15 -38 -13 -18 -14 -17 -12 11 2 23 -2 30 -15
29 -21 -2 -21 -3 -8 30 6 16 6 26 0 26 -6 0 -6 9 0 25 5 14 6 25 1 25 -10 0
-40 -66 -51 -111 -7 -30 -8 -28 -9 23 -2 91 48 135 157 137 24 1 52 -4 63 -9z
m477 -20 c23 -10 42 -26 42 -34 0 -20 -106 -102 -157 -122 -51 -20 -113 -13
-163 18 l-35 21 50 23 c36 17 68 24 113 24 77 0 76 19 -1 21 l-52 1 50 13 50
12 -45 5 c-25 3 -10 4 34 3 91 -4 91 11 0 24 l-59 9 65 1 c45 0 78 -5 108 -19z
m-1399 -55 c33 -15 66 -35 73 -43 11 -14 10 -15 -7 -8 -11 5 -29 11 -40 14
-11 2 -26 9 -32 15 -7 5 -28 6 -48 3 -23 -5 -45 -2 -65 9 -16 8 -30 11 -30 6
0 -6 14 -17 30 -26 17 -9 30 -18 30 -21 0 -2 -13 2 -30 11 -49 25 -86 20 -116
-19 -25 -31 -29 -33 -41 -18 -16 22 -17 68 0 79 27 18 105 32 158 28 34 -2 81
-14 118 -30z m1496 -93 c6 -30 3 -37 -33 -71 -22 -21 -54 -43 -71 -49 -29 -10
-33 -9 -41 14 -14 35 -13 36 10 29 27 -9 25 9 -2 24 -23 11 -23 11 2 5 14 -4
32 -9 40 -11 8 -3 4 5 -9 16 -13 12 -29 21 -37 21 -22 0 -17 15 10 29 20 11
29 10 60 -4 45 -21 58 -13 19 11 -15 10 -30 19 -32 21 -2 2 4 12 14 23 l19 21
22 -23 c12 -13 25 -38 29 -56z m-1619 26 c24 -12 50 -31 58 -43 12 -18 11 -24
-10 -48 -16 -18 -24 -23 -24 -13 0 8 -5 18 -11 22 -9 5 -10 -4 -6 -32 6 -37 5
-36 -19 19 -15 31 -30 57 -34 57 -4 0 2 -31 14 -68 14 -46 20 -85 17 -118 l-4
-49 -19 43 c-25 57 -35 47 -14 -15 27 -79 -9 -31 -39 52 -23 63 -25 66 -20 27
3 -23 11 -61 19 -82 12 -34 12 -40 0 -40 -20 0 -45 43 -51 88 -6 45 28 175 53
203 22 24 37 24 90 -3z m649 -8 c-10 -16 21 -75 51 -99 16 -12 23 -20 17 -16
-7 3 -13 2 -13 -3 0 -5 10 -14 23 -21 20 -11 19 -11 -10 -6 -37 5 -45 -9 -10
-19 34 -9 59 -7 52 4 -3 6 3 10 14 10 28 0 27 -28 -2 -35 -12 -3 -43 -2 -67 4
-43 9 -60 8 -47 -5 3 -3 24 -8 46 -11 23 -3 41 -10 41 -14 0 -13 -67 -11 -92
2 -25 14 -48 68 -48 115 0 39 26 104 42 104 5 0 7 -4 3 -10z m685 -104 c36
-31 68 -56 73 -56 16 0 5 16 -40 57 l-48 42 32 1 c17 0 38 3 47 6 21 8 56 -64
56 -117 0 -52 -20 -119 -37 -126 -17 -7 -130 47 -170 81 -18 15 -33 36 -33 47
0 27 7 24 55 -20 46 -42 105 -77 105 -62 0 5 -11 16 -24 24 -20 13 -56 68 -56
84 0 3 14 -7 31 -22 53 -44 46 -17 -7 30 -28 24 -63 60 -78 80 l-28 37 28 -15
c16 -9 58 -41 94 -71z m-153 28 c29 -29 27 -54 -8 -132 -20 -47 -20 -47 -33
-23 -7 13 -23 29 -36 36 l-23 12 27 7 c14 3 26 11 26 17 0 7 -7 8 -17 4 -10
-3 -31 -8 -48 -11 -23 -4 -18 1 23 20 72 34 66 50 -8 20 -72 -29 -86 -31 -43
-5 17 11 42 34 54 50 28 38 52 39 86 5z m-1041 -61 c78 -98 96 -168 49 -189
-23 -11 -25 -10 -34 30 l-9 41 -2 -60 -2 -60 -18 99 c-10 54 -24 104 -29 109
-13 13 -13 14 -1 -59 23 -131 23 -123 0 -117 -16 4 -20 0 -21 -18 0 -19 -4
-15 -17 18 -9 23 -21 45 -26 50 -12 11 3 -56 20 -89 17 -33 18 -41 3 -32 -40
24 -65 185 -37 237 27 51 59 97 68 97 5 0 30 -26 56 -57z m197 -1 c56 -125 97
-334 97 -497 0 -78 -32 -165 -68 -184 -18 -9 -21 -6 -32 37 -20 75 -73 182
-123 249 -45 60 -45 61 -26 79 36 32 52 115 43 217 -5 51 -9 98 -9 106 0 8 14
18 32 23 18 5 35 13 38 18 12 20 23 9 48 -48z m-503 -52 c0 -11 7 -36 15 -56
13 -31 13 -35 -2 -41 -9 -4 -36 4 -60 17 -54 28 -68 20 -19 -12 40 -26 42 -30
22 -47 -11 -9 -20 -9 -35 -2 -30 17 -36 13 -16 -9 25 -28 8 -25 -31 5 -46 35
-54 31 -16 -8 35 -36 33 -47 -9 -47 -29 0 -39 15 -39 59 0 63 19 90 89 126 77
40 101 43 101 15z m1273 -38 c42 -43 -17 -83 -133 -90 -66 -3 -78 -1 -97 17
-13 12 -23 29 -23 38 0 15 1 15 13 1 6 -9 24 -23 38 -32 23 -14 27 -15 32 -2
3 8 -2 21 -12 30 -21 19 -37 21 -25 3 5 -7 1 -4 -9 7 -18 20 -18 20 0 27 12 4
33 -4 61 -23 26 -18 61 -32 85 -34 45 -5 46 -5 -42 32 -18 8 -31 17 -28 20 3
3 15 0 27 -6 11 -6 29 -9 38 -7 13 3 11 7 -10 15 -16 6 -28 17 -28 23 0 19 89
4 113 -19z m-1123 -86 c0 -8 3 -21 6 -29 4 -13 -2 -15 -38 -11 l-43 4 40 -15
c49 -19 73 -40 30 -26 -41 13 -145 20 -151 9 -3 -4 12 -8 33 -9 61 -2 138 -23
135 -38 -1 -7 4 -24 13 -37 8 -13 15 -26 15 -29 0 -10 -55 -5 -110 10 -65 18
-78 19 -64 5 5 -5 43 -18 84 -29 72 -18 74 -20 47 -30 -65 -25 -136 0 -207 73
-53 56 -52 61 39 124 55 37 69 42 117 42 40 0 54 -4 54 -14z m694 -15 c10 -11
16 -24 13 -28 -3 -5 -8 -40 -11 -77 -6 -75 0 -94 47 -143 17 -18 29 -34 26
-35 -2 -2 -16 -14 -31 -27 -21 -18 -42 -24 -90 -28 -37 -2 -84 -14 -115 -28
l-52 -23 58 116 c32 64 73 156 92 204 19 49 37 88 40 88 3 0 14 -9 23 -19z
m-1834 -227 c42 -89 98 -193 123 -233 28 -42 44 -77 40 -86 -4 -11 -17 3 -45
50 -22 37 -42 64 -45 61 -12 -12 127 -246 147 -246 13 0 13 17 0 25 -5 3 -15
16 -21 28 -14 27 -3 16 42 -43 19 -24 28 -40 20 -35 -11 6 -13 4 -7 -10 5 -15
4 -16 -8 -6 -11 9 -15 9 -18 -1 -3 -7 -35 38 -71 100 -70 118 -211 392 -205
398 2 2 26 -41 53 -96 44 -89 65 -119 65 -91 0 21 -165 344 -168 329 -2 -9 4
-31 13 -48 9 -18 14 -34 11 -38 -3 -3 -6 0 -6 6 0 7 -4 12 -9 12 -14 0 3 -46
60 -167 26 -57 46 -103 43 -103 -6 0 -60 110 -102 213 l-41 96 17 51 c16 51
17 51 25 23 5 -15 44 -101 87 -189z m2290 171 c16 -19 7 -67 -21 -111 -24 -40
-88 -86 -142 -103 -63 -21 -65 -6 -3 22 73 34 67 46 -9 18 -31 -12 -58 -21
-62 -21 -10 0 19 30 51 53 22 15 27 16 17 4 -20 -25 4 -22 41 4 33 24 31 37
-4 19 -18 -10 -16 -5 11 24 48 50 35 53 -24 6 -28 -22 -53 -40 -58 -40 -4 0 3
17 16 38 12 20 27 45 33 56 20 37 130 59 154 31z m-2411 -125 c6 -18 6 -28 1
-25 -6 4 -10 1 -10 -6 0 -20 109 -268 152 -347 22 -40 38 -78 36 -84 -3 -7
-19 15 -38 47 -48 84 -49 52 0 -44 22 -45 38 -81 35 -81 -12 0 -104 199 -93
202 7 3 -10 56 -44 143 -31 77 -58 147 -61 155 -3 9 -6 5 -6 -10 0 -14 10 -54
23 -90 38 -105 38 -109 2 -35 -19 39 -35 63 -35 54 -1 -9 -8 -16 -17 -16 -11
0 -14 6 -9 21 4 12 14 51 22 88 17 71 25 77 42 28z m1531 -154 c22 -45 45
-102 52 -128 11 -45 11 -47 -22 -85 -18 -21 -50 -45 -69 -54 -50 -22 -56 -33
-79 -128 -22 -87 -19 -116 16 -154 11 -13 13 -20 5 -25 -99 -62 -373 -323
-419 -399 -14 -24 -28 -43 -30 -43 -2 0 -4 36 -4 80 l0 80 33 0 c51 1 113 34
148 82 39 53 83 148 105 225 16 57 15 61 -4 104 -11 24 -23 54 -26 65 -4 12
-15 29 -26 39 -17 16 -18 20 -6 44 25 48 34 105 40 244 3 76 8 142 11 147 3 5
18 7 34 4 32 -7 76 13 106 46 l20 22 38 -43 c21 -23 55 -79 77 -123z m-486 14
c68 -71 86 -110 86 -188 0 -64 -16 -125 -31 -116 -5 3 -10 33 -10 67 l-1 62
-8 -48 c-12 -71 -20 -69 -21 4 -2 99 -17 130 -18 36 l-1 -78 -29 22 c-25 18
-29 29 -34 87 -7 73 -27 154 -34 135 -2 -6 1 -38 6 -69 14 -72 14 -134 1 -134
-5 0 -17 16 -26 35 -9 20 -34 51 -56 69 l-39 34 20 40 c24 47 87 102 117 102
13 0 43 -23 78 -60z m-581 -317 c48 -73 87 -136 87 -140 0 -3 8 -16 18 -27 17
-21 17 -21 -5 -2 -29 24 -30 7 -2 -28 11 -14 18 -26 15 -26 -5 0 -148 175
-178 219 -23 32 -36 19 -14 -14 33 -50 7 -23 -42 44 -34 47 -46 71 -38 74 8 3
22 -11 35 -34 13 -22 27 -37 32 -33 5 3 7 11 4 17 -3 7 2 1 12 -13 10 -13 42
-57 71 -97 47 -64 72 -87 72 -67 0 4 -29 45 -64 93 -91 122 -257 405 -228 387
7 -4 12 -2 12 6 0 7 29 -41 64 -107 34 -66 102 -180 149 -252z m679 330 c9
-10 30 -44 48 -76 29 -52 32 -64 27 -116 -4 -50 -44 -168 -53 -158 -2 1 3 25
11 52 17 56 21 149 5 125 -5 -8 -10 -26 -10 -39 0 -37 -31 -141 -41 -136 -4 3
-1 21 7 40 19 46 18 166 -1 213 -9 20 -25 47 -37 59 -17 19 -19 25 -8 38 16
20 33 19 52 -2z m966 -84 c30 -10 32 -13 18 -28 -25 -28 -37 -95 -27 -143 13
-58 -7 -71 -32 -22 -24 48 -72 68 -129 55 -23 -6 -43 -9 -43 -8 -1 1 -9 15
-19 31 l-18 28 54 55 53 55 55 -6 c30 -3 70 -10 88 -17z m353 -9 c10 -14 22
-39 25 -57 6 -29 3 -33 -52 -68 -107 -69 -141 -67 -171 6 -20 46 -20 48 -3 39
28 -15 34 -12 19 7 -11 13 -5 12 22 -5 20 -12 40 -22 44 -22 16 0 -21 33 -67
60 l-49 27 30 13 c25 10 38 9 79 -4 27 -9 58 -23 68 -31 21 -16 54 -21 38 -5
-5 5 -34 23 -64 40 l-55 30 59 -2 c50 -3 62 -7 77 -28z m-2521 -12 c0 -5 23
-67 50 -138 50 -126 177 -357 194 -351 9 3 46 -47 46 -63 0 -4 -9 2 -20 15
-21 26 -26 19 -9 -13 6 -11 8 -22 6 -24 -3 -3 -33 49 -68 114 -69 132 -86 158
-95 150 -3 -4 29 -70 70 -148 42 -78 76 -144 76 -146 0 -3 -5 -2 -10 1 -11 7
-200 379 -200 392 0 5 5 0 11 -9 5 -10 14 -18 19 -18 4 0 -11 48 -35 108 -54
135 -60 157 -46 148 6 -4 11 -12 11 -18z m861 -10 c60 -31 109 -81 109 -113 0
-14 -47 -38 -60 -30 -4 3 -11 20 -14 40 -4 19 -11 37 -17 41 -7 4 -8 -7 -3
-33 11 -54 10 -68 -1 -71 -5 -2 -13 14 -18 35 -4 21 -12 42 -17 47 -12 12 -12
3 -1 -46 5 -22 5 -36 0 -32 -5 3 -9 15 -9 27 0 24 -38 113 -45 106 -2 -3 2
-27 10 -54 20 -63 19 -65 -10 -65 -14 0 -37 -7 -50 -16 -22 -14 -25 -15 -25
-1 0 8 5 17 11 19 8 3 9 15 3 38 l-8 35 -7 -35 -6 -35 -2 43 c0 23 -5 42 -11
42 -11 0 -11 -58 -1 -105 5 -21 1 -42 -13 -69 l-20 -39 -12 39 -12 39 -2 -35
c-1 -29 -3 -32 -10 -15 -16 36 -11 107 10 151 29 59 102 124 138 124 16 0 58
-15 93 -32z m1416 -57 c17 -29 37 -75 42 -103 13 -57 15 -218 3 -218 -13 0
-79 73 -96 107 -24 48 -19 53 9 10 14 -20 31 -37 36 -37 6 0 1 15 -11 33 -45
68 -87 166 -83 192 7 33 16 32 30 -5 6 -17 7 -28 2 -24 -19 11 -8 -33 16 -69
33 -48 53 -48 24 1 l-23 37 22 -19 c29 -25 28 -20 -8 50 -30 59 -36 85 -23 98
13 13 28 -1 60 -53z m-379 -26 c55 -46 80 -172 47 -235 -19 -37 -28 -39 -15
-4 13 33 13 67 0 59 -5 -3 -10 -15 -10 -26 0 -12 -7 -19 -19 -19 -15 0 -19 8
-19 47 -2 57 -24 165 -30 140 -2 -9 1 -48 7 -87 15 -92 14 -92 -9 -85 -15 5
-22 1 -29 -17 -8 -21 -10 -22 -17 -4 -4 10 -7 30 -8 45 -1 33 -46 46 -46 13 0
-11 7 -25 15 -32 21 -17 19 -30 -5 -30 -11 0 -20 -6 -21 -12 -1 -19 -49 84
-49 105 0 10 -4 16 -9 12 -6 -3 -4 -24 4 -52 8 -26 13 -49 11 -51 -2 -2 -9 14
-16 35 -16 49 -35 70 -26 28 12 -54 27 -84 41 -79 7 3 16 1 20 -5 3 -6 1 -11
-6 -11 -6 0 -9 -8 -6 -20 5 -21 5 -21 -24 -2 -49 31 -79 80 -79 131 0 72 46
126 138 162 69 27 124 25 160 -6z m692 -37 c15 -13 36 -28 46 -34 28 -17 25
-96 -6 -129 -21 -23 -27 -24 -132 -20 -87 2 -113 6 -129 20 -10 10 -19 24 -19
32 0 8 10 3 25 -14 26 -30 35 -20 10 13 -16 21 -20 44 -7 44 4 0 13 -14 19
-31 10 -26 16 -30 43 -27 18 2 34 -3 39 -11 5 -7 14 -10 20 -7 16 10 13 36 -4
36 -11 0 -13 8 -8 33 8 43 11 44 19 12 6 -23 11 -26 34 -21 18 3 33 0 40 -9 7
-8 17 -15 23 -15 5 0 -2 15 -17 33 -36 44 -59 87 -46 87 5 0 17 -11 26 -25 17
-26 44 -34 44 -13 0 6 -15 24 -32 39 -39 33 -28 39 12 7z m-489 -41 c23 -43
40 -150 28 -185 -11 -34 -58 -75 -99 -87 -23 -6 -22 -4 8 20 44 36 41 49 -5
23 -30 -17 -35 -19 -24 -5 19 24 0 22 -45 -5 -26 -15 -36 -18 -32 -7 3 8 13
16 24 18 25 4 42 19 76 65 39 54 34 69 -7 21 -29 -34 -33 -36 -28 -15 6 22 5
23 -10 12 -14 -12 -15 -10 -6 23 8 26 7 52 -1 87 -7 28 -9 53 -4 58 27 26 107
11 125 -23z m-1016 -16 c16 -16 40 -34 52 -40 28 -14 42 -60 22 -72 -8 -5 -16
-3 -20 3 -5 7 -14 -2 -23 -23 -16 -35 -16 -35 -2 49 9 52 -9 37 -22 -19 -15
-62 -15 -82 -2 -74 6 4 7 -1 3 -11 -6 -15 -7 -15 -15 0 -8 14 -13 15 -24 5
-19 -16 -18 0 2 22 9 10 14 25 11 33 -8 19 -37 10 -37 -11 0 -14 -37 -52 -51
-53 -9 0 2 49 12 56 12 7 11 41 -1 49 -22 13 -38 -15 -33 -55 4 -26 2 -40 -6
-40 -16 0 -14 69 5 124 21 61 10 71 -17 15 -13 -29 -18 -35 -14 -16 3 15 1 27
-5 27 -5 0 -10 -8 -10 -17 0 -16 -1 -16 -17 0 -17 17 -17 19 2 34 41 31 100
53 130 48 16 -3 43 -18 60 -34z m-271 3 c17 -6 88 -98 99 -128 6 -16 5 -17 -7
-7 -16 14 -36 7 -36 -11 0 -6 8 -21 17 -32 10 -12 11 -15 3 -8 -8 6 -24 12
-37 12 -20 0 -23 5 -23 37 0 44 -10 37 -21 -14 -6 -28 -7 -17 -4 37 3 62 2 71
-7 50 -7 -14 -13 -27 -15 -29 -1 -2 -11 7 -21 20 -19 22 -20 22 -46 6 -37 -25
-32 -9 11 35 36 37 55 44 87 32z m806 -175 l35 -44 -47 28 c-77 45 -79 29 -3
-21 l45 -29 -50 -5 c-28 -3 -59 -13 -69 -22 -18 -16 -20 -16 -35 6 -19 27 -20
55 -2 128 20 78 25 89 55 104 l26 14 5 -58 c4 -47 12 -66 40 -101z m1290 30
c-15 -53 -37 -76 -75 -83 -21 -4 -40 -5 -43 -2 -3 3 4 6 16 6 11 0 24 5 28 11
5 8 0 10 -17 6 -13 -3 -71 -8 -129 -11 -102 -5 -104 -5 -55 8 28 8 69 15 92
15 56 1 141 20 149 33 4 7 -5 8 -25 4 -17 -3 -45 -9 -62 -12 -22 -4 -29 -3
-25 5 5 7 -8 11 -38 11 -43 0 -61 -12 -33 -23 6 -3 -13 -5 -43 -5 -49 1 -51 1
-20 8 27 6 29 8 10 9 -22 1 -23 2 -9 17 10 9 40 16 80 19 43 2 56 1 39 -5 -43
-14 69 -10 120 4 25 8 46 14 47 15 1 0 -2 -13 -7 -30z m-1996 -85 c78 -42 121
-89 137 -146 19 -70 0 -178 -31 -178 -13 0 -22 31 -30 100 l-7 65 -3 -75 c-3
-102 -17 -55 -20 65 -1 60 -4 83 -10 70 -12 -28 -12 -158 0 -189 12 -32 -2
-34 -29 -7 -31 31 -44 97 -38 186 5 65 4 76 -6 60 -14 -23 -16 -167 -3 -208 8
-27 8 -27 -18 -16 -17 8 -22 15 -15 20 8 5 9 20 1 55 l-10 49 -1 -50 c-2 -78
-21 -34 -21 50 0 51 -3 66 -11 58 -7 -7 -10 -38 -7 -82 2 -39 0 -71 -3 -71 -4
0 -17 12 -29 28 -19 23 -21 36 -17 83 5 63 36 130 69 153 30 21 27 21 102 -20z
m398 19 c6 -25 -9 -88 -34 -145 -21 -49 -36 -62 -20 -17 18 47 22 79 12 79 -5
0 -10 -6 -10 -12 0 -7 -11 -40 -25 -72 -25 -61 -25 -86 1 -52 23 32 16 9 -10
-32 -31 -48 -74 -88 -109 -101 -45 -17 -47 -13 -10 26 44 49 108 184 86 182
-4 0 -23 -31 -42 -69 -18 -38 -45 -79 -58 -92 l-25 -23 7 35 c4 19 20 54 36
76 31 46 73 144 61 144 -4 0 -25 -33 -46 -72 l-39 -73 -12 50 c-12 45 -11 52
6 77 53 74 221 139 231 91z m834 -28 c4 -8 -2 -48 -12 -87 -13 -54 -25 -81
-48 -103 l-31 -30 -5 40 c-3 22 -9 45 -13 51 -4 7 2 15 16 19 19 7 17 9 -18
15 l-40 7 43 8 c66 12 63 28 -4 21 l-59 -6 45 16 c25 9 59 27 75 40 36 27 44
29 51 9z m724 -11 c0 -3 -7 -19 -17 -34 -17 -29 -47 -40 -109 -40 -19 0 -34
-5 -34 -11 0 -8 18 -10 55 -7 30 3 55 2 55 -2 0 -3 -13 -21 -28 -39 l-28 -34
-159 -13 -160 -12 158 1 c86 1 157 -1 157 -5 0 -16 -51 -39 -109 -50 -69 -13
-86 -34 -18 -24 23 4 53 10 67 13 23 5 24 4 9 -11 -22 -24 -114 -36 -284 -37
-91 -1 -140 -4 -125 -9 20 -7 17 -8 -20 -8 -25 0 -70 3 -100 7 -55 6 -54 7 39
14 52 4 147 7 212 7 68 0 120 4 124 10 4 6 -28 10 -89 10 -52 0 -98 4 -101 10
-3 6 -26 10 -51 10 -24 0 -44 -4 -44 -10 0 -5 -10 -10 -22 -9 -22 0 -22 1 -3
9 18 8 18 9 -5 9 -14 1 -32 6 -40 12 -11 8 13 9 85 5 57 -2 101 -9 103 -15 5
-14 151 -14 172 -1 11 7 -4 10 -50 11 -40 2 -15 5 62 9 112 6 152 12 137 23
-2 2 -74 -1 -159 -6 -109 -6 -156 -5 -158 2 -2 7 -33 11 -75 11 l-72 1 30 24
c30 25 105 36 105 15 0 -6 42 -10 105 -10 63 0 105 4 105 10 0 6 17 10 39 10
59 0 131 12 131 21 0 5 -8 9 -17 9 -15 0 -16 2 -3 10 11 7 1 10 -35 11 -37 0
-45 3 -30 9 12 5 -34 9 -124 9 -143 1 -144 1 -126 21 16 18 29 20 144 18 l126
-2 -120 -6 c-79 -3 -114 -8 -102 -14 21 -10 253 -2 282 10 19 8 19 8 -5 15
-23 6 -22 7 9 8 24 1 32 5 28 15 -4 10 3 16 21 19 46 7 62 7 62 1z m-1207 -26
c21 -11 21 -11 2 -19 -11 -4 -36 -8 -55 -9 l-35 -2 40 -13 c22 -8 53 -14 70
-14 36 -1 22 -18 -20 -24 -17 -2 -3 -5 29 -6 62 -1 70 -14 11 -18 -19 -1 -43
-6 -52 -12 -16 -9 -15 -11 7 -12 21 -1 18 -3 -15 -14 l-40 -13 32 -1 c17 -1
43 3 56 8 24 10 25 9 19 -32 -6 -50 -16 -59 -59 -53 -42 6 -79 33 -96 71 -18
36 -30 126 -19 141 21 32 85 44 125 22z m-1896 -76 c104 -82 163 -167 163
-232 0 -33 -3 -31 -52 53 -30 51 -54 83 -58 77 -4 -7 -22 8 -44 39 -38 52 -72
73 -41 25 9 -14 15 -28 13 -30 -3 -2 -23 3 -46 12 -38 15 -40 18 -31 42 12 30
28 52 39 52 5 0 30 -17 57 -38z m658 -102 c40 -118 41 -127 20 -199 -11 -36
-30 -66 -63 -99 -45 -46 -47 -46 -50 -22 -2 21 10 66 43 155 19 48 -18 -6 -40
-61 l-25 -59 0 45 c0 39 14 112 36 184 4 11 2 16 -4 12 -18 -11 -44 -107 -51
-185 l-6 -76 -24 49 c-20 40 -25 66 -25 126 0 82 13 125 52 178 28 38 83 75
94 64 4 -4 24 -55 43 -112z m1548 76 c14 5 15 9 5 22 -12 14 -11 14 10 1 26
-16 28 -39 8 -78 -18 -34 -57 -61 -89 -61 -14 0 -41 -7 -60 -15 -44 -18 -89
-19 -105 -3 -16 16 -15 57 3 88 14 25 14 25 16 -15 l1 -40 9 44 c9 47 15 58
26 48 4 -4 1 -13 -5 -19 -18 -18 -14 -46 8 -68 l20 -20 0 53 c0 48 12 77 31
77 5 0 19 -19 34 -42 25 -42 25 -42 23 -13 -1 17 -1 43 0 58 l2 28 23 -26 c15
-18 28 -24 40 -19z m-1421 -55 c36 -94 30 -120 -30 -138 -39 -11 -42 -10 -42
7 0 10 12 33 26 50 32 37 27 48 -8 20 -23 -18 -27 -18 -32 -4 -4 9 0 27 9 40
20 30 19 38 -2 30 -13 -5 -11 0 6 18 27 29 18 34 -17 10 -21 -14 -23 -14 -31
8 -11 30 3 39 54 36 39 -3 39 -3 67 -77z m-850 0 c42 -18 95 -49 117 -69 48
-44 101 -141 101 -186 -1 -30 -1 -29 -22 17 -24 54 -46 65 -23 11 8 -20 15
-46 14 -58 0 -19 -1 -19 -11 4 -42 94 -76 154 -111 194 -21 26 -42 45 -44 42
-3 -2 16 -34 42 -71 52 -73 105 -187 104 -225 0 -14 -3 -18 -5 -10 -13 38 -48
110 -55 110 -4 0 1 -21 11 -47 32 -88 33 -93 13 -93 -11 0 -31 23 -53 62 -35
60 -149 192 -150 173 0 -6 19 -33 42 -60 46 -56 118 -163 118 -177 0 -28 -40
-12 -134 52 -129 87 -150 122 -151 240 0 103 19 134 79 128 22 -3 76 -20 118
-37z m2986 -133 c-2 -6 -18 -16 -35 -22 -41 -14 -52 -26 -25 -26 32 0 26 -19
-23 -75 l-44 -50 -82 -4 c-51 -2 -76 -6 -68 -12 10 -6 8 -9 -8 -9 -13 0 -23
-4 -23 -10 0 -12 82 -13 96 -1 5 5 25 12 44 16 33 7 34 6 18 -10 -22 -23 -140
-34 -323 -30 -77 2 -128 1 -112 -1 15 -3 27 -12 27 -20 0 -10 -12 -14 -47 -13
l-48 2 40 6 40 7 -55 8 c-30 4 -84 11 -120 16 -58 8 -55 8 35 5 111 -5 99 9
-18 20 -37 4 -65 10 -62 15 3 5 20 4 37 -1 54 -15 380 -32 408 -20 22 9 20 10
-20 11 -29 0 -40 3 -32 9 9 5 -39 12 -120 16 -73 5 -124 10 -113 13 47 10 36
22 -21 21 -42 0 -53 -3 -39 -9 18 -8 18 -9 -6 -9 -16 -1 -26 4 -26 13 0 18 61
24 147 16 36 -4 52 -8 36 -8 -20 -1 -27 -5 -20 -12 13 -13 287 -25 349 -15 43
7 47 9 25 16 -20 6 -17 7 20 8 29 1 40 4 32 10 -10 6 -4 10 20 15 29 7 30 9
13 16 -19 7 -18 8 5 12 14 3 -39 6 -117 7 -82 0 -144 5 -148 11 -4 6 39 10
122 10 147 0 211 19 73 21 -47 1 -74 4 -60 8 17 4 1 8 -45 12 -63 5 -57 6 50
8 66 1 140 6 165 10 25 5 49 9 53 10 5 0 7 -4 5 -11z m-2030 -53 c28 -13 52
-27 52 -30 0 -31 -85 -125 -114 -125 -7 0 -21 -9 -31 -20 -18 -20 -82 -46 -92
-37 -3 3 11 12 30 20 19 8 38 20 41 26 9 14 5 14 -37 -4 -64 -26 -54 -16 18
20 77 39 111 62 104 70 -3 2 -45 -15 -95 -40 -49 -24 -105 -47 -123 -51 l-35
-6 35 36 c18 21 50 47 69 58 43 26 80 58 80 70 0 5 -21 -5 -46 -22 -25 -18
-47 -30 -49 -28 -3 2 0 21 6 41 17 61 81 69 187 22z m-1168 13 c0 -7 10 -33
22 -58 12 -25 20 -50 19 -56 -1 -6 22 -21 50 -33 28 -12 48 -25 45 -28 -3 -3
-47 15 -96 40 -50 25 -90 41 -88 34 6 -16 163 -97 189 -97 11 0 17 5 14 10 -3
6 10 1 29 -11 44 -26 45 -30 4 -18 -18 5 -42 9 -53 8 -17 0 -17 -2 5 -11 14
-6 50 -17 80 -24 l55 -12 -70 -1 c-49 0 -90 6 -137 23 -38 13 -68 21 -68 16 0
-8 79 -44 115 -53 11 -2 -6 -5 -38 -6 -37 -1 -72 5 -94 16 -67 32 -128 147
-105 198 20 44 122 96 122 63z m1530 -98 c0 -72 -2 -77 -40 -130 -22 -30 -60
-95 -85 -145 -55 -109 -65 -120 -65 -75 0 19 -5 59 -11 89 -11 53 -10 59 15
106 80 148 165 263 178 242 4 -7 8 -46 8 -87z m583 79 c14 -6 30 -22 36 -36
15 -33 14 -147 -3 -186 -19 -46 -87 -128 -137 -167 l-44 -33 51 54 c62 66 94
108 94 123 0 6 -26 -18 -58 -53 -42 -46 -65 -65 -84 -65 -16 -1 -24 3 -20 9 3
5 26 38 50 73 24 35 41 66 37 70 -3 4 -10 0 -15 -8 -23 -42 -139 -180 -144
-172 -3 5 -8 27 -12 48 -5 33 0 51 35 118 46 88 47 94 26 76 -21 -17 -75 -114
-75 -133 0 -9 -10 -21 -22 -28 -21 -11 -21 -10 -15 42 14 114 102 235 194 263
60 18 76 19 106 5z m-258 -25 c14 -15 25 -30 25 -33 0 -3 -14 -26 -30 -50 -40
-59 -63 -141 -58 -202 7 -71 -9 -60 -20 14 l-9 62 -3 -60 c-2 -58 -2 -59 -12
-25 -9 33 -6 115 7 185 l6 30 -16 -28 c-21 -36 -30 -142 -16 -191 18 -67 5
-51 -24 29 -30 83 -30 135 2 202 17 36 84 93 110 93 8 0 25 -12 38 -26z m-701
-161 c19 -38 76 -219 76 -242 0 -32 -33 -122 -40 -110 -6 8 -13 8 -29 0 -17
-9 -21 -9 -21 3 0 7 7 16 15 20 10 4 15 19 14 48 -1 36 -2 39 -9 18 -8 -24 -8
-24 -9 6 -2 51 -13 124 -20 124 -3 0 -5 -46 -4 -102 l2 -103 -14 73 c-7 42
-26 96 -45 128 -37 67 -35 89 15 133 44 38 51 38 69 4z m1831 -38 c-20 -24
-46 -47 -58 -51 -34 -11 -194 -6 -334 11 -70 8 -130 11 -133 6 -3 -5 -23 -6
-45 -2 l-40 7 41 7 c23 4 70 4 105 0 46 -4 60 -3 55 5 -4 7 -26 12 -48 12 -23
0 -39 2 -36 5 3 3 50 2 106 -1 72 -5 99 -10 96 -19 -6 -15 165 -28 216 -15 28
6 18 8 -55 11 l-90 2 82 6 c81 7 126 21 65 21 -18 0 -32 5 -32 10 0 6 -8 11
-17 12 -10 1 18 5 62 8 44 3 84 7 88 8 5 1 -8 -18 -28 -43z m-2535 5 c12 -7
11 -12 -5 -30 -11 -12 -25 -18 -37 -14 -21 6 -24 -6 -5 -26 6 -8 -3 -2 -20 14
-45 40 -50 28 -9 -20 18 -21 38 -50 45 -64 11 -24 11 -24 -3 -6 -9 11 -16 23
-16 28 0 4 -9 8 -20 8 -25 0 -28 -37 -3 -42 10 -2 17 -14 18 -32 l1 -28 -29
32 c-16 18 -34 29 -42 26 -20 -7 -8 -33 18 -41 17 -5 18 -8 7 -12 -8 -3 -18
-11 -22 -17 -14 -21 -32 51 -33 127 0 67 3 81 19 93 22 16 113 19 136 4z m133
-25 c38 -26 67 -71 67 -102 0 -15 -4 -14 -20 7 -27 35 -25 9 5 -50 26 -51 33
-122 15 -150 -8 -12 -10 -10 -10 11 0 25 -48 110 -69 123 -17 11 -13 -1 15
-52 14 -26 17 -36 7 -22 -34 46 -75 90 -85 90 -5 0 3 -17 20 -37 16 -21 37
-53 48 -70 19 -34 33 -42 35 -20 0 6 5 -1 10 -18 19 -63 -18 -91 -75 -56 -38
24 -45 38 -82 166 -35 121 -34 133 11 172 46 40 59 41 108 8z m449 -62 c52
-88 73 -163 63 -223 -5 -27 -3 -40 5 -40 6 0 8 -5 5 -10 -13 -21 -25 2 -26 48
-1 38 -3 42 -9 22 l-7 -25 -7 30 c-25 107 -40 157 -49 162 -6 4 -8 -3 -4 -18
24 -94 28 -129 18 -148 -6 -11 -11 -38 -11 -60 0 -52 -13 -52 -61 -1 -28 29
-40 54 -50 97 -12 58 -29 93 -29 60 0 -40 24 -119 45 -151 33 -49 7 -34 -33
18 -38 51 -52 93 -52 158 0 63 5 72 62 118 73 58 85 55 140 -37z m1208 62 c40
-20 38 -43 -8 -135 -34 -68 -42 -78 -91 -102 -63 -32 -164 -41 -141 -13 7 8
10 22 6 30 -3 8 0 15 6 15 19 0 148 62 148 72 0 4 -17 1 -37 -8 -142 -60 -161
-57 -43 6 44 24 80 47 80 52 0 13 -10 10 -49 -14 l-36 -22 32 37 c18 21 40 51
48 67 18 34 41 38 85 15z m-938 -75 c28 -114 20 -189 -26 -245 -17 -20 -17
-20 -7 2 6 12 10 37 9 55 -1 18 2 53 6 78 10 57 -3 45 -25 -23 l-17 -52 6 52
c3 39 -1 70 -17 118 -12 36 -19 70 -16 76 4 5 21 9 38 7 30 -3 33 -6 49 -68z
m-466 29 c-22 -26 -26 -40 -26 -92 0 -34 5 -78 11 -97 5 -19 12 -45 13 -57 4
-28 52 -103 66 -103 15 0 12 11 -7 32 -30 33 -8 19 26 -17 30 -32 31 -35 12
-35 -27 0 -101 68 -127 115 -10 19 -22 35 -26 35 -17 0 17 -64 59 -110 54 -61
49 -65 -12 -9 -35 33 -49 56 -66 108 -31 100 -11 208 45 242 47 29 62 24 32
-12z m1930 -57 l89 -7 -34 -32 c-79 -74 -83 -75 -171 -64 -62 8 -63 9 -17 10
75 2 55 21 -25 23 l-73 2 75 6 c74 6 74 6 25 11 -38 4 -27 6 44 7 52 1 92 4
90 7 -3 2 -70 9 -150 14 -145 10 -294 31 -273 38 7 2 65 -3 130 -12 65 -8 143
-14 174 -13 37 1 44 3 22 5 -28 4 -31 7 -21 19 9 11 14 12 19 4 4 -6 47 -15
96 -18z m-2484 -57 l15 -55 -34 21 c-50 31 -52 16 -3 -20 40 -29 41 -30 13
-28 -21 2 -28 -2 -27 -15 1 -15 -2 -16 -20 -6 -25 13 -76 18 -76 7 0 -9 47
-43 72 -52 11 -4 22 3 32 19 13 19 24 24 54 23 l37 -2 -39 -6 c-22 -3 -41 -12
-44 -18 -6 -19 28 -29 39 -12 6 9 9 10 9 2 0 -21 -20 -25 -90 -18 l-70 7 -11
39 c-19 63 0 108 58 141 27 15 53 27 59 27 6 1 18 -24 26 -54z m1118 -49 c-10
-37 -24 -73 -29 -80 -6 -7 -19 -48 -29 -91 -10 -44 -24 -81 -29 -82 -8 -3 -8
8 -2 34 12 51 11 69 -1 57 -5 -5 -22 -72 -36 -149 -15 -77 -32 -144 -36 -150
-6 -6 -7 6 -2 29 5 28 4 37 -5 32 -15 -10 -15 -6 19 121 29 111 97 266 86 196
-3 -18 -1 -33 4 -33 5 0 12 17 16 38 6 37 54 155 60 149 2 -2 -5 -34 -16 -71z
m959 -2 c2 -2 -3 -4 -13 -4 -9 0 -14 -4 -11 -10 3 -5 19 -10 35 -10 16 0 36
-7 45 -16 19 -19 158 -40 163 -24 2 5 18 10 35 11 31 1 31 1 7 9 -14 4 -43 9
-65 10 -22 1 -34 4 -27 7 6 2 9 9 6 15 -6 9 151 1 169 -9 8 -5 -28 -33 -116
-88 l-57 -37 -93 12 c-166 21 -358 60 -296 60 8 0 66 -9 129 -20 103 -18 214
-26 183 -14 -6 3 -58 13 -115 24 -77 13 -93 18 -63 20 39 2 39 2 -18 15 -32 7
-61 16 -65 20 -4 5 1 5 10 2 10 -3 40 -9 68 -13 30 -4 50 -12 50 -19 0 -8 18
-15 48 -19 137 -16 162 -17 162 -8 0 5 -26 12 -57 14 -31 3 -59 10 -61 16 -2
6 -48 18 -102 28 -99 17 -131 38 -42 28 35 -4 43 -3 32 5 -11 8 -6 9 20 5 19
-4 37 -8 39 -10z m-1890 -76 c6 -18 21 -43 34 -56 l22 -25 -55 6 c-53 6 -54 6
-25 -8 17 -8 50 -15 75 -15 44 -2 45 -2 15 -12 -22 -8 -48 -8 -99 1 -38 6 -71
10 -73 7 -12 -12 104 -38 150 -33 l51 4 -32 -28 c-41 -36 -101 -52 -166 -44
-102 15 -114 31 -76 105 40 79 95 124 157 129 6 1 16 -13 22 -31z m769 -105
c-9 -10 -30 -47 -48 -83 -18 -36 -43 -76 -57 -89 -13 -14 -43 -63 -66 -110
-38 -78 -56 -105 -64 -97 -2 2 1 16 7 31 6 15 21 80 34 145 29 144 37 160 77
160 36 0 89 21 108 43 7 10 16 17 19 17 3 0 -2 -8 -10 -17z m-228 -229 c-29
-126 -79 -273 -100 -294 -9 -9 -34 -45 -55 -78 -21 -34 -44 -62 -51 -62 -7 0
-22 -12 -34 -27 -12 -16 -19 -20 -16 -11 3 10 8 52 12 94 5 57 12 82 29 103
13 14 33 47 46 71 12 25 35 60 51 78 15 18 28 38 28 46 0 7 13 30 30 52 16 21
30 45 30 52 0 15 42 74 47 68 3 -2 -5 -44 -17 -92z m847 -40 c10 -26 -60 -87
-159 -139 -49 -26 -104 -60 -122 -74 -38 -33 -78 -61 -87 -61 -19 0 10 55 32
61 13 4 38 12 54 19 27 11 28 13 10 20 -17 6 -13 9 20 20 22 7 45 17 50 22 14
14 -51 0 -102 -22 -24 -11 -43 -17 -43 -15 0 14 117 67 180 82 45 10 66 19 52
21 -22 3 -96 -9 -137 -24 -11 -4 27 18 85 49 108 57 156 69 167 41z m81 -77
c12 -11 22 -27 22 -35 0 -23 -78 -109 -109 -120 -39 -14 -46 -4 -16 20 25 20
32 42 16 52 -4 3 -18 -2 -29 -12 -15 -13 -34 -17 -72 -16 -60 3 -81 14 -27 14
19 0 44 3 54 7 14 6 11 9 -17 13 l-35 6 40 12 c22 7 60 12 85 12 33 0 41 3 30
10 -8 5 -27 10 -42 10 l-26 0 31 33 c35 36 50 35 95 -6z m637 -38 c42 -7 73
-15 68 -19 -4 -4 -33 -15 -63 -24 -30 -10 -62 -19 -70 -22 -8 -3 -1 3 15 12
19 11 25 19 16 22 -11 4 -11 6 0 14 9 6 1 7 -26 3 -22 -4 -50 -6 -63 -6 -13 0
-46 -11 -73 -25 -28 -14 -48 -21 -44 -15 9 16 -11 13 -46 -5 -18 -9 -29 -21
-25 -29 3 -8 -7 -21 -22 -31 l-27 -17 19 21 c11 12 14 22 9 22 -9 0 -135 -86
-180 -122 -9 -7 -13 -7 -13 0 0 13 185 139 281 192 83 44 114 48 244 29z
m-859 -108 c58 -44 134 -117 134 -130 0 -21 -85 -104 -125 -122 -50 -23 -88
-24 -148 -3 -65 22 -65 29 1 27 32 -1 48 0 37 3 -108 27 -123 34 -135 64 -7
17 -11 32 -9 34 2 3 27 -2 54 -9 28 -8 44 -14 38 -14 -29 -2 -10 -20 28 -26
50 -9 65 2 18 12 -28 7 -18 9 51 13 l85 5 -107 13 c-109 13 -168 28 -168 42 0
4 14 13 31 20 17 7 54 33 82 56 29 24 61 44 73 44 12 0 39 -13 60 -29z m264
-7 c33 -39 50 -73 50 -105 0 -26 -112 -139 -136 -139 -11 1 -32 12 -49 26
l-30 25 28 -11 c16 -5 43 -10 60 -9 28 0 30 1 10 9 -15 6 -20 15 -16 29 4 17
14 21 47 22 32 0 37 2 21 9 -11 5 -34 9 -52 9 -17 1 -35 6 -38 12 -5 7 5 8 29
4 28 -6 36 -4 36 7 0 8 -3 18 -7 22 -4 4 -8 15 -8 24 0 13 -8 16 -31 15 -21
-2 -34 2 -38 12 -6 18 31 20 82 6 44 -13 41 5 -4 19 -34 12 -35 13 -18 31 23
26 27 25 64 -17z m906 -6 c68 -34 68 -46 -3 -47 -17 -1 -35 -6 -38 -12 -5 -7
21 -9 82 -5 l88 5 -95 -14 c-108 -17 -196 -41 -205 -55 -3 -6 0 -10 7 -10 7 0
-7 -9 -31 -20 -24 -11 -48 -20 -55 -19 -6 0 0 5 14 10 18 7 21 12 11 18 -10 6
-9 10 5 15 15 6 16 9 5 15 -9 6 13 15 63 27 77 18 107 40 39 29 -97 -16 -213
-66 -213 -90 0 -11 -11 -15 -37 -15 l-38 1 30 16 c17 9 34 22 38 29 4 8 54 28
110 45 165 51 158 47 115 48 -20 1 -48 -3 -62 -9 -17 -6 -26 -6 -26 0 0 6 -8
10 -17 11 -10 0 7 7 37 15 86 23 87 23 70 34 -11 7 -8 10 13 10 16 0 58 -14
93 -32z m238 -129 l61 -39 -55 0 c-66 -1 -197 -17 -212 -27 -8 -4 -5 -9 8 -14
16 -6 15 -8 -8 -8 -14 -1 -35 2 -45 6 -16 6 -16 8 5 13 13 3 22 9 20 15 -3 11
57 24 119 24 23 1 45 6 48 11 7 12 -68 12 -136 1 -31 -5 -49 -4 -57 5 -9 10 0
14 41 19 66 9 80 18 42 26 -25 6 -23 7 18 8 26 0 47 6 47 11 0 13 16 5 104
-51z m-2394 -45 c-12 -68 -19 -83 -60 -133 -51 -61 -194 -201 -205 -201 -7 0
-2 9 35 65 15 22 38 61 52 86 14 25 44 70 67 100 22 30 41 58 41 63 0 5 16 26
35 47 19 22 35 44 35 51 0 6 3 8 6 5 4 -4 1 -41 -6 -83z m1726 -45 c16 -17 63
-56 104 -86 86 -64 104 -80 74 -64 -32 16 -114 78 -156 119 -21 20 -41 32 -45
28 -11 -12 107 -117 178 -159 l60 -35 -40 -4 c-22 -2 -100 -3 -174 -3 -126 1
-137 3 -178 27 -25 15 -56 36 -69 48 l-25 22 25 -16 c29 -19 44 -21 34 -5 -4
7 11 0 32 -15 21 -14 47 -26 57 -26 10 0 27 -5 38 -11 11 -6 25 -9 31 -7 6 2
-18 23 -53 46 -35 23 -58 42 -51 42 7 0 10 5 7 11 -4 6 14 -6 40 -25 67 -51
133 -88 147 -83 7 2 -8 17 -34 33 -27 16 -48 34 -48 41 0 11 10 7 123 -50 60
-30 67 -13 9 22 -28 18 -50 37 -47 41 3 5 -15 25 -40 46 -25 20 -43 39 -40 42
3 3 -2 16 -10 29 -14 21 -14 23 3 23 10 0 31 -14 48 -31z m808 -7 c52 -34 166
-153 166 -171 0 -25 -567 -21 -638 5 -24 9 -41 19 -38 22 4 3 -2 14 -11 25
-10 11 3 5 30 -13 47 -31 79 -35 41 -4 -18 15 -18 15 3 5 27 -14 36 -14 28 -1
-4 6 10 -1 31 -15 20 -14 41 -25 45 -25 19 0 8 16 -22 34 -17 10 -63 45 -101
77 -47 39 -77 58 -88 54 -10 -3 -23 2 -31 12 -13 16 -12 17 4 4 11 -9 17 -10
17 -2 0 6 12 11 27 11 14 0 23 -3 19 -7 -9 -9 54 -73 72 -73 8 0 39 -22 69
-50 30 -27 62 -50 70 -50 13 0 14 2 2 17 -9 11 -3 9 15 -5 37 -29 54 -28 30 1
-10 12 -12 16 -4 10 8 -7 22 -13 32 -13 10 0 30 -7 44 -16 51 -34 32 -5 -31
46 -58 48 -83 72 -55 55 14 -9 12 8 -2 26 -7 7 10 -7 37 -32 56 -52 94 -79
110 -79 5 0 -15 23 -45 50 -37 33 -49 49 -37 50 9 0 17 -6 17 -14 0 -19 67
-63 131 -86 62 -23 59 -22 59 -12 0 4 -21 20 -47 36 -25 17 -42 31 -36 33 15
5 63 -15 63 -26 0 -16 71 -55 79 -43 4 7 12 8 21 2 19 -12 30 -13 30 -2 0 5
-10 14 -22 20 -21 10 -21 11 -3 11 35 2 2 18 -75 37 l-75 19 70 5 c86 6 63 20
-33 20 -50 0 -63 3 -52 11 11 8 -1 9 -45 6 -45 -4 -54 -3 -35 4 14 6 55 9 93
8 37 -1 70 0 73 4 11 11 -68 20 -128 13 -53 -5 -59 -4 -48 9 7 8 22 16 34 17
11 0 -1 4 -29 8 -46 6 -43 7 31 8 79 2 84 1 138 -36z m-1363 -107 l29 -60 -38
-8 c-67 -14 -188 31 -246 92 l-30 31 28 0 c15 0 65 -19 112 -41 47 -23 88 -38
91 -35 8 8 8 9 -72 46 -64 31 -67 34 -45 42 14 5 35 6 49 3 19 -5 22 -3 17 10
-4 11 4 8 25 -9 42 -35 50 -24 13 17 l-29 32 34 -30 c18 -16 46 -57 62 -90z"/>
</g>
    </Svg>
  );
};

export default Icon;
