import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#12110f" stroke="none">
<path d="M1824 4989 c-13 -22 -17 -493 -5 -625 15 -153 49 -345 91 -510 60
-233 208 -641 295 -814 46 -91 41 -110 -9 -34 -22 33 -84 117 -139 187 -335
433 -651 730 -1002 942 -126 76 -372 201 -380 193 -16 -16 165 -351 282 -523
208 -303 522 -616 914 -909 74 -55 144 -106 155 -112 10 -7 17 -14 14 -17 -3
-3 -41 12 -85 33 -44 21 -154 67 -245 103 -378 150 -698 236 -1010 272 -186
22 -700 18 -700 -5 0 -9 198 -141 295 -197 409 -237 901 -383 1513 -450 155
-17 212 -33 117 -33 -62 0 -392 -46 -549 -76 -504 -97 -868 -240 -1250 -492
-120 -79 -128 -86 -105 -94 42 -16 452 -22 599 -9 252 23 535 85 823 182 168
57 435 162 527 209 34 17 65 28 68 25 4 -4 0 -10 -9 -15 -40 -22 -186 -132
-314 -235 -404 -325 -670 -620 -877 -975 -72 -123 -174 -328 -167 -335 16 -15
332 153 504 268 240 160 502 404 745 694 78 93 272 348 296 389 7 10 14 17 17
14 3 -3 -10 -37 -28 -75 -55 -114 -160 -384 -214 -548 -95 -292 -149 -534
-172 -781 -13 -144 -8 -611 8 -627 16 -16 199 273 296 466 134 269 232 585
301 965 28 158 66 440 66 495 0 19 5 35 10 35 6 0 10 -18 10 -39 0 -64 39
-353 71 -526 97 -520 240 -888 497 -1279 71 -109 86 -126 94 -111 6 10 13 124
16 255 7 332 -14 528 -89 840 -56 230 -209 660 -305 854 -19 38 -31 72 -28 75
4 3 11 -3 18 -13 6 -11 50 -71 97 -135 303 -409 632 -740 944 -948 161 -108
488 -283 501 -270 7 7 -71 168 -141 293 -147 259 -303 462 -528 686 -177 176
-474 426 -642 538 -62 41 -59 39 -53 45 3 3 52 -17 109 -44 411 -192 909 -336
1294 -372 149 -14 610 -8 627 9 12 12 -184 144 -348 235 -289 161 -640 280
-1049 356 -173 32 -462 71 -526 71 -71 0 -40 17 44 24 45 4 159 18 253 31 628
91 1068 245 1495 526 115 75 137 94 123 102 -28 16 -475 21 -620 8 -238 -23
-492 -78 -767 -167 -162 -53 -433 -158 -555 -216 -46 -22 -86 -37 -88 -35 -3
3 26 25 63 50 168 113 448 346 623 518 212 209 361 394 493 614 91 149 203
372 193 382 -9 9 -244 -110 -384 -194 -291 -174 -581 -433 -858 -763 -102
-122 -230 -289 -279 -362 -21 -32 -42 -58 -47 -58 -4 0 9 35 31 78 104 207
243 598 310 871 74 299 86 404 86 756 0 206 -4 316 -11 328 -10 17 -20 5 -86
-95 -232 -350 -363 -657 -463 -1088 -52 -220 -115 -626 -115 -737 0 -24 -4
-43 -10 -43 -5 0 -10 19 -10 43 0 59 -26 262 -56 442 -73 443 -198 827 -366
1130 -61 110 -223 365 -232 365 -3 0 -8 -5 -12 -11z m202 -347 c180 -320 303
-693 378 -1147 29 -172 56 -383 56 -436 l0 -36 -62 -13 c-35 -6 -77 -18 -94
-25 l-32 -13 -45 97 c-192 407 -339 912 -377 1290 -11 114 -14 442 -4 515 l7
47 54 -81 c30 -45 83 -134 119 -198z m1133 12 c1 -287 -23 -484 -94 -767 -63
-250 -184 -590 -292 -819 -36 -77 -50 -97 -62 -92 -38 15 -99 33 -135 39 l-39
6 7 89 c4 49 18 164 31 256 88 595 234 1020 490 1428 l80 127 7 -38 c4 -21 7
-124 7 -229z m-2274 -460 c301 -164 482 -301 735 -554 203 -203 409 -447 549
-653 l32 -47 -70 -69 -71 -70 -72 49 c-112 75 -368 283 -514 418 -213 196
-380 389 -523 608 -87 131 -229 394 -214 394 5 0 71 -34 148 -76z m3385 68 c0
-17 -157 -294 -226 -398 -203 -309 -529 -637 -936 -945 -178 -134 -161 -130
-242 -51 -80 78 -85 55 57 245 155 206 278 348 457 527 251 252 436 392 735
554 140 76 155 83 155 68z m-3439 -1135 c277 -49 555 -130 869 -254 229 -91
324 -139 314 -161 -4 -9 -16 -52 -27 -94 -24 -92 -3 -87 -234 -58 -658 84
-1114 233 -1547 505 -110 69 -124 81 -104 87 13 4 149 6 303 4 248 -3 297 -6
426 -29z m4047 25 l43 -7 -127 -80 c-334 -210 -682 -345 -1116 -434 -163 -34
-447 -77 -575 -87 l-83 -7 0 29 c0 16 -11 59 -23 96 l-23 66 95 46 c385 183
910 337 1271 375 102 10 477 12 538 3z m-2253 -176 c189 -50 336 -217 366
-416 31 -202 -82 -414 -271 -509 -149 -74 -293 -76 -439 -5 -254 125 -353 434
-220 685 58 107 203 221 317 248 57 14 191 12 247 -3z m-645 -578 c6 -35 18
-78 25 -96 l14 -32 -67 -34 c-92 -46 -371 -156 -533 -210 -268 -89 -558 -154
-789 -175 -128 -12 -379 -14 -484 -4 l-68 6 113 74 c342 221 723 366 1199 458
166 32 457 73 526 74 l51 1 13 -62z m1316 37 c641 -87 1099 -241 1513 -509
l113 -73 -47 -6 c-74 -11 -401 -8 -516 3 -322 32 -678 126 -1068 282 -240 96
-315 134 -306 156 19 45 35 109 35 139 0 32 1 33 45 33 24 0 129 -11 231 -25z
m-1114 -401 c-14 -30 -173 -238 -282 -369 -342 -411 -671 -684 -1062 -881 -60
-30 -108 -52 -108 -49 0 24 157 301 241 426 213 318 626 721 1003 981 l79 55
69 -71 c60 -61 68 -73 60 -92z m806 134 c81 -55 242 -179 347 -266 407 -339
685 -676 881 -1065 l54 -107 -102 51 c-430 216 -765 503 -1156 992 -61 76
-133 171 -161 211 l-51 74 43 35 c24 19 54 51 68 71 13 20 25 36 27 36 2 0 24
-14 50 -32z m-574 -193 l48 -8 -7 -81 c-14 -166 -57 -440 -101 -636 -95 -424
-222 -734 -438 -1069 l-60 -95 -9 40 c-5 21 -7 154 -4 294 4 218 9 278 32 410
59 343 192 760 345 1087 l48 102 49 -19 c26 -10 70 -21 97 -25z m350 -22 c52
-105 169 -403 224 -574 84 -259 139 -511 162 -738 11 -115 14 -442 3 -516 l-6
-47 -73 113 c-238 368 -390 784 -478 1314 -28 166 -56 382 -56 431 0 31 2 32
48 39 37 5 86 21 139 44 2 1 18 -29 37 -66z"/>
</g>

      
          </Svg>
  );
};

export default Icon;
