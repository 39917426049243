import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2959 4715 c-14 -8 -32 -29 -39 -47 -8 -18 -15 -35 -16 -37 -2 -2
-17 8 -34 23 -35 29 -47 31 -103 15 -32 -9 -41 -18 -57 -55 -13 -30 -25 -44
-38 -44 -11 0 -31 -9 -46 -21 l-26 -20 0 22 c0 41 -41 110 -74 126 -30 14 -39
13 -149 -17 -78 -22 -125 -40 -139 -55 l-22 -23 -15 32 c-9 17 -27 36 -41 43
-21 10 -30 9 -65 -11 -22 -13 -82 -45 -134 -72 -71 -36 -96 -45 -99 -34 -3 8
-16 25 -29 38 -25 25 -29 25 -120 6 -24 -4 -39 -1 -62 15 -50 36 -81 28 -160
-40 -71 -61 -71 -61 -71 -112 0 -48 -3 -55 -38 -83 -20 -17 -76 -56 -123 -86
-97 -62 -119 -88 -119 -135 0 -54 17 -83 60 -101 39 -17 40 -18 40 -70 0 -74
11 -90 68 -97 111 -14 116 -18 101 -78 l-11 -47 -47 41 c-40 36 -51 40 -83 36
-31 -4 -38 -2 -43 14 -11 32 -46 60 -121 95 -40 18 -75 37 -79 43 -3 6 -17 11
-30 11 -43 0 -135 -143 -135 -209 0 -20 9 -54 19 -76 l18 -41 -58 1 c-65 0
-96 -20 -130 -88 -18 -35 -19 -47 -10 -150 14 -152 21 -171 87 -208 50 -29 53
-32 40 -52 -8 -12 -16 -66 -20 -122 -5 -74 -11 -103 -22 -112 -9 -6 -36 -30
-60 -53 -35 -32 -44 -48 -44 -74 0 -66 13 -125 32 -146 19 -21 19 -21 -1 -64
-25 -51 -28 -134 -6 -176 15 -29 65 -72 98 -83 9 -4 29 -2 44 4 21 8 32 5 63
-16 20 -14 47 -25 61 -25 31 0 145 54 152 72 3 8 21 -5 54 -39 45 -48 48 -55
36 -75 -31 -57 -3 -147 56 -178 46 -24 78 -53 125 -112 58 -74 72 -70 226 61
l35 30 -3 88 c-1 48 -3 115 -5 148 -2 58 -1 60 25 63 18 2 30 -3 37 -17 7 -13
30 -24 68 -32 75 -15 98 -31 122 -78 31 -60 64 -69 163 -42 91 24 105 33 124
76 7 16 13 24 14 17 0 -20 52 -82 82 -98 15 -8 39 -14 53 -14 33 0 81 24 88
45 4 8 13 15 22 15 9 0 23 17 32 38 l16 37 13 -57 c25 -107 80 -181 142 -193
15 -3 41 -10 57 -16 67 -24 101 -28 142 -17 23 5 48 16 54 24 13 16 29 -14
144 -276 60 -136 70 -166 56 -168 -48 -8 -61 -14 -96 -42 -30 -24 -45 -29 -66
-25 -38 9 -89 -39 -160 -149 -56 -87 -58 -89 -65 -61 -7 29 16 115 39 143 6 8
32 24 59 38 54 27 68 48 61 85 -4 22 1 30 31 47 42 25 55 72 37 133 -15 50
-44 69 -103 69 -45 0 -48 2 -48 25 0 36 -33 65 -75 65 -29 0 -35 4 -35 20 0
12 -11 32 -25 46 -21 21 -31 24 -58 18 -18 -3 -48 -19 -68 -35 -23 -18 -49
-29 -68 -29 -23 0 -35 -7 -48 -27 -9 -16 -29 -40 -45 -55 -15 -15 -28 -36 -28
-47 0 -32 -19 -35 -45 -7 -25 27 -45 31 -80 17 -14 -6 -28 -2 -44 10 -25 20
-57 24 -78 11 -21 -13 -83 -106 -83 -124 0 -9 -13 -26 -30 -38 -26 -18 -30
-28 -30 -66 0 -32 -4 -44 -14 -44 -8 0 -18 5 -21 10 -9 15 -58 12 -81 -5 -10
-8 -35 -15 -55 -15 -31 0 -42 -7 -78 -51 -41 -47 -43 -53 -36 -95 7 -47 7 -47
-46 -58 -17 -4 -31 -17 -39 -36 -7 -17 -24 -36 -39 -43 -24 -11 -26 -18 -26
-73 0 -56 -3 -64 -32 -91 -39 -37 -49 -71 -33 -117 8 -26 19 -38 38 -42 70
-15 80 -23 86 -72 3 -26 6 -59 6 -73 0 -29 13 -43 62 -68 19 -9 35 -26 38 -41
4 -17 16 -28 38 -35 23 -6 35 -17 39 -35 8 -34 32 -60 56 -60 11 0 46 18 79
40 33 22 71 40 84 40 22 0 23 -3 18 -44 -6 -38 -3 -45 17 -60 13 -9 35 -16 49
-16 32 0 50 -17 50 -45 0 -36 34 -59 76 -52 20 4 45 2 55 -3 27 -15 67 5 91
46 17 29 25 35 45 30 12 -3 34 -9 48 -12 19 -5 32 1 57 28 18 18 38 42 43 51
12 23 45 13 45 -13 0 -10 9 -28 19 -41 18 -22 22 -23 62 -12 41 11 46 10 70
-13 14 -13 37 -24 51 -24 15 0 30 -5 33 -10 10 -17 68 -11 102 9 23 15 35 32
45 67 11 40 18 48 48 58 30 9 37 18 50 59 17 55 41 67 88 43 17 -9 42 -16 57
-16 32 0 65 33 65 64 0 43 17 38 24 -7 14 -94 26 -344 26 -540 0 -162 3 -208
13 -205 14 5 23 101 30 348 6 192 23 300 88 565 47 191 49 199 101 340 56 153
54 149 81 130 33 -23 81 -19 115 10 17 14 32 25 35 25 2 0 7 -15 10 -34 10
-65 80 -143 143 -161 23 -6 67 23 84 55 15 28 23 163 12 202 -6 23 -6 41 1 53
10 18 11 105 2 135 -4 13 6 23 42 40 59 27 73 44 73 89 0 44 -3 49 -64 92 -42
30 -56 34 -115 35 -36 1 -80 8 -98 15 -36 15 -37 10 42 121 l41 57 39 -66 c46
-78 96 -108 181 -107 45 0 54 -3 70 -27 23 -34 54 -49 102 -49 56 0 84 19 100
68 8 25 25 49 40 59 76 47 92 94 51 147 -23 29 -20 30 35 15 76 -21 125 27 96
95 -19 46 -23 83 -11 90 6 4 11 20 11 36 0 34 -49 80 -86 80 -17 0 -24 6 -24
20 0 11 -7 38 -16 61 -14 34 -26 45 -73 67 -48 23 -57 32 -62 62 -11 52 -31
61 -133 59 -87 -2 -89 -1 -118 30 -33 35 -97 71 -127 71 -20 0 -55 -37 -83
-89 -10 -17 -31 -39 -48 -49 -65 -36 -65 -34 -62 -113 2 -69 5 -76 35 -104
l32 -30 -32 -33 c-27 -28 -34 -31 -39 -18 -9 23 -57 46 -97 46 -20 0 -50 -10
-71 -25 l-36 -24 0 69 c0 77 10 91 79 105 34 7 131 91 131 113 0 7 -7 15 -15
18 -9 4 -15 19 -15 41 0 60 -32 103 -77 103 -29 0 -125 -51 -130 -69 -2 -9
-14 -21 -26 -26 -32 -14 -34 -70 -4 -142 20 -46 25 -78 27 -158 4 -147 6 -142
-36 -98 l-36 38 -17 -22 c-13 -15 -25 -19 -44 -16 -111 21 -200 -87 -150 -184
11 -20 33 -43 49 -52 l31 -17 -23 -17 c-13 -10 -28 -34 -33 -54 -13 -47 15
-117 53 -135 31 -14 31 -14 6 -63 -12 -23 -20 -59 -20 -88 0 -42 5 -54 34 -83
19 -19 41 -34 50 -34 13 0 14 -5 6 -26 -28 -73 6 -155 70 -169 29 -6 29 -8 16
-33 -47 -93 -144 -411 -185 -607 -16 -77 -30 -124 -31 -105 0 19 -5 91 -10
160 -7 82 -6 128 0 134 14 14 12 48 -5 83 -15 30 -15 32 15 62 21 20 30 39 28
54 -1 13 7 32 20 45 36 36 33 96 -6 131 -21 19 -27 34 -27 65 0 37 -3 41 -27
44 -25 3 -26 5 -17 30 15 39 -16 76 -68 80 l-40 3 -73 172 c-40 95 -95 219
-123 277 -27 58 -53 121 -57 140 -5 19 -11 44 -15 55 -5 16 0 23 23 31 17 6
48 37 73 70 34 46 44 69 47 109 7 82 -11 128 -68 176 -36 30 -50 49 -50 68 -1
23 -22 91 -44 142 -6 15 3 17 77 20 98 5 121 18 138 83 29 104 28 103 75 121
27 10 50 27 56 41 l11 23 42 -24 c61 -36 111 -33 174 10 28 19 69 42 91 51 55
22 76 61 57 106 -8 18 -22 40 -31 47 -17 14 -16 17 15 42 31 25 33 31 43 125
l11 99 52 0 c41 0 57 5 75 23 21 21 23 32 23 126 0 117 -9 136 -77 163 -35 14
-38 18 -38 54 0 33 -5 41 -30 54 -33 17 -87 20 -110 5 -19 -12 -28 10 -29 71
-1 57 -18 73 -78 74 l-45 0 5 73 c6 76 -1 94 -42 107 -17 5 -17 9 -5 44 16 47
3 190 -20 226 -9 13 -45 35 -85 50 -69 27 -88 40 -123 84 -15 18 -28 21 -85
21 -39 -1 -79 -7 -94 -15z m179 -44 c24 -26 60 -49 105 -67 41 -16 75 -36 84
-51 21 -32 30 -171 13 -202 -16 -30 -85 -60 -157 -67 l-59 -6 1 26 c1 17 15
40 35 59 19 18 26 22 15 9 -32 -38 -5 -39 29 0 44 50 64 90 49 95 -8 3 -8 13
3 39 22 53 1 40 -39 -23 -56 -91 -109 -148 -137 -150 -23 -1 -25 3 -28 48 -4
68 -15 92 -72 158 -55 63 -59 81 -31 128 21 33 38 39 109 42 38 1 47 -4 80
-38z m-606 -28 c27 -31 48 -81 48 -115 0 -19 -21 -78 -50 -137 -48 -102 -53
-120 -30 -106 14 9 100 180 100 200 0 19 73 68 97 63 10 -2 27 -19 37 -39 11
-20 39 -49 62 -65 54 -37 63 -57 54 -119 -8 -54 -40 -89 -116 -125 -49 -24
-73 -24 -139 0 -50 17 -50 18 -20 24 55 13 118 41 123 55 3 10 0 12 -12 8 -49
-20 -44 -15 19 20 95 52 130 76 117 81 -6 2 -49 -17 -94 -42 -131 -71 -151
-75 -89 -20 28 26 50 49 47 51 -6 6 -126 -78 -126 -88 0 -4 6 -10 13 -12 7 -3
5 -6 -5 -6 -9 -1 -21 -9 -27 -18 -8 -15 -12 -16 -22 -5 -7 6 -21 12 -31 12
-18 0 -18 22 -3 123 6 36 -5 117 -16 117 -5 0 -9 -45 -9 -99 0 -55 -3 -102 -7
-104 -5 -2 -18 8 -30 23 -19 23 -20 29 -8 34 10 4 15 -1 15 -16 0 -16 3 -19
11 -11 12 12 -20 197 -45 258 -25 60 -29 35 -7 -51 11 -44 22 -97 25 -119 l5
-40 -19 40 c-11 22 -20 46 -20 53 0 6 -5 12 -11 12 -7 0 -8 -11 -4 -30 11 -52
-4 -33 -22 28 -13 44 -18 52 -21 32 -8 -47 41 -163 89 -211 10 -10 14 -19 9
-19 -6 0 -26 18 -46 40 -20 22 -52 49 -71 61 -63 39 -94 151 -55 199 11 14 51
32 112 50 113 33 151 36 172 13z m388 -80 c81 -86 110 -132 110 -172 0 -11 -5
-23 -12 -27 -6 -4 -8 -3 -5 3 9 14 -43 89 -102 147 -61 60 -82 53 -24 -7 66
-69 116 -139 103 -147 -6 -4 -30 -3 -53 1 -23 4 -45 8 -49 9 -5 0 -8 6 -8 14
0 8 -12 29 -27 48 l-28 33 30 -19 c17 -11 46 -30 65 -43 37 -25 50 -28 50 -14
0 9 -145 106 -177 118 -13 5 -15 2 -10 -13 6 -18 6 -18 -6 0 -7 10 -21 33 -33
50 -34 53 -3 106 62 106 28 0 44 -13 114 -87z m-757 59 c21 -23 37 -66 46
-124 9 -57 -2 -52 -46 18 -15 24 -31 44 -35 44 -16 0 -7 -28 24 -71 18 -24 25
-38 15 -30 -9 7 -34 41 -54 74 -43 71 -56 62 -18 -12 24 -49 25 -51 5 -51 -12
0 -26 12 -35 30 -20 39 -29 38 -21 -3 5 -29 1 -37 -40 -77 -25 -25 -49 -54
-53 -65 -7 -17 -12 -12 -42 33 -31 48 -49 64 -49 44 0 -4 13 -29 30 -55 l29
-46 -37 -11 c-20 -5 -49 -10 -64 -10 -26 0 -28 3 -28 39 0 30 9 50 38 88 21
26 46 54 55 61 16 14 247 140 258 141 3 1 13 -7 22 -17z m-520 -49 l38 -26 52
16 c46 15 54 15 75 1 42 -28 44 -54 5 -102 -35 -43 -60 -113 -47 -134 4 -7 2
-8 -4 -4 -7 4 -17 27 -23 52 -14 56 -30 58 -23 2 6 -41 4 -44 -25 -54 -24 -10
-33 -9 -41 2 -7 7 -9 18 -5 23 7 13 -26 111 -38 111 -8 0 -6 -15 8 -67 7 -24
-41 74 -50 101 -4 13 -8 16 -11 9 -8 -19 23 -104 57 -158 35 -55 31 -70 -9
-33 -42 39 -85 66 -118 74 -35 9 -44 20 -44 59 0 31 40 78 114 133 37 28 42
28 89 -5z m512 -142 c67 -20 123 -56 203 -133 48 -46 61 -75 27 -62 -8 4 -13
10 -10 15 3 4 -10 11 -29 15 -22 4 -38 13 -41 25 -8 25 -73 66 -143 90 -64 22
-62 22 -62 10 0 -4 20 -16 45 -25 25 -10 45 -22 45 -27 0 -15 -36 -10 -92 11
-59 22 -68 24 -68 11 0 -5 21 -17 47 -28 l47 -19 -47 -18 c-47 -18 -47 -18
-72 6 -14 12 -25 33 -25 44 0 23 80 104 102 104 7 0 40 -8 73 -19z m-649 -81
c23 -11 49 -29 59 -40 18 -20 17 -20 -41 -14 -32 3 -79 1 -104 -4 l-45 -10 48
-1 c26 -1 47 -5 47 -11 0 -9 -209 -80 -237 -80 -7 0 -13 -5 -13 -11 0 -11 11
-10 86 6 22 5 25 4 14 -4 -12 -9 -11 -11 7 -11 35 0 80 20 64 29 -9 6 -6 10
10 14 68 18 4 -37 -68 -59 -101 -31 -96 -47 7 -20 30 8 57 12 59 10 3 -2 -18
-25 -45 -50 -47 -44 -51 -46 -97 -41 -61 5 -91 33 -91 81 0 45 12 58 124 131
52 35 104 72 114 84 24 26 48 26 102 1z m361 -55 c140 0 153 -9 99 -64 -28
-29 -39 -50 -44 -85 l-7 -48 -30 51 c-16 28 -33 51 -37 51 -13 0 -10 -6 32
-86 22 -42 40 -86 40 -99 0 -13 7 -28 16 -33 12 -7 13 -11 5 -17 -7 -4 -14 -5
-15 -4 -2 2 -18 26 -36 54 -45 65 -179 205 -197 205 -8 0 17 -34 56 -76 39
-41 85 -94 103 -117 l32 -42 -32 29 c-45 42 -51 25 -8 -25 20 -24 36 -49 36
-56 0 -20 -83 27 -161 89 -33 27 -64 47 -67 44 -9 -10 48 -64 113 -108 33 -22
63 -48 67 -58 6 -16 4 -17 -39 -4 -26 8 -83 14 -128 14 -89 0 -103 7 -125 65
-27 71 -9 140 61 227 62 77 107 112 134 101 11 -4 70 -7 132 -8z m400 3 c5 -7
16 -29 23 -48 7 -19 26 -53 42 -74 33 -47 34 -61 8 -101 -12 -16 -32 -53 -45
-81 -18 -36 -35 -55 -61 -68 -36 -17 -37 -16 -60 5 -19 18 -31 21 -69 17 l-46
-6 40 34 c46 40 161 180 161 196 0 6 -50 -40 -111 -103 -61 -63 -83 -83 -50
-45 54 62 71 86 56 86 -2 0 -42 -38 -88 -85 -46 -47 -89 -85 -95 -85 -25 0 50
148 115 228 32 39 39 63 14 48 -20 -13 -105 -133 -127 -180 -22 -48 -23 -49
-30 -22 -10 43 2 150 20 178 17 26 103 77 173 103 48 17 118 19 130 3z m699
-20 c6 -4 22 -7 35 -6 18 2 25 -2 27 -20 2 -13 10 -26 18 -29 8 -3 14 -12 14
-21 0 -9 10 -41 22 -70 l23 -54 -28 -24 c-16 -13 -34 -24 -40 -24 -7 0 -25 25
-40 55 l-27 56 26 50 c38 76 25 96 -15 22 -28 -53 -37 -62 -65 -65 l-33 -4 54
49 c29 27 53 53 53 58 0 16 -54 -3 -91 -32 -35 -27 -37 -28 -53 -10 -15 17
-15 19 4 36 11 10 20 24 20 31 0 22 69 24 96 2z m398 2 c25 -9 25 -12 22 -81
-3 -66 -6 -73 -38 -106 l-35 -35 -57 13 c-52 11 -59 11 -84 -8 -15 -11 -36
-34 -48 -50 -18 -27 -23 -29 -42 -19 l-22 12 30 22 c37 28 40 73 10 132 -29
57 -25 58 26 7 25 -26 53 -47 61 -47 23 0 -77 107 -102 110 -19 1 -19 2 2 10
18 7 41 0 105 -30 95 -45 138 -58 138 -41 0 6 -6 11 -12 11 -7 0 -49 18 -93
39 l-80 39 45 2 c25 0 61 7 80 14 44 17 63 18 94 6z m-514 -139 c0 -10 -21
-35 -46 -56 -25 -21 -56 -54 -69 -75 -28 -45 -68 -59 -96 -34 -11 9 -19 28
-19 41 0 32 -24 76 -68 125 -30 33 -32 38 -11 25 40 -26 190 -78 213 -75 14 3
2 11 -39 26 -102 38 -102 38 -48 38 36 0 62 7 94 27 l44 26 23 -25 c12 -14 22
-33 22 -43z m-440 44 c0 -8 4 -15 9 -15 5 0 0 -32 -10 -72 -21 -79 -25 -134
-9 -108 5 8 17 48 27 88 21 92 37 94 82 7 18 -36 37 -65 42 -65 13 0 11 6 -11
51 -12 22 -25 52 -31 67 l-9 27 21 -25 c45 -54 77 -111 72 -129 -3 -11 -18
-31 -33 -46 l-29 -28 -43 17 c-30 13 -55 16 -86 11 -74 -11 -90 18 -46 85 22
33 26 48 22 84 -5 41 4 66 24 66 4 0 8 -7 8 -15z m572 -127 c25 -51 36 -62 87
-88 66 -33 75 -51 73 -142 -2 -121 -91 -165 -299 -152 -83 6 -101 9 -92 20 6
7 8 17 5 22 -3 5 11 24 30 43 49 47 156 190 148 198 -3 3 -22 -14 -43 -38 -68
-84 -85 -100 -53 -51 43 66 74 130 69 143 -2 7 -13 -3 -23 -21 -11 -19 -40
-66 -66 -105 -35 -55 -43 -73 -32 -77 11 -4 5 -14 -21 -40 -19 -18 -38 -30
-42 -26 -8 8 0 22 58 115 29 47 34 63 36 127 2 69 4 75 35 104 25 23 41 29 67
27 32 -2 38 -7 63 -59z m502 42 c9 0 15 -20 19 -54 3 -30 13 -79 23 -109 20
-64 12 -97 -34 -139 -25 -23 -101 -52 -110 -43 -3 2 18 38 46 80 29 41 48 75
43 75 -13 0 -68 -64 -87 -101 -20 -41 -25 -34 -17 28 4 34 10 50 17 47 12 -5
69 66 61 75 -3 2 -17 -6 -32 -20 l-28 -24 20 25 c11 14 30 37 44 52 41 46 13
46 -30 1 l-39 -42 0 34 c0 24 -7 39 -22 49 l-22 16 21 21 c21 22 78 40 101 33
8 -2 19 -4 26 -4z m-1978 -26 c-3 -9 -6 -24 -6 -35 0 -16 -8 -19 -42 -20 -81
-2 -99 -17 -22 -18 62 -1 72 -4 77 -20 6 -25 0 -31 -32 -32 -14 0 -48 -7 -76
-14 l-50 -14 90 5 c88 4 89 4 60 -12 -16 -9 -37 -23 -47 -31 -14 -13 -22 -12
-62 1 -26 9 -62 16 -81 16 -41 0 -58 24 -53 78 3 36 5 37 56 48 30 7 64 21 75
32 21 20 47 28 96 31 17 1 22 -3 17 -15z m1833 -61 c21 -13 23 -20 17 -62 -4
-25 -10 -103 -14 -172 -7 -133 -11 -143 -64 -180 -19 -14 -37 -19 -53 -15 -22
6 -25 12 -25 48 0 25 -7 49 -17 60 -16 17 -15 20 14 51 49 52 86 107 79 115
-4 4 -17 -4 -28 -18 -41 -51 -90 -102 -93 -98 -10 10 40 88 95 152 33 38 58
71 56 74 -9 9 -62 -37 -96 -83 -26 -34 -36 -42 -38 -30 -2 10 12 34 32 54 36
36 48 65 24 56 -7 -3 -22 -19 -33 -35 -23 -34 -35 -38 -35 -12 0 10 -6 27 -14
37 -12 17 -12 23 0 47 8 15 26 34 41 42 23 12 34 12 78 -1 27 -8 61 -22 74
-30z m-589 -155 c-22 -33 -40 -66 -40 -74 0 -7 -4 -16 -10 -19 -6 -3 -7 1 -4
10 8 21 -16 145 -28 145 -6 0 -8 -12 -5 -27 15 -75 19 -153 8 -153 -7 0 -11
23 -11 63 0 64 -13 94 -54 128 -27 22 -12 44 17 24 12 -8 36 -15 54 -15 33 0
33 0 33 -51 0 -33 4 -48 11 -43 6 3 9 26 7 53 -2 37 3 55 27 94 l30 47 3 -60
c3 -57 0 -65 -38 -122z m-1643 48 c58 -28 76 -42 89 -70 16 -33 16 -37 -1 -76
-10 -22 -21 -40 -25 -40 -4 0 -13 20 -20 45 -7 25 -17 45 -22 45 -5 0 -7 -8
-4 -17 18 -65 27 -106 22 -111 -3 -3 -6 1 -6 9 0 19 -49 123 -55 117 -3 -3 4
-31 15 -63 11 -32 18 -60 16 -62 -2 -2 -9 11 -15 29 -6 18 -28 60 -48 93 -43
72 -42 70 -48 63 -3 -3 19 -56 50 -119 31 -63 58 -132 62 -153 l6 -40 -32 23
c-41 30 -85 89 -121 163 -31 64 -46 68 -24 6 20 -58 51 -106 99 -158 37 -40
68 -99 44 -84 -5 3 -9 11 -9 19 0 7 -10 15 -23 19 -71 17 -186 187 -172 252 9
36 46 102 81 144 25 29 28 30 48 16 11 -9 54 -31 93 -50z m2551 48 c17 -11 21
-21 16 -40 -8 -31 9 -51 58 -71 21 -8 43 -24 48 -34 6 -10 10 -57 10 -105 0
-104 -12 -124 -72 -124 -36 0 -38 2 -38 30 0 24 6 33 33 45 l32 15 -47 -6
c-35 -4 -54 -1 -74 12 l-26 18 88 45 c52 26 82 46 72 48 -9 3 -38 -7 -65 -20
-106 -54 -120 -58 -144 -38 l-22 18 67 49 c36 27 62 50 56 52 -13 5 -78 -34
-116 -70 -20 -19 -34 -26 -38 -19 -4 6 -2 11 3 11 6 0 23 18 37 40 22 33 26
48 22 89 -4 43 -2 51 16 59 31 15 59 14 84 -4z m-1253 -16 c153 -15 186 -23
206 -49 20 -26 33 -75 27 -107 -3 -19 -8 -16 -63 25 -66 50 -169 103 -200 103
-11 0 17 -20 62 -44 45 -23 102 -60 128 -80 25 -21 53 -39 62 -40 22 -3 30
-26 9 -26 -22 0 -27 -13 -16 -49 8 -28 6 -33 -20 -46 -36 -19 -40 -19 -40 0 0
9 7 18 15 21 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 6 -15 14 0 8 -2 21 -5
29 -5 10 1 13 25 9 47 -7 35 14 -17 29 -26 7 -63 27 -83 45 -47 43 -76 53
-131 47 -36 -4 -47 -1 -63 16 -13 14 -31 21 -55 21 l-36 0 40 19 c22 10 48 28
59 39 10 12 24 18 30 14 6 -4 43 -10 81 -14z m-750 -49 c33 0 76 -3 95 -8 l35
-8 -45 -7 c-72 -13 -200 -56 -200 -68 0 -6 8 -7 18 -3 39 15 206 55 230 55 26
0 26 0 -8 -25 -40 -29 -41 -30 -34 -10 8 19 -5 19 -42 1 -16 -8 -42 -20 -59
-27 -45 -17 -14 -22 36 -5 24 8 45 13 47 11 7 -7 -94 -53 -168 -76 -50 -16
-67 -25 -52 -27 49 -8 188 47 281 111 44 30 44 30 25 4 -10 -14 -34 -32 -51
-41 -18 -9 -33 -22 -33 -30 0 -8 -16 -28 -36 -46 -43 -38 -38 -50 9 -21 l34
21 23 -25 c22 -23 22 -25 4 -25 -10 0 -34 -9 -52 -20 l-33 -20 -40 20 c-31 16
-50 19 -88 14 -44 -6 -51 -4 -83 25 -20 17 -38 44 -41 60 -9 44 19 116 60 152
31 27 40 30 72 24 20 -4 63 -7 96 -6z m617 -54 c20 -23 26 -25 85 -19 l62 7
61 -63 60 -64 0 -102 0 -103 -32 -24 c-18 -13 -51 -31 -73 -40 -50 -22 -177
-58 -183 -53 -2 3 6 13 18 23 66 55 167 220 176 289 5 34 0 28 -34 -43 -63
-129 -138 -228 -165 -217 -15 5 -15 9 -2 34 20 39 78 241 71 248 -9 9 -12 2
-41 -92 -33 -109 -70 -196 -84 -196 -14 0 -14 1 3 42 37 87 73 358 48 358 -6
0 -12 -16 -14 -36 l-3 -35 -38 46 -38 45 23 9 c42 17 77 12 100 -14z m-124
-38 c18 -17 43 -47 54 -66 20 -31 21 -39 10 -100 -6 -36 -17 -86 -25 -111
l-13 -45 -21 92 c-22 93 -50 170 -58 161 -2 -2 9 -57 25 -122 33 -131 38 -186
16 -186 -8 0 -17 12 -21 28 -9 37 -98 188 -103 174 -2 -6 9 -34 25 -64 64
-124 81 -173 24 -74 -17 30 -48 72 -69 93 -22 21 -51 59 -66 85 -15 26 -43 61
-62 78 l-35 30 46 0 c32 0 63 10 112 36 80 42 110 40 161 -9z m-804 -34 c72
-65 79 -81 81 -191 l0 -94 42 -36 c62 -55 66 -70 41 -131 -15 -34 -28 -51 -40
-51 -9 0 -43 21 -75 47 -32 26 -78 57 -103 69 -44 21 -88 60 -79 69 3 2 29 -5
59 -16 50 -19 180 -38 180 -26 0 3 -35 13 -77 22 -86 19 -163 53 -163 71 0 15
65 58 136 89 32 14 55 27 53 30 -7 6 -90 -20 -130 -40 -30 -16 -28 -12 24 46
31 35 54 65 51 68 -7 8 -133 -121 -141 -144 -6 -20 -6 -20 -14 0 -21 54 0 166
42 224 16 23 36 41 44 41 8 0 39 -21 69 -47z m2211 -73 c104 -66 110 -72 95
-100 -6 -12 -15 -65 -19 -117 -6 -90 -8 -97 -36 -119 -17 -13 -35 -24 -40 -24
-6 0 -25 35 -43 77 -33 81 -31 93 15 93 26 0 78 19 71 25 -3 3 -34 1 -69 -4
-61 -8 -66 -7 -106 19 l-43 27 69 7 c133 13 131 26 -3 19 l-130 -6 22 28 c12
15 25 45 28 65 4 25 14 42 28 48 44 22 80 13 161 -38z m-390 -21 c19 -20 19
-22 0 -97 -19 -71 -23 -78 -60 -99 -55 -31 -75 -30 -75 5 0 47 -20 62 -109 83
-83 19 -133 38 -101 38 8 0 44 -6 80 -14 61 -13 209 -21 198 -10 -3 3 -43 10
-89 16 -99 12 -263 49 -255 57 3 3 70 6 149 6 114 1 148 5 172 18 40 23 68 22
90 -3z m-2277 -39 c51 -23 87 -61 69 -72 -7 -4 -25 -8 -41 -8 -48 0 -156 -23
-156 -33 0 -5 10 -7 23 -4 12 3 56 8 97 11 41 3 64 2 50 0 -39 -9 -48 -24 -15
-24 24 0 16 -7 -45 -36 -86 -42 -83 -57 4 -19 65 29 167 46 205 34 32 -9 21
-11 -45 -9 -43 1 -67 -6 -143 -44 -98 -50 -126 -80 -37 -41 l54 24 -16 -40
c-15 -38 -14 -43 6 -90 l21 -50 -26 -12 c-21 -9 -33 -7 -80 18 -70 36 -78 56
-87 203 -7 105 -6 115 15 149 19 31 66 62 94 62 6 0 29 -8 53 -19z m1030 -36
c54 -27 86 -62 141 -154 41 -68 40 -67 50 -51 7 10 10 9 14 -3 9 -24 -35 -21
-107 8 -97 39 -266 69 -251 46 3 -5 16 -10 29 -10 35 0 170 -38 230 -64 46
-20 54 -28 49 -45 -4 -17 -1 -21 18 -21 52 -1 -107 -116 -195 -141 -35 -10
-138 11 -196 41 -50 25 -65 49 -86 130 -20 78 -11 123 40 197 64 94 161 118
264 67z m800 -41 c24 -11 76 -19 149 -22 67 -4 117 -11 124 -18 12 -12 15 -80
4 -91 -3 -3 -30 7 -61 23 -38 20 -52 31 -45 38 6 6 11 15 11 21 0 5 -17 -6
-37 -25 -32 -30 -34 -31 -15 -5 12 16 22 33 22 39 0 15 -50 -33 -66 -65 -13
-25 -16 -26 -49 -15 -28 8 -34 14 -29 29 7 24 -8 23 -24 -2 -7 -11 -19 -20
-26 -20 -8 0 -24 -12 -35 -26 -27 -35 -26 -27 4 42 28 63 29 68 15 59 -6 -4
-21 -27 -35 -53 -13 -26 -24 -42 -24 -37 -1 6 13 37 29 69 17 33 28 62 24 65
-9 10 -43 -41 -60 -92 -15 -44 -17 -46 -29 -30 -33 44 30 130 98 132 10 1 34
-7 55 -16z m660 -18 c30 -16 61 -40 71 -54 22 -32 61 -125 61 -144 0 -8 -25
-38 -56 -66 -42 -38 -63 -51 -86 -51 -44 0 -114 44 -126 78 l-10 29 57 -23
c79 -31 101 -37 101 -26 0 5 -21 17 -47 27 -27 9 -82 35 -123 57 -54 29 -93
42 -140 47 l-65 7 46 8 c38 6 54 4 89 -14 68 -35 169 -70 174 -62 3 5 -35 25
-84 46 -93 39 -130 66 -92 66 11 0 77 -16 148 -35 138 -38 144 -39 144 -27 0
5 -61 26 -135 47 -74 21 -135 41 -135 43 0 3 5 21 11 41 l11 36 66 0 c55 0 77
-6 120 -30z m-2311 -91 l22 -6 -28 -42 c-35 -51 -64 -106 -58 -112 2 -3 28 28
58 68 38 52 54 68 57 55 2 -9 8 -15 13 -12 5 4 10 -29 10 -77 1 -72 3 -78 10
-43 5 22 11 62 15 90 9 71 21 40 42 -100 9 -63 20 -115 24 -115 13 0 2 137
-18 215 -5 22 -5 22 50 -4 84 -41 96 -55 96 -116 0 -49 -3 -54 -55 -108 -62
-64 -89 -72 -100 -29 -10 40 -30 58 -97 83 -33 12 -62 28 -65 35 -2 6 10 40
27 74 17 34 29 64 26 67 -6 6 -63 -81 -72 -109 -4 -13 -15 -18 -43 -18 -33 0
-39 4 -54 35 -21 44 -21 68 -2 105 30 58 80 80 142 64z m1494 -71 l13 -56 -32
-28 c-38 -35 -48 -36 -62 -8 -7 11 -34 31 -62 44 l-51 24 72 7 c39 3 74 11 78
17 4 7 -20 8 -73 4 l-79 -7 55 23 c30 13 64 28 74 34 35 20 54 5 67 -54z m374
42 c31 -18 64 -26 125 -31 124 -11 161 -49 175 -186 9 -87 2 -114 -36 -134
-17 -8 -33 -13 -35 -11 -3 2 3 32 12 65 19 67 15 97 -16 130 l-21 23 23 20
c21 19 21 20 3 16 -11 -3 -29 -8 -40 -12 -14 -5 -11 1 10 19 36 31 15 35 -31
7 -26 -16 -39 -17 -90 -9 -32 5 -70 6 -84 3 -24 -6 -24 -6 -6 14 11 12 16 24
12 29 -4 4 -23 -10 -43 -31 -47 -52 -52 -41 -8 18 20 26 34 50 30 53 -10 10
-62 -50 -89 -103 -14 -27 -26 -44 -26 -37 0 7 7 27 16 44 11 21 14 43 10 69
-5 32 -2 40 16 53 30 22 44 20 93 -9z m-190 -3 c50 -17 74 -52 55 -82 -9 -13
-12 -13 -21 3 -5 9 -15 17 -22 17 -7 0 -30 9 -51 21 -32 17 -38 18 -33 5 4 -9
1 -16 -5 -16 -7 0 -7 -4 1 -14 8 -10 9 -16 1 -21 -5 -3 -15 -25 -21 -49 -10
-39 -13 -43 -37 -38 -26 4 -27 4 -7 -12 19 -15 19 -16 -8 -6 -34 12 -85 13
-71 2 5 -5 28 -14 52 -21 47 -13 49 -17 26 -49 -12 -17 -25 -22 -62 -22 -71 0
-91 -19 -22 -21 l55 -1 -45 -13 c-46 -13 -46 -18 1 -15 25 1 26 1 15 -45 -10
-42 -13 -45 -36 -40 -46 12 -60 33 -60 95 0 87 14 116 76 155 29 19 67 54 83
77 71 102 80 108 136 90z m-391 -92 c36 -12 77 -31 92 -43 l26 -23 -22 -43
c-17 -32 -23 -62 -25 -126 -4 -139 -56 -191 -165 -165 -27 6 -62 9 -77 6 -26
-6 -28 -4 -29 26 -1 60 -21 159 -33 171 -7 7 -35 17 -63 22 -28 6 -53 15 -55
20 -1 6 13 20 31 32 31 20 35 21 38 5 3 -12 10 -6 25 23 l21 39 12 -72 c22
-130 36 -85 16 52 -3 21 22 14 28 -8 3 -13 9 -32 13 -42 4 -12 3 -15 -5 -10
-19 11 -15 -27 10 -91 26 -69 36 -56 14 18 -20 67 -14 74 9 12 10 -27 34 -75
53 -108 41 -71 41 -70 47 -63 3 3 -17 52 -44 109 -60 125 -76 179 -27 91 39
-72 86 -136 93 -128 3 3 -11 32 -31 64 -70 115 -98 202 -67 202 10 0 125 -120
138 -145 7 -11 15 -16 19 -12 10 10 -63 114 -103 144 -33 26 -45 63 -19 63 8
0 44 -9 80 -20z m414 -44 c3 -38 -3 -42 -53 -36 -20 2 -20 2 -1 -19 17 -19 17
-21 2 -21 -15 0 -15 -2 -1 -23 14 -22 13 -25 -15 -45 -29 -22 -72 -87 -85
-129 -11 -38 11 -26 30 16 10 22 28 50 41 62 l23 22 -20 -53 c-21 -55 -22
-108 -1 -60 6 15 11 19 11 9 1 -8 12 -38 26 -65 14 -27 25 -50 25 -51 0 -1
-18 -8 -40 -14 -37 -11 -42 -10 -66 12 -14 13 -37 46 -51 73 -31 61 -27 95 21
167 19 30 42 83 51 119 19 78 46 108 79 87 14 -9 22 -27 24 -51z m820 -16 c33
-50 26 -70 -39 -105 -30 -16 -77 -43 -103 -59 -43 -26 -51 -28 -86 -17 -43 13
-100 61 -100 85 0 13 9 9 42 -14 46 -33 61 -32 32 2 -19 23 -19 23 1 7 29 -24
86 -51 93 -45 6 7 9 4 -50 45 -50 33 -60 46 -30 38 9 -3 35 -8 56 -12 35 -6
38 -5 27 9 -20 23 9 31 64 17 79 -20 89 -6 12 19 l-39 12 28 29 c36 37 61 34
92 -11z m-484 1 c20 -13 43 -36 51 -51 13 -26 13 -35 -5 -92 -10 -35 -26 -94
-35 -133 -29 -127 -47 -138 -201 -128 -117 7 -127 12 -165 85 -21 42 -24 90
-8 136 14 40 22 35 55 -31 29 -59 70 -114 79 -105 3 3 -13 35 -35 71 -30 48
-41 74 -38 97 l3 32 50 -63 c54 -67 141 -146 151 -136 3 3 -25 37 -64 75 -38
38 -74 77 -79 88 -16 30 2 81 31 86 25 5 109 -4 160 -17 20 -6 27 -5 22 3 -11
17 -81 33 -155 35 -90 2 -104 18 -49 52 39 23 51 26 119 22 57 -3 85 -9 113
-26z m-1573 -32 c62 -5 97 -12 107 -23 15 -15 9 -16 -63 -16 -77 0 -188 -20
-203 -35 -4 -4 16 -4 43 0 61 9 271 21 285 17 20 -7 9 -20 -20 -26 -40 -7
-198 -75 -214 -91 -10 -10 -23 -2 -64 37 -51 49 -52 50 -34 73 18 25 51 35
113 35 22 0 39 5 39 11 0 8 -20 9 -65 4 -61 -6 -65 -5 -65 13 0 11 -3 28 -7
38 -5 14 -1 13 25 -6 24 -18 50 -25 123 -31z m-451 -52 c51 -26 89 -51 96 -65
15 -30 2 -99 -32 -171 l-27 -56 -13 74 c-13 74 -75 221 -93 221 -5 0 6 -35 24
-78 38 -91 60 -181 46 -195 -6 -6 -14 -1 -21 13 -10 18 -9 24 1 30 10 7 9 17
-4 52 -21 53 -34 74 -42 67 -3 -3 2 -22 10 -42 25 -61 17 -55 -22 13 -42 76
-41 75 -48 68 -3 -3 18 -54 46 -113 48 -101 50 -110 34 -121 -21 -16 -40 -8
-102 42 -59 48 -65 42 -13 -12 40 -40 42 -44 17 -35 -54 21 -84 40 -102 67
-15 23 -16 34 -6 82 6 30 11 73 11 94 0 26 6 44 20 55 23 20 98 52 120 52 8 0
53 -19 100 -42z m964 -54 c25 -3 50 -11 55 -17 9 -11 31 -127 31 -163 0 -14
-10 -24 -30 -31 -16 -6 -53 -32 -81 -59 l-52 -48 7 40 c3 22 8 76 11 120 5 89
0 74 -30 -92 -14 -71 -21 -93 -34 -94 -9 0 -29 -2 -45 -3 -24 -1 -33 5 -57 44
-16 25 -41 73 -56 108 -15 34 -30 62 -34 62 -13 0 5 -54 45 -133 l41 -82 -50
23 c-27 13 -61 29 -75 35 -31 16 -79 92 -87 140 -5 33 -1 40 48 92 64 68 97
87 137 79 29 -6 29 -7 35 -92 9 -118 47 -262 69 -262 6 0 7 10 4 23 -29 99
-48 183 -54 245 l-8 72 35 0 c20 1 50 7 66 14 25 11 33 11 47 -2 9 -8 38 -17
62 -19z m-648 -60 c29 -27 67 -59 84 -73 27 -22 32 -33 37 -85 4 -52 2 -63
-15 -78 -20 -18 -20 -17 -27 42 -4 34 -11 64 -17 67 -6 4 -7 -20 -2 -65 5 -58
3 -79 -11 -111 -10 -22 -21 -37 -26 -34 -4 2 -11 36 -15 74 -3 39 -11 70 -15
70 -11 0 -11 -6 1 -108 5 -49 20 -107 35 -140 l25 -57 -30 35 c-40 45 -115
192 -131 253 -11 46 -29 73 -29 44 0 -30 43 -154 71 -207 l30 -55 -36 40
c-143 162 -153 246 -46 393 17 23 39 42 48 42 9 0 40 -21 69 -47z m-492 -207
c21 -9 49 -16 61 -16 12 0 27 -6 33 -13 9 -10 -7 -15 -82 -25 -116 -14 -174
-35 -76 -27 36 3 89 8 119 12 64 7 64 -11 0 -21 -45 -7 -102 -34 -166 -78
l-42 -29 -21 21 c-11 11 -20 32 -20 48 0 15 -3 43 -6 62 -5 33 -2 38 52 83
l57 48 26 -25 c14 -13 43 -31 65 -40z m1392 20 c9 -13 11 -40 7 -101 -5 -76
-4 -85 21 -127 32 -54 33 -97 5 -155 -25 -51 -46 -67 -120 -89 -45 -14 -62
-14 -109 -4 -30 6 -66 19 -78 27 -27 17 -57 78 -68 138 -7 39 -6 39 12 23 30
-26 144 -76 193 -83 54 -9 48 10 -6 20 -45 8 -164 69 -188 95 -30 33 -7 39 56
13 33 -13 77 -27 97 -29 l37 -4 -35 16 c-19 8 -61 27 -92 41 -38 17 -55 30
-51 39 3 8 -3 17 -13 21 -32 12 19 21 131 23 84 1 94 3 66 12 -19 5 -71 8
-117 6 l-82 -3 46 50 c79 85 102 96 206 92 52 -2 71 -7 82 -21z m544 -178 c0
-25 -9 -67 -19 -91 -29 -68 -75 -85 -125 -46 -33 26 -36 77 -6 104 11 10 20
25 21 34 0 9 11 -8 24 -36 53 -118 91 -18 59 157 -2 14 7 4 21 -25 16 -32 25
-67 25 -97z m-1174 107 c39 -9 88 -27 110 -40 l39 -24 -46 -1 c-53 0 -185 -32
-226 -54 -39 -21 -16 -20 70 4 84 24 207 37 207 23 0 -5 -20 -19 -45 -31 -36
-17 -43 -18 -38 -5 5 12 1 14 -18 8 -35 -11 -77 -33 -84 -45 -9 -14 16 -12 45
5 58 33 38 11 -28 -31 -80 -51 -100 -83 -24 -38 72 43 186 103 196 104 10 0
-89 -73 -159 -117 -57 -36 -115 -93 -96 -93 7 0 32 16 55 35 24 19 45 35 47
35 9 0 3 -146 -6 -152 -44 -32 -123 23 -154 108 -12 30 -34 80 -50 112 l-30
59 23 49 c23 48 66 96 96 107 16 6 14 6 116 -18z m-424 -62 c23 -34 58 -80 77
-103 l35 -42 -34 17 c-37 19 -137 55 -154 55 -21 0 11 -19 87 -50 42 -18 82
-41 88 -51 9 -17 7 -19 -13 -14 -82 18 -115 25 -133 24 -34 0 2 -14 83 -34 40
-9 75 -21 78 -26 3 -5 -7 -4 -21 1 -15 6 -57 10 -94 10 -60 0 -70 3 -93 28
-26 27 -48 87 -48 132 0 16 16 41 47 72 26 26 48 47 49 45 1 -1 22 -30 46 -64z
m2516 54 c11 -13 16 -46 14 -84 -2 -15 -12 -25 -36 -33 -19 -6 -47 -21 -62
-32 l-27 -22 7 36 c4 20 2 55 -3 77 -8 39 -7 42 18 56 33 18 75 19 89 2z m359
-44 l34 -35 -21 -23 c-11 -12 -25 -35 -29 -51 -8 -28 -8 -28 -23 21 -13 41
-16 45 -17 21 -1 -15 6 -55 15 -88 8 -33 13 -62 11 -64 -2 -3 -17 31 -33 73
-15 43 -30 70 -32 60 -5 -26 45 -170 75 -214 19 -28 21 -34 7 -23 -50 38 -64
54 -53 61 14 8 -39 61 -109 111 -56 39 -70 26 -17 -15 22 -18 53 -45 70 -61
24 -23 20 -21 -22 9 -74 52 -90 38 -18 -16 l59 -44 -54 -3 c-82 -5 -104 16
-108 101 l-3 66 41 21 c26 13 51 38 70 68 47 77 54 83 91 70 17 -6 47 -26 66
-45z m-3087 -1 c0 -5 -12 -15 -26 -23 -34 -18 -105 -97 -139 -155 -42 -71 -22
-63 34 14 44 60 84 100 128 126 7 3 1 -19 -13 -51 -32 -72 -69 -251 -47 -230
3 4 16 46 28 94 21 83 81 231 89 222 9 -9 -19 -109 -31 -109 -14 0 -36 -72
-30 -98 2 -10 11 4 20 33 l16 50 -6 -54 c-8 -68 12 -63 22 6 l7 48 14 -35 c9
-19 25 -45 37 -58 20 -22 20 -24 4 -51 -9 -16 -17 -39 -17 -51 0 -30 -51 -75
-107 -94 -46 -16 -47 -16 -85 10 -31 22 -45 25 -77 20 -48 -7 -96 22 -125 76
-18 32 -19 41 -8 90 8 32 25 69 43 91 35 42 138 103 207 122 59 17 62 17 62 7z
m1378 -27 c17 -14 36 -25 44 -25 8 0 19 -5 25 -11 6 -6 26 -14 44 -18 27 -5
35 -13 42 -41 10 -41 2 -94 -19 -115 -21 -21 -73 -19 -111 5 -30 18 -33 24
-33 72 -1 29 -9 77 -19 106 -10 28 -14 52 -10 52 4 0 21 -11 37 -25z m1942
-25 c11 -6 24 -28 30 -49 9 -33 18 -42 65 -64 73 -35 89 -70 71 -153 -6 -25
-18 -36 -72 -62 -98 -49 -159 -42 -79 9 54 34 61 60 8 29 l-38 -22 30 25 c17
14 27 29 22 33 -4 4 -13 2 -19 -4 -7 -7 -16 -12 -21 -12 -5 0 12 23 37 51 69
76 46 73 -32 -4 -73 -72 -98 -81 -141 -48 -23 17 -24 23 -18 97 3 44 8 87 12
97 4 9 3 17 -3 17 -17 0 -35 -102 -30 -168 5 -75 -14 -75 -37 1 -35 118 -12
206 58 226 44 13 134 13 157 1z m-2460 -114 c-15 -43 -25 -231 -13 -239 6 -4
13 30 17 91 3 53 10 110 14 126 7 27 10 23 35 -55 17 -55 27 -112 29 -166 2
-45 8 -87 14 -93 7 -7 9 14 6 75 -4 71 -2 84 10 80 9 -4 13 -16 10 -38 -2 -18
1 -43 7 -57 8 -22 10 -18 10 29 1 46 3 52 19 46 9 -4 43 -9 75 -12 63 -6 65
-9 46 -76 -12 -45 -45 -68 -123 -87 -69 -16 -100 -8 -116 30 -17 42 -63 78
-108 85 -68 12 -88 24 -110 71 -25 52 -27 82 -8 127 12 31 89 107 107 107 4 0
-3 -24 -16 -52 -26 -58 -44 -140 -27 -123 5 6 15 28 22 50 14 43 56 132 73
153 18 23 40 -35 27 -72z m774 -108 l37 -68 -26 0 c-35 0 -95 -26 -125 -55
-32 -31 -51 -20 -56 31 -2 22 -6 51 -10 66 -5 26 -4 28 29 28 43 0 67 26 74
80 l6 41 16 -28 c10 -15 34 -58 55 -95z m-1344 15 c0 -2 -24 -15 -52 -28 -55
-25 -39 -35 21 -14 55 19 44 4 -21 -32 -42 -22 -57 -35 -45 -37 9 -2 38 8 62
22 50 27 49 24 -7 -48 l-39 -49 -49 54 c-28 30 -50 59 -50 64 0 6 9 24 20 40
17 26 27 31 72 35 52 5 88 2 88 -7z m175 -30 c19 -46 58 -88 95 -103 25 -11
28 -16 34 -88 3 -42 9 -92 12 -111 6 -31 5 -32 -14 -22 -11 6 -33 11 -49 11
-43 0 -76 27 -70 58 7 37 -9 72 -49 110 -31 28 -35 36 -29 65 8 37 41 117 49
117 3 0 13 -17 21 -37z m1446 -111 c9 -2 22 12 33 35 l18 38 13 -30 c11 -27
10 -34 -9 -69 -13 -21 -35 -46 -50 -55 -26 -15 -28 -15 -42 4 -8 11 -34 41
-58 66 l-44 46 24 30 c16 21 30 30 43 26 10 -3 22 1 27 9 6 8 12 -6 19 -42 7
-35 16 -56 26 -58z m-1580 -34 c-17 -62 -33 -124 -37 -138 -5 -19 -4 -22 5
-14 7 6 24 49 39 95 l27 85 23 -28 c26 -31 26 -20 -10 -190 l-21 -97 -29 24
c-15 13 -46 34 -67 46 -64 35 -80 103 -40 169 38 62 133 182 137 172 2 -6 -10
-62 -27 -124z m2698 103 l54 -28 -61 -7 c-88 -10 -91 -28 -3 -20 58 6 71 4 71
-7 0 -9 -18 -20 -42 -27 -48 -13 -143 -70 -134 -79 3 -4 27 5 53 19 53 28 152
63 159 55 6 -5 -73 -105 -129 -164 -23 -24 -38 -46 -34 -49 7 -8 95 78 143
139 22 29 34 37 48 31 16 -6 18 -14 13 -54 -3 -26 -20 -78 -37 -116 -17 -37
-29 -73 -27 -78 7 -20 77 133 85 186 l9 53 8 -38 c11 -57 1 -187 -18 -224 -27
-51 -46 -56 -112 -30 -53 20 -62 28 -100 91 -22 37 -50 73 -61 78 -40 22 -72
59 -67 79 3 10 1 25 -4 32 -37 47 -14 131 46 166 48 27 75 26 140 -8z m556
-11 c22 -23 26 -33 17 -42 -17 -17 -15 -82 5 -122 13 -29 14 -38 3 -51 -21
-25 -58 -19 -75 14 -14 28 -50 61 -67 61 -4 0 -28 -18 -53 -40 -25 -22 -53
-40 -61 -40 -8 0 -31 16 -52 34 l-37 35 52 -15 c29 -9 61 -14 70 -11 21 5 17
6 -57 28 l-45 13 80 7 c83 7 171 26 162 36 -3 3 -45 0 -92 -7 -82 -12 -175 -7
-175 9 0 4 27 5 59 3 81 -6 241 41 218 64 -2 2 -15 0 -29 -5 l-25 -9 16 34
c20 42 48 44 86 4z m-852 -2 c26 -13 48 -42 64 -83 19 -50 15 -69 -17 -88
l-30 -18 0 40 c0 48 -8 71 -25 71 -19 0 -48 -67 -53 -123 -4 -44 -6 -46 -18
-28 -8 11 -30 29 -49 41 -39 24 -43 42 -20 98 34 80 96 118 148 90z m-1377
-64 c9 3 14 -1 14 -12 0 -9 6 -29 14 -44 13 -24 12 -29 -14 -59 -22 -27 -34
-33 -64 -32 -63 2 -127 79 -125 150 0 27 1 27 13 -7 7 -19 20 -36 29 -38 18
-4 77 71 77 97 1 9 10 -1 21 -22 13 -25 25 -37 35 -33z m448 -25 c29 -23 47
-54 70 -120 20 -56 20 -74 -1 -109 -20 -34 -52 -50 -98 -50 -44 0 -115 28
-115 45 0 7 -3 20 -6 29 -5 13 -1 15 25 10 37 -8 81 2 81 18 0 7 -19 31 -42
55 l-42 43 34 -10 c46 -13 117 -13 125 1 9 14 -29 54 -66 69 -31 13 -37 25
-16 33 20 9 23 8 51 -14z m647 -8 c4 -3 9 -27 13 -53 l6 -48 -36 0 c-45 0
-104 -25 -104 -44 0 -9 12 -22 28 -30 20 -12 23 -15 9 -16 -10 0 -32 13 -48
29 -54 55 -30 149 44 170 31 8 76 5 88 -8z m-2001 -105 c0 -22 7 -47 14 -55
12 -13 13 -26 5 -60 -15 -69 -4 -82 15 -18 16 51 20 57 44 57 31 0 72 -25 72
-43 0 -13 -95 -101 -144 -132 l-29 -19 -33 38 c-19 21 -31 43 -27 49 3 6 15
35 25 66 18 54 18 55 13 14 -6 -49 9 -59 19 -12 9 46 7 71 -4 64 -13 -8 -13 2
1 66 13 59 29 50 29 -15z m2891 -89 c71 -57 81 -44 14 19 -33 30 -46 47 -30
37 35 -22 102 -53 113 -53 4 1 -4 10 -17 20 l-23 20 30 0 c24 0 35 -7 52 -35
27 -44 21 -64 -31 -95 -39 -24 -69 -64 -69 -93 0 -24 -26 -38 -70 -40 -37 -1
-46 3 -77 37 -24 26 -32 43 -27 54 7 17 17 57 30 117 6 28 9 24 52 -65 46 -96
68 -112 34 -24 -34 85 -35 90 -12 54 13 -20 25 -30 27 -23 6 18 -39 94 -52 89
-13 -4 -31 21 -45 62 -9 25 -8 25 23 -8 17 -20 53 -53 78 -73z m-828 -87 l25
-25 -27 -31 c-35 -39 -107 -77 -133 -68 -24 7 -58 61 -58 92 0 47 16 57 95 57
64 0 76 -3 98 -25z m-904 -54 c7 -8 16 -27 20 -43 6 -24 13 -29 52 -33 41 -5
44 -7 47 -37 4 -40 26 -53 83 -51 54 2 79 -25 79 -88 0 -40 -3 -46 -40 -69
-37 -23 -41 -29 -38 -63 3 -35 1 -38 -51 -66 -45 -24 -57 -37 -78 -83 -21 -46
-25 -65 -20 -115 4 -41 2 -58 -6 -55 -7 2 -13 36 -15 83 -2 60 2 93 18 138 23
68 23 66 11 66 -5 0 -15 -17 -22 -37 -10 -32 -13 -35 -19 -17 -11 28 -1 74 14
68 8 -3 21 15 34 45 11 28 37 75 56 104 20 30 36 58 36 62 0 15 -43 -30 -72
-74 -29 -46 -39 -46 -13 -1 8 14 14 32 14 40 1 22 -38 -42 -65 -108 -22 -56
-23 -56 -35 -30 -13 31 -3 111 11 88 5 -8 11 -12 13 -10 5 5 57 131 57 137 0
12 -23 -13 -41 -45 -11 -20 -18 -29 -15 -19 11 36 -13 15 -31 -28 -10 -25 -21
-45 -24 -45 -11 0 -20 33 -11 39 10 7 26 101 17 101 -3 0 -10 -10 -16 -22 -8
-20 -9 -19 -4 7 3 17 7 46 10 65 l5 35 -20 -40 c-24 -49 -26 -35 -4 29 21 61
10 72 -16 15 -22 -49 -26 -95 -8 -101 7 -3 9 -12 5 -22 -10 -25 -30 -8 -57 50
-19 39 -23 42 -37 28 -14 -13 -14 -17 2 -34 16 -18 16 -18 -4 -13 -12 3 -21 1
-21 -5 0 -5 11 -13 25 -16 25 -6 59 -37 76 -68 7 -13 -1 -11 -36 8 -25 14 -53
24 -62 22 -13 -3 -4 -12 30 -28 26 -13 47 -30 47 -37 0 -15 37 -36 49 -29 9 6
25 -23 17 -31 -2 -3 -17 2 -33 10 -85 44 -94 26 -10 -21 60 -34 72 -46 81 -84
8 -35 -11 -38 -23 -4 -9 23 -84 62 -100 51 -5 -2 -21 13 -35 34 -15 21 -38 49
-51 61 -46 43 -26 0 40 -86 35 -46 81 -114 101 -151 21 -37 39 -62 42 -55 4
13 -26 80 -63 139 -18 30 -21 41 -11 37 9 -3 14 -10 11 -15 -3 -4 19 -24 50
-43 60 -39 75 -71 28 -60 l-28 6 33 -30 c28 -27 32 -37 32 -81 0 -28 -2 -51
-5 -51 -2 0 -11 14 -20 30 -16 31 -42 43 -31 14 5 -14 4 -15 -9 -4 -8 7 -12
16 -10 21 7 10 -56 139 -68 139 -5 0 -6 -8 -3 -17 6 -17 5 -17 -8 0 -21 27
-26 20 -12 -20 9 -24 19 -37 29 -35 8 1 17 -5 21 -13 4 -11 2 -14 -6 -9 -7 5
-9 0 -6 -14 5 -18 3 -19 -16 -11 -12 6 -33 28 -46 50 -31 49 -55 56 -30 8 14
-25 -1 -24 -33 1 -33 26 -91 121 -124 202 -30 76 -48 98 -38 48 8 -42 57 -150
85 -191 18 -24 19 -29 6 -29 -9 0 -16 7 -16 15 0 21 -53 100 -58 87 -2 -6 2
-21 9 -34 12 -22 11 -22 -6 -8 -17 14 -18 13 -13 -7 6 -22 5 -22 -31 -8 -42
18 -64 42 -99 110 -14 27 -27 44 -30 37 -2 -7 7 -35 20 -63 l25 -49 -23 0
c-15 0 -22 5 -18 13 3 8 -8 38 -23 68 -32 63 -45 44 -15 -22 33 -74 33 -75 -6
-93 -30 -15 -34 -15 -23 -2 23 28 -10 19 -75 -20 -66 -40 -91 -61 -82 -70 4
-3 26 7 50 24 50 34 88 55 88 48 0 -2 -16 -14 -36 -26 -65 -41 -25 -34 60 10
67 34 90 41 112 35 28 -7 27 -8 -33 -38 -35 -17 -63 -35 -63 -39 0 -13 18 -9
59 12 33 16 40 17 45 5 5 -14 -6 -20 -28 -16 -27 5 -139 -56 -130 -70 3 -6 26
2 51 17 25 14 43 21 39 15 -12 -18 7 -13 87 24 83 39 153 50 85 14 -54 -28
-47 -42 9 -18 33 14 58 18 82 14 l35 -7 -41 -18 c-39 -17 -64 -40 -45 -40 5 0
31 9 57 21 46 20 48 20 87 3 38 -17 36 -37 -2 -27 -20 5 -60 -13 -60 -27 0
-11 57 -6 93 9 19 7 34 8 45 1 10 -5 12 -10 5 -10 -7 0 -13 -4 -13 -10 0 -5
13 -7 30 -3 19 3 30 1 30 -6 0 -6 -4 -11 -10 -11 -5 0 -10 -5 -10 -11 0 -6 6
-9 14 -6 10 4 12 0 9 -14 -6 -22 -22 -23 -93 -8 -54 11 -79 6 -40 -9 22 -8 22
-9 -10 -15 -18 -4 -35 -5 -38 -2 -3 3 0 5 6 5 31 0 6 20 -42 34 -92 27 -105
15 -18 -16 59 -21 23 -24 -44 -3 -27 8 -55 15 -62 15 -21 0 -5 -17 22 -24 14
-3 38 -11 53 -17 l28 -12 -30 -8 c-16 -5 -52 -6 -80 -2 -44 6 -47 8 -20 13 26
4 15 10 -73 39 -106 34 -149 36 -71 2 24 -10 51 -21 59 -24 12 -4 11 -6 -2 -6
-21 -1 -24 -17 -5 -24 6 -3 2 -6 -11 -6 -36 -1 -17 -18 30 -26 35 -6 39 -9 23
-18 -14 -7 -20 -7 -23 1 -2 7 -22 12 -45 12 -31 0 -38 -3 -27 -10 12 -8 10
-10 -10 -10 -45 0 -143 28 -229 65 -46 19 -89 34 -95 32 -15 -5 83 -54 164
-83 81 -29 56 -31 -35 -3 -64 19 -96 19 -65 -2 42 -26 222 -59 228 -41 4 14
38 16 46 3 7 -11 -18 -21 -54 -21 -32 0 -50 -17 -23 -22 30 -6 -130 -105 -191
-119 -59 -13 -69 -29 -14 -21 l33 5 -42 -46 c-71 -77 -46 -88 27 -12 19 20 47
46 62 56 l27 20 -19 -57 c-11 -31 -20 -64 -20 -73 0 -26 17 1 35 57 9 29 19
51 21 49 2 -2 -1 -18 -7 -36 -18 -51 -2 -49 21 3 22 51 26 81 10 71 -6 -3 -7
1 -4 10 4 10 13 13 24 10 13 -4 20 1 25 15 3 11 14 24 24 29 15 9 14 -1 -11
-70 -30 -86 -36 -125 -16 -113 7 5 9 2 5 -9 -3 -8 -9 -32 -13 -51 -3 -20 -12
-36 -19 -36 -8 0 -27 -11 -44 -25 -22 -19 -32 -38 -37 -70 -3 -24 -12 -52 -19
-62 -13 -17 -14 -17 -33 0 -11 10 -22 30 -25 45 -4 20 -14 30 -38 37 -23 7
-35 18 -39 35 -4 16 -20 32 -45 46 -32 16 -41 28 -46 55 -3 19 -8 39 -10 44
-9 28 16 56 79 87 78 37 77 36 70 43 -6 6 -107 -28 -135 -45 -17 -11 -24 -10
-44 3 -13 10 -33 17 -46 17 -30 0 -53 26 -53 61 0 22 10 39 36 63 36 33 37 35
32 90 -4 54 -3 58 22 71 15 8 33 28 40 45 9 21 19 30 35 30 13 0 37 11 53 25
51 43 97 43 196 0 54 -23 43 2 -15 33 -35 18 -57 23 -108 21 -88 -3 -97 14
-40 81 36 42 45 48 69 43 20 -3 37 2 59 18 26 19 32 20 47 8 9 -8 36 -14 58
-15 23 -1 51 -4 64 -9 33 -11 26 7 -13 33 -45 29 -47 62 -5 92 17 12 30 29 30
37 0 9 16 40 37 70 38 56 68 68 94 37 10 -12 23 -14 60 -9 41 6 49 4 59 -14 6
-12 17 -21 25 -21 9 0 15 -9 15 -21 0 -30 30 -142 41 -153 19 -21 19 5 0 69
-25 80 -26 97 -5 67 14 -20 42 -22 30 -3 -3 4 18 0 47 -9 29 -10 54 -16 56
-14 10 9 -18 25 -66 36 -40 10 -53 18 -57 35 -7 25 9 73 24 73 5 0 21 17 34
38 19 31 31 39 54 40 17 0 49 14 73 31 46 34 70 38 88 17z m850 -163 c-41 -81
-14 -108 53 -53 l37 31 10 -38 c11 -36 9 -39 -21 -65 -33 -27 -79 -44 -108
-39 -8 1 -27 15 -42 32 -34 35 -36 80 -9 126 18 29 28 34 87 42 7 0 4 -14 -7
-36z m233 -58 c-4 -44 -2 -55 11 -55 16 0 86 68 88 85 1 5 5 -3 9 -20 5 -16
11 -39 15 -51 5 -16 -2 -30 -28 -57 -42 -45 -57 -46 -99 -8 -29 26 -33 36 -33
79 0 44 16 82 35 82 3 0 5 -25 2 -55z m229 -45 c58 -30 78 -105 44 -170 -32
-61 -122 -43 -158 33 l-19 37 44 60 c24 33 44 60 46 60 1 0 21 -9 43 -20z
m-341 -61 c0 -7 -10 -27 -22 -47 -16 -25 -19 -39 -11 -51 6 -9 7 -36 4 -61 -6
-40 -9 -45 -31 -42 -30 3 -80 54 -80 82 0 42 28 88 62 103 63 29 78 32 78 16z
m97 -66 c-25 -50 -29 -83 -11 -90 9 -3 25 -1 34 5 15 9 19 7 22 -19 6 -36 -12
-62 -51 -78 -56 -24 -108 29 -96 99 6 33 14 46 45 65 21 13 41 29 45 35 14 23
25 8 12 -17z m-593 -18 c12 -9 42 -15 72 -15 67 0 104 -26 88 -61 -15 -33 -7
-49 23 -49 24 0 25 -2 18 -36 -6 -33 -3 -40 29 -69 42 -39 45 -67 11 -99 -16
-15 -25 -34 -25 -54 0 -23 -7 -36 -26 -48 -24 -16 -27 -16 -61 0 -20 10 -70
21 -112 26 -42 5 -84 12 -93 15 -13 5 -18 2 -18 -8 0 -11 14 -18 48 -22 127
-17 157 -25 172 -46 8 -11 27 -35 43 -51 33 -38 33 -43 2 -72 -25 -24 -32 -50
-16 -60 5 -3 14 -20 20 -37 10 -28 8 -33 -24 -65 -21 -21 -35 -45 -35 -59 0
-36 -32 -50 -71 -29 -19 9 -47 14 -71 12 -37 -3 -42 -1 -56 29 -9 18 -20 33
-25 33 -11 0 -5 -30 14 -77 19 -46 3 -82 -41 -97 -25 -8 -37 -19 -44 -41 -18
-62 -26 -74 -56 -90 -29 -15 -34 -15 -55 -1 -14 9 -35 16 -48 16 -13 0 -32 9
-43 21 -15 17 -28 20 -73 17 -54 -3 -55 -2 -64 28 -7 24 -19 36 -48 47 -22 9
-42 23 -45 32 -4 9 4 39 16 66 19 40 21 53 12 74 -10 21 -8 29 12 52 30 37 49
73 38 73 -6 0 -26 -19 -47 -41 -33 -37 -37 -45 -31 -80 4 -26 1 -49 -9 -69
-18 -34 -20 -83 -4 -108 9 -14 8 -25 -5 -50 -19 -36 -54 -49 -86 -32 -31 17
-67 4 -84 -28 -24 -46 -41 -56 -74 -43 -20 8 -36 9 -55 2 -22 -9 -29 -7 -42
12 -8 12 -15 29 -15 39 0 23 -38 48 -72 48 -33 0 -42 14 -40 57 2 19 -4 43
-12 55 -14 19 -13 25 5 58 12 21 39 48 60 61 21 13 39 27 39 32 0 18 -40 4
-78 -29 -68 -58 -74 -43 -30 71 45 118 45 158 -1 65 -17 -36 -29 -54 -25 -40
13 50 66 180 76 186 6 4 5 -2 -2 -15 -6 -12 -9 -27 -7 -34 2 -7 14 7 25 30 12
23 31 47 42 53 24 13 24 12 5 -34 -22 -53 -4 -44 28 14 26 49 43 61 104 76 24
6 25 6 12 -17 -7 -14 -14 -19 -17 -11 -8 24 -19 12 -46 -47 -27 -60 -43 -121
-32 -121 3 0 13 15 21 33 13 29 14 29 9 5 -9 -43 5 -33 27 19 11 26 36 73 55
104 29 48 40 58 74 67 23 5 40 6 40 1 0 -5 -8 -9 -19 -9 -13 0 -25 -16 -40
-52 -12 -28 -19 -53 -16 -56 7 -7 8 -4 34 43 12 22 20 32 17 23 -9 -31 8 -19
29 19 11 20 26 39 34 41 8 3 11 0 7 -7 -10 -15 4 -14 23 3 9 8 31 17 50 21 31
7 33 5 26 -11 -4 -11 -9 -28 -12 -39 -4 -17 -2 -17 16 5 11 14 21 30 21 37 0
14 80 28 109 18 33 -10 55 9 119 107 76 117 117 157 157 156 17 -1 41 5 54 12
13 6 21 7 17 2 -3 -6 -2 -13 4 -17 9 -6 11 -1 14 33 1 12 41 41 56 41 3 0 14
-6 24 -14z m886 -217 c0 -38 -25 -85 -49 -93 -28 -9 -76 19 -100 58 -18 29
-19 37 -7 70 l12 36 21 -21 c25 -24 53 -18 53 12 0 20 1 20 35 -6 26 -19 35
-34 35 -56z"/>
<path d="M3136 1450 c-41 -67 -97 -130 -115 -130 -9 0 -9 5 0 21 8 15 13 18
19 10 5 -8 21 9 49 49 22 34 41 66 41 71 0 22 -20 5 -44 -36 -15 -25 -33 -45
-40 -45 -8 0 -25 -15 -40 -34 -21 -28 -36 -37 -83 -46 -32 -6 -66 -14 -77 -17
-26 -7 -11 16 18 27 27 10 67 81 49 87 -10 3 -10 10 -2 25 6 11 7 23 3 26 -4
4 -9 2 -11 -4 -2 -6 -10 -20 -17 -30 -13 -17 -14 -16 -9 11 7 39 0 31 -52 -57
-58 -99 -49 -108 18 -17 18 24 18 24 1 -6 -23 -41 -65 -85 -81 -85 -7 0 -27
-14 -45 -30 l-31 -30 18 -60 18 -59 -46 -96 c-50 -102 -48 -128 2 -42 29 48
30 50 24 18 -4 -25 -3 -32 6 -27 8 5 8 1 -1 -19 -8 -18 -14 -22 -19 -13 -10
15 -7 18 -59 -65 -48 -77 -48 -97 -1 -40 18 21 29 30 25 21 -13 -37 13 -16 44
34 31 52 45 58 17 8 -19 -33 -20 -63 -2 -38 8 10 18 13 26 9 15 -9 -9 -63 -61
-139 -17 -24 -28 -45 -25 -48 8 -8 67 66 90 112 11 24 25 44 29 44 4 0 8 -21
8 -46 0 -34 -9 -62 -31 -101 -38 -66 -32 -80 10 -22 36 49 38 47 11 -21 -24
-61 -25 -71 -2 -45 26 32 48 36 68 14 31 -34 37 -20 8 18 -19 26 -30 54 -34
89 l-6 52 26 -18 c36 -25 127 -70 142 -70 18 1 -15 25 -84 62 -71 38 -84 52
-92 106 -6 42 -6 42 21 37 l28 -6 -30 26 c-21 18 -24 24 -10 19 l20 -7 -20 23
-20 22 35 -21 c19 -11 38 -21 43 -21 18 0 3 17 -38 43 -25 16 -45 30 -45 32 0
8 59 -14 84 -30 32 -21 49 -11 18 11 -12 8 -20 19 -17 24 3 4 -14 25 -37 45
-24 20 -37 34 -30 30 22 -11 13 9 -15 31 -17 13 -22 23 -15 28 14 8 121 -133
177 -232 38 -68 55 -87 55 -60 0 6 -14 36 -31 66 -18 33 -32 73 -34 101 l-4
46 19 -58 c10 -33 22 -62 26 -65 7 -8 -2 65 -13 95 -5 14 -1 13 15 -5 12 -13
24 -45 28 -70 12 -83 25 -92 18 -13 -3 40 -8 80 -11 90 -4 9 7 4 25 -13 18
-17 32 -36 32 -43 0 -8 5 -11 10 -8 6 3 10 2 10 -4 0 -5 3 -17 6 -26 4 -10 2
-15 -6 -13 -10 2 -13 -11 -11 -52 3 -63 9 -75 19 -40 6 25 6 25 13 -5 6 -27 7
-26 9 15 2 42 3 43 10 15 l8 -30 2 35 c2 45 9 28 19 -51 l8 -64 2 59 c1 51 3
57 16 46 10 -9 15 -9 15 -1 0 6 15 11 34 11 19 0 38 5 41 10 4 6 -13 10 -42
11 l-48 1 35 13 35 14 -44 -2 c-28 -1 -46 3 -49 11 -2 7 5 12 17 12 36 0 23
19 -15 22 -25 2 -42 10 -52 26 -14 22 -14 22 27 22 22 0 41 5 41 10 0 6 -24
10 -54 10 -30 0 -57 3 -59 8 -3 4 37 7 89 7 60 0 98 5 105 12 20 20 5 25 -42
14 -55 -12 -118 -21 -101 -14 29 12 8 22 -37 16 -27 -3 -51 -1 -54 4 -4 6 24
12 71 16 135 9 143 27 9 20 -104 -5 -117 -4 -112 10 4 8 18 18 32 22 42 10 15
20 -47 17 -35 -2 -60 1 -65 8 -4 7 5 10 29 8 59 -4 51 9 -11 16 -67 8 -209 75
-161 76 14 0 28 4 30 9 2 4 14 11 28 15 14 3 19 3 13 0 -19 -8 -16 -24 5 -25
14 0 14 -2 -3 -9 -18 -7 -18 -9 10 -19 16 -7 38 -10 49 -7 10 3 32 -2 48 -10
30 -16 56 -10 28 6 -8 5 -24 16 -35 24 -16 13 -17 15 -2 10 30 -11 18 12 -15
29 -28 13 -29 16 -11 16 30 1 53 -9 53 -24 0 -14 77 -51 87 -42 3 4 17 0 31
-9 13 -8 27 -13 30 -9 8 8 -16 27 -88 68 -38 22 -50 33 -37 35 10 2 23 -1 30
-6 7 -6 29 -17 50 -25 41 -18 52 -3 12 15 -34 15 -32 28 5 28 22 0 28 -4 24
-15 -4 -8 -2 -15 2 -15 5 0 42 -25 83 -55 40 -31 75 -54 78 -52 12 13 -31 80
-69 108 -24 17 -35 29 -25 25 10 -3 28 -6 39 -6 12 0 18 -3 15 -7 -10 -9 33
-53 51 -53 11 0 9 7 -9 26 l-24 27 28 -7 c28 -7 38 8 11 18 -8 3 -12 11 -8 17
5 9 -1 10 -20 5 -31 -8 -33 0 -5 30 23 25 18 70 -6 60 -10 -3 -12 0 -9 12 3 9
-18 -9 -48 -41 -45 -49 -58 -58 -87 -57 l-34 0 20 23 c48 54 104 157 85 157
-5 0 -26 -27 -46 -60z"/>
<path d="M2617 1200 c-19 -16 -37 -27 -39 -24 -3 3 -23 -2 -43 -10 -49 -21
-48 -21 -30 14 18 34 18 40 4 40 -5 0 -19 -20 -30 -44 -14 -33 -36 -56 -82
-88 -92 -64 -90 -64 -82 -39 10 34 -11 24 -26 -11 -7 -18 -46 -64 -87 -103
-91 -85 -78 -100 15 -18 100 87 152 125 162 119 5 -3 28 1 52 9 l43 16 -25
-27 c-30 -32 -82 -59 -143 -75 -25 -6 -46 -15 -46 -20 0 -5 4 -9 10 -9 5 0 7
-7 4 -15 -7 -18 -6 -18 35 -4 17 6 31 17 31 25 0 8 4 14 9 14 5 0 18 3 28 7
39 14 -27 -44 -77 -67 -47 -22 -71 -49 -32 -37 9 3 28 9 42 12 l25 7 -26 -25
c-27 -25 -165 -87 -193 -87 -8 0 -18 -4 -21 -10 -10 -17 28 -11 105 15 40 14
75 25 77 25 1 0 -5 -16 -14 -35 -14 -30 -28 -39 -95 -65 -42 -16 -82 -30 -88
-30 -5 0 -10 -4 -10 -10 0 -16 24 -12 95 15 36 13 65 23 65 21 0 -2 -7 -23
-15 -46 -16 -46 -20 -90 -7 -74 5 5 17 32 27 59 10 28 36 86 59 130 l41 80 6
-65 c7 -73 28 -150 41 -150 5 0 6 12 3 28 -3 15 4 1 16 -31 31 -85 39 -61 13
40 -13 48 -23 105 -23 125 -1 20 -6 40 -13 44 -8 5 -10 -14 -6 -72 3 -43 2
-68 -2 -54 -5 14 -8 59 -9 100 0 58 4 80 18 99 l18 24 8 -79 c12 -129 23 -128
21 3 -1 114 0 124 22 145 l22 22 0 -27 c0 -15 -4 -27 -10 -27 -5 0 -10 -22
-10 -49 0 -59 20 -151 32 -151 5 0 7 8 4 18 -10 36 -16 137 -7 117 7 -14 10 4
10 65 l1 86 45 44 45 44 0 -59 c0 -33 5 -67 10 -75 7 -10 10 12 10 72 0 90 16
131 22 56 l3 -43 9 50 c5 28 15 57 23 67 27 30 -5 29 -40 -2z"/>
</g>

    </Svg>
  );
};

export default Icon;
