import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
	  <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M4550 4990 c-8 -5 -28 -10 -43 -10 -15 0 -45 -9 -67 -20 -22 -11 -50
-20 -64 -20 -14 0 -37 -5 -53 -12 -26 -10 -133 -28 -178 -30 -11 0 -45 -7 -75
-16 -30 -8 -65 -14 -78 -14 -13 1 -39 -5 -59 -13 -19 -8 -48 -15 -63 -15 -15
0 -47 -7 -71 -16 -24 -9 -65 -17 -90 -19 -25 -1 -61 -11 -80 -21 -19 -9 -46
-17 -62 -16 -32 3 -94 -27 -121 -57 -11 -12 -27 -21 -36 -21 -18 0 -71 -52
-89 -87 -7 -13 -20 -23 -30 -23 -26 0 -61 -74 -68 -145 -6 -52 -7 -55 -34 -55
-16 0 -54 -18 -92 -43 l-64 -44 -31 63 c-17 35 -43 71 -57 80 -24 16 -34 15
-96 -10 -12 -5 -14 0 -11 29 3 29 -4 47 -39 100 -25 37 -54 68 -66 71 -14 4
-23 14 -23 25 0 10 -12 32 -27 49 -26 29 -35 32 -128 41 -72 7 -113 16 -148
34 -27 14 -55 25 -62 25 -40 0 -172 -53 -200 -80 -31 -30 -32 -30 -74 -16 -23
9 -55 13 -70 10 -18 -4 -38 0 -53 10 -15 10 -53 18 -99 20 -41 2 -108 7 -150
11 l-76 8 -138 -68 c-110 -55 -141 -75 -154 -99 -9 -17 -39 -54 -67 -82 -28
-28 -54 -60 -59 -70 -4 -11 -15 -77 -24 -149 l-16 -130 -28 -3 c-34 -4 -62
-45 -77 -114 -20 -96 -80 -197 -177 -300 -29 -31 -53 -63 -53 -72 0 -8 14 -24
30 -36 66 -47 59 -77 -27 -134 -42 -27 -52 -40 -80 -111 -18 -44 -33 -83 -33
-87 0 -4 20 -36 45 -72 65 -95 77 -129 85 -241 4 -56 13 -110 21 -123 8 -13
50 -40 94 -61 44 -21 89 -49 100 -61 26 -29 68 -50 99 -50 14 0 38 -13 56 -31
31 -31 33 -32 101 -26 67 6 71 5 95 -22 19 -21 38 -30 75 -35 59 -8 69 -24 45
-72 -9 -19 -16 -45 -16 -58 0 -52 73 -109 168 -131 37 -9 62 -21 72 -36 38
-55 69 -79 103 -79 l34 0 -9 -58 c-9 -58 -74 -270 -88 -285 -4 -5 -11 6 -15
25 -13 60 -44 107 -83 127 -45 23 -137 51 -168 51 -33 0 -89 -53 -109 -102
-14 -36 -15 -50 -4 -110 7 -38 17 -68 24 -68 15 0 46 34 79 88 24 38 30 42 67
42 34 0 47 -6 73 -34 17 -18 50 -46 74 -61 27 -18 41 -33 37 -42 -17 -45 -110
-330 -149 -456 -47 -150 -89 -341 -102 -454 -4 -35 -10 -63 -13 -63 -9 0 -53
164 -102 380 -22 102 -58 233 -79 292 -21 60 -35 108 -31 108 3 0 24 -14 45
-30 63 -48 74 -40 77 55 2 59 -16 100 -65 143 -35 30 -35 31 -19 62 35 70 -6
208 -92 313 -43 52 -71 75 -125 102 -84 42 -111 44 -145 8 -26 -27 -27 -27
-38 -9 -19 31 -89 107 -95 102 -2 -3 -29 15 -59 39 -30 25 -58 45 -61 46 -27
3 -44 10 -69 28 -15 11 -50 26 -78 32 -27 6 -71 20 -97 30 -26 11 -60 19 -76
19 -15 0 -50 7 -76 16 -25 9 -63 14 -83 12 -20 -2 -52 1 -71 7 -75 26 -88 -1
-47 -100 14 -33 25 -71 25 -85 0 -14 5 -43 11 -64 12 -45 18 -206 7 -206 -12
0 -9 -61 6 -119 7 -29 12 -61 9 -70 -4 -16 40 -105 58 -116 5 -4 9 -15 9 -26
0 -26 35 -94 71 -140 16 -20 29 -45 29 -54 0 -10 32 -49 70 -86 39 -38 70 -74
70 -79 0 -12 124 -95 164 -110 14 -5 26 -14 26 -20 0 -16 64 -40 133 -51 33
-6 69 -16 81 -24 29 -21 77 -19 173 6 46 12 92 19 107 15 41 -10 139 -155 239
-354 l91 -184 -2 -102 c-5 -169 20 -498 44 -578 7 -25 34 -22 34 3 0 11 -6 75
-14 142 -35 297 -28 587 19 835 25 132 197 709 217 727 1 1 39 12 83 24 44 12
99 26 122 33 36 10 54 8 136 -12 109 -27 137 -23 222 32 70 47 70 63 -2 83
-32 9 -98 30 -147 47 -82 28 -93 30 -139 19 -54 -12 -126 -52 -194 -108 -24
-19 -43 -31 -43 -28 0 4 15 28 34 54 66 89 258 261 384 344 48 31 57 34 88 25
24 -6 58 -6 102 2 152 25 135 28 217 -29 128 -89 153 -82 256 70 58 87 119
143 165 155 16 4 37 -6 78 -40 31 -25 64 -46 72 -46 8 0 40 -18 70 -41 30 -22
67 -42 83 -44 16 -2 47 -15 69 -30 22 -15 53 -28 69 -29 15 -1 54 -15 86 -31
44 -23 62 -27 75 -19 10 6 46 9 85 6 37 -2 67 0 67 4 0 5 27 9 60 10 38 0 66
6 77 15 10 9 34 16 53 18 19 1 45 8 57 16 12 8 47 24 76 35 30 11 63 30 73 41
10 11 40 30 66 41 26 11 48 26 48 32 0 6 21 24 46 40 25 16 50 38 56 50 5 12
29 36 54 54 24 18 44 38 44 44 0 15 79 99 113 121 16 10 31 26 34 35 2 9 33
37 69 61 39 26 64 50 64 61 0 10 16 27 35 39 48 28 47 56 -2 71 -21 7 -47 20
-58 30 -24 20 -78 40 -111 40 -14 0 -24 6 -24 15 0 16 -85 45 -132 45 -17 0
-27 4 -23 10 9 14 -50 37 -105 41 -25 2 -50 10 -55 17 -12 15 -75 31 -124 32
-20 0 -39 7 -46 17 -9 12 -30 16 -91 18 -43 0 -86 5 -95 9 -18 8 -132 -10
-161 -25 -12 -6 -18 -6 -18 1 0 16 -31 11 -95 -16 -33 -14 -73 -27 -88 -29
-37 -5 -121 -68 -151 -111 l-23 -34 -31 39 c-37 46 -38 45 75 110 72 42 78 49
85 111 3 23 9 26 63 32 111 13 169 25 173 36 2 7 16 12 30 12 38 0 102 19 102
30 0 6 18 10 41 10 26 0 50 7 66 20 14 11 34 20 44 20 11 0 28 9 40 21 11 11
49 35 82 53 44 22 63 38 65 54 2 12 7 22 11 22 14 0 101 81 101 94 0 7 18 32
40 55 22 23 40 49 40 57 0 8 14 33 32 55 17 21 29 42 25 45 -3 3 8 27 24 52
16 25 29 56 29 69 0 13 9 40 20 60 11 21 20 48 20 61 0 13 5 33 12 45 6 12 13
42 14 67 10 141 13 267 8 280 -8 20 -7 119 2 196 4 42 3 63 -5 68 -6 4 -11 36
-11 78 0 61 -3 71 -19 75 -12 3 -21 15 -23 32 -2 19 -9 27 -28 29 -14 1 -32
-2 -40 -8z m48 -54 c3 -28 7 -36 20 -34 14 2 18 -6 20 -43 4 -63 -11 -94 -63
-131 -42 -29 -56 -48 -37 -48 4 0 31 15 60 34 l52 35 0 -27 c0 -15 -5 -44 -11
-66 -6 -21 -7 -42 -2 -45 4 -3 9 -52 10 -109 l3 -102 -33 -20 c-48 -30 -179
-79 -258 -97 -79 -17 -95 -39 -21 -28 63 9 216 60 271 91 24 13 46 22 48 20 3
-3 -3 -30 -12 -62 -9 -31 -14 -66 -11 -78 3 -11 -4 -37 -16 -57 -16 -29 -36
-44 -87 -67 -36 -17 -90 -36 -118 -43 -29 -7 -53 -17 -53 -21 0 -18 141 18
213 54 44 22 46 16 11 -35 -15 -22 -23 -45 -19 -56 3 -11 -6 -34 -25 -59 -16
-23 -30 -50 -30 -61 0 -10 -15 -32 -34 -49 -39 -34 -119 -52 -232 -52 -88 0
-246 28 -260 46 -6 8 -25 17 -42 20 -18 4 -29 10 -27 14 3 5 -1 11 -7 14 -7 3
-2 4 12 1 14 -2 53 -7 85 -10 46 -5 57 -4 45 5 -12 9 -1 10 48 8 34 -3 62 -1
62 4 0 4 -3 8 -7 8 -30 1 -160 23 -216 36 -44 11 -65 20 -59 26 5 5 86 11 178
12 160 3 274 18 274 38 0 5 -10 6 -22 3 -81 -19 -354 -23 -343 -5 4 6 1 10 -6
10 -7 0 -5 8 6 20 16 18 31 20 134 20 110 0 189 11 291 41 78 22 10 22 -99 0
-106 -22 -287 -29 -276 -11 3 6 21 10 40 10 42 0 55 17 18 23 -16 2 -4 5 25 6
29 0 51 5 48 10 -3 5 26 13 65 19 67 10 88 17 78 26 -8 8 -174 -15 -177 -24
-2 -6 -12 -10 -23 -10 -17 0 -18 3 -9 20 7 14 8 26 0 40 -6 10 -13 47 -17 80
-6 60 -17 69 -26 20 -4 -25 -8 35 -11 165 -1 71 8 193 19 253 11 59 -12 33
-26 -30 -24 -108 -15 -478 11 -478 5 0 11 10 13 23 4 19 4 19 6 -5 0 -14 -4
-28 -11 -30 -7 -3 -9 -11 -4 -24 8 -19 8 -19 16 1 7 15 9 16 9 4 1 -17 -26
-46 -34 -37 -13 13 -35 216 -35 321 0 81 -4 117 -11 117 -16 0 -5 -325 14
-407 14 -61 14 -63 -9 -84 l-23 -21 -12 61 c-14 65 -36 86 -25 24 17 -97 17
-101 1 -59 -8 21 -20 89 -27 150 -15 144 -33 166 -24 31 3 -55 8 -113 11 -130
l5 -30 -10 30 c-5 17 -15 65 -21 109 -7 49 -15 73 -20 64 -10 -19 7 -154 30
-228 11 -33 16 -60 11 -60 -8 0 -48 127 -57 184 -3 20 -10 39 -16 43 -10 6 -5
-63 8 -127 l6 -25 -11 25 c-16 35 -25 170 -11 150 6 -8 11 -13 11 -10 0 3 2
75 4 160 2 85 7 165 11 178 9 26 -9 31 -20 5 -5 -10 -11 -83 -15 -163 -4 -93
-7 -120 -9 -75 -2 59 -4 66 -13 45 -6 -15 -8 -69 -5 -130 2 -58 2 -96 0 -85
-8 35 -23 41 -22 9 0 -29 28 -141 44 -179 8 -18 7 -17 -15 3 -16 14 -26 18
-31 11 -3 -6 -14 19 -24 55 -10 36 -21 66 -25 66 -8 0 6 -99 20 -135 6 -15 10
-41 10 -58 -1 -30 -2 -29 -20 17 -11 27 -24 73 -30 102 -6 30 -16 56 -21 59
-11 7 -11 1 0 -47 5 -21 5 -36 1 -33 -5 3 -11 -1 -13 -7 -3 -7 -6 10 -7 37 0
28 -7 77 -15 110 -8 33 -15 81 -15 107 0 72 -19 68 -22 -4 l-3 -63 -14 75 c-9
52 -11 105 -7 175 6 82 5 98 -6 87 -10 -10 -13 -53 -13 -167 l0 -153 -30 12
c-40 16 -47 57 -43 223 2 91 0 129 -7 122 -6 -6 -10 -73 -10 -169 0 -87 -3
-156 -7 -153 -5 2 -8 19 -8 37 0 19 -3 48 -7 67 -5 28 -7 30 -14 14 -4 -11 -5
-39 -2 -62 6 -45 -1 -52 -32 -29 -15 11 -20 32 -25 112 -4 74 -8 92 -14 71 -5
-15 -7 -60 -4 -100 l3 -74 -48 20 -49 21 6 43 c6 49 44 139 45 110 1 -25 18
-13 41 31 11 20 32 52 48 71 l29 34 -5 -87 c-3 -52 0 -92 5 -97 7 -7 12 18 15
75 4 82 6 88 39 122 19 20 46 42 61 50 25 13 27 12 30 -6 3 -20 4 -20 41 5 41
28 95 50 103 43 2 -3 0 -18 -7 -34 -20 -54 -41 -179 -49 -299 l-8 -120 3 155
c3 115 1 150 -7 136 -17 -30 -22 -232 -8 -344 14 -118 36 -141 27 -29 -5 58
-4 64 3 28 17 -79 22 -58 15 64 -7 121 6 248 37 370 11 42 19 53 53 72 57 32
107 46 116 31 6 -9 19 -6 49 11 22 12 48 20 57 17 9 -4 40 3 71 14 30 11 57
19 59 16 2 -2 -3 -30 -12 -62 -20 -72 -31 -299 -17 -334 8 -20 11 -2 11 80 1
126 32 289 58 308 10 7 35 18 56 24 31 9 40 9 47 -2 7 -11 11 -11 24 -1 8 7
35 19 60 26 l44 13 -8 -31 c-14 -55 -23 -157 -13 -157 5 0 15 34 21 76 7 41
16 83 22 93 13 25 92 64 101 50 4 -7 18 -3 40 10 44 27 53 23 57 -23z m-2203
-188 c27 -15 63 -23 132 -29 100 -9 137 -23 148 -59 4 -12 5 -23 3 -24 -1 -2
-29 -6 -60 -9 l-58 -6 0 45 c0 55 -15 50 -24 -7 -6 -40 -8 -41 -38 -35 -17 3
-47 6 -68 6 -35 0 -36 1 -32 30 3 18 0 30 -7 30 -6 0 -11 -7 -11 -17 0 -12
-40 -104 -57 -132 -2 -2 -12 4 -23 14 -15 14 -20 31 -20 72 0 29 -4 53 -10 53
-5 0 -10 -23 -10 -52 0 -69 -22 -50 -25 22 -4 61 -10 72 -18 31 -6 -28 -7 -29
-37 -18 -16 6 -30 14 -30 17 0 18 53 52 105 69 79 25 97 25 140 -1z m-580 -26
c50 -4 101 -13 113 -20 l24 -14 -64 -66 -63 -67 -22 69 c-12 37 -26 73 -32 79
-15 15 -14 9 10 -85 17 -65 19 -86 10 -106 l-12 -25 -22 21 c-12 11 -30 50
-41 87 -10 36 -23 68 -27 71 -12 7 -11 -13 5 -73 8 -29 11 -53 8 -53 -21 1
-47 28 -50 50 -4 36 -33 110 -43 110 -13 0 -11 -10 11 -76 l20 -58 -33 32
c-42 41 -80 42 -135 3 -22 -16 -43 -34 -47 -40 -11 -18 -20 -12 -33 21 -10 26
-10 34 0 37 7 2 63 30 123 62 102 54 114 58 160 53 28 -3 91 -9 140 -12z m311
-67 c51 -19 76 -34 85 -52 13 -23 12 -27 -5 -37 -11 -6 -42 -31 -68 -55 l-48
-44 0 34 c0 26 -3 30 -10 19 -5 -8 -10 -29 -10 -46 0 -19 -8 -38 -21 -50 l-22
-19 6 60 c4 33 10 78 13 99 11 59 -11 37 -24 -26 -6 -29 -16 -105 -22 -169
-10 -107 -10 -120 9 -169 l20 -53 -24 -19 c-13 -10 -27 -32 -30 -50 -13 -57
-28 -3 -35 122 -4 85 -8 108 -14 87 -9 -29 -2 -200 10 -249 6 -25 4 -28 -20
-28 -19 0 -26 5 -26 19 0 11 -19 35 -42 55 l-41 35 0 117 c1 75 -3 112 -9 106
-5 -5 -11 -55 -11 -111 l-2 -101 -32 35 c-18 19 -37 35 -44 35 -16 0 -6 148
12 181 25 47 222 264 264 291 34 21 41 20 141 -17z m-545 -71 c13 -14 54 -41
91 -60 36 -18 70 -38 73 -45 4 -6 -5 -23 -19 -38 -28 -29 -46 -78 -46 -123 0
-16 -4 -28 -9 -28 -11 0 -26 98 -17 107 3 4 6 -1 6 -10 0 -9 5 -17 11 -17 8 0
9 11 5 31 -3 17 -6 40 -6 50 0 27 -18 24 -23 -3 -3 -15 -5 -9 -6 14 0 20 -6
40 -12 44 -15 9 -4 -147 16 -232 7 -33 12 -60 10 -62 -7 -8 -44 67 -49 103
-12 69 -28 78 -22 12 5 -45 3 -58 -6 -55 -17 6 -37 69 -45 140 -3 32 -10 61
-16 65 -10 6 -3 -117 9 -162 7 -25 -72 60 -86 93 -6 13 -10 39 -10 58 0 19 -4
34 -10 34 -5 0 -10 -22 -10 -50 0 -36 -4 -50 -14 -50 -8 0 -25 -13 -39 -30
-25 -29 -25 -29 -31 -6 -10 40 12 92 52 121 20 16 53 43 72 61 31 29 85 64
101 64 3 0 16 -12 30 -26z m1014 16 l140 -1 41 -62 c34 -51 41 -68 37 -98 -6
-48 9 -56 59 -33 51 23 63 15 105 -62 36 -66 40 -104 17 -160 -12 -29 -15 -56
-11 -109 6 -60 3 -78 -17 -121 -21 -45 -96 -124 -118 -124 -14 0 -9 20 17 58
30 44 35 72 9 51 -11 -9 -8 3 10 38 28 57 30 63 18 63 -4 0 -18 -20 -32 -45
-14 -25 -27 -45 -31 -45 -3 0 -11 11 -17 25 -10 22 -8 33 12 73 22 45 56 147
56 172 0 20 -18 1 -25 -27 -10 -43 -66 -173 -74 -173 -4 0 -15 13 -24 30 -17
27 -17 33 -2 82 17 58 19 78 7 78 -5 0 -14 -22 -21 -50 -15 -58 -19 -62 -57
-54 -28 5 -29 7 -26 57 2 29 -1 63 -7 77 -8 21 -10 16 -11 -35 -2 -56 -15
-123 -20 -100 -1 6 -2 34 0 63 3 72 -16 66 -25 -8 -5 -41 -11 -60 -21 -60 -11
0 -12 23 -7 128 6 135 -3 266 -18 257 -4 -3 -6 -54 -3 -113 7 -147 -5 -266
-28 -270 -16 -3 -18 6 -19 85 0 97 -17 90 -18 -8 -1 -52 -3 -57 -23 -56 -13 0
-31 -3 -41 -8 -16 -7 -17 -3 -11 34 4 26 3 41 -4 41 -6 0 -13 -18 -17 -40 -4
-22 -8 -40 -10 -40 -2 0 -11 3 -19 6 -14 5 -15 21 -10 114 5 71 3 109 -4 114
-7 4 -13 -28 -17 -91 -4 -54 -10 -103 -14 -110 -4 -9 -22 -6 -65 12 -32 13
-59 25 -60 27 -1 2 -9 41 -18 87 -10 46 -19 86 -23 89 -12 13 -15 -8 -6 -54
21 -108 21 -114 6 -114 -10 0 -15 10 -15 30 0 17 -4 30 -10 30 -5 0 -10 -16
-10 -35 0 -29 -5 -37 -27 -46 -37 -14 -47 -7 -61 39 -14 47 -15 102 -3 135 14
35 115 147 163 180 51 34 65 34 100 0 32 -31 50 -22 68 33 13 37 42 56 75 49
11 -3 83 -5 160 -5z m-1215 -51 c0 -16 -11 -36 -31 -53 -17 -15 -35 -41 -41
-57 -9 -28 -11 -28 -23 -12 -25 34 -23 8 5 -62 26 -64 27 -74 17 -122 -6 -29
-14 -53 -17 -53 -3 0 -14 25 -25 55 -10 30 -23 55 -28 55 -10 0 -8 -7 22 -112
10 -36 17 -69 14 -72 -3 -3 -23 5 -45 17 l-39 22 6 60 c3 33 11 96 17 140 11
78 12 81 61 125 27 25 58 63 69 84 15 30 22 36 29 25 5 -8 9 -26 9 -40z m92
-230 c19 -24 56 -53 87 -68 76 -37 76 -49 -3 -53 l-65 -3 -7 38 c-4 28 -9 36
-17 28 -8 -8 -8 -22 -1 -50 6 -22 8 -45 4 -51 -13 -21 -21 6 -27 85 -3 41 -10
75 -14 75 -14 0 -10 -105 5 -179 30 -137 35 -194 18 -213 -8 -10 -18 -18 -22
-18 -11 0 -48 107 -61 178 -12 64 -28 86 -22 30 9 -80 26 -155 44 -190 21 -41
23 -56 10 -77 -16 -25 -48 4 -55 51 -4 24 -19 67 -33 96 l-27 53 23 92 c64
254 83 276 163 176z m1855 0 c59 -22 135 -49 169 -60 85 -27 100 -47 107 -141
5 -62 14 -93 48 -163 36 -76 41 -94 37 -139 -4 -44 -12 -63 -57 -121 -72 -95
-103 -118 -94 -71 5 27 -27 63 -64 72 -13 4 -23 10 -23 14 0 9 97 48 142 57
28 6 39 23 15 23 -19 0 -108 -27 -153 -47 -22 -9 -51 -15 -66 -14 -16 1 -52
-10 -83 -27 -54 -30 -76 -28 -33 2 32 23 15 30 -24 10 -31 -16 -32 -16 -19 0
8 10 36 28 62 42 73 36 79 63 7 28 -27 -12 -39 -13 -63 -4 l-30 12 39 34 c21
18 82 55 135 81 52 25 91 48 85 50 -15 5 -106 -31 -171 -68 -70 -39 -59 -11
14 35 64 40 57 56 -10 21 -55 -30 -57 -30 -57 -7 0 10 14 31 30 47 34 32 40
51 11 36 -14 -8 -22 -7 -30 3 -6 7 -32 21 -57 31 l-46 17 28 29 c15 16 54 48
86 71 32 24 54 44 48 46 -15 5 -98 -50 -145 -97 -32 -32 -45 -38 -68 -34 -15
3 -27 9 -27 13 0 4 32 42 71 84 39 42 66 76 59 76 -20 0 -100 -74 -137 -127
-35 -52 -63 -68 -63 -38 0 8 4 15 9 15 12 0 74 101 66 108 -3 4 -17 -7 -31
-23 -18 -21 -24 -24 -19 -10 4 11 10 31 13 45 2 14 12 33 21 44 27 31 114 86
138 86 12 0 70 -18 130 -41z m-1578 -189 c29 -28 50 -54 47 -59 -12 -19 -27
-199 -21 -248 11 -92 25 -58 26 65 0 64 3 135 7 156 l7 40 28 -33 c33 -39 33
-44 6 -80 -12 -16 -25 -49 -29 -73 -7 -36 -5 -52 17 -98 18 -40 27 -77 30
-135 3 -58 1 -76 -6 -65 -6 8 -11 46 -11 83 0 43 -4 66 -10 62 -5 -3 -10 -29
-10 -57 0 -44 -2 -49 -15 -38 -8 7 -15 28 -15 46 0 38 -16 46 -23 12 -3 -23
-4 -22 -10 2 -3 14 -9 56 -12 93 -8 84 -18 80 -17 -5 l1 -63 -25 30 c-39 46
-44 68 -44 181 0 63 -4 104 -10 104 -10 0 -15 -78 -11 -164 l3 -49 -56 49 -56
49 0 63 c0 34 -4 62 -10 62 -5 0 -10 -25 -10 -55 0 -56 -11 -70 -30 -36 -14
27 -12 141 3 171 11 23 20 25 97 31 47 3 90 6 96 7 7 1 35 -21 63 -48z m479
11 c20 -10 62 -26 92 -36 30 -10 59 -21 64 -26 14 -12 -41 -89 -63 -89 -11 0
-26 -3 -35 -6 -14 -6 -14 -3 0 31 8 20 12 40 9 43 -8 8 -21 -13 -42 -68 -38
-104 -41 -110 -58 -104 -8 4 -14 14 -11 23 2 9 8 36 11 60 8 51 -9 50 -20 -2
-11 -49 -16 -51 -37 -16 -15 24 -18 42 -14 71 7 44 -8 51 -22 9 -6 -16 -20
-31 -32 -34 -20 -5 -22 -3 -17 26 3 18 9 41 13 52 7 17 6 18 -8 7 -8 -7 -21
-32 -28 -56 -10 -35 -16 -43 -31 -39 -22 6 -23 13 -7 64 14 48 32 65 94 89 62
24 96 25 142 1z m-1005 -36 c22 -15 37 -34 37 -46 0 -11 14 -46 30 -79 17 -33
30 -70 30 -84 0 -38 10 -56 42 -72 16 -8 26 -19 22 -25 -15 -25 8 -79 51 -120
25 -24 45 -47 45 -50 0 -4 -14 -12 -30 -19 -79 -33 -102 -114 -52 -179 15 -20
43 -67 62 -106 28 -56 41 -72 65 -79 17 -5 69 -27 118 -49 84 -39 87 -41 87
-74 0 -50 16 -72 68 -95 l47 -20 -35 -30 c-38 -32 -30 -32 -203 -9 -84 11
-108 30 -153 127 -32 70 -42 81 -114 127 -23 14 -45 43 -65 83 -17 33 -53 86
-81 117 -43 46 -56 70 -79 144 -24 78 -26 93 -16 133 6 25 16 81 22 125 6 44
17 111 26 149 8 38 13 89 10 112 -6 53 9 57 66 19z m-91 -47 c-3 -61 -10 -78
-35 -78 -21 -1 -21 -1 -4 -15 27 -20 17 -48 -21 -60 -40 -12 -42 -25 -2 -25
32 0 33 -2 24 -48 -6 -30 -9 -32 -65 -38 -78 -9 -77 -28 1 -22 33 2 60 1 60
-3 0 -10 -28 -19 -60 -19 -16 0 -32 -4 -35 -10 -4 -6 12 -10 39 -10 25 0 46
-2 46 -4 0 -1 -5 -17 -11 -34 -13 -37 -23 -41 -112 -43 -52 -2 -72 2 -85 14
-15 16 -13 21 35 70 73 74 147 193 167 268 26 96 37 122 49 110 6 -6 10 -29 9
-53z m1366 5 c20 -21 54 -52 75 -70 l37 -31 -6 -104 c-8 -124 -33 -182 -87
-196 -18 -5 -33 -8 -34 -8 -1 1 4 20 12 41 29 81 15 93 -20 18 -28 -60 -38
-73 -57 -73 -47 0 -52 13 -25 67 30 59 60 156 55 181 -2 9 -16 -19 -32 -63
-34 -97 -77 -175 -106 -193 -21 -13 -22 -12 -15 17 4 21 3 31 -5 31 -8 0 -7 9
5 26 12 18 17 50 19 105 1 44 -2 79 -6 79 -5 0 -8 -8 -8 -18 0 -27 -9 -32 -36
-20 -29 14 -30 33 -4 75 15 26 29 34 61 39 36 6 45 13 77 63 31 49 49 70 60
71 1 0 20 -17 40 -37z m228 -5 c19 -21 30 -38 76 -107 23 -33 25 -93 3 -85 -8
4 -15 17 -15 30 0 13 -4 24 -10 24 -5 0 -10 -7 -10 -15 0 -11 -12 -15 -46 -15
l-46 0 7 49 c4 29 3 51 -3 55 -6 3 -13 -16 -17 -46 -9 -73 -38 -168 -43 -144
-2 10 0 44 6 75 13 73 15 127 5 154 -6 20 -8 19 -13 -10 l-5 -31 -50 44 -50
44 96 0 c87 0 97 -2 115 -22z m426 -15 l77 -27 -5 -66 c-3 -36 -7 -78 -10 -95
-5 -35 -45 -68 -77 -62 -20 3 -20 4 8 17 26 12 27 14 10 17 -11 2 -58 -10
-104 -27 -47 -16 -86 -28 -89 -26 -2 2 27 21 64 42 37 21 62 40 55 42 -21 7
-146 -63 -145 -81 1 -10 -4 -13 -14 -9 -12 4 -9 11 17 33 28 23 178 112 206
123 6 2 7 7 3 11 -10 10 -127 -46 -196 -95 -72 -50 -102 -52 -44 -3 24 20 54
53 68 74 13 21 45 50 71 65 59 34 58 33 51 40 -3 3 -23 -2 -43 -10 -48 -20
-45 -21 -45 14 0 22 6 33 23 40 33 13 35 13 119 -17z m-1108 -80 l51 6 30 -47
c22 -34 44 -53 84 -72 49 -24 80 -57 81 -88 0 -7 -9 -25 -19 -40 -10 -15 -22
-51 -25 -81 -3 -29 -13 -65 -21 -78 -16 -24 -17 -24 -36 -7 -20 18 -20 54 -3
117 3 9 1 17 -4 17 -5 0 -15 -21 -21 -46 -9 -33 -15 -44 -23 -36 -7 7 -7 32 2
82 7 39 9 74 6 77 -8 9 -24 -40 -32 -96 -6 -48 -8 -50 -33 -45 -56 12 -54 8
-48 107 3 50 9 102 13 115 3 12 2 22 -4 22 -15 0 -34 -86 -37 -164 -2 -63 -5
-71 -26 -78 -13 -5 -32 -16 -41 -25 -17 -15 -18 -13 -18 33 0 31 -3 44 -9 36
-5 -9 -10 0 -15 28 -4 23 -18 69 -32 103 -29 67 -27 108 6 150 25 31 56 38 94
19 20 -10 46 -12 80 -9z m-461 -102 c48 -44 277 -298 365 -404 56 -68 123
-192 156 -289 30 -88 32 -113 10 -122 -27 -10 -34 -7 -67 28 -22 24 -50 40
-92 52 -46 13 -69 27 -98 58 -20 23 -51 55 -67 72 -33 33 -36 51 -18 136 11
51 10 60 -5 75 -14 15 -22 15 -71 3 -55 -13 -56 -13 -97 16 -42 29 -69 74 -69
115 0 13 -24 45 -60 81 -46 46 -60 67 -60 89 0 39 20 86 54 126 33 39 42 36
119 -36z m1172 -1 c15 -6 56 -35 91 -65 37 -31 75 -55 87 -55 13 0 47 8 77 19
49 17 64 17 140 7 l85 -11 -57 -39 c-55 -37 -61 -38 -113 -32 -42 5 -69 1
-117 -15 -85 -28 -112 -27 -161 8 -31 23 -46 43 -57 78 -9 26 -26 56 -39 66
-45 37 -1 64 64 39z m1093 -40 c45 -12 61 -41 17 -32 -14 2 -25 0 -25 -7 0
-13 138 -56 245 -76 89 -17 98 -18 91 -6 -3 5 -58 21 -121 36 -64 15 -114 29
-112 31 2 3 32 -2 66 -10 33 -8 61 -10 61 -5 0 7 -56 27 -132 44 -15 4 -29 14
-32 22 -5 13 0 14 31 8 21 -4 41 -11 44 -16 24 -39 298 -65 413 -39 27 6 51 8
55 4 3 -3 -14 -24 -39 -46 -25 -22 -44 -46 -42 -54 1 -7 -21 -32 -50 -55 -29
-23 -56 -50 -60 -61 -4 -10 -33 -31 -64 -45 -50 -23 -65 -25 -128 -20 -39 4
-91 13 -116 21 -39 13 -40 15 -10 10 19 -3 43 -8 53 -10 9 -3 17 -1 17 4 0 9
-131 52 -158 52 -7 0 -11 4 -7 10 3 5 -16 21 -42 35 -26 14 -39 23 -28 20 45
-15 169 -45 184 -45 32 0 -6 19 -79 38 -86 24 -200 74 -182 80 7 2 19 -1 26
-7 22 -18 183 -70 269 -86 76 -14 186 -21 175 -10 -3 3 -58 14 -122 25 -64 11
-142 29 -173 39 -135 47 -168 60 -171 71 -3 7 12 3 34 -8 58 -29 152 -59 209
-67 69 -10 48 9 -34 30 -66 17 -205 75 -197 82 2 3 44 -11 93 -31 78 -31 187
-58 173 -43 -3 3 -41 19 -85 35 -69 25 -195 85 -140 66 11 -3 29 -8 40 -11 19
-4 19 -3 -2 15 -22 19 -22 34 0 27 7 -2 31 -9 55 -15z m-1182 -85 c-15 -26
-25 -49 -22 -52 8 -8 34 16 41 38 7 22 25 26 25 5 0 -7 19 -31 43 -53 l43 -40
-40 -61 c-53 -79 -78 -103 -36 -35 36 61 36 97 -1 41 -25 -39 -36 -43 -53 -16
-7 12 -4 30 14 67 26 57 21 80 -8 32 l-19 -31 -11 25 c-6 14 -19 25 -28 25
-20 0 -16 12 21 77 26 44 32 50 44 38 12 -11 10 -20 -13 -60z m778 -54 l66
-30 -7 -98 c-6 -105 -16 -134 -58 -173 -20 -19 -42 -27 -93 -32 -37 -4 -88
-10 -113 -14 -26 -3 -56 -1 -66 4 -29 16 -45 80 -34 136 10 54 29 79 74 97 53
21 96 54 128 98 17 22 32 41 34 41 2 0 33 -13 69 -29z m-218 -16 c14 -14 24
-31 21 -38 -2 -7 -24 -22 -49 -34 -51 -24 -78 -56 -78 -90 0 -21 -4 -23 -57
-23 -32 0 -75 -4 -95 -9 -35 -8 -37 -8 -27 11 7 14 7 23 -1 33 -9 11 1 19 52
42 35 16 79 37 98 46 55 25 8 20 -67 -8 -49 -18 -61 -20 -43 -7 13 9 39 29 56
45 23 19 42 27 71 27 22 0 48 7 59 15 26 20 31 19 60 -10z m-603 -50 c5 -22
15 -53 23 -68 17 -35 18 -61 2 -74 -18 -14 -71 -23 -96 -16 l-21 5 20 40 c28
54 17 59 -17 8 -25 -37 -57 -60 -85 -60 -5 0 0 18 11 41 37 71 17 79 -21 9
-29 -53 -41 -66 -64 -68 -24 -3 -27 -1 -20 15 4 10 10 29 12 43 l5 25 -19 -28
c-19 -26 -20 -26 -36 -10 -12 14 -13 23 -5 36 14 25 0 36 -17 14 -9 -13 -20
-17 -34 -12 -25 8 -26 14 -6 42 29 42 76 60 165 66 47 3 103 12 125 21 57 21
69 17 78 -29z m-1557 11 c1 0 5 -11 8 -23 7 -20 4 -23 -23 -23 -16 0 -33 -4
-36 -10 -4 -6 10 -10 34 -10 32 0 41 -4 46 -21 9 -26 -8 -39 -53 -39 -47 0
-80 26 -108 84 -13 27 -24 50 -24 51 0 1 155 -7 156 -9z m444 -52 c0 -6 -18
-23 -40 -38 -54 -36 -51 -53 3 -21 l44 25 10 -23 c9 -19 8 -26 -6 -35 -13 -10
-11 -11 15 -7 21 4 35 1 44 -10 11 -13 8 -18 -14 -36 -34 -26 -27 -35 11 -16
32 16 125 29 117 16 -7 -12 3 -11 27 1 37 20 19 2 -23 -23 -50 -29 -145 -123
-135 -133 3 -4 31 16 61 43 30 28 71 60 91 72 l37 22 -6 -27 c-4 -14 -10 -39
-13 -55 -3 -15 -19 -40 -35 -54 -36 -32 -29 -47 9 -19 l27 21 41 -41 40 -40
-34 -10 c-29 -8 -41 -6 -88 18 -29 16 -81 39 -116 51 -61 22 -63 23 -102 97
-22 42 -50 88 -63 104 -36 44 -20 86 48 129 34 22 49 18 50 -11z m576 26 c15
-6 45 -26 65 -45 21 -19 61 -46 88 -59 27 -14 59 -39 71 -55 14 -21 33 -32 58
-37 36 -7 112 -40 112 -49 0 -10 -61 -35 -85 -35 -32 0 -83 26 -112 56 -19 21
-30 23 -90 21 l-68 -2 -57 55 c-37 34 -58 63 -58 76 0 13 -11 32 -25 43 -24
18 -25 20 -8 30 23 13 74 14 109 1z m1544 -30 c22 -11 40 -27 40 -35 0 -8 -16
-40 -36 -70 -42 -66 -64 -125 -64 -171 0 -58 -15 -78 -81 -114 -81 -43 -148
-106 -199 -185 -43 -70 -61 -86 -69 -64 -2 8 -33 44 -69 82 -36 37 -61 67 -55
67 5 0 18 -9 29 -21 22 -25 54 -43 54 -31 0 4 -14 23 -32 41 l-31 32 25 25 25
25 50 -45 c28 -25 54 -46 57 -46 16 0 2 21 -44 66 l-50 50 15 37 c8 20 15 46
15 57 0 28 17 25 106 -19 43 -22 81 -38 83 -35 8 8 -13 23 -78 58 -64 34 -64
34 -33 40 23 6 45 0 92 -24 87 -44 89 -30 4 28 -38 25 29 1 93 -34 34 -19 64
-34 68 -34 15 0 -20 32 -62 57 l-44 26 29 31 c40 42 52 68 52 112 l0 37 45 -6
c54 -8 60 7 8 20 -44 10 -54 35 -21 51 32 15 34 15 78 -8z m-1658 -27 c10 -9
18 -26 18 -38 0 -16 22 -41 67 -80 l67 -57 59 5 c53 5 61 3 90 -22 46 -41 71
-51 123 -53 45 -2 46 -3 40 -31 -3 -16 -6 -51 -6 -77 0 -45 -2 -49 -32 -61
-18 -7 -37 -15 -41 -17 -5 -2 -6 33 -3 79 5 65 4 78 -4 59 -6 -14 -14 -54 -17
-90 -5 -49 -13 -74 -34 -103 -15 -21 -30 -44 -32 -50 -3 -9 -8 -8 -20 2 -13
10 -17 31 -18 90 -1 90 -17 70 -18 -23 -1 -51 -3 -57 -16 -46 -8 7 -15 29 -15
49 0 53 -19 77 -21 26 l-1 -40 -10 45 c-5 25 -7 82 -4 128 6 93 -8 112 -19 25
l-7 -58 -19 50 c-10 28 -19 60 -19 73 0 12 -5 21 -12 20 -8 -2 -14 11 -16 35
-3 30 -6 36 -16 28 -9 -8 -20 0 -44 32 -17 23 -41 55 -53 70 -18 24 -20 30 -8
38 19 12 20 12 41 -8z m1823 -27 c44 -19 94 -38 110 -41 29 -6 29 -6 10 10
l-20 17 20 -6 c59 -16 115 -26 160 -26 55 -1 46 -13 -17 -24 -20 -4 -39 -13
-42 -21 -9 -25 -69 -17 -173 21 -54 20 -111 38 -128 41 -28 4 -29 3 -11 -11
18 -15 138 -60 189 -71 48 -10 29 -25 -34 -25 -45 0 -79 8 -137 32 -42 18 -69
34 -60 36 9 2 15 9 13 15 -6 17 22 50 37 44 7 -3 30 -12 51 -21 55 -24 45 -1
-12 29 -45 22 -86 58 -50 42 8 -3 50 -21 94 -41z m-2852 0 l24 -24 -44 -12
c-50 -14 -73 -37 -27 -26 41 9 218 25 223 20 2 -2 -10 -7 -28 -10 -49 -10 -52
-28 -3 -21 47 6 68 -9 25 -19 -62 -13 -137 -41 -140 -51 -3 -7 18 -5 54 7 32
9 76 20 99 23 36 6 44 3 69 -23 22 -23 25 -30 12 -30 -47 0 -209 -81 -193 -97
2 -3 37 11 78 31 40 20 89 39 109 42 33 6 36 4 46 -29 l11 -35 -43 -17 c-72
-27 -216 -135 -180 -135 2 0 28 17 58 38 29 20 81 50 115 66 l62 30 22 -21
c13 -11 37 -28 55 -37 17 -9 39 -26 47 -37 14 -21 13 -23 -22 -49 -47 -35 -79
-70 -63 -70 7 0 33 16 58 35 25 19 49 35 53 35 7 0 34 -80 28 -84 -2 -2 -32
-20 -68 -42 -89 -53 -174 -120 -190 -149 -8 -14 -11 -40 -7 -64 8 -62 -15 -57
-123 25 -30 23 -71 47 -92 53 -69 22 -84 49 -92 157 -9 116 -23 157 -82 241
-50 73 -50 76 -10 168 17 38 36 61 63 78 21 13 44 32 52 41 17 20 16 21 44 -8z
m1965 -14 c9 -6 1 -24 -29 -68 -63 -89 -77 -101 -121 -108 -23 -4 -63 -16 -90
-28 -59 -25 -120 -17 -126 17 -5 26 -39 63 -75 82 -16 8 -26 18 -22 21 4 4 52
10 108 13 84 5 114 11 172 36 90 40 155 52 183 35z m202 -69 c0 -22 -11 -36
-54 -65 -65 -45 -171 -75 -180 -51 -13 33 -5 40 62 50 l67 10 -65 2 c-64 2
-64 2 -35 16 23 10 25 14 10 14 -17 1 -17 3 -5 15 17 17 93 33 158 35 39 1 42
-1 42 -26z m56 -119 c23 -6 25 -10 19 -42 -10 -55 -39 -103 -92 -153 -61 -58
-101 -86 -139 -95 -16 -4 -56 -22 -90 -40 -75 -42 -106 -43 -149 -6 -27 24
-44 30 -108 36 -72 6 -77 5 -123 -24 -26 -16 -59 -30 -72 -30 -30 0 -111 20
-117 30 -2 3 7 22 20 42 30 44 95 76 259 128 71 22 161 56 200 75 39 19 104
42 145 50 82 17 122 34 166 69 l30 25 12 -29 c8 -18 23 -32 39 -36z m780 -147
c-40 -52 -31 -66 14 -22 38 37 42 38 120 43 74 4 81 3 78 -13 -3 -16 6 -18 68
-20 38 -1 75 -4 81 -8 7 -4 -2 -20 -23 -41 -32 -32 -43 -56 -26 -56 5 0 21 14
37 30 30 32 65 37 148 24 31 -6 36 -10 32 -25 -5 -16 -1 -19 23 -19 16 0 54
-5 85 -11 49 -10 55 -13 40 -24 -9 -7 -22 -11 -28 -9 -13 5 -68 -34 -96 -69
-11 -15 -28 -27 -37 -27 -12 0 -8 8 13 30 16 17 25 30 19 30 -5 0 7 14 26 30
47 40 17 39 -43 -1 -52 -34 -66 -51 -49 -57 22 -7 1 -22 -30 -22 l-31 0 23 25
c16 18 19 25 9 25 -8 1 1 14 20 30 42 36 33 53 -10 21 -17 -13 -42 -24 -55
-26 -14 -1 -32 -9 -42 -16 -27 -21 28 32 67 64 18 15 31 30 28 33 -9 10 -50
-18 -112 -75 -33 -31 -47 -41 -31 -23 16 17 26 35 22 39 -5 5 -32 -18 -61 -49
-70 -78 -105 -79 -39 -3 66 78 44 83 -27 6 -64 -69 -77 -76 -29 -16 50 62 33
64 -25 3 -32 -34 -63 -58 -75 -58 -24 0 -26 13 -5 30 10 9 15 9 15 1 0 -19 17
-12 35 15 21 32 12 43 -15 19 -20 -19 -21 -18 -12 6 5 13 36 47 70 74 33 28
61 53 61 58 2 20 -34 2 -88 -43 -34 -28 -61 -45 -61 -38 0 6 -15 -1 -32 -18
-23 -20 -21 -16 7 14 22 24 61 57 87 75 26 18 48 35 48 40 1 27 -79 -22 -151
-94 -22 -22 -44 -37 -49 -34 -4 3 -18 -9 -30 -26 -16 -21 -26 -27 -33 -20 -7
7 8 32 47 81 32 39 55 74 52 77 -3 4 -27 -16 -53 -43 -26 -27 -51 -48 -54 -47
-12 4 -69 -67 -91 -112 -14 -29 -29 -45 -45 -48 -24 -4 -29 8 -13 33 6 10 10
11 10 3 0 -26 20 -12 41 29 13 26 17 44 11 46 -6 2 -8 11 -5 20 10 29 -54 -38
-68 -69 -6 -17 -14 -27 -17 -24 -3 2 -12 -1 -20 -8 -27 -21 49 89 123 181 18
21 22 32 13 32 -25 0 -108 -101 -160 -194 -25 -47 -27 -48 -24 -20 3 34 23 65
91 146 25 28 43 54 40 56 -9 9 -53 -28 -90 -77 -22 -28 -42 -46 -45 -41 -8 13
-42 -23 -74 -80 -14 -25 -26 -39 -26 -32 0 7 -5 10 -11 6 -14 -8 15 49 53 104
17 24 27 46 23 50 -12 12 -62 -57 -105 -142 -22 -45 -40 -75 -40 -68 0 16 56
127 82 165 28 39 12 49 -18 12 -13 -16 -24 -24 -24 -16 0 15 119 135 127 128
2 -3 -1 -15 -7 -27 -19 -36 -3 -35 33 4 31 33 126 86 155 86 6 0 12 -5 12 -11
0 -12 10 -10 75 15 22 8 53 15 68 16 l28 0 -25 -33z m-2100 -18 c12 -11 53
-32 91 -46 40 -14 79 -36 95 -54 22 -24 35 -29 72 -29 28 0 73 -12 123 -34 43
-18 103 -39 133 -45 115 -24 210 -66 210 -91 0 -5 -17 -18 -38 -29 -20 -10
-62 -38 -92 -60 -42 -32 -63 -41 -93 -41 -43 0 -183 52 -288 106 -74 39 -169
124 -169 152 0 24 -35 56 -83 76 -39 16 -62 54 -52 87 5 15 19 22 59 28 6 0
20 -8 32 -20z m1561 -111 c55 -60 55 -96 4 -191 -22 -40 -54 -116 -72 -171
-46 -145 -109 -206 -209 -206 -86 0 -156 -52 -236 -177 -53 -82 -85 -113 -115
-113 -9 0 -54 25 -99 55 -45 30 -93 55 -106 55 -12 0 -53 -7 -91 -16 -95 -22
-142 -15 -202 31 l-49 38 41 55 c37 49 42 61 45 121 2 36 0 91 -5 121 l-9 55
57 0 56 1 27 -83 c15 -46 31 -83 36 -83 9 0 -3 61 -29 136 -16 50 -13 74 10
74 19 0 129 -324 129 -380 0 -10 5 -22 11 -26 25 -15 -30 227 -76 337 -39 94
-40 91 13 124 79 50 66 95 -40 136 l-37 14 34 22 34 22 35 -54 c55 -82 98
-179 136 -302 35 -116 59 -147 35 -45 -35 147 -82 261 -148 360 -20 28 -34 52
-32 52 19 0 40 -21 60 -60 18 -36 32 -50 52 -54 20 -5 31 -16 39 -39 27 -76
80 -197 87 -197 14 0 -6 61 -70 207 -4 9 3 10 29 3 18 -6 49 -10 68 -10 32 0
39 -5 67 -52 18 -29 49 -89 69 -133 20 -44 39 -75 41 -68 6 16 -49 151 -86
209 -27 44 -28 50 -13 56 28 11 31 10 59 -34 29 -48 44 -38 18 12 -24 47 -10
55 93 55 l87 0 37 -70 c21 -38 39 -74 41 -80 2 -5 7 -7 11 -3 4 3 -6 37 -22
76 l-29 69 36 9 c25 6 43 20 60 47 l25 38 43 -43 c52 -51 57 -33 8 26 l-35 43
21 27 c25 32 30 32 56 4z m-260 -53 l68 -65 -91 6 c-80 6 -101 4 -184 -21 -51
-15 -109 -27 -130 -27 -30 1 -146 34 -155 44 -1 2 -10 18 -19 36 -16 31 -16
32 3 32 11 0 37 -15 57 -32 48 -42 100 -43 169 -5 58 33 63 33 77 2 6 -14 15
-25 20 -25 8 0 5 19 -9 57 -3 6 10 14 28 18 29 6 36 3 58 -24 35 -42 48 -39
21 5 -22 39 -21 64 5 64 8 0 45 -29 82 -65z m-1698 -73 c-54 -60 -129 -175
-129 -199 0 -26 16 -11 50 49 19 34 61 90 94 125 45 49 65 63 87 63 l29 0 -50
-62 c-73 -90 -61 -103 17 -18 65 70 68 72 106 67 21 -3 41 -7 43 -10 3 -2 -5
-21 -17 -41 -27 -45 -13 -47 23 -2 18 22 39 36 62 40 20 4 49 18 65 31 62 53
56 12 -11 -69 -58 -70 -94 -126 -81 -126 4 0 39 36 76 81 56 65 71 78 83 69
11 -10 3 -24 -52 -84 -71 -78 -140 -173 -132 -181 3 -3 25 18 48 47 24 29 73
83 109 120 61 63 66 66 83 51 17 -15 17 -17 -3 -44 -12 -17 -26 -26 -34 -23
-45 18 -209 -182 -270 -328 -23 -57 1 -46 31 15 40 79 98 160 166 232 68 73
90 84 54 27 -38 -60 -77 -143 -74 -154 2 -5 23 23 47 64 24 40 61 97 83 126
l39 52 39 -20 39 -20 -29 -51 c-52 -87 -120 -272 -106 -287 8 -7 8 -9 49 102
21 55 53 128 72 163 33 58 37 62 60 53 22 -8 86 -39 92 -45 2 -1 -6 -38 -18
-83 -12 -47 -19 -103 -17 -134 l3 -53 8 40 c18 95 47 209 55 217 41 41 75
-188 39 -259 -9 -16 -35 -51 -59 -79 -24 -27 -59 -69 -78 -94 -52 -69 -55 -71
-107 -53 -33 11 -65 14 -115 10 -77 -7 -68 -11 -147 79 -10 13 -40 26 -70 33
-70 15 -105 34 -136 75 l-26 34 20 41 c12 22 21 49 21 60 0 27 -37 51 -80 51
-27 0 -41 7 -60 30 -26 31 -42 35 -138 30 -53 -3 -76 16 -90 72 -8 31 -8 47 1
64 16 29 103 98 191 151 61 36 73 40 85 28 12 -12 6 -22 -40 -73z m3116 58
c-38 -39 -12 -39 35 0 38 33 79 38 139 16 29 -10 31 -13 17 -25 -27 -22 -18
-28 32 -24 31 3 53 0 66 -10 19 -14 18 -16 -23 -36 -28 -15 -41 -27 -37 -36 5
-15 -38 -56 -86 -81 -16 -8 -33 -26 -38 -39 -5 -13 -29 -34 -52 -46 -40 -21
-48 -22 -128 -11 -47 5 -128 24 -181 41 -106 34 -109 35 -109 22 0 -24 242
-90 335 -91 l50 -1 -38 -35 c-21 -19 -35 -39 -32 -44 8 -13 -76 -70 -104 -70
-56 0 -232 54 -330 100 -112 54 -111 54 -111 42 0 -4 28 -24 63 -44 69 -41
208 -90 307 -109 36 -7 69 -16 73 -20 13 -11 -76 -59 -109 -59 -28 0 -170 31
-206 45 -10 4 -18 3 -18 -3 0 -15 69 -40 147 -53 37 -7 72 -15 76 -19 5 -4
-15 -16 -44 -26 -29 -11 -54 -26 -56 -35 -5 -20 -76 -49 -116 -49 -15 0 -75
15 -133 34 -76 25 -104 30 -100 20 7 -17 6 -17 -37 1 -43 18 -47 18 -47 5 0
-17 91 -52 140 -55 25 -1 71 -8 104 -15 67 -16 55 -28 -26 -29 -36 -1 -48 -5
-48 -16 0 -12 -15 -15 -71 -15 -50 0 -68 -3 -63 -11 8 -13 -79 -3 -108 12 -27
14 -51 11 -44 -6 8 -23 -55 12 -153 85 -46 34 -85 59 -88 56 -3 -3 16 -26 43
-52 52 -49 44 -50 -17 -2 -39 30 -55 35 -61 18 -2 -6 -31 12 -65 39 -38 31
-68 48 -78 45 -9 -4 -15 -1 -15 7 0 7 -10 24 -22 38 -22 24 -22 24 10 -2 35
-28 59 -36 46 -15 -5 7 12 -2 36 -19 62 -47 64 -30 3 27 -45 41 -50 50 -37 60
12 11 13 10 9 -2 -4 -10 15 -35 56 -72 74 -66 113 -77 54 -14 l-40 42 50 -40
c118 -96 189 -128 95 -44 -44 40 -50 48 -30 44 23 -5 23 -3 -10 26 -26 24 -19
21 30 -13 107 -73 269 -147 324 -147 29 0 10 14 -49 35 -91 33 -219 104 -323
181 -57 42 -100 79 -97 82 3 3 11 0 17 -6 7 -7 19 -12 27 -12 11 0 9 6 -7 23
-42 44 -22 37 51 -18 82 -64 245 -149 273 -143 12 2 -12 18 -69 45 -85 41
-108 57 -67 46 38 -10 -3 44 -66 88 -63 44 -114 88 -114 100 0 15 29 17 41 3
10 -12 9 -13 -6 -8 -13 5 -16 3 -13 -7 10 -31 270 -200 285 -185 8 8 11 5 -69
56 -38 23 -68 44 -68 46 0 3 8 0 18 -5 10 -5 24 -9 32 -8 8 1 53 -18 98 -41
46 -24 91 -42 100 -40 10 2 -21 24 -72 51 -112 60 -213 131 -213 151 0 11 10
8 36 -13 98 -76 371 -214 389 -196 7 7 12 4 -118 67 -63 31 -124 64 -135 73
-20 16 -19 16 3 5 13 -6 31 -10 40 -8 11 3 0 13 -34 31 -28 14 -54 35 -57 45
-4 17 3 15 52 -14 61 -37 93 -45 71 -19 -7 8 -37 29 -66 46 -63 35 -72 49 -14
19 48 -24 57 -25 39 -3 -13 15 -12 15 9 4 40 -21 47 -6 8 18 -20 13 -35 26
-31 29 3 3 -5 17 -17 31 -17 19 -14 18 12 -5 67 -59 205 -142 218 -130 7 7 14
1 -88 69 -47 30 -96 65 -110 76 -26 22 -12 28 22 10 23 -12 40 -2 18 11 -8 5
-12 11 -10 14 3 2 48 -23 100 -56 138 -87 330 -181 455 -222 59 -20 115 -36
123 -36 32 0 -4 19 -93 50 -152 54 -424 184 -411 197 2 3 14 0 25 -7 14 -7 21
-7 21 0 0 6 12 4 30 -5 49 -25 80 -35 80 -25 0 4 -45 32 -100 62 -107 56 -124
80 -20 28 65 -33 109 -36 52 -4 -70 40 -71 41 -19 49 42 7 54 3 124 -33 94
-49 189 -85 206 -79 6 3 -6 13 -28 22 -111 48 -189 89 -173 93 9 2 50 -12 90
-32 86 -42 112 -37 36 8 l-53 31 41 3 c31 2 51 -3 79 -22 21 -15 42 -26 48
-26 6 0 8 -3 5 -6 -3 -3 -29 6 -57 20 -56 29 -61 30 -61 16 0 -21 323 -160
338 -146 8 9 5 10 -103 56 -107 45 -126 56 -82 45 41 -11 33 1 -24 32 -28 15
-46 29 -40 31 6 2 18 -4 27 -12 17 -18 44 -21 44 -6 0 6 -5 10 -12 10 -6 0 -9
3 -6 6 3 3 37 -11 74 -30 75 -38 82 -50 9 -14 -42 21 -64 21 -51 0 6 -11 163
-75 209 -86 40 -9 33 9 -10 27 l-38 16 45 -5 45 -4 -39 18 c-21 9 -69 31 -105
49 l-66 32 38 0 c26 1 37 -3 37 -13 0 -15 78 -49 153 -66 104 -24 84 -2 -32
35 -50 16 -89 31 -86 33 2 2 33 -6 69 -18 80 -26 136 -24 66 3 l-45 17 48 -4
c26 -2 47 0 47 5 0 5 -9 9 -19 9 -11 0 -23 5 -26 10 -3 6 -2 10 3 10 5 0 12 8
16 18 3 9 9 22 12 29 11 23 -60 11 -104 -18 -30 -20 -55 -29 -85 -29 l-41 0
39 40 c21 22 41 40 44 40 3 0 -8 -13 -24 -30z m-4376 -440 c52 -24 75 -26 67
-5 -6 17 21 13 84 -13 56 -22 67 -38 100 -138 18 -52 34 -94 37 -94 3 0 3 19
0 43 -5 41 -5 41 10 -10 16 -52 33 -70 33 -35 0 10 -10 47 -22 83 -17 48 -23
58 -26 40 -3 -19 -8 -13 -28 34 -13 33 -21 61 -18 64 3 3 33 -9 66 -28 45 -24
62 -30 68 -21 10 15 103 -33 129 -66 10 -12 31 -70 46 -128 28 -101 45 -133
45 -83 0 12 -7 45 -15 72 -9 28 -22 71 -30 98 l-14 47 44 -37 c24 -20 47 -43
49 -50 3 -7 14 -13 24 -13 35 0 48 -30 41 -95 -3 -33 -6 -45 -7 -27 -1 17 -7
32 -12 32 -7 0 -9 -18 -5 -50 3 -27 2 -50 -3 -50 -5 0 -12 29 -16 64 -7 71
-23 126 -37 126 -11 0 -12 9 5 -80 8 -41 17 -120 20 -175 l6 -100 -16 75 c-17
82 -33 113 -35 65 0 -20 -6 -10 -16 33 -8 34 -19 62 -24 62 -13 0 -12 -14 10
-113 10 -48 15 -85 11 -82 -5 3 -11 -3 -13 -12 -3 -10 -5 -2 -6 18 -1 39 -51
244 -71 294 -25 62 -21 1 8 -112 17 -65 33 -138 36 -163 l5 -45 -10 40 c-5 22
-15 59 -21 83 -6 23 -15 42 -20 42 -6 0 -7 -7 -4 -16 3 -9 9 -34 12 -57 l6
-42 -30 35 c-35 40 -55 35 -23 -6 12 -15 19 -29 17 -32 -3 -2 -35 1 -72 7 -36
7 -69 10 -72 7 -9 -10 52 -28 110 -33 34 -3 63 -12 70 -21 11 -13 7 -14 -40
-10 -50 5 -71 -5 -34 -16 23 -7 -71 2 -105 10 -36 9 -22 -7 20 -23 18 -6 24
-12 15 -12 -15 -1 -169 46 -215 65 -7 3 -13 1 -13 -4 0 -27 205 -91 292 -92
20 0 38 -5 40 -11 2 -6 22 -15 43 -20 35 -7 33 -8 -14 -3 -29 3 -105 18 -168
35 -132 34 -126 33 -121 17 3 -7 40 -22 84 -35 56 -16 66 -21 34 -17 -25 3
-90 21 -145 40 -60 19 -104 29 -109 24 -22 -22 285 -108 346 -98 20 4 19 6
-12 16 l-35 12 34 -5 c18 -3 40 -5 48 -5 9 0 13 -6 10 -14 -4 -9 3 -16 21 -19
33 -6 52 4 26 14 -13 5 -3 8 31 9 28 1 44 4 38 7 -18 7 -16 23 2 23 8 0 19 -9
25 -20 9 -18 8 -20 -15 -20 -15 0 -24 -4 -20 -10 3 -5 1 -10 -6 -10 -27 0 7
-19 41 -23 19 -3 -10 -3 -65 -1 -55 2 -110 7 -122 10 -13 4 -23 2 -23 -3 0 -9
45 -18 170 -37 14 -2 5 -4 -20 -4 -40 -2 -149 17 -201 33 -11 4 -19 2 -19 -4
0 -6 21 -17 48 -25 28 -9 38 -15 25 -15 -37 -1 -26 -20 15 -26 40 -7 45 -9 82
-30 14 -9 51 -15 88 -15 36 0 62 -4 62 -10 0 -7 -13 -9 -32 -6 -18 3 -62 8
-98 11 -56 5 -109 15 -217 40 -13 3 -23 2 -23 -3 0 -19 142 -52 295 -68 39 -4
60 -10 48 -12 -13 -2 -23 -7 -23 -12 0 -14 77 -22 99 -11 25 14 88 14 96 1 4
-6 -14 -10 -46 -10 -61 0 -57 -16 6 -23 22 -3 -16 -3 -85 -1 -84 3 -120 1
-110 -6 8 -5 29 -10 45 -10 17 0 44 -7 60 -16 20 -12 57 -17 112 -18 46 -1 83
-4 83 -8 0 -12 -182 -9 -200 2 -13 9 -13 10 0 11 25 2 -32 17 -68 18 -20 1
-31 -3 -27 -9 3 -5 21 -11 38 -11 30 -2 31 -2 5 -6 -16 -2 -28 -8 -28 -12 0
-12 90 -21 209 -21 107 0 129 -5 53 -13 -81 -8 -53 -24 33 -19 23 2 26 0 15
-8 -8 -5 -23 -10 -32 -10 -30 0 -20 -20 10 -20 25 -1 25 -2 8 -12 -13 -7 -63
-9 -158 -5 -76 4 -138 3 -138 -2 0 -14 101 -23 235 -20 69 1 128 -1 132 -4 3
-4 -3 -7 -15 -7 -23 0 -31 -19 -9 -21 28 -3 -52 -17 -102 -18 -41 -1 -52 -4
-44 -12 7 -7 36 -10 77 -7 35 2 62 0 60 -4 -3 -4 5 -8 18 -9 13 0 -15 -11 -61
-25 -84 -25 -121 -24 -121 2 0 10 -14 14 -52 15 -29 0 -71 6 -93 14 l-40 13
56 1 c64 1 119 10 119 20 0 3 -34 4 -76 3 -42 -2 -98 1 -123 8 -64 16 -191 96
-191 121 0 10 -5 19 -11 19 -20 0 -128 111 -122 126 3 8 -11 32 -30 54 -32 34
-77 108 -77 125 0 3 8 1 18 -4 18 -10 112 -34 170 -45 64 -11 30 11 -50 33
-130 36 -143 44 -177 102 -16 29 -28 56 -26 60 3 4 -4 42 -15 85 -11 42 -18
79 -17 81 2 2 29 -8 60 -22 97 -43 276 -87 246 -61 -2 2 -53 20 -114 40 -139
46 -170 64 -179 106 -8 41 -8 100 0 100 4 0 38 -11 77 -25 38 -14 75 -24 81
-22 10 4 -97 57 -114 57 -14 0 -40 37 -55 80 -12 35 -13 45 -3 52 10 6 8 17
-10 48 -13 23 -30 59 -37 81 -17 46 -11 48 54 19z m2051 -230 c25 -19 25 -19
5 -33 -44 -32 -244 -203 -304 -260 -35 -34 -62 -55 -59 -47 3 8 24 70 47 136
l43 122 47 -16 c68 -21 90 -12 141 58 24 33 46 60 49 60 3 0 17 -9 31 -20z
m-1103 -106 c39 -23 113 -108 143 -164 18 -34 40 -119 40 -156 0 -16 -7 -39
-15 -50 -14 -18 -14 -18 -8 3 3 13 2 44 -3 70 l-8 48 -7 -69 c-8 -76 -29 -114
-30 -55 0 35 -4 31 -24 -26 -5 -14 -11 0 -20 51 -11 65 -48 142 -75 159 -17
10 -12 -10 16 -66 19 -36 29 -75 32 -122 l5 -69 -49 7 c-70 10 -134 45 -172
95 -31 41 -34 49 -30 102 5 63 -2 73 -20 33 -45 -99 52 -227 187 -250 30 -5
39 -10 30 -16 -42 -26 -158 11 -211 67 -60 64 -75 175 -38 280 20 59 64 124
82 124 6 0 32 -22 57 -50 25 -27 50 -50 54 -50 12 0 -11 39 -51 89 -33 41 -34
44 -17 57 20 15 54 4 132 -42z m1069 -288 c32 -13 85 -29 117 -36 31 -7 57
-16 57 -20 0 -12 -116 -70 -140 -70 -12 0 -60 10 -107 22 -81 22 -86 22 -146
6 -34 -9 -71 -19 -82 -23 -11 -4 -5 3 13 15 39 27 41 34 5 26 l-28 -7 30 25
c51 42 145 86 185 86 20 0 63 -11 96 -24z m-871 -167 c52 -46 68 -83 60 -135
l-6 -43 -39 28 c-22 16 -60 34 -85 40 -53 13 -81 47 -71 86 4 14 13 28 21 31
19 8 71 -33 112 -88 33 -45 53 -60 53 -39 0 17 -67 96 -79 94 -6 -2 -11 3 -11
11 0 8 -10 22 -22 30 l-22 16 26 0 c16 0 42 -13 63 -31z m-81 -156 c26 -51 84
-241 111 -360 14 -65 32 -143 40 -173 27 -113 26 -116 -12 -45 -35 63 -110
177 -165 247 l-21 27 21 79 c26 93 27 113 7 167 -26 74 -12 117 19 58z"/>
<path d="M4416 4853 c-9 -34 -15 -79 -21 -158 -7 -83 -25 -129 -25 -62 0 20
-4 37 -10 37 -5 0 -10 -26 -10 -57 -1 -48 -5 -65 -29 -96 l-29 -38 -7 77 c-4
42 -5 108 -2 146 3 37 2 68 -3 68 -15 0 -23 -109 -17 -220 6 -101 5 -107 -17
-135 -14 -17 -27 -27 -31 -23 -4 4 -9 63 -12 132 -3 69 -10 126 -14 126 -15 0
-14 -184 1 -255 8 -35 6 -44 -18 -75 l-27 -35 -7 45 c-3 25 -7 75 -7 113 -1
43 -5 67 -13 67 -8 0 -9 -35 -5 -130 6 -131 -5 -179 -17 -72 -10 91 -29 101
-23 12 8 -111 23 -134 61 -91 10 11 37 21 69 25 57 7 83 19 74 33 -3 5 17 15
46 22 72 18 197 71 197 83 0 6 -8 7 -17 3 -10 -4 -64 -22 -121 -41 -102 -34
-164 -45 -132 -24 13 9 13 11 1 19 -21 13 1 37 47 52 52 17 169 76 221 112 50
34 40 50 -11 19 -56 -35 -200 -104 -205 -99 -2 3 5 8 17 12 12 4 19 12 17 18
-2 7 10 18 27 26 29 13 29 15 9 18 -19 4 -21 7 -11 22 6 10 19 16 28 14 21 -6
84 24 168 80 57 38 75 57 54 57 -4 0 -29 -14 -56 -30 -69 -44 -166 -92 -166
-82 0 5 17 17 38 26 45 21 92 55 74 56 -7 0 -26 -7 -42 -15 -45 -23 -36 -10
10 16 43 25 54 44 18 34 -13 -4 8 15 47 41 69 47 77 76 9 33 -28 -17 -38 -19
-44 -9 -5 9 -19 -4 -45 -41 l-37 -54 6 108 c6 102 3 128 -8 90z"/>
<path d="M180 2492 c0 -5 34 -38 75 -73 41 -35 75 -65 75 -66 0 -2 -16 4 -36
12 -47 19 -60 19 -43 -2 13 -15 12 -15 -8 -5 -12 7 -29 12 -39 12 -31 0 4 -28
62 -50 60 -22 74 -24 74 -12 0 4 -16 14 -36 21 -19 7 -34 15 -32 17 10 9 102
-36 130 -64 l32 -31 -50 13 c-27 7 -66 21 -88 30 -44 18 -56 20 -56 7 0 -12
89 -49 152 -63 51 -11 99 -43 119 -80 8 -15 4 -15 -48 3 -72 24 -93 18 -33
-10 72 -33 28 -24 -71 15 -85 33 -117 40 -87 17 47 -35 298 -116 313 -101 9 9
-34 221 -47 233 -7 7 -9 -2 -4 -30 18 -114 19 -128 7 -121 -6 4 -9 11 -6 16 8
12 -5 60 -16 60 -5 0 -9 -7 -9 -17 0 -15 -2 -15 -19 1 -11 10 -32 58 -47 107
-15 49 -31 89 -36 89 -8 0 -3 -35 18 -112 4 -17 -11 -7 -55 37 -34 33 -61 63
-61 68 0 5 -9 22 -21 38 -22 31 -45 40 -35 13 5 -12 1 -12 -22 4 -42 29 -52
34 -52 24z"/>
</g>
</Svg>

  );
};

export default Icon;
