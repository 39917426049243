import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M1010 4493 c19 -15 78 -71 132 -125 53 -54 114 -106 135 -116 21 -9
55 -28 76 -41 21 -13 64 -31 94 -41 l55 -17 -108 -6 c-86 -5 -130 -3 -209 13
-239 45 -276 50 -380 50 -130 -1 -173 -9 -280 -56 -44 -20 -105 -46 -135 -59
-85 -37 -73 -51 40 -46 77 2 109 -1 165 -19 49 -15 108 -23 196 -27 133 -6
186 3 344 57 110 38 245 50 526 48 270 -1 357 -9 238 -21 -123 -13 -572 -138
-714 -199 -38 -17 -67 -21 -122 -19 -42 1 -74 -2 -78 -8 -3 -6 -39 -11 -80
-11 -53 0 -89 -6 -127 -22 -35 -14 -89 -24 -158 -29 -58 -4 -148 -15 -200 -23
-166 -28 -199 -30 -218 -14 -9 8 -29 19 -45 26 -17 7 -27 19 -27 32 0 11 -5
20 -11 20 -22 0 -98 -54 -108 -78 -14 -30 -14 -109 0 -117 6 -4 7 -19 4 -35
-5 -21 -1 -33 16 -49 13 -12 27 -21 32 -21 6 0 2 -9 -8 -20 -13 -14 -16 -29
-11 -57 3 -21 9 -58 12 -83 4 -25 15 -54 26 -66 10 -12 25 -50 32 -85 l13 -64
74 -27 c41 -15 79 -28 85 -28 6 0 19 -19 30 -42 11 -24 34 -53 54 -66 19 -12
56 -43 81 -67 26 -24 77 -58 115 -75 38 -18 86 -47 106 -66 42 -37 57 -41 218
-51 223 -14 237 -12 310 39 72 50 82 72 51 115 -11 15 -23 40 -27 56 -5 23 -3
27 14 27 23 0 82 31 82 43 0 4 -11 18 -24 30 -13 12 -31 48 -40 80 -10 34 -29
71 -47 90 -43 45 -57 74 -69 153 -6 38 -17 92 -25 119 -7 28 -16 91 -19 142
-6 84 -5 93 12 102 11 6 27 28 35 49 13 32 26 44 79 69 35 17 108 43 163 57
55 15 145 40 200 56 169 50 405 95 405 76 0 -4 -6 -22 -14 -41 -16 -37 -16
-37 -131 -55 -162 -25 -283 -61 -340 -99 -80 -55 -159 -146 -175 -204 -7 -26
-10 -51 -6 -54 4 -4 29 16 57 45 53 56 86 76 155 97 47 14 244 130 257 151 7
13 40 24 116 39 l34 7 -27 -47 c-32 -58 -32 -70 -2 -106 19 -22 26 -46 32
-100 7 -69 8 -72 40 -85 31 -13 33 -16 22 -40 -11 -23 -10 -29 8 -43 12 -9 21
-28 20 -41 -1 -20 7 -27 49 -43 40 -14 53 -24 62 -50 37 -106 41 -112 74 -124
19 -7 44 -12 57 -12 19 -1 22 4 22 37 1 33 2 35 14 20 18 -24 20 -23 60 17 20
19 42 35 49 35 15 0 37 41 37 69 0 10 7 25 16 32 9 8 27 30 40 51 17 26 40 44
84 64 71 32 92 55 116 127 21 65 80 130 110 123 14 -4 36 5 62 24 l40 29 164
-19 c204 -23 220 -27 403 -94 83 -30 206 -71 275 -91 69 -21 131 -40 137 -44
19 -10 -305 -13 -359 -3 -28 6 -79 30 -128 61 -129 83 -170 94 -325 87 -152
-8 -232 -23 -214 -40 2 -3 36 -11 74 -19 39 -8 97 -27 130 -43 53 -24 73 -28
173 -29 111 -2 148 -10 103 -22 -48 -12 -178 -83 -231 -125 -83 -67 -169 -170
-192 -230 -11 -29 -27 -61 -35 -70 -15 -17 -14 -18 6 -18 33 0 57 16 80 53 23
38 102 84 191 112 72 23 163 69 220 110 25 18 91 53 147 78 86 39 116 48 203
57 135 13 145 12 80 -10 -112 -37 -206 -82 -238 -113 -17 -17 -41 -55 -52 -84
-20 -55 -27 -153 -11 -153 5 0 20 17 32 37 47 76 77 101 135 113 35 7 69 24
97 46 63 50 136 92 194 111 46 15 54 15 75 1 113 -75 634 -592 739 -734 28
-38 56 -73 62 -79 39 -34 107 -161 107 -200 0 -29 25 -9 28 22 4 38 -33 109
-130 253 -58 85 -119 152 -327 358 -141 140 -285 277 -320 305 -142 116 -141
112 -11 66 63 -22 167 -68 230 -103 86 -47 119 -61 133 -54 9 5 17 15 17 23 0
22 -111 83 -247 135 -70 26 -168 64 -218 83 -49 19 -114 40 -142 47 -29 6 -63
20 -75 29 -13 10 -43 28 -68 40 -87 43 -172 121 -223 206 -80 135 -147 220
-205 261 -31 21 -94 74 -140 117 -103 94 -201 146 -281 150 -60 2 -77 -13 -33
-29 27 -10 63 -58 134 -178 15 -25 52 -64 84 -88 32 -24 92 -75 134 -115 55
-51 104 -85 171 -118 89 -45 185 -112 175 -122 -3 -3 -65 24 -138 60 -73 35
-155 72 -183 81 -68 21 -285 70 -451 101 l-136 25 -107 99 c-64 60 -116 118
-130 144 -13 25 -34 50 -47 55 -13 6 -30 28 -39 48 -14 35 -14 39 5 59 24 26
18 35 -27 35 -42 0 -85 -21 -111 -55 -25 -31 -34 -32 -30 -3 6 41 -29 31 -102
-27 -38 -31 -70 -60 -70 -65 0 -6 -9 -10 -19 -10 -11 0 -34 -12 -51 -26 -30
-26 -30 -27 -13 -46 16 -18 13 -18 -37 4 -30 14 -95 50 -143 82 -104 67 -157
81 -275 73 -107 -6 -122 -20 -56 -47 27 -11 90 -41 139 -66 70 -35 108 -47
170 -54 44 -6 117 -22 162 -38 l83 -27 0 -44 c0 -42 -1 -43 -27 -36 -16 4
-100 9 -188 12 -114 3 -163 8 -170 17 -6 7 -19 16 -31 20 -11 3 -79 63 -150
132 -160 155 -196 177 -332 198 -56 9 -119 16 -140 16 l-37 -1 35 -26z m174
-19 c55 -8 67 -16 140 -93 28 -29 89 -80 136 -112 84 -57 143 -109 124 -109
-87 2 -322 115 -370 178 -15 20 -55 63 -89 96 l-60 58 35 -5 c19 -3 57 -9 84
-13z m1205 -1 c-14 -24 24 -110 56 -125 13 -6 31 -28 41 -50 11 -24 59 -78
118 -135 l101 -95 -58 31 c-31 17 -81 36 -109 42 -32 7 -75 28 -110 52 -42 30
-69 41 -106 45 -46 4 -50 7 -56 34 -3 17 -6 43 -6 59 0 49 88 159 126 159 11
0 12 -4 3 -17z m-146 -54 c3 -12 1 -33 -4 -47 -5 -14 -7 -45 -3 -69 5 -35 3
-43 -9 -43 -9 0 -27 -18 -40 -41 -14 -22 -39 -49 -56 -60 -64 -39 -89 -124
-56 -189 18 -36 13 -37 -31 -9 -39 24 -43 45 -14 82 56 71 67 175 24 226 -14
17 -23 35 -19 41 9 15 41 12 55 -5 20 -24 33 -18 27 15 -6 26 -1 33 51 74 31
24 60 45 63 45 4 1 9 -8 12 -20z m831 -9 c58 -22 123 -78 195 -167 23 -29 88
-90 143 -135 88 -73 112 -100 180 -203 4 -6 -37 11 -90 38 -69 35 -117 68
-168 117 -39 37 -96 86 -127 109 -58 43 -93 83 -122 141 -10 19 -31 51 -46 71
-44 56 -43 58 35 29z m-1359 -32 c100 -38 114 -44 145 -68 l25 -20 -41 0 c-22
0 -63 7 -90 15 -65 19 -225 101 -216 110 12 11 93 -5 177 -37z m595 -163 c43
-6 95 -31 135 -66 11 -9 43 -22 70 -28 54 -11 249 -104 240 -113 -3 -3 9 -18
27 -33 l33 -28 -30 7 c-16 5 -73 10 -125 12 -52 3 -114 12 -138 21 -59 22 -66
8 -7 -18 36 -16 68 -21 133 -21 46 0 101 -6 121 -13 20 -8 30 -14 23 -14 -6
-1 -11 -7 -10 -13 2 -7 -8 -14 -22 -16 -34 -5 -51 -22 -21 -22 12 0 19 -4 16
-10 -3 -5 1 -10 9 -10 29 0 17 -16 -59 -77 -60 -49 -87 -64 -142 -78 -37 -9
-113 -30 -168 -47 -84 -25 -111 -29 -166 -25 -59 5 -70 3 -103 -19 -23 -16
-36 -20 -36 -12 0 26 52 122 85 160 40 44 43 61 15 103 -11 17 -20 38 -20 48
0 10 -21 40 -46 67 -40 43 -45 53 -39 79 8 38 39 77 76 96 16 9 36 31 44 51
11 28 18 34 37 30 13 -3 43 -7 68 -11z m-1273 -55 c143 -21 253 -48 201 -49
-15 -1 -85 -20 -155 -43 -124 -41 -132 -42 -253 -42 -96 1 -143 6 -205 23 -44
12 -110 22 -149 23 l-70 0 74 30 c48 19 121 36 209 49 74 11 137 22 140 24 9
9 71 5 208 -15z m1903 -171 c149 -31 303 -70 350 -89 30 -12 84 -32 120 -45
74 -25 232 -96 227 -101 -6 -6 -109 26 -152 46 -49 24 -158 52 -290 75 -196
35 -264 51 -282 67 -10 9 -37 25 -60 37 -24 12 -43 24 -43 27 0 6 57 -1 130
-17z m-834 -126 c4 -28 1 -58 -10 -89 -9 -26 -16 -56 -16 -66 0 -10 -4 -18 -9
-18 -5 0 -26 -16 -47 -35 -43 -40 -52 -35 -52 29 0 46 -16 92 -39 119 -22 23
-4 63 34 78 25 10 26 12 11 22 -14 9 -14 13 -2 33 l15 22 54 -27 c50 -24 55
-29 61 -68z m-1957 -103 c6 0 24 -8 39 -19 24 -16 37 -18 102 -11 41 5 118 16
170 26 52 9 120 18 150 20 l55 3 -62 -14 c-34 -8 -89 -26 -122 -40 -33 -14
-84 -31 -113 -37 l-53 -13 37 -3 c22 -2 57 5 85 17 l48 21 -30 -26 c-69 -59
-99 -73 -175 -82 -56 -7 -86 -6 -120 4 -45 14 -59 8 -60 -23 0 -19 -18 -25
-39 -14 -13 7 -21 26 -25 58 -14 100 -12 114 23 145 l33 30 22 -21 c12 -12 28
-21 35 -21z m801 28 c0 -3 10 -4 23 -2 12 2 16 2 10 -2 -7 -3 -13 -11 -13 -17
-1 -7 -35 -38 -78 -69 -115 -85 -133 -100 -170 -140 -19 -21 -50 -42 -68 -48
-19 -7 -34 -16 -34 -22 0 -6 7 -7 16 -4 9 3 19 6 24 6 4 0 44 31 88 69 44 38
107 87 140 110 34 22 64 45 67 51 10 16 45 12 45 -6 0 -14 3 -14 15 -4 9 7 17
11 20 8 8 -7 -11 -63 -21 -60 -5 2 -12 -14 -17 -35 -5 -24 -4 -39 3 -41 5 -2
10 -10 10 -18 0 -8 5 -14 10 -14 6 0 10 10 10 22 0 12 8 49 18 82 l17 61 6
-70 c14 -154 12 -167 -27 -214 -33 -38 -62 -111 -45 -111 4 0 15 15 24 33 14
28 16 29 16 9 1 -13 -6 -58 -15 -100 -12 -56 -14 -100 -9 -162 5 -74 4 -83 -8
-66 -9 12 -16 15 -21 8 -8 -13 10 -62 23 -62 5 0 22 -5 37 -10 l27 -11 -37
-38 c-49 -50 -102 -65 -157 -46 -22 8 -53 12 -69 9 -27 -6 -29 -3 -40 35 -10
36 -35 70 -111 149 -34 35 -94 47 -154 31 -93 -24 -130 -8 -170 71 l-26 50 26
28 c14 15 25 30 25 34 0 16 -24 7 -46 -17 -30 -33 -55 -22 -77 33 -8 21 -22
44 -31 51 -9 8 -16 20 -16 27 0 8 -16 32 -36 53 -29 33 -35 47 -36 89 -2 63 5
74 38 62 43 -17 252 -1 302 22 23 11 42 26 42 34 0 18 -3 18 -42 -6 -55 -33
-52 -23 7 25 74 61 115 85 145 85 14 0 48 9 77 19 29 10 50 14 47 9 -9 -14 20
-9 51 10 21 13 49 18 97 18 38 -1 68 -4 68 -8z m2363 -100 c103 -40 137 -59
66 -37 -19 5 -65 7 -105 4 -58 -5 -81 -3 -130 16 -32 12 -80 29 -107 39 -26 9
-51 20 -55 24 -4 5 45 5 108 1 102 -5 127 -11 223 -47z m-633 1 c-15 -51 -38
-69 -166 -129 -92 -44 -128 -67 -150 -95 -16 -20 -44 -55 -61 -77 -25 -30 -33
-50 -33 -80 0 -39 -1 -40 -27 -33 -29 7 -43 21 -43 42 0 7 -8 28 -17 48 -16
35 -16 36 5 51 12 8 20 19 17 24 -3 5 14 21 37 36 65 41 142 108 129 112 -11
4 -107 -63 -180 -125 l-37 -32 -47 17 c-39 14 -45 20 -41 38 4 14 -1 27 -13
35 -28 21 -25 104 5 125 21 15 22 15 11 -13 -11 -29 -5 -88 11 -98 5 -3 25 9
45 26 35 31 41 32 128 35 67 1 111 8 162 26 39 14 102 33 141 42 39 10 84 26
100 36 16 10 30 19 31 19 1 1 -2 -13 -7 -30z m858 -96 c45 -3 81 -7 79 -9 -2
-2 -39 -11 -83 -20 -82 -17 -148 -46 -221 -96 -24 -16 -89 -53 -146 -82 -121
-62 -160 -89 -188 -131 -12 -16 -18 -22 -15 -12 3 10 6 23 6 29 0 18 172 188
232 229 64 44 190 98 228 99 14 0 62 -3 108 -7z m392 -53 c-14 -4 -44 -13 -67
-19 -23 -6 -61 -27 -84 -47 -24 -20 -69 -49 -100 -64 -31 -16 -73 -48 -93 -71
-41 -49 -46 -36 -12 36 30 64 59 90 127 114 31 10 73 28 95 39 27 15 57 21 99
21 45 0 54 -3 35 -9z m-3814 -109 c14 -11 35 -38 47 -60 12 -22 34 -62 49 -88
14 -27 37 -54 51 -60 13 -6 29 -25 35 -42 15 -43 -9 -48 -89 -18 -63 24 -64
25 -71 68 -4 24 -4 56 -2 71 4 22 1 27 -18 30 -19 2 -24 11 -29 48 -4 25 -12
58 -18 74 -9 22 -8 32 1 44 11 13 13 11 16 -16 2 -19 13 -39 28 -51z m979
-148 c36 -64 65 -148 65 -191 0 -20 7 -43 16 -53 9 -10 19 -45 23 -78 4 -34
15 -69 24 -79 24 -26 21 -34 -18 -59 -33 -21 -34 -21 -40 -2 -10 31 -25 22
-25 -15 0 -40 -11 -46 -81 -46 -42 0 -54 5 -89 36 l-41 37 53 18 c38 12 64 30
90 61 21 24 38 48 38 55 0 7 -15 16 -32 21 -28 7 -33 13 -30 36 2 15 -2 51 -7
80 -7 40 -6 68 4 112 8 33 14 71 15 87 0 15 2 27 4 27 2 0 16 -21 31 -47z
m-630 -229 c101 -21 175 -62 245 -137 51 -55 59 -60 101 -61 35 -2 55 -10 87
-36 52 -41 56 -63 10 -49 -18 5 -66 9 -105 9 -69 0 -75 2 -112 35 -21 19 -68
49 -104 66 -60 28 -66 33 -61 55 7 26 -4 31 -25 12 -11 -11 -14 -10 -18 0 -3
7 -31 31 -64 53 -32 22 -66 53 -74 69 -21 40 -19 44 13 20 15 -11 63 -27 107
-36z"/>
<path d="M7 2493 c-28 -27 10 -114 125 -283 58 -85 119 -152 327 -358 141
-140 285 -277 320 -305 142 -116 141 -112 11 -66 -63 22 -167 68 -230 103 -86
47 -119 61 -132 54 -10 -5 -18 -15 -18 -23 0 -22 111 -83 247 -135 70 -26 169
-64 218 -83 50 -19 114 -40 142 -47 29 -6 63 -20 75 -29 13 -10 43 -27 67 -40
61 -31 152 -102 183 -143 13 -18 41 -61 61 -96 62 -106 129 -188 186 -229 30
-21 93 -73 139 -116 103 -94 201 -146 281 -150 60 -2 77 13 33 29 -27 10 -63
58 -134 178 -15 25 -52 64 -84 88 -32 24 -92 75 -134 115 -55 51 -104 85 -171
118 -88 45 -185 112 -175 122 3 3 65 -24 138 -60 73 -35 156 -71 183 -80 67
-22 283 -71 451 -102 l136 -25 107 -99 c64 -60 116 -118 130 -144 13 -25 34
-50 47 -55 13 -6 30 -28 39 -48 14 -35 14 -39 -5 -59 -24 -26 -18 -35 27 -35
42 0 85 21 111 55 25 31 34 32 30 3 -6 -41 29 -31 102 27 38 31 70 60 70 65 0
6 9 10 19 10 11 0 34 12 51 26 30 26 30 27 13 46 -16 18 -13 18 37 -4 30 -14
95 -50 143 -82 104 -67 157 -81 275 -73 107 6 122 20 56 47 -27 11 -89 41
-139 66 -70 35 -108 47 -170 54 -44 6 -117 22 -162 38 l-83 27 0 44 c0 42 1
43 28 36 15 -4 99 -9 187 -12 114 -3 163 -8 170 -17 6 -7 20 -16 31 -20 12 -3
79 -63 149 -131 137 -134 203 -179 275 -189 25 -4 82 -14 128 -23 102 -19 121
-9 64 33 -59 43 -112 93 -164 155 -29 33 -65 63 -90 75 -24 10 -60 29 -80 42
-20 13 -62 32 -93 41 l-57 18 112 6 c80 5 131 3 183 -8 206 -42 301 -55 403
-55 128 1 172 9 279 56 44 20 105 46 135 59 85 37 73 51 -40 46 -77 -2 -109 1
-165 19 -49 15 -108 23 -196 27 -133 6 -186 -3 -344 -57 -110 -38 -245 -50
-526 -48 -270 1 -357 9 -238 21 123 13 572 138 714 199 38 17 67 21 122 19 42
-1 74 2 78 8 3 6 39 11 80 11 53 0 89 6 127 22 35 14 89 24 158 29 58 4 148
15 200 23 166 28 199 30 218 14 9 -8 29 -19 45 -26 17 -7 27 -19 27 -32 0 -11
4 -20 10 -20 21 0 99 55 109 77 14 31 14 110 0 118 -6 4 -7 19 -4 35 5 21 1
33 -16 49 -13 12 -27 21 -32 21 -6 0 -2 9 8 20 13 14 16 29 11 57 -3 21 -9 58
-12 83 -4 25 -15 54 -25 65 -10 11 -25 49 -33 84 -8 35 -20 67 -25 71 -6 3
-44 17 -85 30 -69 23 -76 28 -93 65 -10 22 -34 50 -53 63 -19 12 -56 43 -81
67 -26 24 -77 58 -115 75 -38 18 -86 47 -106 66 -42 37 -57 41 -218 51 -223
14 -237 12 -310 -39 -72 -50 -82 -72 -51 -115 11 -15 23 -40 27 -55 5 -24 3
-28 -14 -28 -23 0 -82 -31 -82 -43 0 -4 11 -18 24 -30 13 -12 31 -48 40 -79
10 -36 31 -74 56 -103 37 -43 60 -96 60 -141 0 -11 9 -54 19 -95 10 -41 22
-114 25 -162 6 -88 6 -88 -24 -118 -16 -17 -30 -39 -30 -49 0 -21 -62 -60
-135 -84 -264 -86 -681 -184 -701 -164 -3 3 2 22 10 42 16 38 19 40 131 56
246 37 344 80 448 196 51 56 91 144 73 161 -4 4 -30 -17 -59 -46 -56 -57 -85
-75 -153 -96 -46 -14 -242 -129 -260 -153 -10 -14 -132 -47 -141 -38 -2 2 7
22 21 45 32 50 32 66 2 102 -19 22 -26 46 -32 100 -7 69 -8 72 -40 85 -31 13
-33 16 -22 40 11 23 10 29 -8 43 -12 9 -21 28 -20 41 1 20 -7 27 -49 43 -40
14 -53 24 -62 50 -37 106 -41 112 -74 124 -19 7 -44 12 -56 12 -20 1 -23 -4
-23 -36 -1 -34 -2 -36 -14 -20 -18 23 -20 22 -60 -18 -20 -19 -42 -35 -49 -35
-15 0 -37 -41 -37 -69 0 -10 -7 -25 -16 -32 -9 -8 -27 -30 -40 -51 -17 -26
-40 -44 -83 -63 -75 -34 -94 -55 -117 -128 -20 -64 -83 -133 -111 -123 -16 7
-48 -8 -78 -35 -20 -18 -25 -18 -185 1 -206 23 -221 27 -405 94 -82 30 -206
71 -275 91 -69 21 -131 40 -137 44 -19 10 305 13 359 3 28 -6 79 -30 128 -61
129 -83 170 -94 325 -87 152 8 232 23 214 40 -2 3 -36 11 -74 19 -39 8 -97 27
-130 43 -53 24 -73 28 -173 29 -112 2 -148 10 -102 22 47 12 175 83 230 126
82 65 169 168 192 229 11 29 27 61 35 71 15 16 14 17 -6 17 -33 0 -57 -16 -80
-53 -23 -38 -102 -84 -191 -112 -72 -23 -163 -69 -220 -110 -25 -18 -91 -53
-147 -78 -86 -39 -116 -48 -203 -57 -135 -13 -145 -12 -80 10 112 37 206 82
238 113 17 17 41 55 52 84 20 55 27 153 11 153 -5 0 -20 -17 -32 -37 -47 -76
-77 -101 -135 -113 -35 -7 -69 -24 -97 -46 -63 -50 -136 -92 -194 -111 -46
-15 -54 -15 -75 -1 -113 75 -634 592 -739 734 -28 38 -56 74 -62 79 -39 33
-107 161 -107 199 0 17 -12 21 -23 9z m4023 -289 l41 -37 -53 -18 c-38 -12
-64 -30 -90 -61 -21 -24 -38 -48 -38 -55 0 -7 15 -16 33 -21 27 -7 32 -13 29
-35 -1 -14 2 -51 6 -83 7 -40 6 -74 -3 -113 -8 -31 -15 -69 -17 -86 -3 -30 -3
-30 -32 20 -37 66 -66 150 -66 193 0 20 -7 43 -16 53 -9 10 -19 45 -23 78 -4
34 -15 69 -24 79 -24 26 -21 34 18 59 33 21 34 21 40 2 10 -31 25 -22 25 15 0
40 7 44 77 45 47 1 57 -3 93 -35z m167 16 c69 0 75 -2 112 -35 21 -19 68 -49
104 -66 60 -28 66 -33 61 -55 -7 -26 4 -31 25 -11 11 10 14 9 18 -1 3 -7 31
-31 64 -53 32 -22 66 -53 74 -69 21 -40 19 -44 -12 -20 -16 11 -64 27 -108 36
-101 21 -175 62 -245 137 -51 55 -59 60 -101 61 -35 2 -55 10 -87 36 -52 41
-56 63 -10 49 18 -5 66 -9 105 -9z m-78 -95 c23 -8 55 -12 71 -9 27 6 29 3 40
-35 10 -36 35 -70 111 -149 34 -35 94 -47 154 -31 93 24 130 8 170 -71 l26
-50 -26 -28 c-14 -15 -25 -30 -25 -34 0 -16 24 -7 46 17 30 32 54 22 78 -32
24 -55 59 -109 90 -142 22 -22 27 -38 28 -78 2 -64 -5 -75 -38 -63 -43 17
-252 1 -301 -22 -24 -11 -43 -26 -43 -34 0 -18 3 -18 42 6 55 33 52 23 -7 -25
-74 -61 -115 -85 -145 -85 -14 0 -48 -9 -77 -19 -29 -10 -50 -14 -47 -9 9 15
-20 8 -54 -12 -18 -11 -40 -20 -50 -19 -9 0 2 9 26 19 23 11 42 22 42 25 0 9
-54 -6 -70 -20 -17 -14 -110 -27 -110 -15 0 5 -10 6 -22 4 -13 -2 -17 -2 -10
2 6 3 12 11 12 17 1 7 35 38 78 69 108 80 130 98 170 140 19 21 50 42 68 48
19 7 34 16 34 22 0 6 -7 7 -16 4 -9 -3 -19 -6 -24 -6 -4 0 -44 -31 -88 -69
-44 -38 -107 -87 -140 -110 -34 -22 -64 -45 -67 -51 -10 -16 -45 -12 -45 6 0
14 -3 14 -15 4 -9 -7 -17 -11 -20 -8 -8 7 10 58 20 57 14 0 29 75 15 79 -5 2
-10 10 -10 18 0 8 -4 14 -10 14 -5 0 -10 -10 -10 -22 0 -12 -8 -49 -17 -82
l-18 -61 -6 70 c-14 154 -12 167 27 214 33 38 62 111 45 111 -4 0 -15 -15 -24
-32 -14 -29 -16 -30 -16 -10 -1 13 6 58 15 100 12 56 14 100 9 162 -5 74 -4
83 8 66 18 -25 30 -6 18 27 -6 16 -21 28 -40 32 -17 4 -33 10 -35 14 -9 14 68
79 104 89 19 6 36 11 36 11 1 1 20 -6 43 -14z m676 -289 c58 -22 60 -24 67
-67 4 -24 4 -56 2 -71 -4 -22 -1 -27 18 -30 19 -2 24 -11 29 -48 4 -25 12 -58
18 -74 10 -24 9 -32 -4 -45 -13 -14 -15 -12 -15 16 0 22 -8 38 -26 52 -14 11
-35 38 -47 60 -12 22 -34 62 -49 90 -15 27 -37 53 -49 56 -26 9 -53 66 -36 77
17 11 25 10 92 -16z m-2768 -107 c-19 -44 -176 -197 -247 -242 -30 -18 -86
-46 -124 -61 -65 -26 -74 -27 -173 -21 -57 4 -102 9 -100 11 2 2 39 11 83 20
85 18 147 45 234 104 30 20 78 47 105 59 83 37 193 110 212 141 22 38 28 31
10 -11z m758 -12 c1 -1 11 -28 24 -60 l23 -58 -21 -15 c-12 -9 -19 -19 -16
-24 3 -5 -14 -21 -37 -36 -65 -41 -142 -108 -129 -112 11 -4 107 63 180 125
l37 32 47 -17 c39 -14 45 -20 41 -38 -4 -14 1 -27 13 -35 28 -21 25 -104 -5
-125 -21 -15 -22 -15 -11 13 11 29 5 88 -11 98 -5 3 -25 -9 -45 -26 -35 -31
-41 -32 -128 -35 -64 -1 -110 -8 -152 -23 -33 -12 -96 -31 -139 -41 -44 -11
-94 -29 -112 -39 l-32 -19 9 30 c14 49 37 67 166 129 91 44 129 68 150 95 15
20 43 54 61 76 24 30 32 50 32 80 0 39 1 40 27 33 16 -4 28 -7 28 -8z m-1358
-90 c-27 -60 -70 -98 -132 -117 -22 -7 -61 -23 -87 -36 -34 -18 -63 -24 -105
-23 -61 1 -58 3 34 28 23 6 61 27 84 47 24 20 69 49 100 65 32 15 72 46 91 68
41 50 47 37 15 -32z m3515 -199 c16 -13 28 -68 30 -137 1 -27 -6 -40 -31 -63
l-33 -30 -22 21 c-12 12 -28 21 -35 21 -6 0 -24 8 -39 19 -24 16 -37 18 -102
11 -41 -5 -118 -16 -170 -26 -52 -9 -120 -18 -150 -20 l-55 -3 62 14 c34 8 89
26 122 40 33 14 84 31 113 37 l53 13 -37 3 c-22 2 -57 -5 -85 -17 l-48 -21 30
26 c69 59 99 73 175 82 56 7 86 6 120 -4 25 -8 46 -14 47 -14 0 0 6 14 12 30
11 33 19 36 43 18z m-2057 -87 c-15 -32 -42 -74 -61 -92 -39 -38 -42 -61 -14
-104 11 -16 20 -38 20 -48 0 -10 21 -40 46 -67 40 -43 45 -53 39 -79 -8 -38
-39 -77 -76 -96 -16 -9 -36 -31 -44 -51 -11 -28 -18 -34 -37 -30 -13 3 -43 7
-68 11 -43 6 -95 31 -135 66 -11 9 -42 22 -70 28 -54 11 -249 104 -240 113 3
3 -9 18 -27 33 l-33 28 30 -7 c17 -5 73 -10 125 -12 52 -3 114 -12 138 -21 59
-22 66 -8 7 18 -36 16 -68 21 -133 21 -46 0 -101 6 -121 13 -20 8 -30 14 -23
14 6 1 11 7 10 13 -2 7 8 14 22 16 34 5 51 22 21 22 -12 0 -19 4 -16 10 3 6
-1 10 -9 10 -29 0 -17 16 59 77 60 49 87 64 142 78 37 9 113 30 168 47 84 25
111 29 166 25 59 -5 71 -3 100 18 19 12 35 19 37 14 2 -4 -9 -35 -23 -68z
m141 15 c3 -14 3 -29 0 -34 -8 -14 20 -101 40 -123 23 -26 6 -64 -38 -82 -31
-13 -32 -14 -11 -21 21 -7 22 -9 7 -31 l-15 -23 -54 27 c-50 24 -55 29 -61 68
-4 28 -1 58 10 89 9 26 16 55 16 64 0 8 14 24 30 34 17 10 36 26 43 37 16 25
25 24 33 -5z m-1301 -11 c100 -1 117 -5 182 -33 40 -18 79 -32 87 -32 8 0 18
-5 21 -11 5 -7 -27 -9 -111 -3 -106 6 -130 11 -219 46 -55 22 -102 42 -105 45
-3 4 4 3 15 -2 11 -5 70 -9 130 -10z m-304 -110 c30 -9 72 -24 94 -35 69 -32
82 -35 460 -107 50 -9 99 -24 110 -34 11 -9 40 -27 63 -39 24 -12 42 -23 40
-25 -10 -11 -273 42 -394 79 -119 36 -270 93 -359 136 -44 21 -77 39 -74 39 3
1 30 -6 60 -14z m245 -295 c39 -37 96 -86 127 -109 58 -43 93 -83 122 -141 10
-19 31 -52 48 -73 16 -21 27 -40 25 -43 -3 -3 -30 5 -61 16 -59 22 -124 77
-197 168 -23 29 -87 89 -142 134 -62 51 -114 103 -136 137 -21 31 -40 61 -44
66 -4 6 37 -11 90 -38 69 -35 117 -68 168 -117z m1290 99 c39 -24 43 -45 14
-82 -56 -71 -67 -175 -24 -226 14 -17 23 -35 19 -41 -9 -15 -41 -12 -55 5 -20
25 -33 17 -27 -15 5 -24 2 -33 -19 -47 -14 -10 -41 -31 -60 -47 -38 -31 -47
-30 -45 6 1 13 2 51 2 85 1 49 4 62 18 66 10 2 25 20 34 40 9 19 33 44 52 56
68 40 94 125 60 191 -18 36 -13 37 31 9z m1422 -89 c41 -11 106 -21 145 -21
l71 -1 -74 -29 c-46 -18 -126 -38 -205 -50 -71 -11 -146 -22 -165 -25 -40 -7
-303 29 -370 51 -37 11 -40 14 -17 14 15 1 76 17 135 36 59 19 125 39 147 44
60 15 248 4 333 -19z m-1916 -91 c32 -7 75 -28 110 -52 42 -30 69 -41 106 -45
46 -4 50 -7 56 -34 3 -17 6 -43 6 -59 0 -49 -88 -159 -126 -159 -11 0 -12 4
-3 18 14 23 -24 109 -56 124 -13 6 -31 28 -41 50 -11 24 -59 78 -118 135
l-101 95 58 -31 c31 -17 81 -36 109 -42z m1027 -34 c35 -9 110 -40 166 -70 83
-44 110 -66 146 -111 24 -30 69 -76 99 -101 30 -26 46 -43 36 -39 -11 3 -53
12 -94 18 -82 12 -91 17 -166 97 -28 29 -89 80 -136 112 -84 57 -143 109 -124
109 5 0 38 -7 73 -15z m-243 -130 c69 -21 227 -102 214 -110 -31 -19 -255 55
-320 105 l-25 20 41 0 c22 0 63 -7 90 -15z"/>
</g>


    </Svg>
  );
};

export default Icon;
