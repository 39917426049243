import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2547 4124 c-94 -21 -156 -47 -262 -109 -198 -117 -342 -319 -389
-547 -19 -93 -20 -260 -3 -344 l13 -61 -27 -19 c-42 -28 -69 -84 -69 -142 0
-46 16 -77 265 -512 164 -287 265 -473 265 -489 -1 -32 -19 -57 -51 -69 -16
-6 -222 11 -644 53 -341 35 -629 64 -641 66 -13 3 -90 87 -200 219 -177 213
-215 247 -227 203 -16 -54 -158 -995 -153 -1009 4 -11 14 -15 29 -12 13 3 123
66 244 141 122 75 228 137 236 137 8 0 433 -110 943 -246 511 -135 950 -248
975 -251 25 -3 68 -1 95 5 35 8 268 136 794 438 410 235 752 436 760 447 25
34 37 98 28 146 l-8 44 103 106 c154 158 228 279 279 457 20 69 23 101 22 244
0 149 -3 173 -27 253 -112 365 -407 611 -789 658 -122 15 -212 6 -390 -38
l-146 -36 -33 24 c-38 27 -125 37 -168 19 -28 -11 -33 -9 -106 49 -84 67 -226
140 -320 166 -87 24 -311 29 -398 9z m383 -74 c96 -28 232 -98 295 -154 28
-24 48 -46 45 -49 -13 -13 -1302 -748 -1306 -745 -14 15 -26 138 -21 228 5
125 44 254 109 365 49 84 189 226 271 274 65 38 197 86 277 100 81 15 243 5
330 -19z m1228 -191 c86 -13 211 -60 295 -110 90 -53 215 -173 273 -261 193
-290 187 -669 -16 -967 -51 -76 -221 -255 -231 -244 -4 4 -201 347 -439 762
-321 560 -429 756 -419 762 25 15 228 61 301 68 81 7 142 5 236 -10z m-654
-41 c49 -48 959 -1642 964 -1689 3 -29 -2 -43 -25 -67 -43 -47 -1434 -840
-1505 -858 -70 -18 34 -43 -1095 255 -501 133 -917 241 -925 241 -7 0 -105
-59 -218 -130 -113 -70 -206 -128 -207 -127 -1 2 119 767 129 826 2 8 76 -74
166 -182 90 -108 166 -197 168 -197 3 0 299 -29 657 -65 359 -37 666 -63 684
-60 17 3 46 19 64 36 17 16 33 28 34 27 1 -2 14 -24 29 -50 21 -36 34 -48 52
-48 18 0 24 5 24 23 0 12 -114 222 -254 467 -226 394 -253 445 -238 455 9 6
275 159 590 339 507 290 572 331 572 352 0 19 -5 24 -24 24 -13 0 -283 -148
-599 -330 -317 -181 -580 -330 -586 -330 -17 0 -91 146 -91 179 0 19 10 42 28
61 16 18 336 208 767 455 555 318 748 425 773 425 23 0 42 -9 66 -32z"/>
<path d="M2655 3939 c-206 -19 -413 -161 -509 -352 -45 -88 -52 -140 -20 -145
18 -3 29 12 69 95 81 167 215 278 391 323 130 34 256 21 386 -37 47 -21 61
-23 73 -13 23 19 18 39 -17 60 -87 53 -239 81 -373 69z"/>
<path d="M3836 3754 c-3 -8 1 -29 10 -46 15 -29 15 -31 -11 -58 -15 -15 -25
-36 -23 -47 6 -31 41 -26 78 12 33 34 35 35 115 35 302 0 565 -203 631 -487
21 -92 15 -254 -14 -343 -27 -81 -92 -191 -123 -207 -13 -7 -43 -18 -66 -23
-33 -8 -43 -16 -43 -31 0 -27 24 -34 76 -22 43 10 44 10 65 -23 22 -36 55 -45
66 -18 3 8 -2 32 -11 53 l-18 38 31 46 c101 148 139 368 96 553 -71 309 -349
524 -676 524 l-98 0 -18 30 c-19 33 -57 41 -67 14z"/>
<path d="M445 2428 c-7 -21 -165 -1028 -165 -1054 0 -29 26 -42 45 -23 12 13
175 1003 175 1065 0 19 -5 24 -25 24 -14 0 -28 -6 -30 -12z"/>
</g>
    </Svg>
  );
};

export default Icon;
