import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'BOUJE-FTM LP',
    lpAddresses: {
      250: '0x91af3c43b77217c1f9622BFC2Cd313e213332901',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BOUJE-USDC LP',
    lpAddresses: {
      250: '0xacf6728c977E349440827375C73Ee23bCE4Db291',
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'FTM-USDC LP',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },


  {
    pid: 0,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BOUJE',
    lpAddresses: {
      250: '0xacf6728c977E349440827375C73Ee23bCE4Db291'
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },


  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      250: '0xFdef392aDc84607135C24ca45DE5452d77aa10DE'
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 5,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WFTM',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c'
    },
    tokenSymbol: 'WFTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 6,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      250: '0xa572BdF049382F1F98f9a430788dadD51A303969'
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      250: '0x6B5340dFcd7D509Ea931cC4E69462797DbBc0197'
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 8,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'FPUP',
    lpAddresses: {
      250: '0xE2fA732C69F7Ca59944f8007d87c5906AFEb6b8F'
    },
    tokenSymbol: 'fPUP',
    tokenAddresses: {
      250: '0x900825EA297c7c5D1F6fA54146849BC41EdDAf29'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 9,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      250: '0x484237bc35cA671302d19694c66d617142FBC235'
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      250: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'SPELL',
    lpAddresses: {
      250: '0x31c0385DDE956f95D43Dac80Bd74FEE149961f4c'
    },
    tokenSymbol: 'SPELL',
    tokenAddresses: {
      250: '0x468003B688943977e6130F4F68F23aad939a1040'
    },
    quoteTokenSymbol: QuoteToken.fUSDT,
    quoteTokenAdresses: contracts.fusdt,
  },
  {
    pid: 11,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DRACO',
    lpAddresses: {
      250: '0x8562E3032753eDf3eDf5f4d11BC079b43C9b224c'
    },
    tokenSymbol: 'DRACO',
    tokenAddresses: {
      250: '0x8d05B42749428C26613deB12f8989Cb8D1f5c17f'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 12,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'XPAST',
    lpAddresses: {
      250: '0x73B019D2B6fD28D85eeAD4E85909d69Cc0472D5F',
    },
    tokenSymbol: 'XPAST',
    tokenAddresses: {
      250: '0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms

