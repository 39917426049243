import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2433 4963 c-22 -8 -14 -53 13 -86 21 -25 25 -38 20 -66 -3 -20 -6
-44 -6 -55 0 -16 -3 -15 -21 8 -23 29 -49 34 -49 9 0 -9 -3 -14 -6 -10 -4 3
-4 17 0 30 4 19 11 23 30 20 28 -6 40 9 31 37 -9 27 -68 27 -94 1 -19 -19 -20
-19 -18 -1 1 10 5 16 11 13 5 -4 7 1 3 11 -4 10 -7 20 -7 22 0 2 -6 4 -13 4
-7 0 -20 9 -30 20 -9 11 -27 21 -39 21 -13 1 -37 3 -54 5 -44 5 -84 -33 -84
-80 0 -33 -1 -35 -24 -26 -30 11 -122 -10 -118 -28 1 -7 -4 -11 -10 -10 -8 2
-13 -7 -13 -22 0 -24 3 -25 55 -25 30 0 65 4 77 8 16 6 28 2 45 -15 13 -13 26
-22 29 -21 3 1 4 -10 2 -25 -3 -23 -2 -24 6 -7 8 16 10 13 10 -20 0 -31 -2
-36 -9 -20 -5 11 -9 14 -9 7 -1 -8 -10 -21 -20 -30 -32 -28 -34 -66 -6 -96 31
-34 33 -47 2 -18 -13 12 -33 22 -44 22 -11 0 -26 7 -33 15 -15 18 -71 20 -88
3 -32 -32 2 -73 40 -49 34 21 34 1 0 -27 -39 -33 -45 -76 -9 -67 30 8 62 -25
52 -54 -13 -43 -88 -111 -122 -111 -4 0 -7 33 -6 72 3 75 -11 130 -31 127 -6
-1 -18 1 -27 5 -10 4 -41 0 -70 -8 -48 -15 -54 -15 -71 1 -10 9 -18 22 -18 30
0 8 -4 11 -10 8 -35 -22 14 -79 77 -90 34 -5 43 -3 51 12 15 25 35 4 35 -37 0
-42 -21 -77 -60 -101 -27 -17 -36 -19 -48 -9 -13 11 -13 14 2 25 20 15 11 45
-15 45 -26 0 -42 -20 -42 -52 0 -26 -2 -28 -20 -18 -44 24 -62 90 -24 90 19 0
18 16 -1 24 -24 9 -45 -4 -59 -38 l-14 -31 -16 37 c-34 79 -10 163 34 123 19
-17 22 -17 37 -3 22 22 11 45 -27 53 -21 5 -39 2 -55 -9 -17 -11 -26 -13 -31
-4 -11 17 -34 -20 -35 -56 -1 -51 -17 0 -18 56 -1 55 22 78 59 58 11 -6 20 -7
20 -2 0 21 -31 33 -77 30 l-48 -3 0 -62 c0 -51 -2 -60 -15 -53 -8 4 -36 10
-61 14 -35 5 -48 12 -52 26 -3 13 -11 18 -21 14 -21 -8 -80 13 -101 36 -9 10
-18 30 -19 44 -1 29 -11 34 -20 10 -9 -24 18 -76 58 -112 20 -17 41 -32 46
-32 5 0 7 -7 3 -16 -5 -13 0 -14 31 -9 23 5 36 3 36 -4 0 -6 6 -8 14 -5 8 3
30 -3 50 -13 l35 -18 -43 0 c-68 0 -77 -13 -72 -109 l4 -82 -34 -23 c-68 -47
-124 -148 -146 -263 -12 -63 -14 -67 -35 -62 -27 7 -73 -19 -73 -42 0 -10 8
-14 28 -12 23 3 27 0 25 -22 -3 -34 6 -72 15 -66 4 2 9 -4 9 -16 1 -13 -3 -18
-11 -15 -16 6 -36 -11 -36 -31 0 -7 -7 -20 -15 -28 -8 -9 -15 -28 -15 -43 0
-23 7 -29 47 -44 51 -18 86 -14 116 11 16 14 17 12 17 -34 0 -51 -28 -120 -49
-118 -6 1 -17 2 -23 3 -8 1 -13 19 -14 52 -1 27 -6 55 -13 62 -7 7 -40 12 -79
12 -37 0 -73 4 -79 8 -9 5 -13 3 -13 -7 0 -8 9 -20 19 -26 26 -13 81 -13 81 1
0 25 18 -4 22 -35 3 -24 0 -32 -9 -29 -7 3 -14 -1 -15 -8 0 -8 -2 -18 -3 -24
-2 -5 -3 -16 -4 -22 -1 -16 -53 -17 -69 -1 -19 19 -14 28 12 28 36 0 46 38 16
62 -68 55 -124 -60 -64 -131 l26 -31 -61 0 -61 0 0 -1655 0 -1655 833 0 c459
0 1219 3 1689 7 l855 6 2 1654 2 1653 -43 0 c-43 1 -43 1 -17 15 30 16 40 34
46 88 4 41 -17 87 -41 87 -8 0 -16 16 -19 41 -7 53 -32 81 -71 77 -33 -3 -49
-28 -18 -28 39 0 38 -56 -1 -62 -23 -3 -42 -52 -30 -78 3 -8 5 -21 4 -28 -3
-19 29 -52 50 -52 24 0 33 -16 13 -24 -70 -27 -188 53 -169 114 7 24 30 26 39
5 8 -23 46 -19 46 4 0 25 -10 31 -57 35 -48 5 -63 -1 -63 -25 0 -10 -7 -19
-15 -19 -11 0 -15 12 -15 50 0 40 5 56 28 81 24 26 33 31 56 25 15 -4 25 -12
24 -19 -2 -7 0 -13 5 -14 19 -7 27 -1 27 18 0 11 -4 18 -9 15 -5 -4 -7 2 -4
12 6 23 -17 42 -54 46 -27 3 -28 5 -35 77 -11 103 -23 131 -80 194 -68 74
-154 116 -251 123 -40 2 -89 0 -110 -6 l-37 -10 0 35 c0 69 -88 119 -166 95
-40 -12 -44 -3 -10 18 13 8 44 40 70 71 51 64 83 78 121 53 23 -15 24 -18 9
-29 -11 -8 -14 -18 -9 -34 15 -48 51 -2 41 52 -8 43 -52 73 -92 63 -30 -8 -31
4 -1 31 23 22 36 26 73 24 23 -1 43 -33 25 -39 -6 -2 -9 -8 -6 -12 9 -15 35 1
35 22 0 10 -11 30 -25 44 -20 20 -32 24 -70 21 -51 -4 -58 10 -29 52 13 18 23
22 46 17 26 -5 28 -4 18 14 -12 23 -58 27 -79 8 -11 -11 -11 -9 -1 12 16 33
50 59 68 53 7 -3 18 -15 22 -27 8 -20 9 -19 9 5 1 32 -13 44 -61 53 -35 6 -39
4 -58 -26 -11 -18 -20 -41 -20 -52 0 -15 -3 -16 -14 -6 -22 17 -60 8 -64 -15
-3 -16 -1 -18 9 -8 10 10 17 6 36 -19 13 -18 23 -37 23 -44 0 -17 -24 -24 -41
-12 -11 8 -10 9 4 4 9 -3 17 -1 17 5 0 13 -70 8 -73 -5 -1 -6 13 -14 30 -18
24 -6 34 -16 39 -36 3 -15 7 -33 10 -39 2 -7 0 -13 -5 -13 -5 0 -11 8 -14 19
-3 14 -13 18 -42 17 -40 -1 -64 -26 -26 -26 41 0 45 -77 7 -135 -23 -35 -49
-34 -36 1 16 41 12 102 -10 144 -11 22 -20 51 -20 65 0 23 -4 26 -27 24 -16
-1 -55 11 -88 27 -52 25 -72 29 -156 30 l-96 2 -64 46 c-126 92 -148 173 -44
167 40 -2 47 1 52 18 3 12 11 21 19 21 16 0 29 43 19 61 -6 8 -10 7 -17 -5
-12 -21 -48 -21 -48 0 0 9 -6 14 -12 11 -7 -2 -12 -11 -10 -21 2 -9 -2 -16 -8
-16 -18 0 -89 -76 -95 -103 -4 -14 -2 -35 5 -46 7 -14 7 -21 0 -21 -5 0 -10
-9 -10 -20 0 -27 -15 -25 -39 6 -24 31 -28 66 -7 58 9 -3 20 6 29 25 l15 30
-34 7 c-30 5 -32 8 -23 26 6 11 9 36 7 54 -2 19 0 34 4 34 4 0 8 6 8 13 0 8
-7 14 -15 14 -9 0 -15 -10 -15 -25 0 -24 -1 -25 -20 -7 -26 24 -34 53 -20 70
13 16 12 16 -7 8z m-193 -78 c32 -17 49 -60 31 -78 -17 -17 -13 -37 9 -37 11
0 20 5 20 11 0 5 -4 7 -10 4 -5 -3 -10 -1 -10 4 0 6 7 11 15 11 18 0 20 16 3
23 -8 3 -7 6 4 6 22 1 26 -7 38 -69 8 -42 7 -54 -5 -67 -17 -17 -20 -59 -4
-68 6 -4 14 -17 19 -29 l9 -21 0 22 c1 12 -3 25 -9 28 -14 8 -13 52 2 61 17
10 38 -25 38 -64 0 -24 8 -42 30 -63 l29 -30 -34 7 c-29 5 -35 3 -35 -11 0
-27 -25 -11 -28 19 -2 15 -12 32 -23 39 -12 8 -19 23 -19 44 0 40 -18 81 -39
88 -9 3 -23 20 -31 38 -8 17 -24 34 -35 36 -11 1 -28 10 -37 18 -15 13 -15 17
-3 30 18 18 19 33 3 33 -14 1 20 30 34 30 5 0 22 -7 38 -15z m359 -351 c2 -2
-2 -4 -10 -4 -22 0 -46 -34 -43 -61 1 -13 3 -37 3 -52 1 -30 26 -46 43 -29 9
9 8 18 -2 36 -18 36 6 73 49 73 29 0 29 -1 17 -26 -23 -46 -27 -79 -13 -119
l14 -40 -26 8 c-52 16 -81 30 -146 70 -57 37 -63 43 -50 57 8 8 15 18 15 23 0
14 -18 21 -25 10 -4 -7 -13 -6 -27 1 -20 11 -19 12 18 25 21 8 40 14 42 14 1
0 2 -8 2 -17 0 -10 6 -35 13 -55 12 -34 15 -37 32 -24 11 8 16 19 12 30 -4 9
-2 16 3 16 13 0 13 37 0 45 -5 3 -7 12 -3 21 4 11 14 13 42 8 20 -4 38 -8 40
-10z m339 -25 c34 -12 48 -34 26 -43 -8 -3 -14 -18 -15 -33 0 -15 -3 -22 -6
-15 -2 6 -9 9 -14 6 -6 -3 -5 -11 2 -20 6 -8 9 -23 6 -34 -3 -11 -1 -20 4 -20
5 0 9 10 9 23 0 16 3 18 9 9 6 -10 10 -7 13 12 3 15 1 26 -6 26 -6 0 -3 5 7
11 12 7 22 7 31 0 6 -5 22 -8 34 -4 16 4 21 2 17 -8 -3 -8 9 -23 30 -37 37
-24 44 -42 21 -62 -8 -6 -17 -21 -19 -33 -4 -18 -5 -18 -6 5 -2 51 -67 73
-134 44 -74 -32 -117 -113 -117 -223 0 -73 -11 -84 -61 -59 -40 20 -49 20 -49
1 0 -8 -4 -15 -9 -15 -5 0 -16 -3 -25 -6 -12 -5 -16 0 -16 22 0 37 9 49 40 59
42 12 70 53 70 100 0 29 5 45 15 49 17 6 20 43 6 65 -6 10 -4 25 6 45 15 28
14 68 -3 124 -6 21 -4 22 48 22 30 0 69 -5 86 -11z m-518 -176 c80 -60 155
-99 208 -109 17 -3 32 -8 32 -10 0 -17 -40 -59 -75 -79 -51 -30 -55 -31 -55
-4 0 12 -13 34 -30 49 -16 15 -30 37 -30 49 0 12 -4 21 -9 21 -29 0 -16 -92
15 -115 15 -11 15 -14 3 -27 -12 -14 -16 -11 -34 21 -11 20 -30 40 -42 46 -17
7 -21 15 -17 32 4 15 0 26 -15 35 -12 7 -21 20 -21 28 0 26 -25 88 -43 108
-48 53 17 27 113 -45z m-297 -9 c9 -3 12 -14 9 -30 -3 -20 -10 -24 -37 -24
-25 0 -40 -8 -59 -30 -14 -17 -23 -37 -20 -45 7 -17 -1 -19 -68 -15 -28 1 -63
-6 -90 -18 -79 -36 -112 -94 -106 -191 3 -58 2 -61 -19 -61 -13 0 -32 -9 -42
-19 -19 -19 -20 -19 -36 -1 -30 33 -88 54 -164 59 -74 6 -75 6 -83 38 -11 41
-48 67 -88 61 -16 -3 -30 -1 -30 4 0 4 -7 8 -16 8 -9 0 -12 -5 -8 -12 5 -7 3
-8 -5 -4 -7 5 -21 0 -32 -10 -10 -9 -21 -15 -23 -13 -11 11 32 74 81 119 52
47 57 50 95 43 35 -5 40 -4 35 10 -10 27 54 33 100 11 36 -18 38 -22 36 -61
-1 -26 3 -43 12 -46 17 -7 55 20 55 39 0 8 4 14 9 14 18 0 20 -32 5 -63 -14
-26 -15 -36 -4 -66 16 -47 28 -61 51 -61 23 0 25 16 4 24 -20 7 -19 40 1 57
16 13 16 78 -1 139 -6 25 -6 25 52 21 141 -11 186 -6 252 30 38 19 72 47 85
66 22 34 26 36 49 27z m897 -48 c0 -14 10 -32 25 -43 23 -17 24 -21 13 -55 -7
-25 -17 -38 -29 -38 -12 0 -16 6 -12 18 9 34 -76 97 -99 74 -7 -7 -3 -12 10
-17 36 -11 41 -65 6 -65 -18 0 -18 -19 0 -26 12 -4 12 -12 4 -37 -13 -37 -38
-76 -39 -62 -1 6 0 28 0 50 l1 40 -15 -25 c-15 -24 -16 -23 -12 35 2 33 12 77
22 99 32 66 125 105 125 52z m309 -140 c9 -10 8 -15 -4 -20 -20 -7 -19 -43 0
-54 13 -7 10 -14 -18 -44 l-33 -35 -18 21 c-9 11 -15 29 -12 39 3 11 0 17 -7
14 -18 -6 -20 -44 -3 -77 14 -29 14 -30 -9 -30 -14 0 -33 11 -45 28 -18 21
-21 36 -17 74 6 68 19 80 78 72 40 -5 50 -4 55 10 8 20 18 20 33 2z m173 -117
c51 -14 87 -47 94 -88 6 -30 5 -33 -13 -27 -25 8 -55 -18 -46 -40 10 -28 65
-13 88 23 11 18 26 32 32 33 16 0 -2 45 -31 78 -19 22 -19 22 0 22 24 0 56
-21 107 -69 104 -98 115 -251 21 -296 -32 -15 -37 -15 -60 -1 -13 9 -24 21
-24 27 0 6 -4 7 -10 4 -36 -22 18 -78 88 -91 31 -6 32 -7 32 -61 0 -79 44
-152 115 -191 17 -10 13 -11 -26 -12 -37 0 -50 5 -70 26 -13 15 -64 60 -114
102 -76 63 -121 103 -99 88 3 -3 19 0 35 6 21 7 29 16 29 34 0 13 -4 24 -10
24 -5 0 -10 -4 -10 -10 0 -5 -13 -10 -30 -10 -20 0 -35 8 -46 25 -16 24 -12
65 7 65 5 0 9 16 9 35 0 29 5 36 34 50 42 20 60 75 25 75 -6 0 -8 -4 -4 -9 3
-6 -3 -15 -14 -20 -24 -13 -85 25 -87 54 -1 11 -2 28 -3 38 -1 14 -7 17 -23
13 -13 -3 -17 -3 -10 0 6 3 12 17 12 30 0 16 8 28 23 34 20 8 20 9 -8 9 -35 1
-79 -25 -69 -41 5 -7 2 -8 -5 -4 -8 5 -14 0 -18 -13 -5 -21 -31 -25 -44 -7 -4
4 -16 -1 -28 -13 -26 -26 -31 -26 -31 -1 0 39 30 81 73 101 49 22 59 22 109 8z
m-1352 -30 c0 -21 39 -29 87 -18 40 9 55 9 87 -5 45 -19 41 -27 -24 -49 -49
-16 -80 -9 -80 20 0 14 -3 14 -12 5 -10 -10 -9 -19 4 -42 l16 -29 -56 -11
c-31 -6 -72 -15 -90 -20 -33 -9 -33 -9 -27 24 12 59 -51 103 -72 50 -4 -10 0
-22 10 -29 19 -14 10 -31 -26 -44 -53 -20 -108 23 -110 85 -2 62 73 102 93 50
12 -32 18 -34 70 -27 35 5 47 12 57 32 10 21 18 25 43 21 16 -2 30 -8 30 -13z
m-790 -7 l22 -18 -42 -44 c-69 -74 -84 -127 -56 -195 14 -33 14 -35 -5 -35
-33 0 -79 53 -79 92 0 39 7 51 38 67 14 7 22 21 22 36 0 21 -5 25 -32 25 l-31
0 23 25 c13 14 34 25 47 25 13 0 31 9 40 20 21 24 25 25 53 2z m1880 -74 c27
17 27 17 33 -30 3 -23 14 -53 24 -67 22 -29 68 -44 94 -31 24 13 34 -4 13 -24
-26 -26 -93 -24 -141 5 -63 39 -123 129 -109 164 5 14 10 13 36 -7 26 -19 34
-20 50 -10z m-1672 -13 c43 -19 79 -50 98 -87 20 -39 18 -51 -6 -36 -11 7 -20
18 -20 24 0 24 -55 36 -101 23 -56 -16 -69 -26 -69 -55 0 -12 -5 -26 -11 -30
-6 -3 -9 -18 -6 -32 3 -15 -1 -32 -9 -39 -16 -17 -19 -43 -4 -43 16 0 11 -47
-6 -54 -27 -10 -46 -7 -60 12 -12 16 -13 16 -20 -2 -5 -14 -1 -24 16 -38 l24
-19 -34 -27 c-19 -15 -71 -60 -116 -99 -51 -46 -90 -73 -104 -73 -21 0 -20 3
13 28 40 30 71 80 82 130 4 22 12 32 25 32 29 0 50 11 50 27 0 8 -8 13 -17 11
-30 -5 -20 25 11 33 16 5 32 12 35 16 3 5 18 16 33 26 16 10 25 23 22 31 -3 8
-1 17 5 21 31 19 -19 88 -67 92 -13 1 -27 4 -29 7 -10 10 7 55 32 83 20 23 23
24 19 8 -3 -13 4 -30 20 -48 27 -30 56 -36 56 -12 0 9 -9 15 -21 15 -28 0 -21
34 9 38 18 3 21 8 16 28 -6 23 -4 24 45 24 29 0 68 -7 89 -15z m1851 -457 c80
-30 83 -75 6 -96 -46 -12 -49 -12 -75 13 -14 14 -32 25 -39 25 -7 0 -22 11
-33 25 -27 34 -38 31 -31 -9 5 -30 2 -34 -21 -38 -32 -6 -42 7 -27 36 31 57
131 77 220 44z m-1620 -38 c47 -24 91 -97 68 -112 -7 -5 -13 -1 -16 10 -2 9
-7 22 -12 28 -5 6 -7 19 -3 28 8 22 -3 20 -37 -6 -74 -58 -88 -68 -98 -68 -6
0 -11 10 -11 23 0 13 -15 34 -37 53 -33 27 -36 34 -24 48 14 16 5 22 -15 8
-14 -9 -39 -58 -47 -92 -5 -18 -14 -26 -32 -28 -16 -1 -25 3 -25 13 0 8 -7 18
-16 23 -25 14 28 69 84 87 63 20 167 13 221 -15z m1281 -69 c0 -5 -7 -14 -16
-21 -8 -8 -20 -22 -25 -32 -16 -30 19 -22 51 12 37 39 52 39 47 -2 l-3 -33
-62 0 c-52 1 -62 -2 -62 -16 0 -10 5 -21 10 -24 7 -4 -3 -22 -24 -45 -19 -21
-44 -66 -57 -100 -12 -33 -31 -68 -43 -75 -27 -20 -66 -19 -66 0 0 8 -4 15 -9
15 -5 0 -12 7 -15 16 -6 15 -4 15 11 3 17 -12 17 -12 1 5 -18 21 -38 14 -38
-14 0 -25 45 -70 70 -70 10 0 20 -5 22 -12 6 -17 60 12 75 42 13 25 13 25 13
-11 0 -19 -7 -44 -15 -55 -11 -15 -14 -55 -15 -187 0 -130 -3 -172 -14 -182
-7 -8 -16 -11 -19 -8 -9 9 -65 -17 -71 -32 -3 -8 2 -19 11 -26 13 -11 17 -10
28 4 12 17 14 16 33 -10 25 -35 28 -60 13 -98 -7 -16 -10 -38 -7 -48 3 -11 0
-16 -7 -13 -7 3 -37 12 -67 22 -69 21 -77 33 -39 58 25 15 33 16 39 6 11 -18
42 -4 38 17 -2 10 -13 19 -26 21 -13 2 -19 8 -16 14 5 7 -4 9 -24 6 l-30 -4
-7 100 c-3 56 -5 213 -3 351 l3 250 35 -3 c30 -3 38 1 50 24 12 21 12 28 3 31
-24 8 -7 83 18 83 6 0 7 -4 4 -10 -12 -20 12 -9 35 15 16 17 27 22 34 15 18
-18 81 -12 101 10 19 21 35 26 35 11z m-1053 -31 c17 0 34 4 38 10 3 5 14 1
25 -10 11 -11 34 -20 50 -20 23 0 30 -4 30 -20 0 -11 4 -20 9 -20 13 0 32 26
24 33 -3 4 1 7 11 7 26 0 30 -28 10 -65 -18 -34 -44 -54 -44 -35 0 6 -9 10
-20 10 -11 0 -20 -4 -20 -10 0 -14 35 -30 64 -30 24 0 25 -2 28 -82 7 -164 9
-594 3 -610 -5 -13 -14 -16 -35 -12 -21 5 -31 1 -44 -17 -21 -31 -20 -39 4
-39 11 0 20 5 20 10 0 20 20 9 35 -20 22 -42 20 -43 -31 -36 -41 5 -48 3 -72
-24 l-27 -30 3 40 c2 23 -1 47 -8 55 -10 11 -10 15 0 15 6 0 10 8 8 18 -4 19
29 62 48 62 7 0 14 -6 17 -12 4 -10 7 -10 18 0 12 11 10 16 -11 33 -14 11 -35
18 -47 16 -12 -2 -24 2 -27 9 -3 7 -6 97 -7 200 -1 133 -5 197 -15 220 -21 50
-17 59 11 25 26 -30 30 -31 128 -23 26 3 23 52 -3 52 -11 0 -20 -7 -20 -15 0
-26 -39 -17 -74 18 -18 17 -47 57 -63 87 -27 48 -29 57 -16 69 11 10 12 16 3
25 -8 8 -13 8 -19 -2 -9 -15 -37 -6 -45 14 -3 8 -11 14 -18 14 -7 0 -14 13
-15 30 -2 27 0 29 17 17 11 -6 20 -15 20 -20 0 -4 5 -5 10 -2 7 4 7 12 0 25
-5 10 -14 30 -20 45 -10 25 -9 26 12 11 12 -9 37 -16 55 -16z m403 -542 l0
-541 -27 36 c-16 20 -47 44 -70 54 -51 22 -61 52 -26 85 l24 22 6 -22 c6 -26
31 -29 40 -5 8 21 -45 65 -69 57 -9 -2 -18 -1 -21 3 -2 4 -8 162 -12 351 l-7
342 25 0 c43 0 87 21 87 41 0 23 -18 25 -26 4 -10 -24 -31 -17 -51 18 -25 41
-19 57 20 57 18 0 48 8 67 19 19 10 36 19 38 20 1 0 2 -243 2 -541z m227 495
c75 -7 78 -9 51 -42 -26 -33 -38 -38 -38 -17 0 20 -19 31 -32 18 -8 -8 -6 -19
6 -38 15 -21 23 -25 47 -20 l28 5 3 -342 c3 -290 1 -344 -12 -353 -8 -7 -20
-9 -27 -7 -16 6 -53 -24 -53 -43 0 -18 19 -27 33 -16 8 7 19 5 33 -7 28 -23
16 -57 -24 -66 -16 -3 -48 -22 -71 -41 l-41 -36 0 528 0 527 24 -22 c16 -16
41 -25 73 -28z m-992 -53 l25 -10 -27 -42 c-24 -36 -28 -53 -31 -129 -4 -101
-17 -124 -51 -90 -23 23 -41 27 -41 9 0 -6 -9 -3 -20 7 -24 21 -26 60 -5 81
31 31 7 95 -30 80 -24 -9 -18 37 8 61 42 38 119 53 172 33z m1823 -40 c48 -34
50 -40 22 -58 -24 -15 -27 -64 -5 -82 17 -14 20 -57 5 -80 -5 -8 -20 -15 -32
-15 -16 0 -23 -5 -22 -18 3 -31 -30 -15 -49 26 -16 32 -18 51 -12 147 7 105 9
110 30 110 12 0 41 -14 63 -30z m-2581 -16 c-1 -37 16 -57 44 -50 37 8 52 -93
22 -145 -20 -33 -28 -36 -37 -13 -4 11 -13 14 -31 10 l-25 -6 0 120 c0 96 3
120 14 120 10 0 14 -11 13 -36z m425 -86 c22 -31 67 -58 97 -58 26 0 31 -20
13 -47 -14 -23 -42 -31 -42 -13 0 6 -7 10 -15 10 -19 0 -20 -24 -2 -39 8 -6
24 -12 38 -13 13 -2 27 -7 31 -13 9 -12 10 -219 1 -228 -3 -4 -12 -2 -20 5
-20 17 -63 -9 -63 -38 0 -31 18 -40 34 -18 12 16 15 14 31 -19 17 -36 17 -37
-5 -55 -17 -14 -21 -24 -15 -41 5 -18 13 -21 38 -19 29 3 32 0 35 -29 5 -50
-40 -93 -103 -101 -45 -5 -49 -4 -37 11 8 9 11 25 7 38 -6 16 -13 20 -36 17
-24 -4 -29 -1 -33 21 -7 39 -7 587 1 661 6 62 7 64 15 30 6 -19 19 -47 30 -62z
m2380 -335 c-4 -307 -4 -313 -24 -313 -17 0 -20 -5 -16 -30 4 -28 2 -30 -28
-30 -69 0 -114 43 -114 110 0 27 3 30 30 30 34 0 38 13 16 55 -13 25 -13 31 2
51 23 31 44 38 58 20 6 -9 19 -16 28 -16 27 0 18 30 -14 48 -16 9 -30 20 -30
25 0 4 -11 5 -24 1 -24 -6 -24 -4 -31 78 -10 119 -7 134 28 130 33 -3 77 21
77 43 0 20 -20 19 -35 -1 -10 -15 -14 -14 -38 12 -15 16 -27 32 -27 35 0 4 21
12 48 18 26 7 58 20 72 31 14 10 25 18 25 17 0 -1 -1 -143 -3 -314z m-434 234
l39 10 7 -178 c8 -196 3 -219 -44 -219 -15 0 -35 -6 -45 -14 -17 -12 -17 -16
-5 -31 13 -16 15 -16 28 2 13 17 14 17 33 -6 21 -26 26 -77 7 -84 -19 -7 4
-41 31 -45 31 -5 29 -65 -4 -97 -41 -41 -135 -30 -135 17 0 17 5 19 27 15 55
-10 85 30 38 51 -28 13 -67 3 -97 -25 -23 -21 -24 -1 -2 33 14 21 16 51 12
214 -5 199 -4 205 40 193 22 -5 67 20 76 43 8 22 -23 29 -38 9 -11 -15 -15
-16 -34 -4 -12 8 -22 19 -22 26 0 7 -3 13 -7 13 -5 0 -8 11 -8 25 0 14 3 25 8
25 4 0 13 12 19 26 11 24 13 24 25 9 10 -15 18 -16 51 -8z m-1523 -13 c22 5
35 3 35 -4 0 -6 11 -15 24 -21 23 -11 43 -49 26 -49 -4 0 -11 -11 -14 -24 -7
-30 -37 -43 -48 -21 -12 21 -28 19 -28 -4 0 -20 49 -46 82 -44 17 2 18 -11 18
-191 0 -153 3 -196 15 -212 16 -21 20 -64 6 -64 -5 0 -12 16 -15 35 -12 61
-91 101 -91 45 0 -19 6 -26 25 -29 42 -5 50 -31 20 -61 -31 -31 -85 -27 -121
9 -35 35 -42 90 -10 69 35 -22 67 24 38 54 -18 19 -15 39 11 73 20 27 23 28
33 13 14 -24 39 -23 39 2 0 18 -54 53 -76 49 -33 -6 -34 1 -34 197 0 173 2
195 15 184 9 -8 28 -10 50 -6z m1337 -30 c44 -7 46 -15 17 -56 -19 -26 -23
-28 -31 -14 -12 21 -38 20 -38 -1 0 -21 54 -55 75 -47 13 5 15 -20 15 -183 l0
-190 -22 1 c-21 1 -23 -3 -20 -45 2 -31 -2 -50 -12 -58 -8 -7 -20 -28 -26 -47
-14 -42 -33 -43 -71 -5 -35 35 -40 81 -9 81 15 0 20 7 20 24 0 14 -4 28 -10
31 -17 10 -11 37 12 62 25 27 43 29 52 8 8 -22 26 -18 26 5 0 30 -36 61 -68
58 l-27 -3 1 175 c0 125 4 185 14 212 l14 36 25 -19 c13 -11 41 -22 63 -25z
m-1054 -185 c2 -129 0 -199 -7 -195 -16 10 -59 -12 -66 -34 -10 -32 12 -51 30
-27 13 17 15 16 26 -24 10 -33 11 -43 1 -47 -7 -2 -12 -15 -12 -28 0 -18 5
-24 21 -24 19 0 21 -4 15 -32 -6 -29 -41 -78 -56 -78 -3 0 -14 16 -23 35 -9
19 -21 35 -27 35 -14 0 -13 36 2 59 15 25 -6 54 -33 46 -18 -6 -19 3 -19 180
l0 187 31 -7 c35 -8 69 15 69 47 0 21 -4 22 -31 6 -18 -12 -23 -9 -45 17 -33
41 -32 65 4 65 15 0 43 8 62 18 20 11 39 15 45 10 6 -5 11 -92 13 -209z m1366
139 c26 -22 72 -20 70 4 -1 13 6 17 27 15 l29 -2 0 -96 c0 -107 -4 -115 -60
-121 -28 -2 -36 -8 -38 -27 -3 -21 -1 -23 21 -17 48 12 77 -51 49 -108 -19
-40 -11 -119 17 -156 17 -23 19 -34 11 -55 -8 -22 -15 -25 -61 -25 l-51 0 6
34 c8 40 -12 96 -40 117 -24 18 -24 48 0 77 20 23 46 30 46 12 0 -5 9 -10 20
-10 28 0 25 18 -6 47 -18 16 -35 23 -50 20 -14 -2 -25 2 -28 10 -4 8 -6 87 -6
176 1 127 3 158 12 142 6 -11 21 -28 32 -37z m-1752 -317 c-1 -3 -8 1 -14 7
-20 20 -48 14 -60 -13 -9 -20 -9 -28 1 -38 10 -9 15 -8 26 6 13 18 14 17 29
-12 21 -40 20 -48 -7 -80 -13 -16 -29 -53 -37 -85 -10 -42 -17 -55 -28 -51 -7
3 -26 5 -42 5 -38 0 -42 25 -13 69 27 40 31 116 8 147 -8 10 -15 27 -15 36 0
24 33 78 48 78 7 0 11 -4 8 -9 -8 -12 21 -34 34 -26 30 19 -25 75 -74 75 l-26
0 0 109 c0 61 4 112 8 115 5 3 6 -8 4 -24 -3 -21 1 -32 12 -36 22 -9 34 5 16
17 -13 8 -13 10 0 18 10 7 17 6 22 -2 6 -9 8 -9 8 1 0 6 8 12 18 12 9 0 28 9
42 20 l25 19 5 -177 c3 -97 4 -179 2 -181z m-721 329 c22 0 26 -4 22 -19 -3
-11 -15 -21 -26 -23 -28 -4 -36 -38 -9 -38 16 0 21 -11 29 -62 9 -58 9 -64 -9
-77 -11 -7 -28 -27 -39 -45 l-19 -31 0 160 c0 124 3 156 12 147 7 -7 24 -12
39 -12z m2947 -500 c6 0 12 -12 12 -27 0 -35 9 -48 38 -57 12 -4 22 -15 22
-25 0 -9 -10 -28 -22 -41 -13 -14 -22 -28 -20 -33 1 -4 -12 -6 -30 -4 -18 2
-32 6 -30 10 13 32 13 40 2 47 -7 5 -19 6 -27 3 -10 -4 -13 12 -13 75 l0 81
28 -15 c15 -7 33 -14 40 -14z m-1667 -130 c22 -12 26 -50 5 -50 -23 0 -30 -20
-13 -38 13 -13 19 -13 32 -2 21 17 28 8 16 -21 -6 -13 -15 -79 -20 -147 -10
-139 -28 -187 -60 -158 -10 9 -22 16 -28 16 -13 0 -25 -28 -17 -41 9 -15 78
-10 104 8 22 16 23 16 17 -1 -4 -10 -7 -97 -8 -194 0 -119 -3 -169 -9 -154 -6
13 -15 22 -22 21 -7 -2 -23 0 -35 5 -31 10 -65 -10 -61 -37 4 -31 36 -34 47
-5 8 22 8 22 23 -7 9 -17 19 -46 23 -65 3 -19 9 -43 11 -52 4 -14 0 -16 -30
-11 -20 3 -42 1 -49 -5 -11 -9 -13 -6 -9 17 2 17 -5 49 -18 77 -28 61 -30 98
-8 127 l17 22 16 -23 c18 -25 35 -21 35 10 0 11 -8 22 -20 25 -11 3 -20 9 -20
14 0 5 -10 8 -22 6 l-23 -2 -2 266 -3 266 30 -5 c37 -5 70 20 70 54 0 31 -42
58 -75 50 -18 -5 -23 -3 -19 8 12 31 86 46 125 26z m-762 -12 c-2 -44 -69
-103 -69 -63 0 15 -2 15 -16 4 -15 -12 -14 -15 10 -36 29 -25 51 -29 61 -13
20 33 25 -27 25 -305 l0 -303 -47 14 c-50 15 -70 8 -59 -22 5 -12 14 -15 36
-10 29 5 30 5 30 -34 0 -39 -24 -115 -42 -135 -6 -6 -7 -3 -3 8 4 9 3 17 -3
17 -15 0 -21 -26 -24 -97 -2 -37 -7 -61 -13 -59 -5 2 -18 -1 -27 -6 -17 -8
-18 15 -18 422 l0 431 29 -22 c35 -26 76 -20 86 12 13 39 -34 66 -54 32 -9
-16 -12 -16 -21 -3 -17 26 -11 85 11 114 19 24 82 64 102 66 4 0 7 -6 6 -12z
m2076 -6 c-1 -18 -43 -72 -57 -72 -4 0 -8 6 -8 14 0 20 -19 31 -32 18 -8 -8
-6 -19 6 -37 15 -22 24 -26 52 -23 l34 4 0 -296 c0 -285 -1 -298 -20 -315 -11
-10 -18 -25 -16 -34 2 -9 7 -26 11 -38 5 -20 3 -23 -19 -23 -26 0 -66 -41 -66
-67 0 -9 -10 -2 -25 15 l-25 30 0 295 0 295 30 7 c37 8 60 39 60 80 0 53 -42
72 -88 39 -37 -25 -28 9 14 55 49 55 152 91 149 53z m-466 -80 c7 -15 26 -39
42 -54 l29 -27 0 -220 c0 -120 -3 -226 -6 -235 -6 -16 -54 -23 -54 -8 0 4 -16
14 -35 21 -28 10 -39 10 -50 1 -13 -11 -15 25 -15 273 0 180 -4 288 -10 292
-5 3 -10 12 -10 19 0 22 93 -31 109 -62z m-1017 -289 c-3 -287 -3 -293 -22
-281 -15 10 -22 10 -31 1 -9 -9 -16 -7 -29 10 -10 12 -26 22 -37 22 -17 0 -18
14 -21 228 -3 233 2 265 35 253 15 -6 43 42 43 73 0 12 11 26 28 35 27 14 27
14 32 -17 3 -18 4 -164 2 -324z m758 291 c20 4 30 1 34 -10 5 -12 17 -15 51
-12 l45 3 0 -251 0 -251 -43 0 c-24 0 -49 -3 -55 -7 -14 -8 -16 -46 -3 -46 20
0 41 12 35 21 -3 5 1 6 8 4 18 -7 40 -44 48 -80 5 -24 1 -34 -18 -49 -30 -24
-52 -74 -52 -121 0 -32 -2 -35 -19 -25 -11 5 -29 10 -41 10 -21 0 -22 3 -15
47 12 82 56 137 77 98 12 -21 38 -19 38 3 0 24 -35 44 -65 36 -16 -4 -25 -2
-25 6 0 7 -8 10 -20 7 -19 -5 -20 0 -20 140 0 88 -4 153 -11 166 -10 18 -7 17
14 -5 20 -22 35 -28 67 -28 28 0 43 5 47 16 9 25 -12 38 -31 18 -17 -16 -19
-16 -36 2 -12 12 -23 46 -31 94 -6 41 -20 104 -30 139 -11 35 -19 76 -19 90
l0 25 21 -23 c16 -17 29 -22 49 -17z m-1068 9 c10 -9 28 -21 41 -28 13 -6 22
-20 21 -33 0 -12 5 -22 13 -22 15 0 36 46 28 60 -3 4 12 11 33 15 20 4 43 11
50 17 9 8 12 6 12 -6 0 -8 -14 -23 -30 -31 -17 -9 -35 -26 -39 -38 -5 -13 -6
-120 -3 -239 5 -232 2 -249 -41 -241 -27 5 -62 -29 -54 -52 9 -21 37 -19 37 3
0 14 4 12 19 -8 31 -40 32 -65 1 -84 -32 -18 -54 -66 -48 -101 4 -17 1 -25 -9
-25 -8 0 -17 -8 -20 -18 -4 -15 -14 -19 -54 -18 -56 1 -60 11 -24 55 l26 30
-1 373 c-1 342 2 408 18 408 3 0 14 -7 24 -17z m1438 -11 c-8 -29 3 -62 20
-62 6 0 10 9 10 20 0 17 7 20 40 20 33 0 40 -3 40 -20 0 -11 9 -35 20 -55 19
-33 20 -54 20 -335 0 -188 -4 -301 -10 -305 -16 -10 -12 -23 11 -30 18 -6 59
-59 59 -78 0 -4 -11 -5 -24 -1 -13 3 -33 1 -45 -5 -17 -9 -24 -7 -45 15 -17
19 -31 25 -45 21 -19 -5 -21 -1 -21 43 0 32 -6 55 -19 71 -14 17 -17 29 -10
41 5 10 7 18 4 18 -2 0 3 9 12 20 18 21 43 27 43 10 0 -5 7 -10 15 -10 8 0 15
9 15 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 16 0 11 -8 14 -32 12 l-33 -3 -5
257 c-5 242 -6 258 -25 272 -11 9 -22 16 -25 16 -3 0 -11 9 -18 20 -11 19 -10
20 32 20 44 0 45 0 36 -28z m520 -40 c0 -40 -18 -76 -27 -54 -7 17 -50 15 -57
-3 -17 -46 39 -70 85 -36 l29 21 0 -435 c0 -270 -4 -434 -10 -430 -5 3 -7 12
-4 19 3 7 -2 21 -11 30 -14 13 -16 28 -10 74 6 56 -5 112 -23 112 -5 0 -7 -13
-4 -28 2 -17 -2 -33 -12 -41 -13 -11 -17 -5 -31 41 -30 95 -34 119 -23 148 12
35 38 47 54 25 15 -21 48 -16 52 8 6 30 -55 42 -115 23 l-23 -7 0 275 0 274
46 -1 c34 -2 48 2 55 16 15 26 29 11 29 -31z m-2136 -7 c29 -27 47 -31 77 -15
19 10 19 4 19 -329 l0 -340 -25 -37 c-14 -20 -25 -50 -25 -67 0 -31 -34 -112
-51 -123 -15 -9 -36 13 -42 45 -4 21 -15 31 -44 42 l-37 15 22 22 c32 32 68
26 65 -11 -1 -20 3 -27 17 -27 26 0 33 15 25 52 -5 21 -16 34 -35 41 -26 10
-27 12 -14 33 8 12 19 36 24 53 7 26 5 33 -11 41 -18 10 -19 25 -19 314 0 166
3 306 7 309 11 11 19 8 47 -18z m1057 -65 c6 0 9 -8 7 -17 -2 -10 -12 -18 -23
-18 -11 0 -20 -6 -19 -12 1 -7 2 -21 3 -31 2 -29 41 -64 65 -60 21 4 22 2 13
-32 -11 -39 -3 -90 14 -90 6 0 7 5 4 11 -4 5 -4 22 0 37 l7 27 19 -24 c17 -21
19 -40 19 -182 0 -135 -2 -159 -15 -159 -8 0 -15 -4 -15 -9 0 -5 -14 -9 -30
-9 -35 1 -70 -33 -70 -69 0 -18 5 -23 25 -23 20 0 25 5 25 25 0 49 58 23 75
-35 8 -24 8 -24 -19 -6 -24 15 -30 16 -57 2 -16 -8 -27 -10 -24 -5 12 18 -10
8 -37 -18 l-28 -27 0 246 c0 134 3 306 6 380 7 131 7 135 25 117 11 -11 24
-19 30 -19z m-189 -368 l-5 -383 -22 21 c-12 11 -29 20 -38 20 -9 0 -17 5 -17
11 0 7 -13 10 -34 7 -30 -3 -33 -2 -25 14 10 19 52 34 67 24 6 -3 8 -15 5 -26
-4 -16 0 -20 21 -20 34 0 33 19 -1 69 -23 33 -34 40 -60 41 l-33 0 0 166 c0
132 3 173 16 200 13 27 14 37 4 49 -10 13 -9 15 10 15 13 0 24 -8 27 -20 3
-11 10 -17 15 -14 5 3 7 17 4 29 -5 20 -1 24 24 30 20 4 32 14 36 30 7 28 -11
65 -31 65 -18 0 -20 -36 -2 -43 6 -3 -1 -6 -16 -6 -19 -1 -39 9 -58 28 -41 42
-37 50 18 40 43 -8 50 -7 72 16 14 13 26 23 27 22 1 -1 -1 -174 -4 -385z
m-1336 350 c0 -9 9 -21 22 -27 28 -12 29 -49 1 -69 -12 -8 -19 -18 -16 -23 3
-5 -3 -9 -14 -9 -12 0 -18 5 -14 14 3 7 0 22 -6 33 -8 16 -13 18 -25 8 -39
-32 10 -105 66 -97 17 3 33 1 36 -4 3 -5 10 -6 15 -3 12 8 12 -22 -1 -46 -7
-12 -21 -19 -38 -19 -28 0 -62 -29 -62 -54 0 -20 32 -28 63 -17 22 9 26 7 36
-17 20 -52 23 -81 11 -135 -13 -65 -43 -103 -33 -42 5 30 3 37 -14 41 -13 4
-23 20 -31 49 -8 29 -22 52 -42 66 -26 20 -28 25 -16 38 15 18 26 68 19 88 -3
7 -12 13 -20 13 -8 0 -12 4 -9 9 7 11 -22 23 -36 14 -7 -4 -8 -1 -3 7 5 8 2
17 -7 24 -29 21 -39 52 -23 72 12 15 16 16 24 6 14 -23 46 -10 46 18 0 14 2
27 5 30 3 3 11 17 17 32 9 19 17 25 31 21 11 -2 19 -12 18 -21z m2876 16 c33
-19 45 -32 52 -56 8 -23 40 -30 52 -11 11 19 27 -8 20 -36 -5 -18 -15 -27 -36
-32 -33 -6 -36 -13 -18 -31 10 -10 7 -16 -10 -28 -12 -8 -22 -22 -22 -30 0 -8
-7 -17 -16 -21 -12 -5 -15 -14 -10 -44 5 -32 2 -42 -19 -63 -13 -13 -27 -37
-31 -53 -4 -15 -16 -43 -27 -61 -16 -28 -18 -38 -8 -58 18 -40 -7 -17 -29 26
-22 42 -27 121 -10 164 8 22 14 25 41 19 34 -7 49 6 49 44 0 26 -28 44 -57 37
-24 -7 -28 -1 -37 46 l-7 35 21 -32 c18 -27 25 -31 41 -23 10 6 19 17 19 25 0
8 7 15 15 15 8 0 26 12 40 28 33 35 32 85 -2 90 -19 3 -23 -1 -23 -22 0 -14 5
-26 11 -26 5 0 7 -4 4 -10 -11 -18 -35 -11 -35 10 0 12 -6 20 -12 18 -31 -5
-58 14 -58 41 0 36 10 45 27 23 12 -16 14 -16 31 5 20 27 17 26 44 11z m-3044
-311 c9 -11 28 -24 42 -29 22 -8 23 -10 8 -19 -10 -6 -18 -21 -18 -34 0 -27
17 -34 26 -10 8 21 30 19 53 -6 24 -26 35 -63 16 -55 -21 8 -85 -13 -85 -28 0
-8 -7 -16 -15 -20 -18 -7 -20 -52 -2 -59 7 -2 13 -20 12 -38 0 -29 -4 -34 -22
-33 -22 1 -32 -19 -15 -29 4 -3 6 -44 5 -91 -2 -71 -5 -88 -23 -106 l-20 -20
1 323 c0 223 3 314 10 297 6 -13 18 -33 27 -43z m3208 -137 c1 -127 -2 -179
-10 -177 -7 3 -10 25 -8 56 3 46 1 53 -17 58 -11 3 -21 15 -23 26 -4 29 -56
55 -70 36 -8 -11 -9 -10 -4 4 4 13 0 17 -20 17 -22 0 -23 2 -14 20 17 32 59
55 79 43 9 -5 16 -16 16 -23 0 -7 5 -15 12 -17 14 -5 28 40 19 63 -5 12 27 74
37 74 1 0 2 -81 3 -180z m-2153 -131 c44 -12 107 -64 107 -88 0 -13 -8 -16
-35 -16 -56 2 -71 -15 -86 -100 -9 -51 -17 -75 -27 -75 -29 0 -4 -25 32 -32
45 -10 96 -43 96 -64 0 -8 -4 -14 -10 -14 -5 0 -10 -4 -10 -10 0 -17 28 -11
43 10 14 20 14 20 31 1 10 -11 32 -23 50 -26 56 -11 66 -16 66 -31 0 -12 -39
-14 -247 -14 l-248 1 57 29 c31 16 75 32 97 36 36 6 41 4 52 -19 18 -40 69
-46 69 -8 0 22 -19 41 -41 41 -9 0 -19 7 -23 15 -3 9 -19 15 -41 15 -30 0 -35
3 -35 24 0 14 7 31 15 40 24 23 47 98 35 110 -14 14 -13 26 3 26 13 0 57 76
57 98 0 6 -4 12 -10 12 -5 0 -10 -4 -10 -9 0 -17 -31 -21 -54 -5 -13 8 -28 14
-32 13 -51 -9 -51 15 0 37 38 16 51 17 99 3z m-947 -95 c-18 -18 -21 -80 -5
-121 12 -32 58 -82 77 -83 6 0 12 -6 12 -14 0 -8 10 -16 23 -18 17 -2 22 -10
24 -33 1 -16 -1 -22 -3 -12 -3 9 -11 17 -18 17 -7 0 -24 13 -37 28 -13 16 -32
31 -41 35 -10 3 -18 14 -18 22 0 24 -19 8 -35 -31 -8 -19 -21 -34 -29 -34 -14
0 -19 -10 -29 -65 -3 -18 -5 -17 -17 8 -18 36 -3 81 27 85 29 4 35 25 16 62
-22 45 -13 128 17 152 29 24 59 26 36 2z m784 -152 c0 -48 -19 -100 -44 -121
-15 -13 -19 -13 -27 0 -21 33 5 100 33 83 6 -4 9 -1 6 7 -7 22 -45 22 -66 1
-11 -11 -26 -46 -33 -79 -7 -32 -18 -61 -23 -65 -19 -14 -63 -8 -86 10 -22 17
-21 17 18 17 22 0 47 7 56 14 20 17 21 68 1 87 -8 9 -15 23 -15 32 0 14 8 16
50 14 50 -4 50 -4 66 35 9 21 21 41 28 45 16 11 35 -31 36 -80z m1020 63 c-17
-21 -5 -45 24 -45 31 0 41 -38 22 -84 -45 -108 -248 -78 -278 42 -8 28 -5 28
28 -3 32 -30 88 -34 103 -7 8 13 12 14 22 5 21 -21 51 3 49 40 -3 49 -1 55 18
60 9 3 19 5 21 6 2 0 -2 -6 -9 -14z m404 -5 c7 0 28 -13 46 -30 20 -18 45 -30
61 -30 27 0 27 0 13 -29 -28 -56 14 -119 71 -109 37 7 12 -19 -25 -27 -46 -10
-84 14 -90 55 -3 23 -10 35 -20 34 -10 -1 -15 9 -16 28 -1 36 -23 78 -41 78
-15 0 -17 -16 -3 -25 13 -8 13 -55 1 -55 -5 0 -11 -10 -14 -22 -3 -20 -6 -19
-20 14 -9 21 -17 50 -17 65 0 31 31 84 38 65 2 -7 9 -12 16 -12z m-1066 -15
c7 -14 12 -34 12 -43 1 -27 27 -54 54 -57 14 -2 30 -6 35 -10 6 -3 27 -3 46 1
27 5 37 12 42 33 3 14 12 35 19 46 13 18 14 15 14 -27 0 -41 -5 -52 -35 -82
-84 -85 -255 -41 -255 66 0 25 4 38 13 37 28 -3 37 2 37 25 0 13 -6 27 -12 29
-9 4 -7 6 3 6 9 1 21 -10 27 -24z m34 -307 c-7 -7 -12 -31 -12 -54 0 -45 24
-74 62 -74 25 0 12 -28 -23 -45 -35 -19 -59 -19 -59 -1 0 9 -10 13 -31 12 -18
0 -34 5 -36 12 -3 6 2 12 11 12 9 0 16 7 16 15 0 8 -6 15 -13 15 -21 0 -48
-35 -41 -54 17 -43 -77 7 -101 55 -20 37 -8 78 22 76 13 -2 18 5 17 21 -1 21
2 22 100 22 78 0 97 -3 88 -12z m772 -3 c-4 -8 1 -17 10 -21 31 -12 19 -59
-24 -98 -22 -20 -44 -36 -49 -36 -5 0 -18 -3 -28 -6 -10 -3 -3 3 15 15 26 17
32 27 32 56 0 33 -20 55 -49 55 -17 0 -13 -30 4 -36 22 -9 18 -32 -7 -40 -15
-4 -26 -1 -35 12 -12 16 -15 17 -23 4 -5 -8 -10 -18 -10 -21 0 -4 -11 -14 -25
-23 -23 -15 -26 -15 -52 9 l-28 24 24 1 c14 0 33 7 43 17 20 18 24 73 6 91 -9
9 12 12 95 12 90 0 106 -2 101 -15z m526 -35 c27 0 46 -28 33 -48 -13 -22 0
-60 24 -69 10 -4 22 -12 26 -20 10 -17 59 -17 76 0 11 10 12 9 6 -2 -15 -27
-68 -40 -116 -30 -62 14 -89 42 -82 85 4 25 2 35 -11 40 -9 3 -16 11 -16 17 0
16 34 50 38 38 2 -6 12 -11 22 -11z m-1915 -21 c4 -12 2 -24 -4 -28 -5 -4 -19
-27 -31 -53 -24 -55 -56 -78 -107 -77 -41 0 -94 17 -60 18 10 1 20 8 23 16 4
8 12 15 20 15 17 0 37 37 38 70 1 18 7 26 24 28 12 2 22 7 22 12 0 5 12 11 28
13 15 2 30 5 34 6 4 0 10 -9 13 -20z m1657 -11 c-17 -17 -15 -45 4 -52 23 -9
46 13 38 36 -5 17 -3 18 15 9 23 -13 28 -36 10 -47 -7 -4 -9 -23 -6 -49 5 -33
2 -46 -12 -59 -24 -22 -57 -20 -82 5 -17 17 -23 19 -30 8 -6 -10 -11 -6 -19
15 -8 21 -7 32 1 42 16 19 3 30 -16 14 -32 -26 -2 -114 46 -134 26 -11 28 -14
15 -26 -9 -7 -16 -23 -16 -35 0 -29 -28 -41 -45 -19 -12 17 -37 16 -80 -2 -18
-7 -18 -6 3 20 37 48 23 106 -26 106 -33 0 -77 -58 -69 -90 5 -18 0 -20 -52
-20 -71 0 -120 25 -139 70 -12 31 -12 32 2 21 11 -9 30 -11 60 -7 39 6 46 4
56 -15 14 -25 34 -12 28 17 -3 17 23 54 38 54 3 0 15 11 26 25 23 30 38 33 31
6 -3 -14 4 -23 28 -35 36 -18 79 -21 79 -6 0 6 -7 10 -15 10 -8 0 -19 9 -25
21 -29 54 49 125 140 128 19 1 21 -2 12 -11z m-1404 -14 c23 -16 30 -58 12
-69 -15 -10 -12 -25 6 -25 11 0 14 -8 12 -27 -2 -16 -8 -28 -14 -28 -6 0 -18
-8 -28 -17 -10 -10 -23 -18 -31 -18 -7 0 -21 -3 -30 -7 -15 -5 -16 -3 -6 15
14 27 14 79 0 88 -8 5 -6 10 5 16 19 11 21 34 3 45 -8 4 -16 16 -20 26 -5 14
0 17 31 17 21 0 48 -7 60 -16z m2282 -227 c0 -191 0 -192 -20 -167 -12 16 -15
29 -9 35 5 5 9 18 9 28 0 16 -5 18 -33 13 -25 -5 -38 -2 -50 11 -23 26 -30 65
-17 98 13 35 30 47 46 34 29 -24 60 21 33 48 -8 8 -7 17 5 34 9 13 16 34 16
47 0 13 4 20 10 17 6 -4 10 -81 10 -198z m-3129 163 c22 0 28 -6 33 -32 4 -19
21 -46 40 -63 41 -36 54 -76 32 -94 -11 -9 -16 -10 -16 -2 0 6 -7 11 -15 11
-21 0 -19 -27 4 -39 16 -9 17 -15 7 -56 -9 -34 -21 -52 -49 -72 -21 -15 -40
-38 -44 -52 -3 -14 -10 -31 -15 -38 -12 -15 -2 -85 20 -136 19 -44 66 -77 111
-77 19 0 30 -4 26 -10 -10 -16 -93 -12 -120 6 -45 30 -107 167 -88 197 3 6 7
26 9 45 4 41 19 62 46 62 14 0 19 6 17 23 -2 12 5 35 15 50 28 42 32 118 10
175 -10 26 -18 56 -18 67 -1 11 -8 20 -16 20 -21 1 -40 14 -40 28 0 8 3 8 12
-1 7 -7 24 -12 39 -12z m956 -7 c8 -35 53 -81 91 -94 17 -6 32 -21 39 -39 12
-33 49 -44 38 -11 -5 15 1 20 31 25 43 9 49 4 34 -24 -16 -30 -69 -50 -130
-50 -48 0 -52 2 -61 30 -12 33 -53 45 -43 13 5 -15 4 -16 -5 -3 -15 21 -14 27
10 33 28 8 23 51 -7 55 -54 8 -71 -66 -28 -117 31 -38 22 -62 -12 -30 -13 12
-30 19 -38 16 -23 -9 -20 -27 4 -27 17 0 47 -36 38 -46 -3 -2 -23 5 -46 16
-22 11 -46 20 -52 20 -5 0 -10 6 -10 14 0 7 -9 25 -21 40 l-20 26 25 0 c60 0
106 44 106 103 0 31 22 77 37 77 7 0 16 -12 20 -27z m-571 -98 c25 -9 31 -14
18 -15 -24 0 -47 -38 -42 -67 1 -11 -3 -27 -11 -36 -7 -9 -24 -42 -37 -74
l-24 -58 0 159 0 160 29 -28 c16 -15 46 -34 67 -41z m1263 -60 c-3 -5 -12 -34
-18 -63 -9 -44 -8 -58 4 -82 16 -31 33 -38 51 -20 8 8 14 7 22 -2 8 -10 1 -14
-31 -16 -48 -3 -77 -25 -77 -59 0 -16 6 -23 20 -23 11 0 23 7 26 15 4 8 15 15
25 15 12 0 16 -5 13 -14 -3 -8 2 -26 11 -40 11 -16 13 -26 6 -26 -6 0 -11 5
-11 10 0 14 -74 12 -88 -2 -9 -9 -12 37 -12 207 l0 219 33 -54 c19 -30 30 -59
26 -65z m-239 -105 c0 -199 0 -201 -20 -190 -33 18 -83 8 -96 -18 -10 -20 -12
-21 -18 -7 -11 31 -6 62 14 80 23 21 50 13 50 -15 0 -25 38 -28 47 -4 4 12 -5
26 -28 45 -36 30 -68 36 -86 18 -21 -21 -22 8 -2 30 15 16 24 19 34 11 15 -12
45 -3 45 14 0 7 -5 38 -11 69 -11 56 -11 57 19 91 17 19 33 44 37 55 3 12 8
21 11 21 2 0 4 -90 4 -200z m-925 51 c-3 -11 1 -25 10 -34 9 -10 11 -18 5 -22
-5 -3 -10 -15 -10 -25 0 -10 -8 -23 -17 -29 -11 -6 -17 -22 -17 -48 0 -21 -2
-40 -5 -43 -3 -3 -11 -15 -18 -27 -14 -24 -2 -74 23 -104 8 -8 14 -21 14 -27
0 -11 14 -17 72 -26 26 -5 40 -2 52 9 15 15 16 14 16 -14 0 -45 -36 -65 -100
-58 -58 7 -94 30 -111 70 -10 24 -9 34 5 58 20 34 15 59 -11 59 -14 0 -12 5 9
28 14 15 26 34 26 42 1 8 6 33 13 56 15 51 6 94 -22 94 -16 0 -18 -3 -10 -18
19 -35 -45 -130 -71 -104 -17 17 44 135 85 161 42 27 71 28 62 2z m1448 -35
l29 6 -7 -46 c-6 -39 -5 -46 9 -46 9 0 16 6 16 14 0 8 7 17 16 20 12 5 15 0
12 -22 -3 -25 -8 -28 -56 -33 -56 -7 -82 -22 -83 -48 0 -12 -3 -10 -11 6 -14
29 -4 53 22 53 11 0 20 4 20 10 0 14 -32 28 -79 36 -38 6 -41 9 -41 39 l0 32
63 -14 c34 -7 75 -10 90 -7z m-795 -13 c40 -4 52 -9 48 -20 -4 -8 -6 -21 -6
-29 0 -10 -8 -12 -30 -8 -34 7 -60 -9 -60 -37 0 -12 7 -19 19 -19 26 0 38 -18
46 -68 5 -29 15 -48 31 -59 13 -8 24 -21 24 -29 0 -15 61 -44 91 -44 11 0 19
5 19 10 0 6 9 10 20 10 10 0 24 8 29 18 9 14 10 12 10 -13 1 -75 -103 -126
-146 -72 -26 33 -42 40 -58 27 -13 -11 -15 -7 -15 19 0 17 -4 31 -9 31 -5 0
-11 8 -13 18 -3 10 -22 26 -42 36 -43 20 -46 28 -8 20 19 -4 32 -1 41 10 23
28 5 60 -48 86 -41 21 -46 26 -30 32 10 4 19 12 19 19 0 6 -6 9 -14 6 -20 -8
-43 14 -49 47 -5 28 -4 28 37 22 22 -4 65 -9 94 -13z m1160 -29 c29 -20 44
-50 37 -73 -8 -27 -35 -35 -50 -14 -11 15 -15 15 -29 4 -20 -17 -20 -25 2 -47
16 -17 16 -18 -1 -11 -10 4 -23 7 -28 7 -5 0 -9 11 -9 25 0 21 -5 25 -30 27
-52 2 -55 3 -63 16 -11 17 -11 17 43 28 36 7 46 14 48 32 4 27 45 30 80 6z
m164 -51 c41 -42 58 -79 58 -123 0 -27 -2 -29 -30 -24 -42 9 -86 -12 -100 -47
-14 -34 -30 -38 -30 -9 0 22 -8 34 -54 80 l-30 28 40 7 c55 9 69 18 72 49 2
14 10 26 18 26 23 0 16 29 -13 57 l-28 26 30 -16 c17 -8 47 -33 67 -54z
m-1804 35 c-20 -32 -22 -78 -3 -78 8 0 15 -4 15 -10 0 -5 12 -20 26 -33 17
-16 22 -27 15 -31 -6 -4 -11 -18 -11 -31 0 -13 -5 -25 -10 -27 -6 -2 -12 -14
-15 -27 -6 -31 -15 -40 -15 -14 0 11 -10 33 -22 48 -19 24 -26 27 -65 22 -41
-4 -43 -3 -43 22 1 42 34 103 78 144 48 42 72 49 50 15z m154 -8 c12 -21 25
-30 45 -32 15 0 34 -2 42 -2 8 -1 16 -7 19 -15 4 -11 -3 -13 -28 -8 -28 6 -32
5 -28 -11 8 -30 -27 -46 -56 -27 -17 11 -30 13 -48 6 -72 -27 -65 80 7 110 29
11 28 12 47 -21z m1074 -160 c14 0 27 7 30 15 4 8 10 12 15 9 14 -8 11 -24 -5
-24 -22 0 -86 -62 -86 -82 0 -16 -6 -19 -32 -16 -29 3 -33 0 -36 -22 -5 -35
-20 -50 -49 -50 -53 0 -103 49 -103 101 0 18 2 17 23 -7 21 -24 31 -27 73 -26
27 1 51 7 52 12 2 6 10 10 17 10 22 0 45 29 45 56 0 13 6 33 13 45 l12 20 3
-21 c2 -14 11 -20 28 -20z m296 6 c3 -24 46 -36 58 -16 12 20 30 1 30 -30 0
-43 -50 -61 -72 -26 -4 6 -10 3 -14 -8 -10 -26 28 -56 71 -56 37 0 105 -26
105 -39 0 -5 14 -14 30 -20 17 -5 30 -12 30 -15 0 -4 -40 -6 -90 -6 -89 0 -90
0 -90 25 0 25 -41 55 -74 55 -10 0 -13 6 -10 15 5 12 -3 15 -35 15 -23 0 -41
5 -41 11 0 6 -7 18 -16 26 -14 15 -18 15 -45 -1 -16 -9 -29 -15 -29 -12 0 16
14 36 26 36 17 0 18 12 2 28 -10 10 -8 12 7 10 24 -3 105 22 105 32 0 4 11 5
24 3 16 -3 26 -13 28 -27z m-1237 -20 c19 -8 35 -22 35 -30 0 -8 8 -19 18 -25
16 -9 16 -10 0 -16 -10 -4 -18 -14 -18 -22 0 -11 -7 -13 -29 -8 -20 4 -44 -1
-80 -19 -29 -14 -60 -22 -72 -19 -18 5 -20 3 -14 -16 8 -28 -22 -42 -40 -18
-18 25 -28 21 -23 -8 5 -24 4 -25 -55 -25 l-60 0 37 25 c20 14 41 25 46 25 4
0 10 3 12 8 6 13 69 42 93 42 28 0 59 43 50 70 -4 12 -2 20 4 20 6 0 11 7 11
15 0 19 40 20 85 1z"/>
</g>

    </Svg>
  );
};

export default Icon;
