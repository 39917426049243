import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 2,
    tokenName: 'WFTM',
    stakingTokenName: QuoteToken.BOUJE,
    stakingTokenAddress: '0x37f70aa9fefc8971117bd53a1ddc2372aa7eec41',
    contractAddress: {
      250: '0x5BE35C02996320688F9E5968148dE5bC31635f15'
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://bouje.finance/',
    harvest: true,
    tokenPerBlock: '0.003',
    sortOrder: 1,
    isFinished: true, 
    tokenDecimals: 18,
  }
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
